import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getProduct, updateProduct } from '../../../actions/Product';
import ProductForm from './ProductForm';
import wrapProductEditor from './productEditorWrapper';
import PageHeader from '../../Common/PageHeader';

class UpdateProductContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };
    this.returnUrl =
      props.location.state && props.location.state.returnUrl
        ? props.location.state.returnUrl
        : '/products';
  }

  updateError = (err) => {
    this.setState({ error: err });
  };

  getProductIdFromProps(props) {
    const { match } = props;
    const productId = match.params.productId;
    return productId ? parseInt(productId, 10) : undefined;
  }

  getProductFromProps(props) {
    const productId = this.getProductIdFromProps(props);
    if (productId === undefined) return undefined;

    const product = props.products[productId];
    return product;
  }

  async componentDidMount() {
    const productId = this.getProductIdFromProps(this.props);
    await this.props.getProduct(productId);

    const product = this.getProductFromProps(this.props);
    this.props.setStateFromProduct(product);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getProductIdFromProps(this.props);
    const newId = this.getProductIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getProduct(newId);

      const product = this.getProductFromProps(nextProps);
      this.props.setStateFromProduct(product);
    }
  }

  saveProduct = async (productId, editorInfo) => {
    try {
      this.props.validateEditorInfo(editorInfo);
    } catch (err) {
      this.setState({ error: err });
      return;
    }

    const exportable = this.props.exportInfo(editorInfo);

    try {
      await this.props.updateProduct(productId, exportable);
      if (this.returnUrl) {
        this.props.history.push(this.returnUrl);
      } else {
        this.props.history.push('/products');
      }
    } catch (err) {
      this.setState({ error: err });
    }
  };

  render() {
    const productId = this.getProductIdFromProps(this.props);

    return (
      <div className="create-product-container">
        <PageHeader title="Products" subtitle="Update Product" />
        <ProductForm
          productId={productId}
          saveProduct={this.saveProduct}
          editorInfo={this.props.editorInfo}
          error={this.state.error}
          currentUser={this.props.currentUser}
          updateError={this.updateError}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.Product.productDict,
    currentUser: state.Account.currentUser
  };
};

const actions = { getProduct, updateProduct };

UpdateProductContainer = withRouter(
  wrapProductEditor(
    connect(
      mapStateToProps, // connect component props to application (redux) state
      actions // these actions are automatically wrapped in a dispatch by connect
    )(UpdateProductContainer)
  )
);

export default UpdateProductContainer;
