import React, { Component } from "react";
import { connect } from "react-redux";
import DistrictSelector
  from "../Common/DistrictSelector/DistrictSelectorContainer";
import PageHeader from "../Common/PageHeader";

class InventoryDistrictSelector extends Component {
  onSelect = district => {
    this.props.history.push(`/districts/${district.id}/inventory`);
  };

  render() {
    return (
      <div>
        <PageHeader title="District Inventory" subtitle="Choose a Region" />
        <DistrictSelector onSelect={this.onSelect} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = {};

InventoryDistrictSelector = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(InventoryDistrictSelector);

export default InventoryDistrictSelector;
