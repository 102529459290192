import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import SingleRegionContainer from "./SingleRegion/SingleRegionContainer";
import UpdateRegionContainer from "./RegionEditor/UpdateRegionContainer";
import NewRegionContainer from "./RegionEditor/NewRegionContainer";
import RegionListContainer from "./RegionList/RegionListContainer";

class RegionContainer extends Component {
  render() {
    const { match } = this.props;

    return (
      <div className="region-container">
        <Switch>
          <Route path={`${match.url}/create`} component={NewRegionContainer} />
          <Route
            path={`${match.url}/:regionId/update`}
            component={UpdateRegionContainer}
          />
          <Route
            path={`${match.url}/:regionId`}
            component={SingleRegionContainer}
          />
          <Route path={match.url} component={RegionListContainer} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = {};

RegionContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(RegionContainer);

export default RegionContainer;
