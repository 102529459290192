import React from 'react';
import DeletionButton from "../Common/DeletionButton";

const InvalidateAllPasswords = ({update}) => {
  return(
    <div className="col-sm-3">
      <p className="lead">Invalidate Passwords</p>
      <p><strong>Click here to invalidate all passwords across the app</strong></p>
      <div className="form-group">
        <DeletionButton
          buttonText="Invalidate Passwords"
          className="btn btn-danger btn-lg"
          title="Are you Sure?"
          body="This will invalidate all users' passwords"
          confirmButtonText="Invalidate Passwords"
          onConfirm={() => {
            update()
          }}
        />
      </div>
    </div>
  );
};

export default InvalidateAllPasswords;