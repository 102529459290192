import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getDistrict, createDistrict } from "../../../actions/District";
import DistrictForm from "./DistrictForm";
import { getRegionList, getAttachmentTypes } from "../../../actions/Options";
class NewDistrictContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      name: "",
      region_id: "",
      district_attachments: [],
      show_in_product_profile_listing: false,
      hide_vendor_public_privacy_si: false,
      hide_service_providers_si: false,
    };
  }

  async componentDidMount() {
    await this.props.getRegionList();
    this.setStateFromRegions(this.props.regions);
    this.props.getAttachmentTypes();
  }

  setStateFromRegions(regions) {
    const keys = Object.keys(regions);

    this.setState({
      region_id: keys[0]
    });
  }

  saveDistrict = async (districtId, name, region_id) => {
    if (!name) {
      this.setState({ error: "You must provide a district name" });
      return;
    }

    try {
      const newDistrict = await this.props.createDistrict({ ...this.state });
      this.props.history.push(`/districts/${newDistrict.id}`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  updateName = name => {
    this.setState({ name });
  };

  updateRegionId = region_id => {
    this.setState({ region_id });
  };

  updateShowInProductProfileListing = () => {
    this.setState({show_in_product_profile_listing: !this.state.show_in_product_profile_listing});
  }

  updateHideVendorPublicPrivacySi = () => {
    this.setState({hide_vendor_public_privacy_si: !this.state.hide_vendor_public_privacy_si});
  }

  updateHideServiceProvidersSi = () => {
    this.setState({hide_service_providers_si: !this.state.hide_service_providers_si});
  }

  removeAttachment = attachment => {
    this.setState({
      district_attachments: this.state.district_attachments.filter(x => x !== attachment)
    });
  };

  updateAttachment = attachment => {
    this.setState({
      district_attachments: this.state.district_attachments.map(x => {
        return x.id === attachment.id
          ? {
              ...attachment,
              name: attachment.name || attachment.type
            }
          : { ...x };
      })
    });
  };

  addAttachment = newAttachment => {
    this.setState({
      district_attachments: this.state.district_attachments.concat({
        ...newAttachment,
        name: newAttachment.name || newAttachment.type
      })
    });
  };

  render() {
    const { name, error, region_id, show_in_product_profile_listing, hide_vendor_public_privacy_si, hide_service_providers_si } = this.state;
    if (!region_id) return <div>Loading...</div>;
    if (!this.props.regions) return <div>Loading...</div>;

    return (
      <div className="update-district-container">
        <DistrictForm
          error={error}
          districtId={null}
          regionId={region_id}
          name={name}
          isDistrictAdmin={false}
          showInProductProfileListing={show_in_product_profile_listing}
          hideVendorPublicPrivacySi={hide_vendor_public_privacy_si}
          hideServiceProvidersSi={hide_service_providers_si}
          updateName={this.updateName}
          updateRegionId={this.updateRegionId}
          updateShowInProductProfileListing={this.updateShowInProductProfileListing}
          updateHideVendorPublicPrivacySi={this.updateHideVendorPublicPrivacySi}
          updateHideServiceProvidersSi={this.updateHideServiceProvidersSi}
          regions={this.props.regions}
          saveDistrict={this.saveDistrict}
          attachments={this.state.district_attachments}
          addAttachment={this.addAttachment}
          updateAttachment={this.updateAttachment}
          removeAttachment={this.removeAttachment}
          attachmentTypes={this.props.attachmentTypes}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    regions: state.Options.regions,
    attachmentTypes: state.Options.attachmentTypes
  };
};

const actions = {
  getDistrict,
  createDistrict,
  getRegionList,
  getAttachmentTypes
};

NewDistrictContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(NewDistrictContainer)
);

export default NewDistrictContainer;
