import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import ClassificationListContainer from "./ClassificationList/ClassificationListContainer";
import UpdateClassificationContainer from "./ClassificationEditor/UpdateClassificationContainer";
import NewClassificationContainer from "./ClassificationEditor/NewClassificationContainer";


class ClassificationContainer extends Component {
  render() {
    const { match } = this.props;

    return (
      <div className="classification-container">
        <Switch>
          <Route path={`${match.url}/create`} component={NewClassificationContainer} />
          <Route path={`${match.url}/:classificationId/update`} component={UpdateClassificationContainer} />
          <Route path={match.url} component={ClassificationListContainer} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = {};

ClassificationContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(ClassificationContainer);

export default ClassificationContainer;
