import React, { Component } from "react";
import { connect } from "react-redux";
import { uploadAttachmentFile } from "../../../actions/Attachment";
import NewAttachmentPanel from "./NewAttachmentPanel";

class NewAttachmentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      name: "",
      uri: "",
      description: "",
      show_in_bor: false,
      error: ""
    };
  }

  handleChange = e => {
    e.preventDefault();

    const field = e.target.name;
    const value = e.target.value;
    this.setState({ [field]: value }, () =>  {
      // Update name to type if it hasnt been entered yet
      if(field === 'type') {
        this.handleTypeChange(value);
      }
    });
  };

  handleTypeChange = newType => {
    if(this.state.name === '' || this.state.name === null || this.props.attachmentTypes.includes(this.state.name)) {
      this.setState({name: newType});
    }
  };

  saveAttachment = () => {
    this.props.saveAttachment({
      name: this.state.name,
      type: this.state.type,
      uri: this.state.uri,
      description: this.state.description,
      show_in_bor: this.state.show_in_bor
    });

    this.setState({
      type: "",
      name: "",
      uri: "",
      description: "",
      error: "",
      show_in_bor: false
    });
  };

  uploadFile = async e => {
    try {
      const uri = await this.props.uploadAttachmentFile(e.target.files[0]);
      this.setState({ uri, error: '' });
      this.props.updateError('');
    } catch (err) {
      this.setState({ error: err });
      this.props.updateError(err);
    }
  };

  render() {
    const state = this.state;

    if (!this.props.attachmentTypes) {
      return <div />;
    }

    return (
      <NewAttachmentPanel
        {...this.props}
        {...state}
        saveAttachment={this.saveAttachment}
        uploadFile={this.uploadFile}
        handleChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = { uploadAttachmentFile };

NewAttachmentContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(NewAttachmentContainer);

export default NewAttachmentContainer;
