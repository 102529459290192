const initialState = {
  searchResults: null,
  companyDict: {},
  companyOptions: null
};

const Company = (state = initialState, action) => {
  switch (action.type) {
    case "COMPANY_SEARCH_RECEIVED":
      return { ...state, searchResults: action.companies };
    case "COMPANY_RECEIVED":
      return {
        ...state,
        companyDict: {
          ...state.companyDict,
          [action.companyId]: action.company
        }
      };
    case "COMPANY_DELETED":
      const newCompanyDict = { ...state.companyDict };
      delete newCompanyDict[action.companyId];

      return {
        ...state,
        companyDict: newCompanyDict
      };
    default:
      return state;
  }
};

export default Company;
