import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getServiceProvider, deleteServiceProvider } from "../../../actions/ServiceProvider";
import SingleServiceProvider from "./SingleServiceProvider";
import DeletionButton from "../../Common/DeletionButton";
import PageHeader from "../../Common/PageHeader";
import {Link} from "react-router-dom";

const SingleServiceProviderContainer = (props) => {
    const [serviceProvider, setServiceProvider] = useState(null);

    const getServiceProviderIdFromProps = () => {
        const { match } = props;
        const serviceProviderId = match.params.serviceProviderId;
        return serviceProviderId ? parseInt(serviceProviderId, 10) : undefined;
    };

    useEffect(() => {
      const fetchData = async () => {
        const serviceProviderId = getServiceProviderIdFromProps();
        const result = await getServiceProvider(serviceProviderId);
        setServiceProvider(result);
      }
    
      fetchData()
        .catch(console.error);
    }, []);

    const handleDelete = async () => {
        await deleteServiceProvider(serviceProvider.id);

        const newPath = `/districts/${serviceProvider.district.id}/service-providers`;
        props.history.push(newPath);
    }

    const serviceProviderDeleteButton = serviceProvider && !!serviceProvider.user_can_delete ? (
        <DeletionButton
            onConfirm={() => {
                handleDelete();
            }}
            className="btn btn-danger deletion-link"
            buttonText="Remove Service Provider"
        />
    ) : null;

    const serviceProviderEditButton = serviceProvider && !!serviceProvider.user_can_edit ? (
        <Link
            to={`/service-provider/${serviceProvider.id}/update`}
            className="btn btn-warning"
        >
            Edit
        </Link>
    ) : null;

    return (
        (!serviceProvider ? (<div>Loading...</div>) : 
            <div className="">
                <PageHeader title="Service Provider" subtitle="View Service Provider" />
                <div className="text-right form-group">
                    {serviceProviderEditButton}
                    {" "}
                    {serviceProviderDeleteButton}
                </div>

                <SingleServiceProvider
                    serviceProvider={serviceProvider}
                />
            </div>
        )
    );
}

const mapStateToProps = (props) => ({
    currentUser: props.Account.currentUser
});

const mapDispatchToProps = {
    getServiceProvider
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleServiceProviderContainer);