import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createDelegate } from "../../../actions/Delegate";
import PageHeader from "../../Common/PageHeader";
import DelegateForm from "./DelegateForm";
import { getInventoryRecord } from "../../../actions/InventoryRecord";

class NewDelegateContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      name: "",
      location: "",
      authorities: []
    };
  }

  saveDelegate = async () => {
    const state = this.state;
    const { inventoryId } = this.props.match.params;

    if (state.authorities.length === 0) {
      this.setState({ error: "You must select at least one authority" });
      return;
    }

    try {
      await this.props.createDelegate(inventoryId, {
        ...this.state
      });

      this.props.history.push(`/inventory/${inventoryId}`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  async componentDidMount() {
    const { inventoryId } = this.props.match.params;
    this.props.getInventoryRecord(inventoryId);
  }

  selectAuthority = authorityId => {
    this.setState({
      authorities: [...this.state.authorities, authorityId]
    });
  };

  removeAuthority = authorityId => {
    const currentAuths = this.state.authorities;
    const newAuthorities = currentAuths.filter(x => x !== authorityId);

    this.setState({ authorities: newAuthorities });
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  async componentWillReceiveProps(nextProps) {
    const oldInventoryId = this.props.match.params.inventoryId;
    const { inventoryId } = nextProps.match.params;

    if (oldInventoryId !== inventoryId) {
      this.props.getInventoryRecord(inventoryId);
      return;
    }

    const oldRecord = this.props.records[inventoryId] || {};
    const record = nextProps.records[inventoryId] || {};
    if (oldRecord.id !== record.id) {
      // record changed
      const authorities = record.authorities;

      this.setState({
        authorities: authorities.map(x => x.id)
      });
    }
  }

  render() {
    const { inventoryId } = this.props.match.params;
    const record = this.props.records[inventoryId] || {};
    const possibleAuthorities = record.authorities || [];

    if (!possibleAuthorities) {
      return <div>Loading...</div>;
    }

    return (
      <div className="new-delegate-container">
        <PageHeader title="District Inventory" subtitle="Add Delegate" />
        <DelegateForm
          {...this.state}
          handleChange={this.handleChange}
          possibleAuthorities={possibleAuthorities}
          selectAuthority={this.selectAuthority}
          removeAuthority={this.removeAuthority}
          saveDelegate={this.saveDelegate}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    records: state.InventoryRecord.inventoryRecordDict
  };
};

const actions = {
  getInventoryRecord,
  createDelegate
};

NewDelegateContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(NewDelegateContainer)
);

export default NewDelegateContainer;
