import React, { Component } from "react";
import { withRouter } from "react-router";
import DistrictList from "./DistrictList";
import { connect } from "react-redux";
import { searchForDistricts, deleteDistrict, impersonateDistrictAdmin } from "../../../actions/District";
import qs from "qs";

class DistrictListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ""
    };
  }

  componentDidMount() {
    const page = this.getCurrentPageFromProps(this.props);
    const search = this.getSearchFromProps(this.props);

    this.setState({
      query: search
    });
    this.props.searchForDistricts(page, search);
  }

  componentWillReceiveProps(nextProps) {
    const oldPage = this.getCurrentPageFromProps(this.props);
    const newPage = this.getCurrentPageFromProps(nextProps);
    const pageChanged = oldPage !== newPage;

    const oldSearch = this.getSearchFromProps(this.props);
    const newSearch = this.getSearchFromProps(nextProps);
    const searchChanged = oldSearch !== newSearch;


    if (pageChanged || searchChanged) {
      this.props.searchForDistricts(newPage, newSearch);
    }
  }

  getSearchFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    return parsedSearch.query || "";
  }

  updateQuery = query => {
    this.setState({ query });
  };

  performSearch = query => {
    const { match, location } = this.props;

    const search = location.search ? location.search.slice(1) : "";
    if (query) {

      const parsedSearch = qs.parse(search);

      let newSearch = {
        ...parsedSearch,
        query,
        page: 1
      };

      const newRoute = {
        pathname: match.url,
        search: qs.stringify(newSearch)
      };

      this.props.history.push(newRoute);
    } else {
      const parsedSearch = qs.parse(search);

      let newSearch = {
        ...parsedSearch,
        query: "",
        page: 1
      };

      delete newSearch.query;

      const newRoute = {
        pathname: match.url,
        search: qs.stringify(newSearch)
      };

      this.props.history.push(newRoute);
    }
  };

  getCurrentPageFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    if (parsedSearch.page !== undefined) {
      return parseInt(parsedSearch.page, 10);
    }

    return 1;
  }

  changePage = page => {
    const { match, location } = this.props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    const newSearch = { ...parsedSearch, page };

    const newRoute = {
      pathname: match.url,
      search: qs.stringify(newSearch)
    };

    this.props.history.push(newRoute);
  };

  deleteDistrict = async districtId => {
    const page = this.getCurrentPageFromProps(this.props);

    await this.props.deleteDistrict(districtId);
    this.props.searchForDistricts(page);
  };

  render() {
    const currentPage = this.getCurrentPageFromProps(this.props);

    const searchResult = this.props.districtSearchResults;
    const districtList = searchResult ? searchResult.data : null;
    const totalPages = searchResult ? searchResult.last_page : 0;

    return (
      <DistrictList
        districts={districtList}
        currentPage={currentPage}
        changePage={this.changePage}
        totalPages={totalPages}
        deleteDistrict={this.deleteDistrict}
        query={this.state.query}
        updateQuery={this.updateQuery}
        performSearch={this.performSearch}
        isRegionAdmin={this.props.isRegionAdmin}
        impersonateDistrictAdmin={this.props.impersonateDistrictAdmin}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    districtSearchResults: state.District.searchResults,
    isRegionAdmin: state.Account.currentUser.user_type === 'REGION_ADMINISTRATOR',
  };
};

const actions = {
  searchForDistricts,
  deleteDistrict,
  impersonateDistrictAdmin,
};

DistrictListContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(DistrictListContainer)
);

export default DistrictListContainer;
