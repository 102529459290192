import React, { useState } from "react";
import { connect } from "react-redux";
import { uploadAttachmentFile2 } from "../../../actions/Attachment";
import NewAttachmentPanel from "./NewAttachmentPanel";

const NewAttachmentContainer = ({
    saveAttachment,
    updateError,
    updateValue,
    checkboxText,
    characterLimit
}) => {
    const initialForm = {
        error: "",
        maxDescriptionLength: false,
        name: "",
        description: "",
        showInSupplementalInformation: false,
        uri: "",
        id: "",
    }

    const [formData, setFormData] = useState(initialForm);

    const handleChange = e => {
        e.preventDefault();
        const field = e.target.name;
        const value = e.target.value;
        if(field === 'description' && value.length >= characterLimit) {
            setFormData((oldFormData) => ({ ...oldFormData, [field]: value, maxDescriptionLength: true }));
        } else {
            setFormData((oldFormData) => ({ ...oldFormData, [field]: value, maxDescriptionLength: false }));
        }
    };

    const handleSaveAttachment = () => {
        saveAttachment({
            name: formData.name,
            uri: formData.uri,
            description: formData.description,
            showInSupplementalInformation: formData.showInSupplementalInformation
        });

        setFormData(initialForm);
    };

    const uploadFile = async e => {
        try {
            const uri = await uploadAttachmentFile2(e.target.files[0]);
            setFormData((oldFormData) => ({ ...oldFormData, uri, error: '' }));
            updateError('');
        } catch (err) {
            setFormData((oldFormData) => ({ ...oldFormData, error: err }));
            updateError(err);
        }
    };

    return (
            <NewAttachmentPanel
                handleChange={handleChange}
                name={formData.name}
                uri={formData.uri}
                description={formData.description}
                maxDescriptionLength={formData.maxDescriptionLength}
                showInSupplementalInformation={formData.showInSupplementalInformation}
                saveAttachment={handleSaveAttachment}
                uploadFile={uploadFile}
                checkboxText={checkboxText}
                characterLimit={characterLimit}
            />
    )
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    uploadAttachmentFile2
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAttachmentContainer);