const initialState = {
  searchResults: null,
  delegateDict: {},
  delegates: null
};

const Delegate = (state = initialState, action) => {
  switch (action.type) {
    case "DELEGATE_SEARCH_RECEIVED":
      return { ...state, searchResults: action.delegates };
    case "DELEGATE_RECEIVED":
      return {
        ...state,
        delegateDict: {
          ...state.delegateDict,
          [action.delegateId]: action.delegate
        }
      };
    case "DELEGATE_DELETED":
      const newDelegateDict = { ...state.delegateDict };
      delete newDelegateDict[action.delegateId];

      return {
        ...state,
        delegateDict: newDelegateDict
      };
    default:
      return state;
  }
};

export default Delegate;
