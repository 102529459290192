import apiInstance from "../utils/apiInstance";

export const uploadAttachmentFile = file => async (dispatch, getState) => {
  const url = `upload-attachment`;

  const data = new FormData();
  data.append("file", file);

  const response = await apiInstance.post(url, data);
  return response.data;
};

export const uploadAttachmentFile2 = async (file) => {
  const url = `upload-attachment`;

  const data = new FormData();
  data.append("file", file);

  const response = await apiInstance.post(url, data);
  return response.data;
}
