import React from "react";
import { UnmountClosed } from "react-collapse";
import ServiceProviderAttachmentRow from "./ServiceProviderAttachmentRow";

export default ({
    serviceProvider,
    isOpened,
    allowShow
}) => {
    return (
        <tr className="service-provider-attachment-row">
            <td colSpan="3"></td>
            <td colSpan="2">
                <UnmountClosed isOpened={isOpened}>
                    <table className="table table-condensed">
                        <thead>
                            <tr>
                                <th>Document Name</th>
                                <th>Show In Supplemental Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                serviceProvider.attachments.length ? serviceProvider.attachments.map(attachment => {
                                    return (
                                        <ServiceProviderAttachmentRow
                                            key={attachment.id}
                                            serviceProvider={serviceProvider}
                                            attachment={attachment}
                                            allowShow={allowShow}
                                        />
                                    );
                                }) :
                                    <tr>
                                        <td colSpan="3">
                                            <p className="text-center">No Attachments Found</p>
                                        </td>
                                    </tr>

                            }
                        </tbody>
                    </table>
                </UnmountClosed>
            </td>
        </tr>
    );
};