import React from "react";
import { Link } from "react-router-dom";
import userTypes from "../../../utils/userTypes";
import DeletionButton from "../../Common/DeletionButton";

const UserRow = ({ currentUserIsDistrictAdmin, currentUserIsDistrictSystemAdmin, user, deleteUser }) => {
  const userTypeSplit = user.user_type.split("_");
  
  const hideDistrictAdminLinks = (currentUserIsDistrictAdmin && (user.user_type === userTypes.districtAdmin || user.user_type === userTypes.districtSystemAdmin)) ? true : currentUserIsDistrictSystemAdmin && user.user_type === userTypes.districtSystemAdmin ? true : false;

  const userTypeJoin = [];
  userTypeSplit.forEach(word => {
      userTypeJoin.push(word.charAt(0) + word.slice(1).toLowerCase())
  });
  const userType = userTypeJoin.join(" ");

  const district = user => {
    switch (user.user_type) {
      case userTypes.districtAdmin:
      case userTypes.districtSystemAdmin:
      case userTypes.dataAdmin:
        return (
          <Link to={`/districts/${user.entity_id}/`}>{user.entity.regionName} - {user.entity.name}</Link>
        );
      case userTypes.companyAdmin:
        return (
          <Link to={`/companies/${user.entity_id}/`}>{user.entity.name}</Link>
        );
      case userTypes.regionAdmin:
        const regionList = user.regions.map(region => {
          return (
            <li key={region.id}>
              <Link to={`/regions/${region.id}/`}>{region.name}</Link>
            </li>
          );
        });

        return <ul className="list-unstyled">{regionList}</ul>;

      default:
        return null;
    }
  };

  return (
    <tr>
      <td>
        {hideDistrictAdminLinks ? (
          <span>
            {user.last_name}, {user.first_name}
          </span>
        ) : (
          <Link to={`/users/${user.id}`}>
            {user.last_name}, {user.first_name}
          </Link>
        )}
      </td>
      <td>{district(user)}</td>
      <td>{user.email}</td>
      <td>{userType}</td>
      {hideDistrictAdminLinks ? (
        <td />
      ) : (
        <td className="text-right">
          <Link
            to={`/users/${user.id}/update`}
            className="btn btn-xs btn-warning"
          >
            Edit
          </Link>{" "}
          <DeletionButton
            onConfirm={() => {
              deleteUser(user.id);
            }}
          />
        </td>
      )}
    </tr>
  );
};

export default UserRow;
