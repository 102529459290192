export const compareInventoryRecordNames = (record1, record2) => {
  return compareStrings(record1.product.name, record2.product.name);
}

export const compareServiceProviderNames = (record1, record2) => {
  return compareStrings(record1.name, record2.name);
}

const compareStrings = (name1, name2) => {
  const nameA = name1.toUpperCase(); // ignore upper and lowercase
  const nameB = name2.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};
