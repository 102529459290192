import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import SingleDelegateContainer from "./SingleDelegate/SingleDelegateContainer";
import UpdateDelegateContainer from "./DelegateEditor/UpdateDelegateContainer";
import NewDelegateContainer from "./DelegateEditor/NewDelegateContainer";

class DelegateContainer extends Component {
  render() {
    const { match } = this.props;

    return (
      <div className="delegate-container">
        <Switch>
          <Route path={`${match.url}/create`} component={NewDelegateContainer} />
          <Route
            path={`${match.url}/:delegateId/update`}
            component={UpdateDelegateContainer}
          />
          <Route
            path={`${match.url}/:delegateId`}
            component={SingleDelegateContainer}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = {};

DelegateContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(DelegateContainer);

export default DelegateContainer;
