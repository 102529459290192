import React, { useState } from "react";
import { connect } from "react-redux";
import SupplementalInformationListContainer from "./SupplementalInformationList/SupplementalInformationListContainer";
import PageHeader from "../Common/PageHeader";
import ServiceProvidersListContainer from "../ServiceProviders/ServiceProvidersList/ServiceProvidersListContainer";
import '../../sass/SupplementalInformation.css';

const SupplementalInformationParentContainer = (props) => {
    const [selectedTab, setSelectedTab] = useState("supplemental");
    const [districtName, setDistrictName] = useState("");
    const [hideServiceProviders, setHideServiceProviers] = useState(false);
    const updateSelectedTab = tab => {
        setSelectedTab(tab);
    };

    const handleNameUpdate = (name) => {
        setDistrictName(name);
    };

    const updateHideServiceProviders = (show) => {
        setHideServiceProviers(show);
    }

    return (
        <div>
            <div className="supplemental-information-container">
            {!districtName ? <span></span> :
                <span>
                <PageHeader
                    title={districtName ? districtName : ""}
                    subtitle="Supplemental Information"
                />
                {props.hasServiceProviders && !hideServiceProviders ? <div className="row">
                    <div className="col-sm-12">
                        <div>
                            <ul className="nav nav-tabs nav-justified">
                                <li role="presentation" className={ selectedTab === 'supplemental' ? 'active' : ''} onClick={() => updateSelectedTab('supplemental')}>
                                    <a role="button">Products</a>
                                </li>
                                 <li role="presentation" className={selectedTab === 'providers' ? 'active' : ''} onClick={() => updateSelectedTab('providers')}>
                                    <a role="button">Service Providers</a>
                                </li>
                            </ul>
                            <br />
                        </div>
                    </div>
                </div> : null}
                </span>}
                <SupplementalInformationListContainer setDistrictName={handleNameUpdate} setHideServiceProviders={updateHideServiceProviders} parentDistrictName={districtName} hidden={selectedTab !== "supplemental"} />
                <ServiceProvidersListContainer {...props} hidden={selectedTab === "supplemental"} onSupplementalInformationPage={true}/>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        hasServiceProviders: Object.values(state.ServiceProvider.serviceProviders).some(serviceProviderList => serviceProviderList.length > 0)
    }
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplementalInformationParentContainer);
