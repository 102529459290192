import {compareServiceProviderNames} from "../utils/sortFunctions";

const initialState = {
    searchResults: [],
    serviceProviders: [],
    pagination: {
      current_page: 1,
      last_page: 1
    },
    districtName: ""
};

const ServiceProvider = (state = initialState, action) => {
    switch(action.type) {
        case "SERVICE_PROVIDERS_RECEIVED":
            return {...state, searchResults: action.serviceProviders};
        case "SERVICE_PROVIDERS_RESULTS_RECEIVED":
            return {
                ...state,
                serviceProviders: {
                    ...state.serviceProviders,
                    [action.districtId]: action.records
                },
                pagination: action.pagination,
                districtName: action.districtName
            };
        case "SERVICE_PROVIDERS_RESULTS_APPEND_RECEIVED":
            return {...state,
                serviceProviders: {
                    ...state.serviceProviders,
                    [action.districtId]: [...state.serviceProviders[action.districtId], ...action.records].sort(compareServiceProviderNames)},
                    pagination: action.pagination,
                    districtName: action.districtName};
        default:
            return state;
    }
};


export default ServiceProvider;
