const initialState = {
  inventoryRecordDict: {}
};

const District = (state = initialState, action) => {
  switch (action.type) {
    case "INVENTORY_RECORD_RECEIVED":
      return {
        ...state,
        inventoryRecordDict: {
          ...state.inventoryRecordDict,
          [action.inventoryRecordId]: action.inventoryRecord
        }
      };
    case "INVENTORY_RECORD_DELETED":
      const newDistrictDict = { ...state.inventoryRecordDict };
      delete newDistrictDict[action.inventoryRecordId];

      return {
        ...state,
        inventoryRecordDict: newDistrictDict
      };
    case "DELEGATE_DELETED":
      const inventoryRecordId = action.inventoryRecordId;
      const currentEntry = state.inventoryRecordDict[inventoryRecordId];

      return {
        ...state,
        inventoryRecordDict: {
          [inventoryRecordId]: {
            ...currentEntry,
            delegates: currentEntry.delegates.filter(
              x => x.id !== action.delegateId
            )
          }
        }
      };
    default:
      return state;
  }
};

export default District;
