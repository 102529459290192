import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateDistrictInventoryRecordAttachmentBOR } from '../../../actions/District';

const DistrictInventoryAttachmentRow = ({
  attachment,
  allowShowInBOR,
  updateDistrictInventoryRecordAttachmentBOR,
  updateExpandedAttachmentId,
  borDisabled,
  isArchived,
}) => {
  const [isChecked, setIsChecked] = useState(!!attachment.show_in_bor);

  const updateShowInBOR = () => {
    const updatedValue = !isChecked;
    setIsChecked(updatedValue);
    updateDistrictInventoryRecordAttachmentBOR(attachment, updatedValue);
  };

  return (
    <tr>
      <td>
        <a href={attachment.uri} target="_blank">
          {attachment.name}
        </a>
      </td>
      <td>
        <input
          type="checkbox"
          disabled={!allowShowInBOR || borDisabled}
          checked={isChecked}
          onChange={updateShowInBOR}
        />
      </td>
      <td>
        <Button bsStyle="warning" onClick={() => updateExpandedAttachmentId(attachment.id)} disabled={isArchived}>
          Edit
        </Button>
      </td>
    </tr>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateDistrictInventoryRecordAttachmentBOR
};

export default connect(mapStateToProps, mapDispatchToProps)(DistrictInventoryAttachmentRow);
