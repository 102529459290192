export const serverToClientProduct = product => {
  return {
    ...product,
    other: product.other || "",
    educationalAgency: product.educational_agency,
    privacyPledge: product.privacy_pledge,
    // data_classifications: [...product.data_classifications],
    attachments: [...product.attachments],
  };
};

export const clientToServerProduct = product => {
  return {
    ...product,
    educational_agency: product.educationalAgency,
    privacy_pledge: product.privacyPledge,
    // data_classifications: product.data_classifications.map(x => x.id),
    product_attachments: product.attachments,
  };
};
