import config from "../config";
import axios from "axios";
import store from "../store";
import { clearAuthKey } from "./cookieHelper";

// Get your authentication token before each request
const getAuthToken = () => {
  const state = store.getState();

  return state.Account.authKey;
};

let apiInstance = axios.create({
  baseURL: config.apiUrl
});

// Set config defaults when creating the instance

// Add a request interceptor
apiInstance.interceptors.request.use(config => {
  if (!config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${getAuthToken()}`;
  }

  return config;
});

// Add a response interceptor
apiInstance.interceptors.response.use(null, error => {
  if (
    error.response &&
      (error.response.status === 403 || error.response.status === 401)
  ) {
    clearAuthKey();
    store.dispatch({ type: "API_TOKEN_INVALID" });
  }

  if (error.response && error.response.data) {
    return Promise.reject(error.response.data);
  }

  return Promise.reject(error);
});

export default apiInstance;
