import React from 'react';

export default () => {
  return (
    <div>
      <p>
        All passwords must:
      </p>
      <ul>
        <li>Be at least 8 characters long</li>
        <li>Include a capital letter</li>
        <li>Include a lowercase letter</li>
        <li>Include a number</li>
        <li>Include at least one of the following characters: @ $ ! % * ? &</li>
      </ul>
    </div>
  );
}
