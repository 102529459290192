import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getDelegate, deleteDelegate } from "../../../actions/Delegate";
import SingleDelegate from "./SingleDelegate";

class SingleDelegateContainer extends Component {
  getDelegateIdFromProps(props) {
    const { match } = props;
    const delegateId = match.params.delegateId;
    return delegateId ? parseInt(delegateId, 10) : undefined;
  }

  async componentDidMount() {
    const delegateId = this.getDelegateIdFromProps(this.props);
    await this.props.getDelegate(delegateId);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getDelegateIdFromProps(this.props);
    const newId = this.getDelegateIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getDelegate(newId);
    }
  }

  deleteDelegate = async delegateId => {
    await this.props.deleteDelegate(delegateId);
    this.props.history.push("/delegates");
  };

  render() {
    const delegateId = this.getDelegateIdFromProps(this.props);
    const delegate = this.props.delegates[delegateId];
    if (!delegate) return <div>Loading...</div>;

    return (
      <SingleDelegate delegate={delegate} deleteDelegate={this.deleteDelegate} />
    );
  }
}

const mapStateToProps = state => {
  return { delegates: state.Delegate.delegateDict };
};

const actions = { getDelegate, deleteDelegate };

SingleDelegateContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(SingleDelegateContainer)
);

export default SingleDelegateContainer;
