import React, {Component} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";

import PasswordExpiration from "./PasswordExpiration";
import InvalidateAllPasswords from "./InvalidateAllPasswords";
import renderFor from "../../utils/renderFor";
import PageHeader from "../Common/PageHeader";
import {getSecurityOptions, updatePasswordPolicyExpiration, invalidatePasswords} from "../../actions/Options";
import renderIf from "../../utils/renderIf";
import SuccessAlert from "../Common/SuccessAlert";
import ErrorAlert from "../Common/ErrorAlert";

class SecuritySettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      error: null
    };
  }

  componentDidMount() {
    this.props.getSecurityOptions();
  }

  updatePasswordExpiration = async password_policy_expiration => {
    const result = await this.props.updatePasswordPolicyExpiration(password_policy_expiration);

    if(result) {
      this.setState({
        message: "Successfully updated password expiration"
      })
    } else {
      this.setState({
        error: result.error
      })
    }
  };

  invalidatePasswords = async () => {
    this.clearStatus();
    const result = await this.props.invalidatePasswords();

    if(result) {
      this.setState({
        message: "Passwords Successfully Invalidated"
      })
    } else {
      this.setState({
        error: result.error
      })
    }
  };

  clearStatus = () => {
    this.setState({
      message: null,
      error: null
    });
  };

  render() {
    return renderFor(
      {},
      () => (
        <div>
          <PageHeader title="Security Settings"/>

          <SuccessAlert message={this.state.message}/>
          <ErrorAlert message={this.state.error} />

          {renderIf(this.props.security_options, () => (
            <div>
              <div className="row">
                <PasswordExpiration
                  passwordExpirationDays={this.props.security_options.password_policy_expiration}
                  update={this.updatePasswordExpiration}
                />
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <hr/>
                </div>
              </div>
              <div className="row">
                <InvalidateAllPasswords update={this.invalidatePasswords}/>
              </div>
            </div>
          ))}
        </div>
      )
    )
  }
}

const mapStateToProps = state => {
  return {security_options: state.Options.securityOptions};
};

const actions = { getSecurityOptions, updatePasswordPolicyExpiration, invalidatePasswords };

SecuritySettingsContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(SecuritySettingsContainer)
);

export default SecuritySettingsContainer;
