import React from "react";
import SuccessAlert from "../../Common/SuccessAlert";

const ImportedRecordsTable = ({ records }) => {
    const importedRecordRows = records ? records.map(record => (
        <tr key={record.id}>
            <td>{record.product_name}</td>
        </tr>
    )) : null;

    return (
        <div className="imported-records-table-container">
            <div className="row">
                <div className="col-sm-12">
                    <SuccessAlert message="Import Success" />
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Product Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {importedRecordRows}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )

}

export default ImportedRecordsTable;