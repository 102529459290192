import React from "react";
import { Alert } from "react-bootstrap";

const SuccessAlert = ({ message, ...props }) => {
  if (!message) return null;
  
  if (typeof message === "string") {
    return <Alert {...props} bsStyle="success">{message}</Alert>;
  }

  return null;
};

export default SuccessAlert;
