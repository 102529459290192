import React, { Component } from "react";
import { withRouter } from "react-router";
import UserList from "./UserList";
import { connect } from "react-redux";
import { searchForUsers, deleteUser } from "../../../actions/User";
import qs from "qs";

class UserListContainer extends Component {
  constructor(props) {
    super(props);

    const searchQuery = this.getCurrentQueryFromProps(props);

    this.state = {
      query: searchQuery || ""
    };
  }

  componentDidMount() {
    const page = this.getCurrentPageFromProps(this.props);
    const districtId = this.getCurrentDistrictFromProps(this.props);
    const searchQuery = this.getCurrentQueryFromProps(this.props);

    this.props.searchForUsers(page, districtId, searchQuery);
  }

  componentWillReceiveProps(nextProps) {
    const oldPage = this.getCurrentPageFromProps(this.props);
    const newPage = this.getCurrentPageFromProps(nextProps);
    const pageChanged = oldPage !== newPage;

    const oldQuery = this.getCurrentQueryFromProps(this.props);
    const newQuery = this.getCurrentQueryFromProps(nextProps);
    const queryChanged = oldQuery !== newQuery;

    const oldDistrictId = this.getCurrentDistrictFromProps(this.props);
    const districtId = this.getCurrentDistrictFromProps(nextProps);
    const districtChanged = oldDistrictId !== districtId;

    if (pageChanged || districtChanged || queryChanged) {
      this.props.searchForUsers(newPage, districtId, newQuery);
    }
  }

  getCurrentDistrictFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    if (parsedSearch.district !== undefined) {
      return parseInt(parsedSearch.district, 10);
    }

    return undefined;
  }

  getCurrentQueryFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);

    return parsedSearch.query || "";
  }

  getCurrentPageFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    if (parsedSearch.page !== undefined) {
      return parseInt(parsedSearch.page, 10);
    }

    return 1;
  }

  changePage = page => {
    const { match, location } = this.props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    const newSearch = { ...parsedSearch, page };

    const newRoute = {
      pathname: match.url,
      search: qs.stringify(newSearch)
    };

    this.props.history.push(newRoute);
  };

  updateQuery = query => {
    this.setState({ query });
  };

  deleteUser = async userId => {
    const page = this.getCurrentPageFromProps(this.props);
    const districtId = this.getCurrentDistrictFromProps(this.props);

    await this.props.deleteUser(userId);
    this.props.searchForUsers(page, districtId);
  };

  performSearch = query => {
    const { match, location } = this.props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    const newSearch = {
      ...parsedSearch,
      query,
      page: 1
    };

    const newRoute = {
      pathname: match.url,
      search: qs.stringify(newSearch)
    };

    this.props.history.push(newRoute);
  };

  render() {
    const currentPage = this.getCurrentPageFromProps(this.props);

    const searchResult = this.props.userSearchResults;
    const userList = searchResult ? searchResult.data : null;
    const totalPages = searchResult ? searchResult.last_page : 0;

    return (
      <UserList
        users={userList}
        query={this.state.query}
        currentPage={currentPage}
        changePage={this.changePage}
        totalPages={totalPages}
        deleteUser={this.deleteUser}
        updateQuery={this.updateQuery}
        performSearch={this.performSearch}
        currentUser={this.props.currentUser}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    userSearchResults: state.User.searchResults,
    currentUser: state.Account.currentUser
  };
};

const actions = {
  searchForUsers,
  deleteUser
};

UserListContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(UserListContainer)
);

export default UserListContainer;
