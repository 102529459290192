import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getSuggestedProduct,
  approveSuggestedProduct,
  updateSuggestedProduct,
  deleteSuggestedProduct
} from "../../../actions/SuggestedProduct";
import { createProduct } from "../../../actions/Product";
import { getBuildingOptions } from "../../../actions/Options";
import SuggestedProductForm from "./SuggestedProductForm";
import wrapProductEditor from "../ProductEditor/productEditorWrapper";
import PageHeader from "../../Common/PageHeader";
import userTypes from "../../../utils/userTypes";

class VerifySuggestedProductContainer extends Component {
  constructor(props) {
    super(props);

    const suggestedProduct = this.getSuggestedProductFromProps(props) || {};

    this.state = {
      error: "",
      registrarFirstName: suggestedProduct.registrar_first_name || "",
      registrarLastName: suggestedProduct.registrar_last_name || "",
      registrarEmail: suggestedProduct.registrar_email || "",
      hasUnderageStudents: suggestedProduct.has_underage_students || false,
      suggestedProductPurpose: suggestedProduct.purpose || "",
      buildings: suggestedProduct.buildings || []
    };
  }

  setStateFromSuggestedProduct(suggestedProduct) {
    this.setState({
      registrarFirstName: suggestedProduct.registrar_first_name || "",
      registrarLastName: suggestedProduct.registrar_last_name || "",
      registrarEmail: suggestedProduct.registrar_email || "",
      hasUnderageStudents: suggestedProduct.has_underage_students || false,
      suggestedProductPurpose: suggestedProduct.purpose || "",
      buildings: [...suggestedProduct.buildings] || []
    });
  }

  getSuggestedProductIdFromProps(props) {
    const { match } = props;
    const suggestedProductId = match.params.suggestedProductId;
    return suggestedProductId ? parseInt(suggestedProductId, 10) : undefined;
  }

  getSuggestedProductFromProps(props) {
    const suggestedProductId = this.getSuggestedProductIdFromProps(props);
    if (suggestedProductId === undefined) return undefined;

    const product = props.suggestedProducts[suggestedProductId];
    return product;
  }

  async componentDidMount() {
    const suggestedProductId = this.getSuggestedProductIdFromProps(this.props);
    await this.props.getSuggestedProduct(suggestedProductId);

    const suggestedProduct = this.getSuggestedProductFromProps(this.props);
    this.props.setStateFromProduct({
      ...suggestedProduct,
      purpose: false
    });
    this.setStateFromSuggestedProduct(suggestedProduct);

    this.props.getBuildingOptions();
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getSuggestedProductIdFromProps(this.props);
    const newId = this.getSuggestedProductIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getSuggestedProduct(newId);

      const suggestedProduct = this.getSuggestedProductFromProps(nextProps);
      this.props.setStateFromProduct({
        ...suggestedProduct,
        purpose: false
      });

      this.props.getBuildingOptions();
    }
  }
  saveProduct = async (
    suggestedProductId,
    editorInfo,
    registrarFirstName,
    registrarLastName,
    registrarEmail,
    hasUnderageStudents,
    suggestedProductPurpose,
    buildings
  ) => {
    try {
      this.props.validateEditorInfo(editorInfo);
    } catch (err) {
      this.setState({ error: err });
      return;
    }
    const exportable = this.props.exportInfo(editorInfo);
    const buildingIds = buildings.map(x => x.id);

    const {
      name,
      type,
      company,
      sii,
      protection,
      disposal,
      correction,
      location,
      nystcc,
      educational_agency,
      privacy_pledge,
      aicpa,
      purpose,
      data_classifications,
      product_attachments
    } = exportable;

    try {
      await this.props.updateSuggestedProduct(
        suggestedProductId,
        name,
        type,
        company,
        data_classifications,
        registrarFirstName,
        registrarLastName,
        registrarEmail,
        hasUnderageStudents,
        suggestedProductPurpose,
        buildingIds
      );

      const newProduct = await this.props.createProduct(
        name,
        type,
        company,
        sii,
        protection,
        disposal,
        correction,
        location,
        nystcc,
        educational_agency,
        privacy_pledge,
        aicpa,
        purpose,
        data_classifications,
        product_attachments,
        buildingIds
      );
      await this.props.approveSuggestedProduct(suggestedProductId);
      this.props.history.push(`/products/${newProduct.id}`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  saveSuggestedProduct = async (
    suggestedProductId,
    editorInfo,
    registrarFirstName,
    registrarLastName,
    registrarEmail,
    hasUnderageStudents,
    suggestedProductPurpose,
    buildings
  ) => {
    try {
      this.props.validateEditorInfo(editorInfo);
    } catch (err) {
      this.setState({ error: err });
      return;
    }
    const exportable = this.props.exportInfo(editorInfo);
    const buildingIds = buildings.map(x => x.id);

    const { name, type, company, data_classifications } = exportable;
    try {
      await this.props.updateSuggestedProduct(
        suggestedProductId,
        name,
        type,
        company,
        data_classifications,
        registrarFirstName,
        registrarLastName,
        registrarEmail,
        hasUnderageStudents,
        suggestedProductPurpose,
        buildingIds,
      );
      await this.props.approveSuggestedProduct(suggestedProductId);
      this.props.history.push(`/products/register/all`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  updateRegistrarFirstName = registrarFirstName => {
    this.setState({ registrarFirstName });
  };

  updateRegistrarLastName = registrarLastName => {
    this.setState({ registrarLastName });
  };

  updateRegistrarEmail = registrarEmail => {
    this.setState({ registrarEmail });
  };
  // 453 TODO: Decide whether this should also pass in district system admin
  updateDistrictAdmin = districtAdmin => {
    this.setState({ districtAdmin });
  };

  updateHasUnderageStudents = hasUnderageStudents => {
    this.setState({ hasUnderageStudents });
  };

  updateSuggestedProductPurpose = suggestedProductPurpose => {
    this.setState({ suggestedProductPurpose });
  };

  addBuilding = building => {
    const currentBuildings = this.state.buildings;
    const newBuildings = currentBuildings.concat(building);
    return this.setState({ buildings: newBuildings });
  };

  removeBuilding = building => {
    const currentBuildings = this.state.buildings;

    const newBuildings = currentBuildings.filter(x => x.id !== building.id);
    return this.setState({ buildings: newBuildings });
  };

  deleteSuggestedProduct = async suggestedProductId => {
    await this.props.deleteSuggestedProduct(suggestedProductId);
    this.props.history.push("/products/register/all");
  };

  render() {
    const suggestedProductId = this.getSuggestedProductIdFromProps(this.props);
    const suggestedProduct = this.props.suggestedProducts[suggestedProductId];
    if (!suggestedProduct) return <div>Loading...</div>;
    if (!this.props.buildingOptions) return <div>Loading...</div>;

    var saveProduct;
    if (this.props.currentUser.user_type === userTypes.superAdmin) {
      saveProduct = this.saveProduct;
    }
    if (this.props.currentUser.user_type === userTypes.districtAdmin || this.props.currentUser.user_type === userTypes.districtSystemAdmin) {
      saveProduct = this.saveSuggestedProduct;
    }

    const {
      registrarFirstName,
      registrarLastName,
      registrarEmail,
      districtAdmin,
      hasUnderageStudents,
      suggestedProductPurpose,
      buildings
    } = this.state;
    const buildingOptions = Object.keys(this.props.buildingOptions).map(x => {
      return { id: parseInt(x, 10), label: this.props.buildingOptions[x] };
    });
    return (
      <div className="verify-suggested-product-container">
        <PageHeader title="Products" subtitle="Register A Product" />
        <SuggestedProductForm
          registrarFirstName={registrarFirstName}
          updateRegistrarFirstName={this.updateRegistrarFirstName}
          registrarLastName={registrarLastName}
          updateRegistrarLastName={this.updateRegistrarLastName}
          registrarEmail={registrarEmail}
          updateRegistrarEmail={this.updateRegistrarEmail}
          currentUser={this.props.currentUser}
          productId={suggestedProductId}
          saveProduct={saveProduct}
          editorInfo={this.props.editorInfo}
          error={this.state.error}
          hasUnderageStudents={hasUnderageStudents}
          updateHasUnderageStudents={this.updateHasUnderageStudents}
          suggestedProductPurpose={suggestedProductPurpose}
          updateSuggestedProductPurpose={this.updateSuggestedProductPurpose}
          buildings={buildings}
          buildingOptions={buildingOptions}
          addBuilding={this.addBuilding}
          removeBuilding={this.removeBuilding}
          districtAdmin={districtAdmin}
          updateDistrictAdmin={this.updateDistrictAdmin}
          deleteSuggestedProduct={this.deleteSuggestedProduct}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    suggestedProducts: state.SuggestedProduct.suggestedProductDict,
    users: state.User.userDict,
    buildingOptions: state.Options.buildingOptions,
    currentUser: state.Account.currentUser
  };
};

const actions = {
  getSuggestedProduct,
  approveSuggestedProduct,
  getBuildingOptions,
  createProduct,
  updateSuggestedProduct,
  deleteSuggestedProduct
};

VerifySuggestedProductContainer = withRouter(
  wrapProductEditor(
    connect(
      mapStateToProps, // connect component props to application (redux) state
      actions // these actions are automatically wrapped in a dispatch by connect
    )(VerifySuggestedProductContainer)
  )
);

export default VerifySuggestedProductContainer;
