import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {updateAccountName, updateAccountPassword} from "../../actions/Account";
import MyAccount from "./MyAccount";

const MyAccountContainer = ({
  updateAccountName, 
  updateAccountPassword,
  currentUser
}) => {
  const initialForm = {
    firstName: "",
    lastName: "",
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    error: "",
    succeeded: false
  };

  const [formData, setFormData] = useState(initialForm);

  useEffect(() => {
    if(currentUser) {
      setFormData({
        ...formData,
        firstName: currentUser.first_name || "",
        lastName: currentUser.last_name || ""
      });
    }
  }, []);

  const handleChange = e => {
    e.preventDefault();

    const field = e.target.name;
    const value = e.target.value;

    setFormData((oldFormData) => ({ ...oldFormData, [field]: value }));
  };

  const submitUserInfoForm = async () => {
    if(!formData.firstName || !formData.lastName) {
      setFormData((oldFormData) => ({
        ...oldFormData,
        error: "You must provide a first and last name",
        succeeded: false
      }));
      
      return;
    }

    try {
      await updateAccountName(
        formData.firstName,
        formData.lastName,
      );

      setFormData({
        ...formData,
        error: "",
        succeeded: true
      });
    } catch (err) {
      setFormData({
        ...formData,
        error: err,
        succeeded: false,
      });
    }
  };

  const submitPasswordForm = async () => {
    if(!formData.currentPassword || !formData.newPassword || !formData.confirmNewPassword) {
      setFormData({
        ...formData,
        error: "You must provide the current password and a new password",
        succeeded: false
      });
      return;
    }

    if(`${formData.newPassword}` !== `${formData.confirmNewPassword}`) {
      setFormData({
        ...formData,
        error: "The new password and confirmation do not match",
        succeeded: false
      });
      return;
    }

    try {
      await updateAccountPassword(
        formData.currentPassword,
        formData.newPassword,
        formData.confirmNewPassword
      );

      setFormData({
        ...initialForm,
        firstName: formData.firstName,
        lastName: formData.lastName,
        error: "",
        succeeded: true
      });
    } catch (err) {
      setFormData({
        ...formData,
        error: err,
        succeeded: false,
      });
    }
  };

  return (
    <MyAccount
        {...formData}
        handleChange={handleChange}
        submitUserInfoForm={submitUserInfoForm}
        submitPasswordForm={submitPasswordForm}
      />
  );
}

const mapStateToProps = state => {
  return {
    currentUser: state.Account.currentUser
  };
};

const actions = {updateAccountName, updateAccountPassword};

export default connect(mapStateToProps, actions)(MyAccountContainer);
