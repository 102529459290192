import React from "react";
import { Modal, Button } from "react-bootstrap";

export default ({
  productToKeep,
  productToDelete,
  confirmCallback,
  dismissCallback,
  modalVisible
                }) => {

  return (
    <Modal onHide={dismissCallback} show={modalVisible}>
      <Modal.Header closeButton>
        <Modal.Title>
          Are you sure that you want to merge products?
        </Modal.Title>
      </Modal.Header>

      { (productToKeep && productToDelete) &&
        <Modal.Body>
          <p>
            {productToKeep.name} will be kept
          </p>
          <p>
            {productToDelete.name} will be deleted, and its Inventory Records will be assigned to {productToKeep.name}
          </p>
          Once you confirm this it cannot be reversed.
        </Modal.Body>
      }

      <Modal.Footer>
        <Button onClick={confirmCallback} bsStyle="danger">
         Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
