import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDistrict, getDistrictInventory, updateDistrictDataAdminProducts } from "../../../actions/District";
import { getDistrictAdminList } from "../../../actions/User";
import userTypes from "../../../utils/userTypes";
import DataAdminForm from "./DataAdminForm";

const DataAdminContainer = (props) => {
    const [dataAdmin, setDataAdmin] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [formData, setFormData] = useState({});
    const [inventoryRecords, setInventoryRecords] = useState([]);
    const [documentChanged, setDocumentChanged] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
        if(props.currentUser) {
            if(!(props.currentUser.user_type === userTypes.superAdmin || props.currentUser.user_type === userTypes.districtAdmin || props.currentUser.user_type === userTypes.regionAdmin || props.currentUser.user_type === userTypes.districtSystemAdmin)) {
                props.history.push('/');
            }
        }

        const fetchData = async () => {
            const districtId = getDistrictIdFromProps();
            props.getDistrictAdminList(districtId);
            props.getDistrict(districtId);
            props.getDistrictInventory(districtId);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if(props.districtAdminList.length) {
            setDataAdmin(props.districtAdminList[0]);
        } else {
            setDataAdmin(null);
        }
    }, [props.districtAdminList]);

    useEffect(() => {
        setDocumentChanged(false);
        const districtId = getDistrictIdFromProps();

        if (props.inventoryRecords[districtId] && dataAdmin) {
            let newForm = {};
            props.inventoryRecords[districtId].forEach(record => {
                newForm[record.id] = {
                    name: record.product.name,
                    checked: record.data_admin_id === dataAdmin.id,
                };
            });
            setFormData(newForm);
            setInventoryRecords(props.inventoryRecords[districtId]);
        }

    }, [props.inventoryRecords, dataAdmin]);

    useEffect(() => {
        setSuccessMessage(props.message);
    }, [props.message]);

    const getDistrictIdFromProps = () => {
        const { match } = props;
        const districtId = match.params.districtId;
        return districtId ? parseInt(districtId, 10) : undefined;
    };

    const getDistrictFromProps = () => {
        const districtId = getDistrictIdFromProps();
        if (districtId === undefined) return undefined;

        return props.districts[districtId];
    }

    const updateProducts = (e) => {
        const formattedData = [];
        for (const [id, value] of Object.entries(formData)) {
            formattedData.push({
                inventory_record_id: id,
                checked: value.checked
            });
        }
        props.updateDistrictDataAdminProducts(getDistrictIdFromProps(), dataAdmin.id, formattedData);
    }

    const toggleSelectAll = () => {
        handleChange(null, !selectAll);
        setSelectAll(!selectAll);
    }

    const changeDataAdmin = (e) => {
        setSuccessMessage(false);
        if (e !== dataAdmin.id) {
            const newlySelectedAdmin = props.districtAdminList.filter(admin => admin.id === e)[0];
            setDataAdmin(newlySelectedAdmin);
            setSelectAll(false);
        }
    }

    const handleChange = (recordId, setAll = null) => {
        if (setAll === null) {
            setFormData({
                ...formData,
                [recordId]: {
                    name: formData[recordId].name,
                    checked: !formData[recordId].checked
                }
            });
        } else {
            let newFormData = {};
            for (const [id, value] of Object.entries(formData)) {
                newFormData[id] = {
                    name: value.name,
                    checked: setAll,
                }
            };
            setFormData(newFormData);
        }
        setDocumentChanged(true);
    }

    return (
        <div className="data-admin-conatiner">
            {inventoryRecords ? <DataAdminForm
                district={getDistrictFromProps()}
                dataAdminsList={props.districtAdminList}
                inventoryRecords={inventoryRecords}
                dataAdmin={dataAdmin}
                selectAll={selectAll}
                updateProducts={updateProducts}
                formData={formData}
                dataAdmin={dataAdmin}
                toggleSelectAll={toggleSelectAll}
                handleChange={handleChange}
                changeDataAdmin={changeDataAdmin}
                documentChanged={documentChanged}
                successMessage={successMessage}
            /> : ""}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        districtAdminList: state.User.districtAdminList ? Object.values(state.User.districtAdminList) : [],
        districts: state.District.districtDict,
        inventoryRecords: state.District.inventoryRecords,
        message: state.District.message,
        currentUser: state.Account.currentUser
    };
};
const mapDispatchToProps = {
    getDistrictAdminList,
    getDistrict,
    getDistrictInventory,
    updateDistrictDataAdminProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(DataAdminContainer);
