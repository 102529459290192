import React from "react";
import history from "../../history";

const GoBack = ({ ...props }) => (
  <button
    className="btn btn-link"
    {...props}
    onClick={e => {
      e.preventDefault();
      history.goBack();
    }}
  >
    {props.children ? props.children : "Cancel"}
  </button>
);

export default GoBack;
