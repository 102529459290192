import React from "react";
import UserRow from "./UserRow";
import userTypes from "../../../utils/userTypes";

import PageHeader from "../../Common/PageHeader";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";

const UserList = ({
  users,
  currentPage,
  changePage,
  totalPages,
  performSearch,
  query,
  updateQuery,
  deleteUser,
  currentUser
}) => {
  const currentUserIsDistrictAdmin =
    userTypes.districtAdmin === currentUser.user_type;
  const currentUserIsDistrictSystemAdmin = userTypes.districtSystemAdmin === currentUser.user_type;

  const clearSearch = () => {
    updateQuery('');
    performSearch('');
  };

  const userRows = users
    ? users.map(user => (
        <UserRow
          currentUserIsDistrictAdmin={currentUserIsDistrictAdmin}
          currentUserIsDistrictSystemAdmin={currentUserIsDistrictSystemAdmin}
          key={user.id}
          user={user}
          deleteUser={deleteUser}
        />
      ))
    : null;

  const paginator =
    totalPages > 1 ? (
      <Pagination
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        items={totalPages}
        maxButtons={5}
        activePage={currentPage}
        onSelect={changePage}
      />
    ) : null;

  return (
    <div className="user-list-container">
      <PageHeader title="Users" subtitle="User Information" />
      <div className="row">
        <div className="col-sm-12 text-right">
          <form
            action
            className="form-inline"
            onSubmit={e => {
              e.preventDefault();
              performSearch(query);
            }}
          >
            <div className="input-group">
              <input
                name="search"
                placeholder="Search for..."
                className="form-control"
                type="search"
                onChange={e => {
                  e.preventDefault();
                  updateQuery(e.target.value);
                }}
                value={query}
              />
              <span className="glyphicon glyphicon-remove-circle search-clear" style={{right: '80px'}} onClick={clearSearch}></span>
              <span className="input-group-btn">
                <button type="submit" className="btn btn-default">
                  Search
                </button>
              </span>
            </div>{" "}
            <div className="input-group hidden-xs">
              <Link to="/users/create" className="btn btn-success">
                Add User
              </Link>{" "}
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Organization</th>
                  <th>Email</th>
                  <th>Role Group</th>
                  <th className="text-right">Manage</th>
                </tr>
              </thead>
              <tbody>{userRows}</tbody>
            </table>
          </div>
          {paginator}
        </div>
      </div>
    </div>
  );
};

export default UserList;
