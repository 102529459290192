import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getRegion, updateRegion } from "../../../actions/Region";
import RegionForm from "./RegionForm";

class UpdateRegionContainer extends Component {
  constructor(props) {
    super(props);

    const region = this.getRegionFromProps(props) || {};

    this.state = {
      error: "",
      name: region.name || "",
      contactName: region.contact_name || "",
      contactTitle: region.contact_title || "",
      contactEmail: region.contact_email || ""
    };
  }

  getRegionIdFromProps(props) {
    const { match } = props;
    const regionId = match.params.regionId;
    return regionId ? parseInt(regionId, 10) : undefined;
  }

  getRegionFromProps(props) {
    const regionId = this.getRegionIdFromProps(props);
    if (regionId === undefined) return undefined;

    const region = props.regions[regionId];
    return region;
  }

  setStateFromRegion(region) {
    this.setState({
      name: region.name || "",
      contactName: region.contact_name || "",
      contactTitle: region.contact_title || "",
      contactEmail: region.contact_email || ""
    });
  }

  async componentDidMount() {
    const regionId = this.getRegionIdFromProps(this.props);
    await this.props.getRegion(regionId);

    const region = this.getRegionFromProps(this.props);
    this.setStateFromRegion(region);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getRegionIdFromProps(this.props);
    const newId = this.getRegionIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getRegion(newId);

      const region = this.getRegionFromProps(nextProps);
      this.setStateFromRegion(region);
    }
  }

  saveRegion = async (
    regionId,
    name,
    contactName,
    contactTitle,
    contactEmail
  ) => {
    if (!name) {
      this.setState({ error: "You must provide a region name" });
      return;
    }


    try {
      await this.props.updateRegion(
        regionId,
        name,
        contactName,
        contactTitle,
        contactEmail
      );

      this.props.history.push(`/regions/${regionId}`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  updateName = name => {
    this.setState({ name });
  };

  updateContactName = contactName => {
    this.setState({ contactName });
  };

  updateContactTitle = contactTitle => {
    this.setState({ contactTitle });
  };

  updateContactEmail = contactEmail => {
    this.setState({ contactEmail });
  };

  render() {
    const regionId = this.getRegionIdFromProps(this.props);
    const { name, contactEmail, contactTitle, contactName, error } = this.state;

    return (
      <div className="update-region-container">
        <RegionForm
          error={error}
          regionId={regionId}
          name={name}
          updateName={this.updateName}
          contactEmail={contactEmail}
          updateContactEmail={this.updateContactEmail}
          contactName={contactName}
          updateContactName={this.updateContactName}
          contactTitle={contactTitle}
          updateContactTitle={this.updateContactTitle}
          saveRegion={this.saveRegion}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { regions: state.Region.regionDict };
};

const actions = { getRegion, updateRegion };

UpdateRegionContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(UpdateRegionContainer)
);

export default UpdateRegionContainer;
