import React, { Component } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { logout } from "../../actions/Account";
import { restoreImpersonatingRegionAdmin } from "../../actions/District";

class LogoutContainer extends Component {
  componentDidMount() {
    this.handleLogout();
  }

  componentWillReceiveProps() {
    this.handleLogout();
  }

  handleLogout() {
    if (this.props.isTemporaryImpersonationUser) {
      this.props.restoreImpersonatingRegionAdmin();
    } else {
      this.props.logout();
    }
  }

  render() {
    return <Redirect to="/" />;
  }
}

const mapStateToProps = state => {
  return {
    isTemporaryImpersonationUser: state.Account.currentUser.temporary
  };
};

const actions = { logout, restoreImpersonatingRegionAdmin };

LogoutContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(LogoutContainer);

export default LogoutContainer;
