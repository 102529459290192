import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRegionList } from "../../../actions/Options";
import { getRegion } from "../../../actions/Region";
import DistrictSelector from "./DistrictSelector";

class DistrictSelectorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRegion: null
    };
  }

  componentDidMount() {
    this.props.getRegionList();
  }

  selectRegion = async regionId => {
    await this.props.getRegion(regionId);
    this.setState({ selectedRegion: regionId });
  };

  selectDistrict = district => {
    this.props.onSelect(district);
  };

  render() {
    const selectedRegionId = this.state.selectedRegion;
    const selectedRegion = selectedRegionId
      ? this.props.regionDict[selectedRegionId]
      : null;

    const possibleDistricts = selectedRegion ? selectedRegion.districts : [];

    return (
      <DistrictSelector
        selectedRegion={selectedRegion}
        regions={this.props.regions}
        districts={possibleDistricts}
        selectRegion={this.selectRegion}
        selectDistrict={this.selectDistrict}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    currentUser: state.Account.currentUser,
    regions: state.Options.regions,
    regionDict: state.Region.regionDict
  };
};

const actions = { getRegionList, getRegion };

DistrictSelectorContainer = connect(mapStateToProps, actions)(
  DistrictSelectorContainer
);

DistrictSelectorContainer.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default DistrictSelectorContainer;
