import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import {getProduct, mergeProducts} from "../../../actions/Product";
import PageHeader from "../../Common/PageHeader";
import ProductMergePane from "./ProductMergePane";
import ConfirmModal from "./ConfirmModal";
import ProductMergeSearch from "./ProductMergeSearch";
import ErrorAlert from "../../Common/ErrorAlert";
import _ from 'lodash';

const ProductMergeContainer = ({
                                 match,
                                 products,
                                 getProduct,
                                  mergeProducts,
  history
                               }) => {

  const productId = parseInt(match.params.productId, 10);

  // Product 1
  const [product, setProduct] = useState(products[productId] || null);

  // Product 2 - product that is searched for
  const [selectedProductId, setSelectedProductId] = useState(null);
  const selectedProduct = products[selectedProductId] || null;
  let districtIntersection = [];
  if(selectedProduct) {
    const product1Districts = product.districts;
    const product2Districts = selectedProduct.districts;
    districtIntersection = _.intersectionBy(product1Districts, product2Districts, 'id');
  }

  // Product to keep
  const [mergingProductId, setMergingProductId] = useState(productId);

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const [error, setError] = useState(null);

  useEffect(() => {
    getProduct(productId);
  }, []);

  useEffect(() => {
    setProduct(products[productId])
  }, [products]);

  useEffect(() => {
    if(selectedProductId !== null) {
      getProduct(selectedProductId);
    }
  }, [selectedProductId]);


  const productIdToDelete = () => {
    return mergingProductId === productId ? selectedProductId : productId;
  }

  const submitMerge = () => {
    const productIdToKeep = mergingProductId;
    const productToDelete = productIdToDelete();

    if(productIdToKeep && productToDelete) {
      mergeProducts(productIdToKeep, productToDelete)
      .then(() => history.push(`/products/${productIdToKeep}`))
      .catch((err) => setError(err));
    }
  }





  return !product ? null : (
    <div>
      <ConfirmModal
        modalVisible={confirmModalVisible && (selectedProductId && productId)}
        confirmCallback={submitMerge}
        dismissCallback={() => setConfirmModalVisible(false)}
        productToKeep={products[mergingProductId]}
        productToDelete={products[productIdToDelete()]}
      />
      <PageHeader title="Products" subtitle="Merge Products"/>
      <div className="row">
        <div className="col-xs-4">
          <div className="panel panel-default">
            <div className="product-merge_title-container">
              <h4>Merge Product 1</h4>
              <hr />
            </div>
            <ProductMergePane
              product={product}
              mergingProductId={mergingProductId}
              setMergingProductId={setMergingProductId}/>
          </div>
        </div>
        <div className="col-xs-4">
            <ProductMergeSearch
              selectProduct={setSelectedProductId}
              baseProductId={product.id}
              selectedProduct={products[selectedProductId] || null}
              mergingProductId={mergingProductId}
              setMergingProductId={setMergingProductId}
            />
        </div>
        {
          selectedProduct &&
            <div className="col-xs-4">
              <div className="panel panel-default">
                <div className="product-merge_title-container">
                  <h4>Shared Districts</h4>
                  <hr />
                  {districtIntersection.length > 0 ? (
                    districtIntersection.map((district, index) => (
                      <div key={index}>
                        {district.region_name} - {district.name}
                      </div>
                    ))
                  ) : (
                    <div>No Districts</div>
                  )}
                </div>
              </div>
            </div>
        }

      </div>

      {error
        ? <div className="row">
          <div className="col-sm-12">
            <ErrorAlert error={error} />
          </div>
        </div>
        : null}
      {selectedProductId && (
        <div className="row">
          <div className="col-xs-12">
            <button onClick={() => setConfirmModalVisible(true)} className="btn btn-primary btn-lg">
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  products: state.Product.productDict
});

const mapDispatchToProps = ({
  getProduct,
  mergeProducts
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductMergeContainer);
