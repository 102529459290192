import React from "react";
import ConfirmationButton from "../ConfirmationButton";
import DeletionButton from "../DeletionButton";

const AttachmentPanel = ({
    attachment,
    handleChange,
    index,
    name,
    uri,
    characterLimit,
    maxDescriptionLength,
    description,
    updateAttachment,
    uploadFile,
    showInBORCheckbox,
    showInSupplementalInformation,
    removeAttachment,
    documentChanged,
    loading,
    checkboxText
}) => {
    let uploadInput = null;

    const showInBOROption = showInBORCheckbox ?
        (<div className="form-group checkbox">
            <label>
                <input
                    type="checkbox"
                    name="showInSupplementalInformation"
                    checked={showInSupplementalInformation}
                    onChange={e => {
                        const state = {
                            target: {
                                value: !showInSupplementalInformation,
                                name: 'showInSupplementalInformation'
                            },
                            preventDefault: () => { }
                        };

                        handleChange(state)
                    }}
                />
                {checkboxText ? checkboxText : 'Show in Supplemental Information'}
            </label>
        </div>)
        : null;

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <div className="panel-title">
                    Document {index + 1}
                </div>
            </div>
            <div className="panel-body">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="form-group">
                            <label className="control-label" htmlFor={`attachments-${index}-uri`}>
                                URL
                            </label>
                            <input
                                className="form-control"
                                name="uri"
                                id={`attachments-${index}-uri`}
                                value={uri}
                                onChange={handleChange}
                                type="text"
                            />
                        </div>
                        {showInBOROption}
                    </div>

                    <div className="col-12 col-sm-6">
                        <div className="form-group">
                            <label htmlFor={`attachments-${index}-name`} className="control-label">
                                Name*
                            </label>
                            <input
                                className="form-control"
                                id={`attachments-${index}-name`}
                                name="name"
                                type="text"
                                value={name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Or upload a file</label>
                            <input ref={(input) => uploadInput = input} type="file" accept=".pdf" onChange={uploadFile} />
                        </div>

                        <div className="form-group">
                            <label
                                className="control-label"
                                htmlFor={`attachments-${index}-description`}
                            >
                                Description (max {characterLimit} characters)*
                            </label>
                            <textarea
                                rows={4}
                                name="description"
                                id={`attachments-${index}-description`}
                                className="form-control"
                                onChange={handleChange}
                                value={description || ""}
                                maxLength={characterLimit}
                            />
                            {maxDescriptionLength ? <div style={{color: '#989ca0'}}>You have reached the maximum description length of 255 characters</div> : null}
                        </div>
                    </div>
                    <div className="form-group form-group col-xs-12 text-right">
                        <ConfirmationButton
                            isDisabled={loading}
                            title="Are you sure?"
                            body="The supporting document has changed, saving will permanently remove the old supporting document"
                            confirmBeforeExecuting={documentChanged}
                            confirmCallback={() => {
                                updateAttachment(attachment);
                            }}
                            buttonText="Save Document"
                        />
                        {" "}
                        <DeletionButton
                            onConfirm={() => {
                                removeAttachment(attachment);
                            }}
                            buttonText="Remove Document"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AttachmentPanel;
