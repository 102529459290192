import React from "react";
import PageHeader from "../../Common/PageHeader";
import ErrorAlert from "../../Common/ErrorAlert";
import GoBack from "../../Common/GoBack";

const CompanyForm = ({
  companyId,
  name,
  contactTitle,
  contactName,
  contactEmail,
  phone,
  nys_education_law_does_not_apply,
  address,
  handleChange,
  handleCheckbox,
  saveCompany,
  error
}) => {
  return (
    <div className="company-editor">
      <PageHeader
        title="Companies"
        subtitle={companyId ? "Update Company" : "Create Company"}
      />
      <form
        onSubmit={e => {
          e.preventDefault();
          saveCompany(
            companyId,
            name,
            contactName,
            contactTitle,
            contactEmail,
            phone,
            address,
            nys_education_law_does_not_apply
          );
        }}
      >
        <div className="row">
          <div className="form-group col-sm-6">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              name="name"
              placeholder="Name of company"
              className="form-control"
              type="text"
              value={name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-sm-6">
            <label htmlFor="contactName">Contact Name</label>
            <input
              id="contactName"
              name="contactName"
              placeholder="Contact Name"
              className="form-control"
              type="text"
              value={contactName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-6">
            <label htmlFor="contactName">Contact Title</label>
            <input
              id="contactTitle"
              name="contactTitle"
              placeholder="Contact Title"
              className="form-control"
              type="text"
              value={contactTitle}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-sm-6">
            <label htmlFor="contactEmail">Contact Email</label>
            <input
              id="contactEmail"
              name="contactEmail"
              placeholder="Contact Email"
              className="form-control"
              type="email"
              value={contactEmail}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-sm-6">
            <label htmlFor="contactEmail">Phone</label>
            <input
              id="phone"
              name="phone"
              placeholder="Phone"
              className="form-control"
              type="text"
              value={phone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-sm-6">
            <label htmlFor="contactEmail">Address</label>
            <textarea
              id="address"
              name="address"
              placeholder="Address"
              className="form-control"
              type="text"
              value={address}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-group col-sm-6">
          <div className="checkbox">
            <label htmlFor="nys_education_law_does_not_apply">
              <input
                id="nys_education_law_does_not_apply"
                name="nys_education_law_does_not_apply"
                type="checkbox"
                checked={nys_education_law_does_not_apply}
                onChange={handleCheckbox}
              />
              NYS Education Law Third-Party Vendor Requirements Do Not Apply
            </label>
          </div>
        </div>
        {error
          ? <div className="row">
              <div className="col-sm-6">
                <ErrorAlert error={error} />
              </div>
            </div>
          : null}
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group text-right">
              <button type="submit" className="btn btn-primary">
                Save Company
              </button>{" "}
              <GoBack />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CompanyForm;
