import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ImportRecordsForm from "./ImportRecordsForm";
import { importRecordsExcel, downloadImportRecordsExceptionsExcel, exportInventoryReordImportErrorsExcel, downloadImportInventoryRecordsTemplate } from "../../../actions/InventoryRecord";
import ImportedRecordsTable from "./ImportedRecordsTable";
import userTypes from "../../../utils/userTypes";


const ImportRecordsContainer = ({
    importRecordsExcel,
    match,
    exportInventoryReordImportErrorsExcel,
    downloadImportRecordsExceptionsExcel,
    downloadImportInventoryRecordsTemplate,
    currentUser,
    history
}) => {
    const [error, setError] = useState("");
    const [uri, setUri] = useState("");
    const [importResponse, setImportResponse] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [wasUploaded, setWasUploaded] = useState(false);
    const [errorsDownloading, setErrorsDownloading] = useState(false);


    useEffect(() => {
        if(currentUser) {
            if(!(currentUser.user_type === userTypes.superAdmin || currentUser.user_type === userTypes.districtAdmin || currentUser.user_type === userTypes.districtSystemAdmin || currentUser.user_type === userTypes.regionAdmin)) {
                history.push('/');
            }
        }
    });

    const getDistrictIdFromProps = () => {
        // const { match } = props;
        const districtId = match.params.districtId;
        return districtId ? parseInt(districtId, 10) : undefined;
    };

    const uploadFile = async e => {
        try {
            setErrorsDownloading(false);
            setWasUploaded(false);
            setError("");
            setIsUploading(true);
            const response = await importRecordsExcel(e.target.files[0], getDistrictIdFromProps());

            setIsUploading(false);
            setWasUploaded(true)
            setImportResponse(response);
            setErrorsDownloading(response.filename !== null);

            if (response.filename) {
                const blob = await exportInventoryReordImportErrorsExcel(response.filename, getDistrictIdFromProps());
                downloadImportRecordsExceptionsExcel(blob);
            }
        } catch (err) {
            setIsUploading(false);
            setErrorsDownloading(false);
            setWasUploaded(false);
            setError(err);
        }
    };

    return (
        <div className="import-records-container">
            <ImportRecordsForm 
                uploadFile={uploadFile}
                inventoryAttachmentId={null}
                error={error}
                uri={uri}
                isUploading={isUploading}
                wasUploaded={wasUploaded}
                errorsDownloading={errorsDownloading}  
                downloadTemplate={downloadImportInventoryRecordsTemplate}  
            />
            {(importResponse && importResponse.importedRecords.length) ? <ImportedRecordsTable records={importResponse.importedRecords} /> : <span></span>}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.Account.currentUser
    }
}

const mapDispatchToProps = {
    importRecordsExcel,
    downloadImportRecordsExceptionsExcel,
    exportInventoryReordImportErrorsExcel,
    downloadImportInventoryRecordsTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportRecordsContainer);