import React from "react";
import { getOptListWithIdValue } from "../../../Common/OptionList";

const DataAdminUserForm = ({
  districtList,
  districtId,
  handleChange,
  showCreateUser,
  handleCreateUserCheckbox,
  userEmailLocked,
  showDistrictSelect
}) => {
  const districtOptions = districtList ? getOptListWithIdValue(districtList) : null;

  const districtSelect = () => {
    return (districtOptions.length && showDistrictSelect)
      ? <div className="row col-xs-6">
          <label htmlFor="districtId">District</label>
          <select
            name="districtId"
            className="form-control"
            value={districtId}
            onChange={handleChange}
          >
            <option value>Choose a District...</option>
            {districtOptions}
          </select>
        </div>
      : null;
  };
  return (
    <div id="data-user-form">
      <div className="row col-xs-12">
        <div className="row">
          <p className="lead col-xs-12">
            Data ADMINS have limited rights to only the products they administer
            and may ONLY add or edit delegates for those products.
          </p>
        </div>
        {districtSelect()}
      </div>
      <div className="row">
        <p className="lead col-xs-12">Data Admin Information</p>
        <div className="form-group col-xs-6">
          <label><input type="checkbox" disabled={userEmailLocked} checked={userEmailLocked || showCreateUser} onChange={e => {
            handleCreateUserCheckbox(e.target.checked)
          }}/> Create User for Data Administrator</label>
        </div>
      </div>
    </div>
  );
};

export default DataAdminUserForm;
