import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import PageHeader from '../../Common/PageHeader';
import DistrictInventoryRow from './DistrictInventoryRow';
import InfiniteScrollLoader from '../../Common/InfiniteScrollLoader';
import Search from '../../Common/Search';
import SuccessAlert from '../../Common/SuccessAlert';
import userTypes from '../../../utils/userTypes';
import renderFor from '../../../utils/renderFor';


const DistrictInventory = ({
  district,
  records,
  expandAttachmentsInventoryRecordId,
  updateExpandAttachmentsInventoryRecordId,
  updateExpandedAttachmentId,
  expandedAttachmentId,
  updateAddingAttachmentInventoryRecordId,
  addingAttachmentInventoryRecordId,
  expandedContractInventoryRecordId,
  updateExpandedContractInventoryRecordId,
  infiniteScrollFunction,
  infiniteScrollPagination,
  query,
  updateQuery,
  performSearch,
  updateSort,
  requestPDFView,
  sortBy,
  sortOrder,
  currentUser,
  attachmentTypes,
  updateAttachment,
  removeAttachment,
  createAttachment,
  attachmentErrors,
  attachmentCreationSuccess,
  updateArchivedFilter,
  archivedFilter,
  displayArchivedMessage,
  successMessage,
  listSuccessMessage,
  handleSuccessfulContractUpdate
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => window.scrollTo(0, 0);
  }, []);

  const rows = records.map((record) => {
    if (
      (!record.archived && (archivedFilter === 'false' || archivedFilter === '')) ||
      (record.archived && archivedFilter === 'true')
    ) {
      return (
        <DistrictInventoryRow
          key={record.id}
          record={record}
          expandAttachmentsInventoryRecordId={expandAttachmentsInventoryRecordId}
          updateExpandAttachmentsInventoryRecordId={updateExpandAttachmentsInventoryRecordId}
          updateExpandedAttachmentId={updateExpandedAttachmentId}
          updateAddingAttachmentInventoryRecordId={updateAddingAttachmentInventoryRecordId}
          addingAttachmentInventoryRecordId={addingAttachmentInventoryRecordId}
          expandedAttachmentId={expandedAttachmentId}
          attachmentTypes={attachmentTypes}
          updateExpandedContractInventoryRecordId={updateExpandedContractInventoryRecordId}
          expandedContractInventoryRecordId={expandedContractInventoryRecordId}
          onSuccessfulContractUpdate={handleSuccessfulContractUpdate}
          updateAttachment={updateAttachment}
          removeAttachment={removeAttachment}
          createAttachment={createAttachment}
          attachmentErrors={attachmentErrors}
          attachmentCreationSuccess={attachmentCreationSuccess}
          displayArchivedMessage={displayArchivedMessage}
          successMessage={successMessage}
        />
      );
    }
  });

  let chevronClassName = 'glyphicon ';

  const arrow = (columnName) => {
    let chevronClass = chevronClassName;
    if (sortBy === columnName && sortOrder === 'desc') {
      chevronClass = chevronClassName + 'glyphicon-chevron-down';
    } else {
      chevronClass = chevronClassName + 'glyphicon-chevron-up';
    }

    return (
      <span
        className={chevronClass}
        style={{ color: columnName === sortBy ? '#165d9c' : '#bfc3c7' }}>
        {' '}
      </span>
    );
  };

  const handleSearch = (query) => {
    performSearch(query, sortBy, sortOrder, archivedFilter);
  };

  const handleUpdateQuery = (query) => {
    updateQuery(query, sortBy, sortOrder, archivedFilter);
  };

  const userCanEditDataAdmins = (user) => {
    return (
      user.user_type === userTypes.superAdmin ||
      user.user_type === userTypes.districtAdmin ||
      user.user_type === userTypes.districtSystemAdmin ||
      user.user_type === userTypes.regionAdmin
    );
  };

  const userCanImportRecords = (user) => {
    return (
      user.user_type === userTypes.superAdmin ||
      user.user_type === userTypes.districtAdmin ||
      user.user_type === userTypes.districtSystemAdmin ||
      user.user_type === userTypes.regionAdmin
    );
  };

  return (
    <div className="district-inventory">
      <PageHeader title="District Inventory" subtitle="District Product Listing" />
      <div className="row">
        <div className="col-sm-12">
          <p className="lead">{district.name}</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Search
              query={query}
              updateQuery={handleUpdateQuery}
              performSearch={handleSearch}
              showClearButton={true}
              searchButtonText="Search Products"
            />
            <button className="btn btn-primary" type="button" onClick={requestPDFView}>
              View as PDF
            </button>
            {renderFor(
              {
                districtAdmin: true,
                districtSystemAdmin: true,
                regionAdmin: true,
                superAdmin: true
              },
              () => {
                return (
                  <Link className="btn btn-primary" to={`/inventory/${district.id}/import`}>
                    Import New Records
                  </Link>
                );
              }
            )}
          </div>
          <br />
          <br />
          {renderFor(
            { districtAdmin: true, districtSystemAdmin: true, regionAdmin: true, superAdmin: true },
            () => {
              return (
                <Link
                  to={`/districts/${district.id}/data-admins`}
                  className="btn btn-success district-admins-btn">
                  Edit Data Admins
                </Link>
              );
            }
          )}

          <div className="row">
            <div className="col-sm-6"></div>
            <div className="col-sm-6">
              {listSuccessMessage && (
                <>
                  <br></br>
                  <SuccessAlert message={listSuccessMessage} />
                </>
              )}
            </div>
          </div>

          <br></br>
          <br></br>

          <div className="row">
            <div className="col-sm-12">
              <ul className="nav nav-tabs nav-justified">
                <li
                  role="presentation"
                  className={archivedFilter === 'false' || archivedFilter === '' ? 'active' : ''}
                  onClick={() => updateArchivedFilter('false')}>
                  <a role="button">Active Products</a>
                </li>
                <li
                  role="presentation"
                  className={archivedFilter === 'true' ? 'active' : ''}
                  onClick={() => updateArchivedFilter('true')}>
                  <a role="button">Archived Products</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="table-responsive district-inventory-table">
            <table className="table table-hover" key={query + sortOrder + sortBy + district.id}>
              <thead>
                <tr>
                  <th>
                    <button className="btn btn-link" onClick={() => updateSort('product')}>
                      <span className="nowrap">Product {arrow('product')}</span>
                    </button>
                  </th>
                  <th>
                    <button className="btn btn-link" onClick={() => updateSort('company')}>
                      <span className="nowrap">Company {arrow('company')}</span>
                    </button>
                  </th>
                  <th>
                    <button className="btn btn-link" onClick={() => updateSort('expires_at')}>
                      Contract Expiration <span className="nowrap">Date {arrow('expires_at')}</span>
                    </button>
                  </th>
                  <th>
                    <button className="btn btn-link" onClick={() => updateSort('contractor')}>
                      Contract <span className="nowrap">Source {arrow('contractor')}</span>
                    </button>
                  </th>
                  <th>Data Administrator</th>
                  <th>Delegates</th>
                  <th>Archive</th>
                  <th>Show Product in Supplemental Information</th>
                  <th>Documentation</th>
                </tr>
              </thead>
              <InfiniteScroll
                pageStart={1}
                loadMore={infiniteScrollFunction}
                hasMore={infiniteScrollPagination.current_page < infiniteScrollPagination.last_page}
                element="tbody"
                loader={<InfiniteScrollLoader key="loader" />}>
                {rows}
              </InfiniteScroll>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistrictInventory;
