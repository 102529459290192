import React from "react";
import { Link } from "react-router-dom";
import { encode } from "../../../utils/cryptoHelper";
import DeletionButton from "../../Common/DeletionButton";

const DistrictRow = ({ district, deleteDistrict, isRegionAdmin, impersonateDistrictAdmin }) => {

  const deleteButton = district.user_can_delete ? (<DeletionButton
    onConfirm={() => {
      deleteDistrict(district.id);
    }}
  />) : null;

  const editButton = district.user_can_edit ? (<Link
    to={`/districts/${district.id}/update`}
    className="btn btn-xs btn-warning"
  >
    Edit
  </Link>) : null;

  return (
    <tr>
      <td>
        <Link to={`/regions/${district.region_id}`}>
          {district.region.name}
        </Link>
      </td>
      <td>
        <Link to={`/districts/${district.id}`}>
          {district.name}
        </Link>
      </td>
      <td>
        <a href={`/supplemental-information/${encode(district.id)}`}>
            Supplemental Information Report
        </a>
      </td>
      <td>
        <Link to={`/districts/${district.id}/inventory`}>
          Inventory
        </Link>
      </td>
      <td>
        <form>
          <input
            defaultValue="Delete Entry"
            className="sr-only"
            type="submit"
          />
          {editButton}
          {" "}
          {deleteButton}
        </form>
          {
              isRegionAdmin ?
                  <button onClick={() => impersonateDistrictAdmin(district.id)} style={{marginTop: 5}} className="btn btn-xs btn-info">
                      Impersonate District Admin
                  </button> : null
          }
      </td>
    </tr>
  );
};

export default DistrictRow;
