import apiInstance from "../utils/apiInstance";
import qs from "qs";
import { serverToClientProduct } from "../utils/converter";

export const searchForProducts = (type, search, page, startsWith, receivedEvent = "PRODUCT_SEARCH_RECEIVED") => async (
  dispatch,
  getState
) => {
  const urlOptions = { type, search, page, startsWith };

  const url = `products?${qs.stringify(urlOptions)}`;
  const response = await apiInstance.get(url);
  const products = {
    ...response.data,
    data: response.data.data.map(serverToClientProduct)
  };

  dispatch({ type: receivedEvent, products });
};

export const clearProductMergeSearch = () => async (
  dispatch,
  getState
) => {

  dispatch({ type: "CLEAR_PRODUCT_MERGE_SEARCH"});
};

export const updateProduct = (productId, data) => async (dispatch, getState) => {
  const url = `products/${productId}`;
  const response = await apiInstance.put(url, data);

  dispatch({
    type: "PRODUCT_RECEIVED",
    productId,
    product: serverToClientProduct(response.data)
  });
};

export const createProduct = productData => async (dispatch, getState) => {
  const url = `products`;

  const response = await apiInstance.post(url, productData);

  dispatch({
    type: "PRODUCT_RECEIVED",
    productId: response.data.id,
    product: serverToClientProduct(response.data)
  });

  return response.data;
};

export const deleteProduct = productId => async (dispatch, getState) => {
  const url = `products/${productId}`;

  await apiInstance.delete(url);

  dispatch({ type: "PRODUCT_DELETED", productId });
};

export const getProduct = productId => async (dispatch, getState) => {
  const url = `products/${productId}`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "PRODUCT_RECEIVED",
    productId,
    product: serverToClientProduct(response.data)
  });
};

export const mergeProducts = (productIdToKeep, productIdToDelete) => async (dispatch, getState) => {
  const url = `products/merge/${productIdToKeep}/${productIdToDelete}`;
  const response = await apiInstance.post(url);
}
