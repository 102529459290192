import apiInstance from "../utils/apiInstance";
import qs from "qs";

export const searchForInventoryRecords = page => async (dispatch, getState) => {
  const urlOptions = { page };
  const url = `inventory-records?${qs.stringify(urlOptions)}`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "INVENTORY_RECORD_SEARCH_RECEIVED",
    inventoryRecords: response.data
  });
};

export const createInventoryRecord = (districtId, record) => async (
  dispatch,
  getState
) => {
  const url = `district-inventory/${districtId}`;

  const response = await apiInstance.post(url, record);

  dispatch({
    type: "INVENTORY_RECORD_RECEIVED",
    inventoryRecordId: response.data.id,
    inventoryRecord: response.data
  });

  return response.data;
};

export const deleteInventoryRecord = inventoryRecordId => async (
  dispatch,
  getState
) => {
  const url = `inventory-records/${inventoryRecordId}`;

  await apiInstance.delete(url);

  dispatch({ type: "INVENTORY_RECORD_DELETED", inventoryRecordId });
};

export const toggleInventoryRecordArchive = inventoryRecordId => async (
  dispatch,
  getState
) => {
  const url = `inventory-records/${inventoryRecordId}/toggle-archive`;

  const response = await apiInstance.put(url);

  dispatch({
    type: "INVENTORY_RECORD_RECEIVED",
    inventoryRecordId,
    inventoryRecord: response.data
  });
}

export const checkInventoryRecord = async (productId, districtId) => {
  const url = `inventory-records/check/${productId}/${districtId}`;
  const response = await apiInstance.get(url);

  return response.data;
};

export const getInventoryRecord = inventoryRecordId => async (
  dispatch,
  getState
) => {
  const url = `inventory-records/${inventoryRecordId}`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "INVENTORY_RECORD_RECEIVED",
    inventoryRecordId,
    inventoryRecord: response.data
  });
};

export const updateInventoryRecord = (inventoryRecordId, record) => async (
  dispatch,
  getState
) => {
  const url = `inventory-records/${inventoryRecordId}`;
  const data = {
    ...record
  };

  const response = await apiInstance.put(url, data);

  dispatch({
    type: "INVENTORY_RECORD_RECEIVED",
    inventoryRecordId,
    inventoryRecord: response.data
  });

  return response.data;
};

export const updateInventoryRecordAttachmentArchived = (inventoryRecordId, attachmentId, archived) => async (
 dispatch,
 getState,
) => {
  const url = `inventory-records/${inventoryRecordId}/attachment/${attachmentId}/archive`;
  const data = {archived};

  const response = await apiInstance.put(url, data);
  
  dispatch({
    type: "INVENTORY_RECORD_RECEIVED",
    inventoryRecordId,
    inventoryRecord: response.data
  });

  return response.data;
}

export const importRecordsExcel = (file, districtId) => async (dispatch, getState) => {
  const url = `inventory-records/${districtId}/upload-from-excel`;

  const data = new FormData();
  data.append("file", file);

  const response = await apiInstance.post(url, data);

  return response.data;
};

export const exportInventoryReordImportErrorsExcel = (filename, districtId) => async (dispatch, getState) => {
  const url = `inventory-records/${districtId}/export-excel-upload-errors`;

  const data = { filename: filename };

  const response = await apiInstance.post(url, data, {responseType: 'blob'});

  return response.data;
}

export const downloadImportRecordsExceptionsExcel = (data) => async (dispatch, getState) => {
  const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
  const excelUrl = window.URL.createObjectURL(blob);
  let tempLink = document.createElement("a");
  tempLink.href = excelUrl;
  tempLink.setAttribute('download', 'invalid-rows.xlsx');
  tempLink.click();
  return true;
}

export const downloadImportInventoryRecordsTemplate = () => async (dispatch, getState) => {
  const url = `inventory-records/download-excel-template`;

  const response = await apiInstance.get(url, {responseType: 'blob'});
  const blob = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
  const excelUrl = window.URL.createObjectURL(blob);
  let tempLink = document.createElement("a");
  tempLink.href = excelUrl;
  tempLink.setAttribute('download', 'inventory-record-import-template.xlsx');
  tempLink.click();
  return true;
}

