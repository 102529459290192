import React, { Component } from "react";
import { withRouter } from "react-router";
import ProductList from "./ProductList";
import { connect } from "react-redux";
import { searchForProducts, deleteProduct } from "../../../actions/Product";
import { getProductTypeList } from "../../../actions/Options";
import qs from "qs";

class ProductListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ""
    };
  }

  async componentDidMount() {
    await this.props.getProductTypeList();

    const page = this.getCurrentPageFromProps(this.props);
    const search = this.getSearchFromProps(this.props);
    const startsWith = this.getStartsWithFromProps(this.props);

    this.setState({
      query: search
    });

    this.props.searchForProducts(null, search, page, startsWith);
  }

  async componentWillReceiveProps(nextProps) {
    const oldPage = this.getCurrentPageFromProps(this.props);
    const newPage = this.getCurrentPageFromProps(nextProps);
    const pageChanged = oldPage !== newPage;

    const oldSearch = this.getSearchFromProps(this.props);
    const newSearch = this.getSearchFromProps(nextProps);
    const searchChanged = oldSearch !== newSearch;

    const oldStartsWith = this.getStartsWithFromProps(this.props);
    const newStartsWith = this.getStartsWithFromProps(nextProps);
    const startsWithChanged = oldStartsWith !== newStartsWith;

    if (pageChanged || searchChanged ||  startsWithChanged) {
      this.props.searchForProducts(null, newSearch, newPage, newStartsWith);
    }
  }

  getCurrentPageFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    if (parsedSearch.page !== undefined) {
      return parseInt(parsedSearch.page, 10);
    }

    return 1;
  }

  getSelectedTypeFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    return parsedSearch.type || parsedSearch.startsWith || "";
  }

  getTypeFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    return parsedSearch.type || "";
  }

  deleteProduct = async productId => {
    const type = this.getTypeFromProps(this.props);
    const page = this.getCurrentPageFromProps(this.props);
    const search = this.getSearchFromProps(this.props);
    const startsWith = this.getStartsWithFromProps(this.props);

    await this.props.deleteProduct(productId);
    this.props.searchForProducts(type, search, page, startsWith);
  };

  getSearchFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    return parsedSearch.query || "";
  }

  getStartsWithFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    return parsedSearch.startsWith || "";
  }

  updateQuery = query => {
    this.setState({ query });
  };

  performSearch = query => {
    const { match, location } = this.props;

    if (query) {
      const search = location.search ? location.search.slice(1) : "";
      const parsedSearch = qs.parse(search);

      let newSearch = {
        ...parsedSearch,
        query,
        page: 1
      };

      delete newSearch.startsWith;

      const newRoute = {
        pathname: match.url,
        search: qs.stringify(newSearch)
      };

      this.props.history.push(newRoute);
    } else {
      const search = location.search ? location.search.slice(1) : "";
      const parsedSearch = qs.parse(search);

      let newSearch = {
        ...parsedSearch,
        query: "",
        page: 1
      };

      delete newSearch.query;
      delete newSearch.type;

      const newRoute = {
        pathname: match.url,
        search: qs.stringify(newSearch)
      };

      this.props.history.push(newRoute);
    }
  };

  selectType = newType => {
    const { match, location } = this.props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);

    let newSearch = {
      ...parsedSearch,
      startsWith: newType,
      page: 1
    };

    delete newSearch.type;
    delete newSearch.query;

    const newRoute = {
      pathname: match.url,
      search: qs.stringify(newSearch)
    };

    this.setState({query: ""})

    this.props.history.push(newRoute);
  };

  changePage = page => {
    const { match, location } = this.props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    const newSearch = { ...parsedSearch, page };

    const newRoute = {
      pathname: match.url,
      search: qs.stringify(newSearch)
    };

    this.props.history.push(newRoute);
  };

  render() {
    const selectedProductType = this.getSelectedTypeFromProps(this.props);
    const currentPage = this.getCurrentPageFromProps(this.props);

    const searchResult = this.props.productSearchResults;
    if (!searchResult) return <div>Loading...</div>;

    const productList = searchResult ? searchResult.data : null;
    const totalPages = searchResult ? searchResult.last_page : 0;


    return (
      <ProductList
        products={productList}
        selectType={this.selectType}
        selectedProductType={selectedProductType}
        currentPage={currentPage}
        changePage={this.changePage}
        totalPages={totalPages}
        performSearch={this.performSearch}
        query={this.state.query}
        updateQuery={this.updateQuery}
        deleteProduct={this.deleteProduct}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    productSearchResults: state.Product.searchResults
  };
};

const actions = {
  searchForProducts,
  getProductTypeList,
  deleteProduct
};

ProductListContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(ProductListContainer)
);

export default ProductListContainer;
