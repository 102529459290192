import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getDistrict, deleteDistrict } from "../../../actions/District";
import SingleDistrict from "./SingleDistrict";

class SingleDistrictContainer extends Component {
  getDistrictIdFromProps(props) {
    const { match } = props;
    const districtId = match.params.districtId;
    return districtId ? parseInt(districtId, 10) : undefined;
  }

  async componentDidMount() {
    const districtId = this.getDistrictIdFromProps(this.props);
    await this.props.getDistrict(districtId);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getDistrictIdFromProps(this.props);
    const newId = this.getDistrictIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getDistrict(newId);
    }
  }

  deleteDistrict = async districtId => {
    await this.props.deleteDistrict(districtId);
    this.props.history.push("/districts");
  };

  render() {
    const districtId = this.getDistrictIdFromProps(this.props);
    const district = this.props.districts[districtId];
    if (!district) return <div>Loading...</div>;

    return (
      <SingleDistrict district={district} deleteDistrict={this.deleteDistrict} />
    );
  }
}

const mapStateToProps = state => {
  return { districts: state.District.districtDict };
};

const actions = { getDistrict, deleteDistrict };

SingleDistrictContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(SingleDistrictContainer)
);

export default SingleDistrictContainer;
