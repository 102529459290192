const initialState = {
  attachmentTypes: null,
  inventoryRecordAttachmentTypes: null,
  districtAttachmentTypes: null,
  companyOptions: null,
  dataAdmins: {},
  districts: null,
  inventoryRecordAuthorities: {},
  myProductTypeList: null,
  productTypes: null,
  regions: null,
  classificationOptions: null,
  buildingOptions: null,
  authorities: null,
  securityOptions: null
};

const Options = (state = initialState, action) => {
  switch (action.type) {
    case "AUTHORITY_OPTIONS_RECEIVED":
      return { ...state, authorities: action.authorities };
    case "REQUEST_ATTACHMENT_TYPES":
      return { ...state, attachmentTypes: [] };
    case "ATTACHMENT_TYPES_RECEIVED":
      return { ...state, attachmentTypes: action.attachmentTypes };
    case "REQUEST_INVENTORY_RECORD_ATTACHMENT_TYPES":
      return { ...state, inventoryRecordAttachmentTypes: [] };
    case "INVENTORY_RECORD_ATTACHMENT_TYPES_RECEIVED":
      return { ...state, inventoryRecordAttachmentTypes: action.inventoryRecordAttachmentTypes };
    case "REQUEST_DISTRICT_ATTACHMENT_TYPES":
      return { ...state, districtAttachmentTypes: [] };
    case "DISTRICT_ATTACHMENT_TYPES_RECEIVED":
      return {
        ...state,
        districtAttachmentTypes: action.districtAttachmentTypes
      };
    case "DATA_ADMIN_OPTIONS_RECEIVED":
      return {
        ...state,
        dataAdmins: {
          ...state.dataAdmins,
          [action.districtId]: action.dataAdmins
        }
      };
    case "CLASSIFICATION_OPTIONS_RECEIVED":
      return { ...state, classificationOptions: action.classifications };
    case "BUILDING_OPTIONS_RECEIVED":
      return { ...state, buildingOptions: action.buildings };
    case "COMPANY_OPTIONS_RECEIVED":
      return { ...state, companyOptions: action.companies };
    case "DISTRICT_LIST_RECEIVED":
      return { ...state, districts: action.districts };
    case "PRODUCT_TYPES_RECEIVED":
      return { ...state, productTypes: action.productTypes };
    case "REGION_LIST_RECEIVED":
      return { ...state, regions: action.regions };
    case "SECURITY_OPTIONS_RECEIVED":
      return { ...state, securityOptions: action.options};
    default:
      return state;
  }
};

export default Options;
