import apiInstance from "../utils/apiInstance";
import qs from "qs";

export const searchForUsers = (page, district_id, search) => async (
    dispatch,
    getState
) => {
    const urlOptions = {page, district_id, search};
    const url = `users?${qs.stringify(urlOptions)}`;
    const response = await apiInstance.get(url);

    dispatch({type: "USER_SEARCH_RECEIVED", users: response.data});
};

export const createUser = (
    email,
    first_name,
    last_name,
    password,
    password_confirmation,
    user_type,
    company_id,
    district_id,
    regions,
    title,
) => async (dispatch, getState) => {
    const url = `users`;
    let data = {
        email,
        first_name,
        last_name,
        password,
        password_confirmation,
        user_type,
        regions
    };

    switch (user_type) {
        case "REGION_ADMINISTRATOR":
            data.regions = regions || [];
            break;
        case "COMPANY_ADMINISTRATOR":
            data.company_id = company_id || null;
            break;
        case "DATA_ADMINISTRATOR":
        case "DISTRICT_SYSTEM_ADMINISTRATOR":
        case "DISTRICT_ADMINISTRATOR":
            data.district_id = district_id || null;
            data.title = title || null;
            break;
        default:
    }

    const creationResponse = await apiInstance.post(url, data);

    const response = await apiInstance.get(`users/${creationResponse.data.id}`);

    dispatch({
        type: "USER_RECEIVED",
        userId: response.data.id,
        user: response.data
    });

    return response.data;
};

export const deleteUser = userId => async (dispatch, getState) => {
    const url = `users/${userId}`;

    await apiInstance.delete(url);

    dispatch({type: "USER_DELETED", userId});
};

export const getUser = userId => async (dispatch, getState) => {
    const url = `users/${userId}`;
    const response = await apiInstance.get(url);

    dispatch({type: "USER_RECEIVED", userId, user: response.data});
};

export const sendSetPasswordEmail = userId => async (dispatch, getState) => {
    const url = `users/${userId}/sendSetPasswordEmail`;
    return await apiInstance.post(url);
};

export const updateUser = (
    userId,
    email,
    first_name,
    last_name,
    password,
    password_confirmation,
    user_type,
    company_id,
    district_id,
    regions,
    title,
) => async (dispatch, getState) => {
    const url = `users/${userId}`;
    let data = {
        email,
        first_name,
        last_name,
        password,
        password_confirmation,
        user_type,
        regions
    };

    switch (user_type) {
        case "REGION_ADMINISTRATOR":
            data.regions = regions || [];
            break;
        case "COMPANY_ADMINISTRATOR":
            data.company_id = company_id || null;
            break;
        case "DATA_ADMINISTRATOR":
        case "DISTRICT_SYSTEM_ADMINISTRATOR":
        case "DISTRICT_ADMINISTRATOR":
            data.district_id = district_id || null;
            data.title = title || null;
            break;
        default:
    }

    await apiInstance.put(url, data);
    const response = await apiInstance.get(url);

    dispatch({type: "USER_RECEIVED", userId, user: response.data});
};

export const getDistrictAdminList = districtId => async (
    dispatch,
    getState
) => {
    const url = `options/data-admins/${districtId}`;
    const response = await apiInstance.get(url);

    dispatch({type: "DISTRICT_ADMIN_LIST_RECEIVED", userList: response.data});
};
