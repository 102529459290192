import apiInstance from "../utils/apiInstance";
import moment from "moment";

export const getDataAdministrators = districtId => async (
  dispatch,
  getState
) => {
  const url = `options/data-admins/${districtId}`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "DATA_ADMIN_OPTIONS_RECEIVED",
    dataAdmins: response.data,
    districtId
  });
};

export const getAuthorities = () => async (dispatch, getState) => {
  const url = `options/authorities`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "AUTHORITY_OPTIONS_RECEIVED",
    authorities: response.data
  });
};

export const getDistrictAttachmentTypes = () => async (dispatch, getState) => {
  dispatch({
    type: "REQUEST_DISTRICT_ATTACHMENT_TYPES"
  });
  const url = `options/district-attachment-types`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "DISTRICT_ATTACHMENT_TYPES_RECEIVED",
    districtAttachmentTypes: response.data
  });
};

export const getAttachmentTypes = () => async (dispatch, getState) => {
  dispatch({
    type: "REQUEST_ATTACHMENT_TYPES"
  });
  const url = `options/attachment-types`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "ATTACHMENT_TYPES_RECEIVED",
    attachmentTypes: response.data
  });
};

export const getInventoryRecordAttachmentTypes = () => async (dispatch, getState) => {
  dispatch({
    type: "REQUEST_INVENTORY_RECORD_ATTACHMENT_TYPES"
  });
  const url = `options/inventory-record-attachment-types`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "INVENTORY_RECORD_ATTACHMENT_TYPES_RECEIVED",
    inventoryRecordAttachmentTypes: response.data
  });
};

export const getServiceProviderAttachmentTypes = async () => {
  const url = `options/service-provider-attachment-types`;
  const response = await apiInstance.get(url);
  return response.data;
}

export const getClassificationOptions = () => async (dispatch, getState) => {
  const response = await apiInstance.get("options/data-classifications");
  dispatch({
    type: "CLASSIFICATION_OPTIONS_RECEIVED",
    classifications: response.data
  });
};

export const getCompanyOptions = () => async (dispatch, getState) => {
  const url = `options/companies`;
  const response = await apiInstance.get(url);

  response.data.sort((left, right) => {
    if (left.name < right.name) {
      return -1;
    }
    if (left.name > right.name) {
      return 1;
    }

    return 0;
  });

  dispatch({ type: "COMPANY_OPTIONS_RECEIVED", companies: response.data });
};

export const getBuildingOptions = () => async (dispatch, getState) => {
  const response = await apiInstance.get("options/buildings");
  dispatch({
    type: "BUILDING_OPTIONS_RECEIVED",
    buildings: response.data
  });
};

export const getDistrictList = () => async (dispatch, getState) => {
  const url = `options/districts`;
  const response = await apiInstance.get(url);

  dispatch({ type: "DISTRICT_LIST_RECEIVED", districts: response.data });
};

export const getProductTypeList = () => async (dispatch, getState) => {
  const response = await apiInstance.get("options/my-product-type-list");

  dispatch({ type: "PRODUCT_TYPES_RECEIVED", productTypes: response.data });
};

export const getRegionList = () => async (dispatch, getState) => {
  const url = `options/regions`;
  const response = await apiInstance.get(url);

  dispatch({ type: "REGION_LIST_RECEIVED", regions: response.data });
};

export const getSecurityOptions = () => async (dispatch, getState) =>  {
  const url = `options/security`;
  const response = await apiInstance.get(url);

  dispatch({ type: "SECURITY_OPTIONS_RECEIVED", options: response.data });
};

export const updatePasswordPolicyExpiration = password_policy_expiration => async (dispatch, getState) => {
  const url = 'options/security/password-expiration';
  const data = {password_policy_expiration};
  try {
    await apiInstance.put(url, data);
  }
  catch(e) {
    return e;
  }
  dispatch({ type: "SECURITY_OPTIONS_RECEIVED", options: {...getState().Options.securityOptions, password_policy_expiration}});
  return true;
};

export const invalidatePasswords = () => async (dispatch, getState) => {
  const url = 'options/security/invalidate-all-passwords';
  try {
    await apiInstance.put(url);
  }
  catch(e) {
    return e;
  }

  const today = moment().format('YYYY-MM-DD');
  dispatch({ type: "SECURITY_OPTIONS_RECEIVED", options: {...getState().Options.securityOptions, password_all_expired: today}});

  return true;
};
