import React from "react";
import { Link } from "react-router-dom";
import renderFor from "../../../utils/renderFor";
import DeletionButton from "../../Common/DeletionButton";

const ProductHeader = ({
  product,
  deleteProduct,
  addProductToDistrictInventory
}) => {
  return (
    <div className="text-right">
      {renderFor(
        {
          regionAdmin: true,
          districtAdmin: true,
          districtSystemAdmin: true,
          dataAdmin: true
        },
        () => {
          return (
            <a
              onClick={e => {
                e.preventDefault();
                addProductToDistrictInventory(product);
              }}
              className="btn btn-sm btn-success"
            >
              Add Product to District Inventory
            </a>
          );
        }
      )}
      {
        renderFor({}, () => {
          return (
            <span>
            {" "}
              <Link
                to={`/products/${product.id}/merge`}
                className="btn btn-sm btn-warning btn-primary"
              >
              Product Merge
            </Link>
          </span>
          );
        })
      }
      {renderFor({companyAdmin: true}, () => {
        return (
          <span>
            {" "}
            <Link
              to={{
                pathname: `/products/${product.id}/update`,
                state: { returnUrl: window.location.pathname }
              }}
              className="btn btn-sm btn-warning"
            >
              Edit
            </Link>
            {" "}
            <DeletionButton
              className="btn btn-sm btn-danger deletion-link"
              onConfirm={() => {
                deleteProduct(product.id);
              }}
            />
          </span>
        );
      })}
    </div>
  );
};

export default ProductHeader;
