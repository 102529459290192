import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import UpdateServiceProviderContainer from "./ServiceProviderEditor/UpdateServiceProviderContainer";
import SingleServiceProviderContainer from './SingleServiceProvider/SingleServiceProviderContainer';
import ServiceProviderDistrictSelector from "./ServiceProviderDistrictSelector";

const ServiceProvidersContainer = (props) => {
    const { match } = props;

    return (
        <div className="service-providers-container">
            <Switch>
                <Route
                    path={`${match.url}/:serviceProviderId/update`}
                    render={(props) => (<UpdateServiceProviderContainer {...props} />)}
                />
                <Route
                    path={`${match.url}/selector`}
                    render={(props) => (<ServiceProviderDistrictSelector {...props} />)}
                />
                <Route
                    path={`${match.url}/:serviceProviderId`}
                    render={(props) => (<SingleServiceProviderContainer {...props} />)}
                />
            </Switch>
        </div>
    )
}

const mapStateToProps = (props) => ({
    currentUser: props.Account.currentUser,
    ...props
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProvidersContainer);