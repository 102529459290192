import React, { Component } from "react";
import { withRouter } from "react-router";
import SupplementalInformationList from "./SupplementalInformationList";
import { connect } from "react-redux";
import { searchForBillOfRights } from "../../../actions/BillOfRights";
import qs from "qs";

import PageHeader from "../../Common/PageHeader";
import {decode} from "../../../utils/cryptoHelper";

// TODO: Add Document show in supplemental
class SupplementalInformationListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
    };
  }

  componentDidMount() {
    this.performSimpleSearch();
  }

  performSimpleSearch = (page = 1) => {
    const districtId = this.getDistrictIdFromProps(this.props);
    const search = this.getSearchFromProps(this.props);

    this.props.searchForBillOfRights(districtId, search, page);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.billOfRightsSearchResults && nextProps.billOfRightsSearchResults.district) {
      this.props.setDistrictName(nextProps.billOfRightsSearchResults.district.name)
      this.props.setHideServiceProviders(nextProps.billOfRightsSearchResults.district.hide_service_providers_si);
    }
    const oldId = this.getDistrictIdFromProps(this.props);
    const newId = this.getDistrictIdFromProps(nextProps);
    const idChanged = oldId !== newId;

    const oldSearch = this.getSearchFromProps(this.props);
    const newSearch = this.getSearchFromProps(nextProps);
    const searchChanged = oldSearch !== newSearch;

    if (searchChanged || idChanged) {
      this.props.searchForBillOfRights(newId, newSearch, 1);
    }
  }

  getDistrictIdFromProps = props => {
    const { match } = props;
    const districtId = match.params.districtId;
    const decoded = decode(districtId);

    return decoded ? parseInt(decoded, 10) : undefined;
  };

  getSearchFromProps = props => {
    const { location } = props;
    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    return parsedSearch.query || "";
  };

  getFilterFromProps = props => {
    const parsedSearch = this.getSearchFromProps(props);

    return parsedSearch.filter || null;
  };

  performSearch = query => {
    const { match, location } = this.props;

    if (query) {
      const search = location.search ? location.search.slice(1) : "";
      const parsedSearch = qs.parse(search);

      let newSearch = {
        ...parsedSearch,
        query,
      };

      delete newSearch.startsWith;

      const newRoute = {
        pathname: match.url,
        search: qs.stringify(newSearch)
      };

      this.props.history.push(newRoute);
    } else {
      const search = location.search ? location.search.slice(1) : "";
      const parsedSearch = qs.parse(search);

      let newSearch = {
        ...parsedSearch,
        query: "",
      };

      delete newSearch.query;
      delete newSearch.type;

      const newRoute = {
        pathname: match.url,
        search: qs.stringify(newSearch)
      };

      this.props.history.push(newRoute);
    }
  };

  updateQuery = query => {
    this.setState({ ...this.state, query });
    if (!query) {
      this.performSearch('');
    }
  };

  render() {
    const searchResult = this.props.billOfRightsSearchResults;
    if (!searchResult) return <div>Loading...</div>;

    const inventoryRecordList = searchResult.inventoryRecords;
    const districtName = searchResult.district.name;

    return (
      <div style={{display: this.props.hidden ? 'none' : ''}}>
        <SupplementalInformationList
          inventoryRecords={inventoryRecordList}
          districtName={districtName}
          attachments={searchResult.district.district_attachments || []}
          query={this.state.query}
          updateQuery={this.updateQuery}
          performSearch={this.performSearch}
          infiniteScrollFunction={this.performSimpleSearch}
          infiniteScrollPagination={this.props.pagination}
        />
        <hr />

        {/*<DistrictAttachmentList districtAttachments={districtAttachmentList} />*/}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    billOfRightsSearchResults: state.BillOfRights.searchResults,
    pagination: state.BillOfRights.pagination
  };
};

const actions = {
  searchForBillOfRights
};

SupplementalInformationListContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(SupplementalInformationListContainer)
);

export default SupplementalInformationListContainer;
