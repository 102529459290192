import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import SingleRecordContainer from "./SingleRecord/SingleRecordContainer";
import NewRecordContainer from "./RecordEditor/NewRecordContainer";
import UpdateRecordContainer from "./RecordEditor/UpdateRecordContainer";
import InventoryDistrictSelector from "./InventoryDistrictSelector";
import NewDelegateContainer from "../Delegates/DelegateEditor/NewDelegateContainer";
import ImportRecordsContainer from "./ImportRecords/ImportRecordsContainer";

class InventoryRecordContainer extends Component {
  render() {
    const { match } = this.props;

    return (
      <div className="inventory-container">
        <Switch>
          <Route
            path={`${match.url}/:inventoryId/delegates/create`}
            component={NewDelegateContainer}
          />
          <Route
            path={`${match.url}/:productId/:districtId/create`}
            component={NewRecordContainer}
          />
          <Route
            path={`${match.url}/:inventoryId/update`}
            component={UpdateRecordContainer}
          />

          <Route
            path={`${match.url}/:districtId/import`}
            render={(props) => <ImportRecordsContainer {...props} />}
          />

          <Route
            path={`${match.url}/selector`}
            component={InventoryDistrictSelector}
          />
          <Route
            path={`${match.url}/:inventoryId`}
            component={SingleRecordContainer}
          />
          <Route path={match.url} component={null} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = {};

InventoryRecordContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(InventoryRecordContainer);

export default InventoryRecordContainer;
