import React from "react";

const DistrictSelector = ({
  selectRegion,
  selectedRegion,
  regions,
  districts,
  selectDistrict
}) => {
  const regionItems = Object.keys(regions || {}).map(regionId => {
    return (
      <button
        key={regionId}
        onClick={e => {
          e.preventDefault();
          selectRegion(regionId);
        }}
        className={`btn btn-link list-group-item ${selectedRegion === regionId
          ? " active"
          : ""}`}
      >
        {regions[regionId]}
      </button>
    );
  });

  const districtRows = districts
    ? districts.map(district =>
        <tr key={district.id}>
          <td>
            <button
              className="btn btn-link"
              onClick={e => {
                e.preventDefault();
                selectDistrict(district);
              }}
            >
              {district.name}
            </button>
          </td>
        </tr>
      )
    : null;

  return (
    <div className="row">
      <div className="col-md-9 col-md-push-3">
        <p className="lead">
          {selectedRegion ? selectedRegion.name : "Please select a region"}
        </p>
        {selectedRegion
          ? <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>District</th>
                  </tr>
                </thead>
                <tbody>
                  {districtRows}
                </tbody>
              </table>
            </div>
          : null}
      </div>
      <div className="col-md-3 col-md-pull-9">
        <p className="lead">Region</p>
        <div className="list-group">
          {regionItems}
        </div>
      </div>
    </div>
  );
};

export default DistrictSelector;
