const initialState = {
  attachmentTypes: null
};

const Atttachment = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default Atttachment;
