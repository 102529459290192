const initialState = {
  searchResults: null,
  suggestedProductDict: {}
};

const SuggestedProduct = (state = initialState, action) => {
  switch (action.type) {
    case "SUGGESTED_PRODUCT_SEARCH_RECEIVED":
      return { ...state, searchResults: action.suggestedProducts };
    case "SUGGESTED_PRODUCT_RECEIVED":
      return {
        ...state,
        suggestedProductDict: {
          ...state.suggestedProductDict,
          [action.suggestedProductId]: action.suggestedProduct
        }
      };
    case "SUGGESTED_PRODUCT_DELETED":
      const newSuggestedProductDict = { ...state.suggestedProductDict };
      delete newSuggestedProductDict[action.suggestedProductId];
      return {
        ...state,
        suggestedProductDict: newSuggestedProductDict
      };
    case "SUGGESTED_PRODUCT_APPROVED":
      return state;
    case "SUGGESTED_PRODUCT_UPDATED":
      return state;
    default:
      return state;
  }
};

export default SuggestedProduct;
