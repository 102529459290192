import React from "react";
import PageHeader from "../../Common/PageHeader";
import ErrorAlert from "../../Common/ErrorAlert";
import SuccessAlert from "../../Common/SuccessAlert";

const ImportRecordsForm = ({
    error,
    uri,
    uploadFile,
    isUploading,
    wasUploaded,
    errorsDownloading,
    downloadTemplate
}) => {
    const disableUri =
        uri.indexOf("https://dpit.s3.amazonaws.com") >= 0 ||
        uri.indexOf("https://s3.amazonaws.com/dpit") >= 0;

    return (
        <div className="import-records">
            <PageHeader
                title="Inventory Records"
                subtitle="Import Records"
            />
            <div className="row">
                <div className="col-sm-12 text-center">
                    {/* <a href="/inventory-record-import-template.xlsx" className="btn btn-warning">Download Template</a>
                     */}
                     <button type="button" onClick={downloadTemplate} className="btn btn-warning">Download Template</button>
                </div>
            </div>
            <br />
            <form onSubmit={e => {
                    e.preventDefault();
                }}>
                <div className="row">
                    <div className="form-group col-xs-6">
                        <label>Upload Excel Records</label>
                        <input type="file" onChange={uploadFile} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                    </div>
                </div>

                {(isUploading ? <SuccessAlert message="Processing your import..." /> : <span></span>)}
                {(wasUploaded ? <SuccessAlert message="Your import was processed." /> : <span></span>)}
                {(errorsDownloading ? <ErrorAlert error="A copy of your excel containing any errors will download shortly." /> : <span></span>)}
                <ErrorAlert error={error} />
                <br />

                <div className="row">
                    <div className="col-sm-12">
                        <p className="lead">Instructions:</p>
                    </div>
                    <div className="col-sm-6">
                        <ol>
                            <li>Download the Import Template by clicking the yellow button.</li>
                            <li>Open the template in Excel.</li>
                            <li>Fill in a row of the template for each new Product you wish to import to the DPIT.
                                <ul>
                                    <li>The only required field is the Product Name.</li>
                                    <li>Make sure any dates are of the format MM/DD/YYYY.</li>
                                    <li>Some fields have specific values defined in a dropdown (eg. Contract Source, and Criticality). Make sure the supplied values are the only values in these fields.</li>
                                    <li>Each column to the right of Renews Annually is a Data Category to add to the Imported Product.</li>
                                    <li>The Show in Supplemental Information box on the Product screen will be checked by default upon successful import for each Document link in the file.</li>
                                </ul>
                            </li>
                            <li>After you have filled out the template, save and upload your file using the 'Choose File' button above.</li>
                            <li>All Products that have been successfully imported will appear below.</li>
                            <li>If we found any errors when processing your file, your browser will automatically download a new spreadsheet called invalidrows.xlsx. This file will include your entries with an error column on each row with suggestions on how to fix that entry. Fix the errors in the invalidrows.xlsx sheet and upload the file to finish your import.</li>
                        </ol>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ImportRecordsForm;
