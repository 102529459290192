import React from "react";
import RegionRow from "./RegionRow";
import PageHeader from "../../Common/PageHeader";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";

const RegionList = ({
  regions,
  currentPage,
  changePage,
  totalPages,
  deleteRegion
}) => {
  const regionRows = regions
    ? regions.map(region => (
        <RegionRow
          key={region.id}
          region={region}
          deleteRegion={deleteRegion}
        />
      ))
    : null;

  const paginator = totalPages > 1
    ? <Pagination
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        items={totalPages}
        maxButtons={5}
        activePage={currentPage}
        onSelect={changePage}
      />
    : null;

  return (
    <div className="region-list-container">
      <PageHeader title="Regions" subtitle="Region Listing" />
      <div className="row">
        <div className="col-sm-12 text-right">
          <Link to="/regions/create" className="btn btn-success">
            Add Region
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Region</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {regionRows}
              </tbody>
            </table>
          </div>
          {paginator}
        </div>
      </div>
    </div>
  );
};

export default RegionList;
