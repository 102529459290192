import React from "react";
import { Alert } from "react-bootstrap";

const ErrorAlert = ({ error, ...props }) => {
  if (!error) return null;
  
  if (typeof error === "string") {
    return <Alert {...props} bsStyle="danger">{error}</Alert>;
  }

  if (error instanceof Error) {
    return <ErrorAlert {...props} error={error.message} />;
  }

  if (typeof error === "object") {
    // Server error that returned a single error
    if (typeof error.error === "string") {
      return <ErrorAlert {...props} error={error.error} />;
    } else {
      const firstErrorKey = Object.keys(error)[0];

      return <ErrorAlert {...props} error={error[firstErrorKey]} />;
    }
  }

  // Unsure what to do here
  console.debug("Found an unexpected error case");
  console.error(error);

  return null;
};

export default ErrorAlert;
