import React from 'react';

const DistrictInventoryAttachmentPanel = ({
  attachmentTypes,
  handleChange,
  type,
  name,
  uri,
  description,
  uploadFile,
  show_in_bor,
  showInBORCheckbox,
  index,
  archived,
  isArchived,
  newAttachment
}) => {
  let uploadInput = null;

  const showInBOROption = showInBORCheckbox ? (
    <div className="checkbox">
      <label>
        <input
          type="checkbox"
          name="show_in_bor"
          checked={show_in_bor}
          onChange={(e) => {
            const state = {
              target: {
                value: !show_in_bor,
                name: 'show_in_bor'
              },
              preventDefault: () => {}
            };

            handleChange(state);
          }}
          disabled={isArchived}
        />
        Show Document in Supplemental Information
      </label>
    </div>
  ) : null;

  const archiveDocument = (
    <div className="checkbox">
      <label>
        <input
          type="checkbox"
          name="archived"
          checked={archived}
          disabled={newAttachment}
          onChange={(e) => {
            const state = {
              target: {
                value: !archived,
                name: 'archived'
              },
              preventDefault: () => {}
            };

            handleChange(state);
          }}
        />
        Archive Document
      </label>
    </div>
  )

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <div className="panel-title">Document {index + 1}</div>
      </div>
      <div className="panel-body">
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="control-label" htmlFor={`attachments-${index}-type`}>
                Type
              </label>
              <select
                className="form-control"
                id={`attachments-${index}-type`}
                name="type"
                value={type || 'undefined'}
                onChange={handleChange}>
                <option value>Choose...</option>
                {attachmentTypes.map((type) => {
                  return (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor={`attachments-${index}-uri`}>
                URL
              </label>
              <input
                className="form-control"
                name="uri"
                id={`attachments-${index}-uri`}
                value={uri}
                onChange={handleChange}
                type="text"
              />
            </div>
            {showInBOROption}
            {archiveDocument}
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label htmlFor={`attachments-${index}-name`} className="control-label">
                Name
              </label>
              <input
                className="form-control"
                id={`attachments-${index}-name`}
                name="name"
                type="text"
                value={name}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Or upload a file</label>
              <input
                ref={(input) => (uploadInput = input)}
                type="file"
                accept=".pdf"
                onChange={uploadFile}
              />
            </div>

            <div className="form-group">
              <label className="control-label" htmlFor={`attachments-${index}-description`}>
                Description
              </label>
              <textarea
                rows={4}
                name="description"
                id={`attachments-${index}-description`}
                className="form-control"
                onChange={handleChange}
                value={description || ''}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistrictInventoryAttachmentPanel;
