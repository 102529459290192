import React, {useState, useRef, useEffect} from 'react';

import {connect} from 'react-redux';
import {searchForProducts, clearProductMergeSearch} from "../../../actions/Product";
import ProductMergePane from "./ProductMergePane";
import _ from 'lodash';

const ProductMergeSearch = ({
                              baseProductId,
                              searchResults,
                              searchForProducts,
                              clearProductMergeSearch,
                              selectedProduct,
                              selectProduct,
                              mergingProductId,
                              setMergingProductId
                            }) => {

  const [searchQuery, setSearchQuery] = useState("");

  const debouncedSearchForProducts = useRef(_.debounce(
    query => {
      if (query === "") {
        clearProductMergeSearch();
      } else {
        searchForProducts(null, query, null, null, "PRODUCT_MERGE_SEARCH_RECEIVED")
      }
    }, 250)).current;

  useEffect(() => {
    return () => {
      clearProductMergeSearch();
    }
  }, [])

  const onSearchChangeFunction = function (event) {
    const newValue = event.target.value;

    debouncedSearchForProducts(newValue);
    setSearchQuery(newValue);
  }

  const clearProduct = () => {
    clearProductMergeSearch();
    selectProduct(null);
    setSearchQuery("");
  }

  const searchOutput = searchResults && searchResults.data.length ? searchResults.data.filter(result => (result.id !== baseProductId))
  .map(result => (
    <button className="list-group-item" key={result.id} onClick={() => selectProduct(result.id)}>
      {result.name}
    </button>))
    :
    (
      <li className="list-group-item">No Results</li>
    )


  if (selectedProduct === null) {
    return (
      <div className="panel panel-default product-merge_search-container clearfix">
        <div>
        <label className="btn-group search-clear-group">
          Search for Product to Merge
          <input type="search" className="form-control" placeholder="Search" onChange={onSearchChangeFunction} value={searchQuery}/>
          {
            searchQuery !== "" ?
            <span style={{top: 20}} className="glyphicon glyphicon-remove-circle search-clear" onClick={clearProduct}></span>

            : null
          }

        </label>
        </div>

        {(searchResults) &&
        <div className="list-group">
          {searchOutput}
        </div>
        }
      </div>
    )
  }
else {
    return (
      <div className="panel panel-default">
        <div className="product-merge_title-container clearfix">
          <h4>Merge Product 2</h4>
          <button  className="btn btn-sm btn-warning pull-right" onClick={clearProduct}>Clear</button>
          <hr />
        </div>
        <ProductMergePane mergingProductId={mergingProductId} product={selectedProduct} setMergingProductId={setMergingProductId}/>
      </div>
    )
  }
};

const mapStateToProps = state => (
  {
    searchResults: state.Product.mergeSearchResults
  }
);

const mapDispatchToProps =
  {
    clearProductMergeSearch,
    searchForProducts
  }

export default connect(mapStateToProps, mapDispatchToProps)(ProductMergeSearch);
