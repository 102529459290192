import React, {Component} from 'react';

class PasswordExpiration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: ""
    }

  }

  componentDidMount() {
    this.setState( {
      value: this.props.passwordExpirationDays
    });
  }

  updateValue = (e) => {
    const {value} = e.target;
    this.setState({
       value
    });
  };

  submitValue = () => {
    this.props.update(this.state.value);
  };

  render() {
    return(
      <div className="col-sm-3">
        <p className="lead">Password Expiration</p>
        <div className="form-group">
          <label htmlFor="password_expiration">Password Expiration time (in days)</label>
          <br/>
          <small>Set to 0 to disable password expiration</small>
          <input
            id="password_expiration"
            className="form-control"
            min="0"
            required
            onChange={this.updateValue}
            style={{marginBottom: 10}}
            type="number"
            value={this.state.value}
          />
            <button
              onClick={this.submitValue}
              disabled={this.state.value === "" || this.state.value < 0}
              className="btn btn-primary">Save</button>
        </div>
      </div>
    );
  }
}

export default PasswordExpiration;