import React, { useEffect } from "react";
import { connect } from "react-redux";
import ServiceProviderRow from "./ServiceProviderRow";
import PageHeader from "../../Common/PageHeader";
import { Link } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScrollLoader from "../../Common/InfiniteScrollLoader";
import Search from "../../Common/Search";
import renderFor from "../../../utils/renderFor";


const ServiceProvidersList = ({
    districtId,
    districtName,
    districtServiceProviders,
    infiniteScrollFunction,
    infiniteScrollPagination,
    updateExpandAttachmentsServiceProviderId,
    expandAttachmentsServiceProviderId,
    query,
    sort,
    order,
    updateSort,
    updateQuery,
    performSearch,
    tempQuery,
    onSupplementalPage,
    currentUser
}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => window.scrollTo(0, 0);
    }, []);

    const serviceProviderRows = districtServiceProviders ?
        districtServiceProviders.map(districtServiceProvider => {
            return (
                <ServiceProviderRow
                    key={districtServiceProvider.id}
                    serviceProvider={districtServiceProvider}
                    expandAttachmentsServiceProviderId={expandAttachmentsServiceProviderId}
                    updateExpandAttachmentsServiceProviderId={updateExpandAttachmentsServiceProviderId}
                    onSupplementalPage={onSupplementalPage}
                />
            );
        }) : null;


    let chevronClassName = "glyphicon ";
    const arrow = (columnName) => {
        let chevronClass = chevronClassName;
        if (sort === columnName && order === 'desc') {
            chevronClass = chevronClassName + 'glyphicon-chevron-down';
        } else {
            chevronClass = chevronClassName + 'glyphicon-chevron-up';
        }
        return (
            <span className={chevronClass} style={{ color: sort === columnName ? '#337ab7' : '#8ca8c2' }}></span>
        );
    };

    return (
        <div className="service-providers">
            {onSupplementalPage ? '' :
            <PageHeader
                title="Service Providers"
                subtitle="Service Providers Listing"
            />
            }
            <div className="row">
                <div className="col-sm-12">
                    {onSupplementalPage ? null : <p className="lead">{districtName}</p>}
                    <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                        <Search query={tempQuery} updateQuery={updateQuery} performSearch={performSearch} showClearButton={true} />
                        {onSupplementalPage ? null : (renderFor({superAdmin: true, districtAdmin: true, districtSystemAdmin: true, regionAdmin: true}, () => {
                            return (
                            <Link to={`/districts/${districtId}/service-providers/create`} className="btn btn-success">
                                Add Service Provider
                            </Link>);
                        }))}
                    </div>
                    {districtServiceProviders ?
                    <div className="table-responsive">
                        <table className="table table-hover" key={query + order + sort + districtId}>
                            <thead>
                                <tr>
                                    <th><button className="btn btn-link" onClick={() => updateSort("name")}>Provider Name {arrow("name")}</button></th>
                                    <th><button className="btn btn-link" onClick={() => updateSort("expires_at")}>Contract Expiration Date {arrow("expires_at")}</button></th>
                                    <th>Description</th>
                                    {onSupplementalPage ? null : <th>Publish Description</th>}
                                    {onSupplementalPage ? null : <th>Publish Service Provider</th>}
                                    {onSupplementalPage ? <th>Supporting Documentation</th>  : <th>Documentation</th>}
                                </tr>
                            </thead>
                            <InfiniteScroll
                                pageStart={1}
                                loadMore={infiniteScrollFunction}
                                hasMore={infiniteScrollPagination.current_page < infiniteScrollPagination.last_page}
                                element='tbody'
                                loader={<InfiniteScrollLoader key="loader" />}>
                                {serviceProviderRows}
                            </InfiniteScroll>
                        </table>
                    </div>
                    : <div>Loading...</div>}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.Account.currentUser,
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProvidersList);
