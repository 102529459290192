import React from "react";
import { Link } from "react-router-dom";
import DeletionButton from "../../Common/DeletionButton";

const ClassificationRow = ({ classification, deleteClassification }) => {
  return (
    <tr>
      <td>
        {classification.name}
      </td>
      <td className="text-right">
        <Link
          to={`/categories/${classification.id}/update`}
          className="btn btn-xs btn-warning"
        >
          Edit
        </Link>
        {" "}
        <DeletionButton
          onConfirm={() => {
            deleteClassification(classification.id);
          }}
        />
      </td>
    </tr>
  );
};

export default ClassificationRow;
