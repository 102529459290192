import React from "react";
import { Link } from "react-router-dom";
import DeletionButton from "../../Common/DeletionButton";
import {withRouter} from 'react-router';
import renderFor from "../../../utils/renderFor";

const CompanyRow = ({ company, deleteCompany, location }) => {

  return (
    <tr>
      <td>
        <Link to={{
          pathname: `/companies/${company.id}`,
          state: {pathname: '/companies', search: location.search}
        }}>
          {company.name}
        </Link>
      </td>
      {
        renderFor({}, () => (
          <td className="text-right">
            <Link
              to={`/companies/${company.id}/update`}
              className="btn btn-xs btn-warning"
            >
              Edit
            </Link>
            {" "}
            <DeletionButton
              onConfirm={() => {
                deleteCompany(company.id);
              }}
            />
          </td>
        ))
      }
    </tr>
  );
};

export default withRouter(CompanyRow);
