import React from "react";
import PageHeader from "../../Common/PageHeader";
import { DropdownButton, MenuItem, FormGroup, Checkbox } from "react-bootstrap";
import ConfirmationButton from "../../Common/ConfirmationButton";
import './DataAdmin.css';
import SuccessAlert from "../../Common/SuccessAlert";

const DataAdminForm = ({
    district,
    dataAdminsList,
    changeDataAdmin,
    updateProducts,
    handleChange,
    inventoryRecords,
    dataAdmin,
    toggleSelectAll,
    selectAll,
    formData,
    documentChanged,
    successMessage
}) => {

    const products = (
        formData && inventoryRecords.map((record) => {
            return (
                <Checkbox key={record.id} name={record.id} title={record.id.toString()} onChange={() => handleChange(record.id)} checked={formData[record.id].checked}>{record.product.name} {record.data_admin ? `(Current Data Admin: ${record.data_admin.first_name} ${record.data_admin.last_name})` : ""}</Checkbox>
            );
        })
    );

    return (
        <div className="data-admins-form-container">
            <PageHeader
                title="Data Admins"
                subtitle="Assign Products"
            />
            {district && formData ?
                (dataAdmin && dataAdminsList ?
                (<div className="row">
                    <div className="col-sm-12">
                        <p className="lead">{district.name}</p>
                        <hr />
                    </div>
                    <div className="col-sm-12" style={{marginBottom: '10px'}}>
                        <DropdownButton title={dataAdmin.first_name + ' ' + dataAdmin.last_name} id="data-admin-dropdown">
                            {dataAdminsList.map(admin => {
                                return (
                                    <MenuItem key={admin.id} eventKey={admin.id} onSelect={changeDataAdmin}>{admin.first_name + ' ' + admin.last_name}</MenuItem>
                                )
                            })}
                        </DropdownButton>
                    </div>
                    <div className="col-sm-12">
                            <Checkbox name="select_all" title="select_all" checked={selectAll} onChange={toggleSelectAll}>Select All</Checkbox>
                            <br />
                            <FormGroup>
                                {products ? products : <div>Loading...</div>}
                            </FormGroup>
                            <br />
                    </div>
                    <div className="form-group col-sm-12 text-left">
                    <ConfirmationButton
                        isDisabled={!documentChanged}
                        title="Are you sure?"
                        body="Assigning this Data Admin to the chosen inventory records will remove any other Data Admin currently assigned to that inventory record"
                        confirmBeforeExecuting={documentChanged}
                        confirmCallback={() => updateProducts()}
                        buttonText="Add to Selected Products"
                        />
                    </div>
                    {successMessage && !documentChanged ? <div className="col-sm-12">
                        <SuccessAlert message={successMessage}/>
                    </div> : null}
                </div>) : 
                <div className="row">
                    <div className="col-sm-12">
                        <p className="lead">The district has no Data Admins.</p>
                    </div>
                </div>) : (<div>Loading...</div>)}
        </div>
    )
}

export default DataAdminForm;