import React from 'react';
import ConfirmationButton from '../../Common/ConfirmationButton';

const ArchivedAttachmentTable = ({ attachments, unarchiveAttachment, disabled }) => (
  <table className="table table-product-view">
    <tbody>
      {attachments.map((attachment) => {
        return (
          <tr key={attachment.id}>
            <th style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
              <a href={attachment.uri} target="_blank" rel="noopener noreferrer">
                {attachment.name}{' '}
              </a>
              <ConfirmationButton
                disabled={disabled}
                title="Are you sure?"
                body="Unarchiving this attachment will make it active in the District Inventory."
                confirmCallback={() => unarchiveAttachment(attachment.id)}
                buttonText="Unarchive"
              />
            </th>
          </tr>
        );
      })}
    </tbody>
  </table>
);

export default ArchivedAttachmentTable;
