import React from "react";

const NewAttachmentPanel = ({
  handleChange,
  name,
  uri,
  description,
  maxDescriptionLength,
  saveAttachment,
  uploadFile,
  showInBORCheckbox,
  showInSupplementalInformation,
  checkboxText,
  characterLimit
}) => {
  const disableUri = uri.indexOf("https://dpit.s3.amazonaws.com") >= 0 || uri.indexOf("https://s3.amazonaws.com/dpit") >= 0;
  
  let uploadInput = null;

  const showInBOROption =
    (<div className="form-group checkbox">
        <label>
          <input
            type="checkbox"
            name="showInSupplementalInformation"
            checked={showInSupplementalInformation}
            onChange={e => {
              const state = {
                target: {
                  value: e.target.checked,
                  name: e.target.name
                },
                preventDefault: ()=>{}
              };

              handleChange(state)
            }}
          />
          {checkboxText ? checkboxText : 'Show in Supplemental Information'}
        </label>
      </div>);

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <div className="panel-title">
          New Attachment
        </div>
      </div>
      <div className="panel-body">
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="control-label" htmlFor={`attachments-new-uri`}>
                URL
              </label>
              <input
                className="form-control"
                name="uri"
                id={`attachments-new-uri`}
                value={uri}
                onChange={handleChange}
                type="text"
                disabled={disableUri}
              />
            </div>
            {showInBOROption}
          </div>

          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label htmlFor={`attachments-new-name`} className="control-label">
                Name*
              </label>
              <input
                className="form-control"
                id={`attachments-new-name`}
                name="name"
                type="text"
                value={name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Or upload a file</label>
              <input ref={(input) => uploadInput = input} type="file" accept=".pdf" onChange={uploadFile} />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor={`attachments-new-description`}
              >
                Description (max {characterLimit} characters)*
              </label>
              <textarea
                rows={4}
                name="description"
                id={`attachments-new-description`}
                className="form-control"
                onChange={handleChange}
                value={description || ""}
                maxLength={characterLimit}
              />
              {maxDescriptionLength ? <div style={{color: '#989ca0'}}>You have reached the maximum description length of 255 characters</div> : null}
            </div>
          </div>
          <div className="form-group form-group col-xs-12 text-right">
            <button
              type="button"
              disabled={uri === "" || typeof uri === "undefined"}
              onClick={e => {
                e.preventDefault();
                uploadInput.value = null;
                saveAttachment();
              }}
              className="btn btn-primary"
            >
              Save Document
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAttachmentPanel;
