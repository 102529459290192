import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  updateDistrictInventoryRecordBOR,
  updateDistrictInventoryRecordArchived
} from '../../../actions/District';
import DistrictInventoryAttachmentTable from './DistrictInventoryAttachmentTable';
import DistrictInventoryContractForm from './DistrictInventoryContractForm';

const DistrictInventoryRow = ({
  record,
  expandAttachmentsInventoryRecordId,
  expandedContractInventoryRecordId,
  updateExpandAttachmentsInventoryRecordId,
  updateDistrictInventoryRecordBOR,
  updateExpandedAttachmentId,
  expandedAttachmentId,
  attachmentTypes,
  updateExpandedContractInventoryRecordId,
  updateAddingAttachmentInventoryRecordId,
  addingAttachmentInventoryRecordId,
  onSuccessfulContractUpdate,
  updateAttachment,
  removeAttachment,
  createAttachment,
  attachmentErrors,
  attachmentCreationSuccess,
  updateDistrictInventoryRecordArchived,
  displayArchivedMessage,
  successMessage
}) => {
  const [isChecked, setIsChecked] = useState(!!record.BOR);
  const [isArchived, setIsArchived] = useState(!!record.archived);

  const updateShowInBOR = () => {
    const updatedValue = !isChecked;
    setIsChecked(updatedValue);
    updateDistrictInventoryRecordBOR(record, updatedValue);
  };

  const updateInventoryRecordArchived = () => {
    const updatedValue = !isArchived;
    setIsArchived(updatedValue);
    displayArchivedMessage(updatedValue);
    updateDistrictInventoryRecordArchived(record, updatedValue);
  };

  const handleUpdateAttachment = (attachment) => {
    updateAttachment(attachment, record);
  };

  const handleRemoveAttachment = (attachment) => {
    removeAttachment(attachment, record);
  };

  const handleCreateAttachment = (attachment) => {
    createAttachment(attachment, record);
  };

  let chevronClassName = 'glyphicon inventory-record-attachment-row-chevron ';

  if (expandAttachmentsInventoryRecordId === record.id) {
    chevronClassName += 'glyphicon-chevron-up inventory-record-collapse-attachments';
  } else {
    chevronClassName += 'glyphicon-chevron-down inventory-record-expand-attachments';
  }

  if (record.attachments.length) {
    chevronClassName += ' green';
  } else {
    chevronClassName =
      'glyphicon glyphicon-remove inventory-record-attachment-row-chevron inventory-record-expand-attachments';
  }

  const expand = (
    <span
      className={chevronClassName}
      onClick={() => updateExpandAttachmentsInventoryRecordId(record.id)}></span>
  );
  const expandContractEdit =
    expandedContractInventoryRecordId !== record.id && !!record.user_can_edit ? (
      <span
        className="glyphicon glyphicon-pencil expand"
        onClick={() => updateExpandedContractInventoryRecordId(record.id)}></span>
    ) : null;

  return (
    <React.Fragment key={record.id}>
      <tr>
        <td>
          <Link to={`/inventory/${record.id}`}>
            {record.product ? record.product.name : 'PRODUCT DELETED'}
          </Link>
        </td>
        <td>
          <Link to={`/companies/${record.product.company.id}`}>
            {record.product && record.product.company
              ? record.product.company.name
              : 'COMPANY DELETED'}
          </Link>
        </td>
        <td>
          {record.expires_at
            ? new Date(record.expires_at).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                timeZone: 'UTC'
              })
            : ''}{' '}
          {expandContractEdit}
        </td>
        <td>
          {record.contractor === 'BOCES'
            ? !!record.boces_name && record.boces_name !== 'BOCES'
              ? `BOCES - ${record.boces_name}`
              : 'BOCES'
            : record.contractor}{' '}
          {expandContractEdit}
        </td>

        <td>
          {record.data_admin
            ? `${record.data_admin.first_name} ${record.data_admin.last_name}`
            : ''}
        </td>
        <td>
          <ul className="list-unstyled">
            {record.delegates.map((delegate) => {
              return <li key={delegate.id}>{delegate.name}</li>;
            })}
          </ul>
        </td>
        <td>
          <input type="checkbox" checked={isArchived} onChange={updateInventoryRecordArchived} />
        </td>
        <td>
          <input
            type="checkbox"
            checked={isChecked}
            disabled={!!!record.user_can_edit || record.archived}
            onChange={updateShowInBOR}
          />
        </td>
        <td>{expand}</td>
      </tr>
      <DistrictInventoryAttachmentTable
        record={record}
        allowShowInBOR={isChecked}
        isOpened={expandAttachmentsInventoryRecordId === record.id}
        expandedAttachmentId={expandedAttachmentId}
        updateExpandedAttachmentId={updateExpandedAttachmentId}
        updateAddingAttachmentInventoryRecordId={updateAddingAttachmentInventoryRecordId}
        addingAttachmentInventoryRecordId={addingAttachmentInventoryRecordId}
        attachmentTypes={attachmentTypes}
        updateAttachment={handleUpdateAttachment}
        removeAttachment={handleRemoveAttachment}
        createAttachment={handleCreateAttachment}
        attachmentErrors={attachmentErrors}
        attachmentCreationSuccess={attachmentCreationSuccess}
        successMessage={successMessage}
      />
      <DistrictInventoryContractForm
        record={record}
        isOpened={expandedContractInventoryRecordId === record.id}
        close={() => updateExpandedContractInventoryRecordId(null)}
        onSuccessfulContractUpdate={onSuccessfulContractUpdate}
      />
    </React.Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateDistrictInventoryRecordBOR,
  updateDistrictInventoryRecordArchived
};

export default connect(mapStateToProps, mapDispatchToProps)(DistrictInventoryRow);
