import React, { Component } from "react";
import { connect } from "react-redux";
import { uploadAttachmentFile } from "../../../actions/Attachment";
import AttachmentPanel from "./AttachmentPanel";

class AttachmentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      name: "",
      uri: "",
      description: "",
      show_in_bor: false,
      error: "",
      loading: false,
      documentChanged: false,
      archived: false,
    };

    this.isArchived = JSON.parse(JSON.stringify(!!this.props.attachment.archived));
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps.attachment, error: "" });
  }

  componentWillMount() {
    this.setState({ ...this.props.attachment, error: "" });
  }

  handleChange = e => {
    e.preventDefault();

    const field = e.target.name;
    const value = e.target.value;
    this.setState({ [field]: value }, () =>  {
      // Update name to type if it hasnt been entered yet
      if(field === 'type') {
        this.handleTypeChange(value);
      }
      if(field === 'uri') {
        this.setState({ documentChanged: true });
      }
    });
  };

  handleTypeChange = newType => {
    if(this.state.name === '' || this.state.name === null || this.props.attachmentTypes.includes(this.state.name)) {
      this.setState({name: newType});
    }
  };

  updateAttachment = () => {
    this.props.updateAttachment({
      id: this.props.attachment.id,
      name: this.state.name,
      type: this.state.type,
      uri: this.state.uri,
      description: this.state.description,
      show_in_bor: this.state.show_in_bor,
      archived: this.state.archived
    });
  };

  uploadFile = async e => {
    this.setState({ loading: true })
    try {
      const uri = await this.props.uploadAttachmentFile(e.target.files[0]);
      this.setState({ uri, error: '' });
      this.setState({ documentChanged: true });
      this.props.updateError('');
    } catch (err) {
      this.setState({ error: err });
      this.props.updateError(err);
    } finally {
      this.setState({ loading: false })
    }
  };

  render() {
    const state = this.state;

    if (!this.props.attachmentTypes) {
      return <div />;
    }

    return (
      <AttachmentPanel
        {...this.props}
        {...state}
        documentChanged = {this.state.documentChanged}
        uploadFile={this.uploadFile}
        updateAttachment={this.updateAttachment}
        handleChange={this.handleChange}
        loading={this.state.loading}
        isArchived={this.isArchived}
      />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = { uploadAttachmentFile };

AttachmentContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(AttachmentContainer);

export default AttachmentContainer;
