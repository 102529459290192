import {compareInventoryRecordNames} from "../utils/sortFunctions";

const initialState = {
  searchResults: null,
  districtDict: {},
  inventoryRecords: {},
  districts: null,
  pagination: {
    current_page: 1,
    last_page: 1
  },
  message: null
};

const District = (state = initialState, action) => {
  switch (action.type) {
    case "DISTRICT_SEARCH_RECEIVED":
      return {...state, searchResults: action.districts};
    case "DISTRICT_INVENTORY_RESULTS_RECEIVED":
      return {
        ...state,
        inventoryRecords: {
          ...state.inventoryRecords,
          [action.districtId]: action.records
        },
        pagination: action.pagination,
        message: action.message || null
      };
    case "DISTRICT_INVENTORY_RESULTS_APPEND_RECEIVED":
      return {...state,
        inventoryRecords: {
        ...state.inventoryRecords,
        [action.districtId]: [...state.inventoryRecords[action.districtId], ...action.records]},
        pagination: action.pagination};

    case "DISTRICT_INVENTORY_RECORD_RECEIVED":
      let newDistrictInventoryRecords = state.inventoryRecords[action.districtId].map((inventoryRecord) => {
        if (inventoryRecord.id === action.inventoryRecord.id) {
          return action.inventoryRecord;
        }
        return inventoryRecord;
      });

      return {
        ...state,
        inventoryRecords: {
          ...state.inventoryRecords,
          [action.districtId]: newDistrictInventoryRecords
        }
      };

    case "DISTRICT_RECEIVED":
      return {
        ...state,
        districtDict: {
          ...state.districtDict,
          [action.districtId]: action.district
        }
      };
    case "DISTRICT_DELETED":
      const newDistrictDict = {...state.districtDict};
      delete newDistrictDict[action.districtId];

      return {
        ...state,
        districtDict: newDistrictDict
      };
    default:
      return state;
  }
};

export default District;
