import React from "react";
import ErrorAlert from "../../Common/ErrorAlert";
import GoBack from "../../Common/GoBack";

const DelegateForm = ({
  handleChange,
  id,
  name,
  location,
  authorities,
  selectAuthority,
  removeAuthority,
  possibleAuthorities,
  error,
  saveDelegate
}) => {
  const authorityOptions = possibleAuthorities.map(authority => {
    return (
      <div className="checkbox" key={authority.id}>
        <label>
          <input
            type="checkbox"
            name="authorities"
            checked={authorities.indexOf(authority.id) >= 0}
            onChange={e => {
              if (e.target.checked) {
                selectAuthority(authority.id);
              } else {
                removeAuthority(authority.id);
              }
            }}
          />
          {authority.name}
        </label>
      </div>
    );
  });

  return (
    <div className="row">
      <div className="col-sm-12">
        <form
          onSubmit={e => {
            e.preventDefault();
            saveDelegate();
          }}
        >
          <div className="row">
            <div className="col-xs-6">
              <div className="form-group col-sm-12">
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Name of delegate"
                  className="form-control"
                  value={name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-sm-12">
                <label htmlFor="name">Location</label>
                <input
                  id="location"
                  type="text"
                  name="location"
                  placeholder="Name of location"
                  className="form-control"
                  value={location}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-sm-6">
                {authorityOptions.length ? <label>Authorities</label> : null}
                {authorityOptions}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <ErrorAlert error={error} />
              <div className="text-right">
                <button type="submit" className="btn btn-primary">
                  {id ? "Update Delegate" : "Add Delegate"}
                </button>
                <GoBack />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DelegateForm;
