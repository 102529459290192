import apiInstance from "../utils/apiInstance";
import qs from "qs";

export const searchForRegions = page => async (dispatch, getState) => {
  const urlOptions = { page };
  const url = `regions?${qs.stringify(urlOptions)}`;
  const response = await apiInstance.get(url);

  dispatch({ type: "REGION_SEARCH_RECEIVED", regions: response.data });
};

export const createRegion = (
  name,
  contact_name,
  contact_title,
  contact_email
) => async (dispatch, getState) => {
  const url = `regions`;
  const data = {
    name,
    contact_name,
    contact_title,
    contact_email
  };

  const creationResponse = await apiInstance.post(url, data);

  const response = await apiInstance.get(`regions/${creationResponse.data.id}`);

  dispatch({
    type: "REGION_RECEIVED",
    regionId: response.data.id,
    region: response.data
  });

  return response.data;
};

export const deleteRegion = regionId => async (dispatch, getState) => {
  const url = `regions/${regionId}`;

  await apiInstance.delete(url);

  dispatch({ type: "REGION_DELETED", regionId });
};

export const getRegion = regionId => async (dispatch, getState) => {
  const url = `regions/${regionId}`;
  const response = await apiInstance.get(url);

  dispatch({ type: "REGION_RECEIVED", regionId, region: response.data });

  return response.data;
};

export const updateRegion = (
  regionId,
  name,
  contact_name,
  contact_title,
  contact_email
) => async (dispatch, getState) => {
  const url = `regions/${regionId}`;
  const data = {
    name,
    contact_name,
    contact_title,
    contact_email
  };

  const response = await apiInstance.put(url, data);

  dispatch({ type: "REGION_RECEIVED", regionId, region: response.data });
};

export const requestRegionExportDownload = async (regionId, type = "BASIC") => {
  const url = `export/token/${type}?region=${regionId}`;
  const response = await apiInstance.get(url);
  return response.data.url;
}