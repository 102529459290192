import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import DocumentForm from "./DocumentForm";
import { createDistrictAttachment } from "../../../actions/DistrictAttachment";
import { uploadAttachmentFile } from "../../../actions/Attachment";

class NewDocumentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      name: "",
      uri: "",
      type: "",
      description: "",
      showInSupplementalInformation: false
    };
  }

  getDistrictIdFromProps(props) {
    const { match } = props;
    const districtId = match.params.districtId;
    return districtId ? parseInt(districtId, 10) : undefined;
  }

  saveDistrictAttachment = async (
    districtAttachmentId,
    name,
    uri,
    description,
    showInSupplementalInformation
  ) => {

    if (!name) {
      this.setState({ error: "You must provide a name" });
      return;
    }

    try {
      const districtId = this.getDistrictIdFromProps(this.props);

      await this.props.createDistrictAttachment(
        name,
        uri,
        description,
        districtId,
        showInSupplementalInformation
      );

      this.props.history.push(`/districts/${districtId}/documents`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  uploadFile = async e => {
    const uri = await this.props.uploadAttachmentFile(e.target.files[0]);
    this.setState({ uri });
  };

  render() {
    const state = this.state;

    return (
      <div className="update-district-container">
        <DocumentForm
          {...state}
          handleChange={this.handleChange}
          saveDistrictAttachment={this.saveDistrictAttachment}
          uploadFile={this.uploadFile}
          districtAttachmentId={null}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = {
  createDistrictAttachment,
  uploadAttachmentFile
};

NewDocumentContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(NewDocumentContainer)
);

export default NewDocumentContainer;
