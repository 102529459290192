import React, { useState } from 'react';
import { UnmountClosed } from 'react-collapse';
import { connect } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import { updateDistrictInventoryRecordContract } from '../../../actions/District';
import moment from 'moment';
import ErrorAlert from '../../Common/ErrorAlert';

const DistrictInventoryContractForm = (props) => {
  const { record, isOpened, close, onSuccessfulContractUpdate } = props;
  const initialState = {
    contractor: record.contractor,
    boces_name: record.boces_name ?? '',
    expires_at: record.expires_at ? moment.utc(record.expires_at) : null,
    renews_annually: record.renews_annually ?? false
  };

  const [contractInfo, setContractInfo] = useState(initialState);
  const [dateFocused, setDateFocused] = useState('');
  const [error, setError] = useState('');

  const changeFocus = (newFocus) => {
    setDateFocused(newFocus);
  };

  const closeRow = () => {
    setError('');
    setContractInfo(initialState);
    close();
  };

  const handleChange = (event) => {
    setContractInfo({
      ...contractInfo,
      [event.target.name]: event.target.value
    });
  };

  const handleCheckbox = (e) => {
    setContractInfo({
      ...contractInfo,
      [e.target.name]: e.target.checked
    });
  };

  const updateContractInfo = async () => {
    try {
      await props.updateDistrictInventoryRecordContract(record.id, contractInfo);
      setError('');
      onSuccessfulContractUpdate();
      close();
    } catch (err) {
      setError(err);
    }
  };

  return (
    <tr className="inventory-record-attachment-row">
      <td colSpan="4"></td>
      <td colSpan="6">
        <UnmountClosed isOpened={isOpened}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateContractInfo();
            }}>
            <div className="row">
              <div className="col-xs-12">
                <p className="lead">Contract Information</p>
              </div>
              <div className="form-group col-xs-6 col-sm-3">
                <label htmlFor="contractor">Contract Source</label>
                <select
                  id="contractor"
                  name="contractor"
                  className="form-control"
                  onChange={handleChange}
                  value={contractInfo.contractor || ''}>
                  <option value="">Select a Contract Source</option>
                  <option value="District">District</option>
                  <option value="BOCES">Boces</option>
                  <option value="District - Free">District - Free</option>
                </select>

                <br></br>

                {contractInfo.contractor === 'BOCES' ? (
                  <>
                    <label htmlFor="boces_name">BOCES name: (Optional)</label>
                    <input
                      onChange={handleChange}
                      id="boces_name"
                      type="text"
                      name="boces_name"
                      placeholder="Enter a specific BOCES name"
                      className="form-control"
                      value={contractInfo.boces_name}
                    />
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className="form-group col-xs-6 col-sm-5">
                <label htmlFor="expires_at">Contract Expiration Date (Format: MM/DD/YYYY )</label>
                <div>
                  <SingleDatePicker
                    date={contractInfo.expires_at ?? null}
                    showClearDate={true}
                    readOnly={true}
                    isOutsideRange={() => false}
                    onDateChange={(value) =>
                      handleChange({
                        target: { name: 'expires_at', value },
                        preventDefault: () => {}
                      })
                    }
                    focused={dateFocused === 'expires_at'}
                    onFocusChange={({ focused }) => changeFocus(focused ? 'expires_at' : '')}
                    numberOfMonths={1}
                    id="expires_at"
                    placeholder="Select a Date"
                    withPortal={true}
                  />
                </div>
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="renews_annually"
                      checked={contractInfo.renews_annually}
                      onChange={handleCheckbox}
                    />
                    Renews Annually
                  </label>
                </div>
              </div>
              <br></br>
            </div>
            <div className="row text-left">
              <div className="col-xs-6">
                <ErrorAlert error={error} />
              </div>
            </div>
            <div className="form-group col-12 text-left">
              <button type="submit" className="btn btn-primary">
                Update Inventory Record
              </button>{' '}
              <button type="button" className="btn btn-danger" onClick={closeRow}>
                Cancel
              </button>
            </div>
          </form>
        </UnmountClosed>
      </td>
    </tr>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateDistrictInventoryRecordContract
};

export default connect(mapStateToProps, mapDispatchToProps)(DistrictInventoryContractForm);
