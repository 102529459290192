import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUser, updateUser } from "../../../actions/User";
import {
  getDistrictList,
  getCompanyOptions,
  getRegionList
} from "../../../actions/Options";
import userTypes from "../../../utils/userTypes";
import UserForm from "./UserForm";
import { orderByName } from "../../Common/OptionList";

const UpdateUserContainer = (props) => {
  const initialForm = {
    userId: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirm: "",
    roleGroup: "",
    companyId: "",
    title: "",
    regions: [],
    districtId: ""
};

  const [formData, setFormData] = useState(initialForm);
  const [error, setError] = useState("");
  const [hideDistrict, setHideDistrict] = useState(false);
  const [user, setUser] = useState("");

  useEffect(() => {
    const userId = getUserIdFromProps(props);

    const fetchUser = async (userId) => {
      await props.getUser(userId).then();
    }
 
    if(userId) {
      fetchUser(userId);
    }

    props.getCompanyOptions();
    if (
      props.currentUser.user_type === userTypes.superAdmin ||
      props.currentUser.user_type === userTypes.regionAdmin
    ) {
      props.getDistrictList();
      props.getRegionList();
    }

    if(props.currentUser.user_type === userTypes.districtSystemAdmin || props.currentUser.user_type === userTypes.districtAdmin) {
      setHideDistrict(true);
    }
  }, []);

  useEffect(() => {
    if(props.users !== {}) {
      const user = getUserFromProps(props);
      if(user !== undefined) {
        setUser(user);
        setStateFromUser(user);
      }
    }
  }, [props.users]);

  const handleChange = e => {
    e.preventDefault();
    const field = e.target.name;
    const value = e.target.value;

    setFormData((formData) => ({...formData, [field]: value }));
  };

  const getUserIdFromProps = (props) => {
    const userId = props.match.params.userId;
    return userId ? parseInt(userId, 10) : undefined;
  }

  const getUserFromProps = (props) => {
    const userId = getUserIdFromProps(props);
    if (userId === undefined) return undefined;

    const user = props.users[userId];
    return user;
  }

  const setStateFromUser = (user) => {
    setFormData((formData) => ({
      ...formData,
      userId: user.id || "",
      email: user.email || "",
      firstName: user.first_name || "",
      lastName: user.last_name || "",
      roleGroup: user.user_type || "",
      companyId:
        user.user_type === userTypes.companyAdmin ? user.entity_id : "",
      regions: user.regions.map(x => x.id.toString()),
      title: user.title || "",
      districtId:
        user.user_type === userTypes.districtAdmin ||
        user.user_type === userTypes.districtSystemAdmin ||
        user.user_type === userTypes.dataAdmin
          ? user.entity_id
          : ""
    }));
  }

  const addRegion = (region) => {
    const currentRegions = formData.regions;
    const newRegions = currentRegions.concat(region);

    setFormData((formData) => ({...formData, regions: newRegions }));
  };

  const removeRegion = (region) => {
    const currentRegions = formData.regions;
    const newRegions = currentRegions.filter(x => x !== region);

    setFormData((formData) => ({ ...formData, regions: newRegions }));
  }


  const saveUser = async (createUserForDataAdmin) => {
    if (!formData.firstName) {
      setError("You must provide a user name");
      return;
    }

    if(createUserForDataAdmin && !formData.email) {
      setError("You must provide an email");
      return;
    }

    try {
      await props.updateUser(
        formData.userId,
        formData.email,
        formData.firstName,
        formData.lastName,
        formData.password,
        formData.passwordConfirm,
        formData.roleGroup,
        formData.companyId,
        formData.districtId,
        formData.regions,
        formData.title,
      );

      props.history.push(`/users/${formData.userId}`);
    } catch (err) {
      setError(err);
    }
  };

  if (props.currentUser.user_type === userTypes.superAdmin || props.currentUser.user_type === userTypes.regionAdmin) {
    if (!props.districts || !props.regions) return <div>Loading...</div>;
  }

  if(!props.companies || !user) return <div>Loading...</div>;

  return (
    <div className="update-user-container">
        <UserForm
          {...formData}
          handleChange={handleChange}
          error={error}
          addRegion={addRegion}
          removeRegion={removeRegion}
          districtList={props.districts ? orderByName(props.districts) : []}
          regionList={props.regions}
          companyList={props.companies}
          saveUser={saveUser}
          userType={props.currentUser.user_type}
          dataAdminNoUser={props.dataAdminNoUser}
          hideDistrict={hideDistrict}
        />
      </div>
  );
}

const mapStateToProps = state => {
  return {
    users: state.User.userDict,
    districts: state.Options.districts,
    regions: state.Options.regions,
    companies: state.Options.companyOptions,
    currentUser: state.Account.currentUser
  };
};

const mapDispatchToProps = {
  getUser,
  updateUser,
  getDistrictList,
  getRegionList,
  getCompanyOptions
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserContainer);
