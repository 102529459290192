import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getAttachmentTypes,
  getClassificationOptions,
  getCompanyOptions,
  getProductTypeList
} from '../../../actions/Options';
import { clientToServerProduct } from '../../../utils/converter';
import userTypes from '../../../utils/userTypes';

function wrapProductEditor(WrappedComponent) {
  class ProductEditor extends Component {
    constructor(props) {
      super(props);

      this.state = {
        attachments: [],
        id: null,
        disposal: false,
        error: '',
        name: '',
        type: 'Profile Pending',
        company: '',
        // data_classifications: [],
        inactive: false,
        sii: false,
        other: '',
        protection: false,
        correction: false,
        location: false,
        nystcc: false,
        noPiiInAccount: false,
        educationalAgency: false,
        privacyPledge: false,
        aicpa: false,
        purpose: false,
        signs_dppr: false,
        hideCompliance: false
      };
    }

    componentDidMount() {
      this.props.getProductTypeList();
      this.props.getAttachmentTypes();
      this.props.getCompanyOptions();
      this.props.getClassificationOptions();
    }

    setStateFromProduct = (product) => {
      const classificationNames = product.data_classifications.map((x) => x.name);

      const newState = {
        ...product,
        company: product.company ? product.company.name : '',
        data_classifications: [...product.data_classifications],
        attachments: product.attachments ? [...product.attachments] : [],
        noPiiInAccount: classificationNames.indexOf('No PII Data') !== -1,
        educationalAgency: product.company
          ? product.company.nys_education_law_does_not_apply
          : false
      };

      this.setState(newState);
    };

    getCompanyIdForUser = () => {
      const { currentUser } = this.props;

      if (!currentUser) {
        return null;
      }
      if (!currentUser.user_type === userTypes.companyAdmin) {
        return null;
      }

      return currentUser.entity_id;
    };

    validateEditorInfo = (editorInfo) => {
      const userCompanyId = this.getCompanyIdForUser();

      if (!editorInfo.name) {
        throw new Error('You must provide a name');
      }
      if (!editorInfo.type) {
        throw new Error('You must provide a product type');
      }
      if (!userCompanyId && !editorInfo.company) {
        throw new Error('You must provide a company');
      }

      return true;
    };

    exportInfo = (editorInfo) => {
      const serverFormat = clientToServerProduct(editorInfo);
      const userCompanyId = this.getCompanyIdForUser();

      return {
        ...serverFormat,
        company: editorInfo.company || userCompanyId
      };
    };

    // addDataClassification = dataClassification => {
    //   const currentClassifications = this.state.data_classifications;
    //   const newClassifications = currentClassifications.concat(
    //     dataClassification
    //   );
    //
    //   if (dataClassification.label === "No PII Data") {
    //     this.setState({ noPiiInAccount: true });
    //   }
    //
    //   return this.setState({ data_classifications: newClassifications });
    // };

    // removeDataClassification = dataClassification => {
    //   const currentClassifications = this.state.data_classifications;
    //
    //   const newClassifications = currentClassifications.filter(
    //     x => x.id !== dataClassification.id
    //   );
    //   if (dataClassification.label === "No PII Data") {
    //     this.setState({ noPiiInAccount: false });
    //   }
    //   return this.setState({ data_classifications: newClassifications });
    // };

    handleCheckbox = (e) => {
      this.setState({ [e.target.name]: e.target.checked });
    };

    handleChange = (e) => {
      e.preventDefault();

      this.setState({ [e.target.name]: e.target.value });
    };

    removeAttachment = (attachment) => {
      this.setState({
        attachments: this.state.attachments.filter((x) => x !== attachment)
      });
    };

    updateAttachment = (attachment) => {
      this.setState({
        attachments: this.state.attachments.map((x) => {
          return x.id === attachment.id
            ? {
                ...attachment,
                name: attachment.name || attachment.type
              }
            : { ...x };
        })
      });
    };

    addAttachment = (newAttachment) => {
      this.setState({
        attachments: this.state.attachments.concat({
          ...newAttachment,
          name: newAttachment.name || newAttachment.type
        })
      });
    };

    render() {
      if (!this.props.companies) return null;
      if (!this.props.classifications) return null;
      if (!this.props.productTypes) return null;
      if (!this.props.attachmentTypes) return null;

      const classifications = Object.keys(this.props.classifications).map((x) => {
        return { id: parseInt(x, 10), label: this.props.classifications[x] };
      });

      const editorInfo = {
        ...this.state,
        companies: this.props.companies,
        classifications,
        productTypes: this.props.productTypes,
        attachmentTypes: this.props.attachmentTypes,
        changeCompany: this.changeCompany,
        handleChange: this.handleChange,
        handleCheckbox: this.handleCheckbox,
        // addDataClassification: this.addDataClassification,
        // removeDataClassification: this.removeDataClassification,
        removeAttachment: this.removeAttachment,
        addAttachment: this.addAttachment,
        updateAttachment: this.updateAttachment
      };

      return (
        <WrappedComponent
          {...this.props}
          validateEditorInfo={this.validateEditorInfo}
          exportInfo={this.exportInfo}
          editorInfo={editorInfo}
          setStateFromProduct={this.setStateFromProduct}
        />
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      currentUser: state.Account.currentUser,
      productTypes: state.Options.productTypes,
      companies: state.Options.companyOptions,
      classifications: state.Options.classificationOptions,
      attachmentTypes: state.Options.attachmentTypes
    };
  };

  const actions = {
    getAttachmentTypes,
    getCompanyOptions,
    getClassificationOptions,
    getProductTypeList
  };

  return connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(ProductEditor);
}

export default wrapProductEditor;
