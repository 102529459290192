import React from "react";
import PageHeader from "../../Common/PageHeader";
import ErrorAlert from "../../Common/ErrorAlert";
import GoBack from "../../Common/GoBack";

// TODO: Add Document show in supplemental
const DocumentForm = ({
  error,
  name,
  uri,
  description,
  saveDistrictAttachment,
  // showInSupplementalInformation,
  uploadFile,
  districtAttachmentId,
  handleChange
}) => {
  const disableUri =
    uri.indexOf("https://dpit.s3.amazonaws.com") >= 0 ||
    uri.indexOf("https://s3.amazonaws.com/dpit") >= 0;

  return (
    <div className="district-editor">
      <PageHeader
        title="Document Library"
        subtitle={districtAttachmentId ? "Update Document" : "Create Document"}
      />
      <form
        onSubmit={e => {
          e.preventDefault();
          saveDistrictAttachment(
            districtAttachmentId,
            name,
            uri,
            description,
            // showInSupplementalInformation
          );
        }}
      >
        <div className="row">
          <div className="form-group col-xs-6">
            <label htmlFor={`attachments-new-name`} className="control-label">
              Name
            </label>
            <input
              className="form-control"
              id={`attachments-new-name`}
              name="name"
              type="text"
              value={name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-xs-6">
            <label className="control-label" htmlFor={`attachments-new-uri`}>
              URL
            </label>
            <input
              className="form-control"
              name="uri"
              id={`attachments-new-uri`}
              value={uri}
              onChange={handleChange}
              type="text"
              disabled={disableUri}
            />
          </div>
          <div className="col-xs-6">
            <div className="form-group">
              <label>Or upload a file</label>
              <input type="file" onChange={uploadFile} />
            </div>
            <div className="form-group">
              {/**
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="showInSupplementalInformation"
                    checked={showInSupplementalInformation}
                    onChange={e => {
                      const state = {
                        target: {
                          value: !showInSupplementalInformation,
                          name: 'showInSupplementalInformation'
                        },
                        preventDefault: () => {}
                      };

                      handleChange(state);
                    }}
                  />
                  Show in Supplemental Information
                </label>
              </div>
                */}
            </div>
          </div>


          <div className="form-group col-xs-6">
            <label
              className="control-label"
              htmlFor={`attachments-new-description`}
            >
              Description
            </label>
            <textarea
              rows={4}
              name="description"
              id={`attachments-new-description`}
              className="form-control"
              onChange={handleChange}
              value={description || ""}
            />
          </div>
        </div>

        <ErrorAlert error={error} />
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group text-right">
              <button type="submit" className="btn btn-primary">
                Save Document
              </button>
              <GoBack />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DocumentForm;
