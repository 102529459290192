import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import SingleDistrictContainer from "./SingleDistrict/SingleDistrictContainer";
import UpdateDistrictContainer from "./DistrictEditor/UpdateDistrictContainer";
import NewDistrictContainer from "./DistrictEditor/NewDistrictContainer";
import DistrictListContainer from "./DistrictList/DistrictListContainer";
import DocumentListContainer from "./DocumentList/DocumentListContainer";
import NewDocumentContainer from "./DocumentEditor/NewDocumentContainer";
import UpdateDocumentContainer from "./DocumentEditor/UpdateDocumentContainer";
import DocumentDistrictSelector from "./DocumentDistrictSelector";
import DataAdminContainer from "./DataAdminEditor/DataAdminContainer";
import DistrictInventoryContainer
  from "./DistrictInventory/DistrictInventoryContainer";
import ServiceProvidersListContainer from "../ServiceProviders/ServiceProvidersList/ServiceProvidersListContainer";
import NewServiceProviderContainer from "../ServiceProviders/ServiceProviderEditor/NewServiceProviderContainer";

class DistrictContainer extends Component {
  render() {
    const { match } = this.props;

    return (
      <div className="district-container">
        <Switch>
          <Route
            path={`${match.url}/create`}
            component={NewDistrictContainer}
          />
          <Route
            path={`${match.url}/document/selector`}
            component={DocumentDistrictSelector}
          />
          <Route
            path={`${match.url}/:districtId/update`}
            component={UpdateDistrictContainer}
          />
          <Route
            path={`${match.url}/documents/:districtAttachmentId/update`}
            component={UpdateDocumentContainer}
          />
          <Route
            path={`${match.url}/:districtId/inventory`}
            component={DistrictInventoryContainer}
          />
          <Route
            path={`${match.url}/:districtId/data-admins`}
            render={(props) => <DataAdminContainer {...props}/>}
          />
          <Route
            path={`${match.url}/:districtId/documents/create`}
            component={NewDocumentContainer}
          />
          <Route
            path={`${match.url}/:districtId/documents`}
            component={DocumentListContainer}
          />
          <Route 
            path={`${match.url}/:districtId/service-providers/create`}
            render={(props) => (<NewServiceProviderContainer {...props}/>)}
          />
          <Route 
            path={`${match.url}/:districtId/service-providers`}
            render={(props) => (<ServiceProvidersListContainer {...props}/>)}
          />
          <Route
            path={`${match.url}/:districtId`}
            component={SingleDistrictContainer}
          />
          <Route path={match.url} component={DistrictListContainer} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.Account.currentUser, 
  };
};

const actions = {};

DistrictContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(DistrictContainer);

export default DistrictContainer;
