import React, { useState } from "react";
import {updateDistrictServiceProviderAttachmentVisibility} from '../../../actions/ServiceProvider';

const ServiceProviderAttachmentRow = ({
    attachment,
    allowShow,
}) => {
    const [isChecked, setIsChecked] = useState(!!attachment.showInSupplementalInformation);

    const updateVisibility = () => {
        const updatedValue = !isChecked;
        setIsChecked(updatedValue);
        updateDistrictServiceProviderAttachmentVisibility(attachment, updatedValue);
    }

    return (
        <tr>
            <td>
                <a href={attachment.uri} target="_blank">{attachment.name}</a>
            </td>
            <td>
                <label htmlFor={`showInSupplementalInformation-${attachment.id}`} className="sr-only">Show in Supplemental Information</label>
                <input
                    id={`showInSupplementalInformation-${attachment.id}`}
                    name={`showInSupplementalInformation-${attachment.id}`}
                    type="checkbox"
                    checked={isChecked}
                    onChange={updateVisibility}
                />
            </td>
        </tr>
    );
};

export default ServiceProviderAttachmentRow;