import React from "react";
import PageHeader from "../../Common/PageHeader";
import { Link } from "react-router-dom";
import ClassificationRow from "./ClassificationRow";

const ClassificationList = ({ classifications, deleteClassification }) => {
  const classificationRows = classifications
    ? classifications.map(classification => (
        <ClassificationRow
          key={classification.id}
          classification={classification}
          deleteClassification={deleteClassification}
        />
      ))
    : null;

  return (
    <div className="classification-list-container">
      <PageHeader
        title="Data Category"
        subtitle="Data Category Listing"
      />
      <div className="row">
        <div className="col-sm-12 text-right">
          <Link to="/categories/create" className="btn btn-sm btn-success">
            Add Category
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Category</th>
                  <th className="text-right">Manage</th>
                </tr>
              </thead>
              <tbody>
                {classificationRows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassificationList;
