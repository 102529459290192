const initialState = {
  districtAttachmentList: null,
  districtAttachmentDict: {}
};

const DistrictAttachment = (state = initialState, action) => {
  switch (action.type) {
    case "DISTRICT_ATTACHMENT_LIST_RECEIVED":
      return {
        ...state,
        districtAttachmentList: action.districtAttachmentList
      };
    case "DISTRICT_ATTACHMENT_RECEIVED":
      return {
        ...state,
        districtAttachmentDict: {
          ...state.districtAttachmentDict,
          [action.districtAttachmentId]: action.districtAttachment
        }
      };
    case "DISTRICT_ATTACHMENT_DELETED":
      const newDistrictAttachmentDict = { ...state.districtAttachmentDict };
      delete newDistrictAttachmentDict[action.districtId];

      return {
        ...state,
        districtAttachmentDict: newDistrictAttachmentDict
      };
    default:
      return state;
  }
};

export default DistrictAttachment;
