import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getCompany, deleteCompany } from "../../../actions/Company";
import SingleCompany from "./SingleCompany";

class SingleCompanyContainer extends Component {
  getCompanyIdFromProps(props) {
    const { match } = props;
    const companyId = match.params.companyId;
    return companyId ? parseInt(companyId, 10) : undefined;
  }

  async componentDidMount() {
    const companyId = this.getCompanyIdFromProps(this.props);
    await this.props.getCompany(companyId);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getCompanyIdFromProps(this.props);
    const newId = this.getCompanyIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getCompany(newId);
    }
  }

  deleteCompany = async companyId => {
    await this.props.deleteCompany(companyId);
    this.props.history.push("/companies");
  };

  render() {
    const companyId = this.getCompanyIdFromProps(this.props);
    const company = this.props.companys[companyId];
    if (!company) return <div>Loading...</div>;

    return (
      <SingleCompany
        currentUser={this.props.currentUser}
        company={company}
        deleteCompany={this.deleteCompany}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.Account.currentUser,
    companys: state.Company.companyDict
  };
};

const actions = { getCompany, deleteCompany };

SingleCompanyContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(SingleCompanyContainer)
);

export default SingleCompanyContainer;
