import React, {useEffect} from "react";
import SupplementalInformationRow from "./SupplementalInformationRow";
import Search from "../../Common/Search";
import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScrollLoader from "../../Common/InfiniteScrollLoader";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const SupplementalInformationList = ({
    inventoryRecords,
    districtName,
    query,
    updateQuery,
    performSearch,
    infiniteScrollFunction,
    infiniteScrollPagination,
  }) => {

  useEffect(() => {
    window.scrollTo(0,0);
    return () => window.scrollTo(0,0);
  }, []);

  const inventoryRecordRows =
    inventoryRecords.length !== 0
      ? inventoryRecords.map(inventoryRecord => (
        <SupplementalInformationRow
          key={inventoryRecord.id}
          inventoryRecord={inventoryRecord}
        />
      ))
      : null;

  if (!inventoryRecordRows) {
    return (
      <div className="bill-of-rights-list-container">
          <div className="col-sm-12">
            <p className="lead">{districtName}</p>
            <Search query={query} updateQuery={updateQuery} performSearch={performSearch} showClearButton={true} customId={"supplemental-information"} />
            <p className="lead text-center">
              {
                query ? 'No products found.' : 'No products are associated with this district.'
              }
            </p>
          </div>
      </div>
    );
  }


  return (
    <div className="bill-of-rights-list-container">
          <div className="row">
            <div className="col-sm-12">
          <Search query={query} updateQuery={updateQuery} performSearch={performSearch} showClearButton={true} customId={"supplemental-information"} />
          <div className="table-responsive">
            <table className="table" key={query}>
              <thead>
              <tr>
                <th>Product</th>
                <th>Company</th>
                <th>
                  Contract Source
                </th>
                <th>Contract Expiration</th>
                <th>Supporting Documentation
                    <OverlayTrigger
                        key={query}
                        placement={'top'}
                        overlay={
                            <Tooltip id={`tooltip-${query}`}>
                                Supporting Documentation may be listed under product or company
                            </Tooltip>
                        }
                    >
                        <span className="glyphicon glyphicon-info-sign"></span>
                    </OverlayTrigger>
                </th>
              </tr>
              </thead>
              <InfiniteScroll
                pageStart={1}
                loadMore={infiniteScrollFunction}
                hasMore={infiniteScrollPagination.current_page < infiniteScrollPagination.last_page}
                element='tbody'
                loader={<InfiniteScrollLoader key="loader"/>}>
                {inventoryRecordRows}
              </InfiniteScroll>

            </table>
          </div>
        </div>
      </div>
     </div>
  );
};

export default SupplementalInformationList;
