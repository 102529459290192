import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getClassification,
  createClassification
} from "../../../actions/Classification";
import ClassificationForm from "./ClassificationForm";

class NewClassificationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      name: ""
    };
  }

  saveClassification = async (classificationId, name) => {
    if (!name) {
      this.setState({ error: "You must provide a Category name" });
      return;
    }

    try {
      await this.props.createClassification(name);

      this.props.history.push(`/categories`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  updateName = name => {
    this.setState({ name });
  };

  render() {
    const { name, error } = this.state;

    return (
      <div className="create-classification-container">
        <ClassificationForm
          error={error}
          name={name}
          updateName={this.updateName}
          saveClassification={this.saveClassification}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { classifications: state.Classification.classificationDict };
};

const actions = { getClassification, createClassification };

NewClassificationContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(NewClassificationContainer)
);

export default NewClassificationContainer;
