import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

const ConfirmationButton = ({
  confirmCallback,
  confirmBeforeExecuting = true,
  buttonText,
  title,
  body,
  isDisabled,
  className,
  confirmButtonText,
  ...otherProps }) => {

  const [showModal, setShowModal] = useState(false);

  const acceptAndCloseModal = e => {
    e.preventDefault();
    setShowModal(false);
    confirmCallback();
  };

  const modal = (
    <Modal onHide={() => setShowModal(false)} show={showModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {title || "Are you sure?"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body || "Are you sure you want to perform this action?"}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={acceptAndCloseModal} bsStyle="primary">
          {confirmButtonText || "Confirm"}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <span>
      <button
        disabled={isDisabled}
        className={className || "btn btn-xs btn-primary deletion-link"}
        {...otherProps}
        onClick={e => {
          e.preventDefault();
          if (confirmBeforeExecuting) {
            setShowModal(true);
          }
          else {
            confirmCallback();
          }
        }}
      >
        {buttonText || "Confirm"}
      </button>
      {modal}
    </span>
  );
}

ConfirmationButton.propTypes = {
  confirmCallback: PropTypes.func.isRequired,
  confirmBeforeExecuting: PropTypes.bool
};

export default ConfirmationButton;
