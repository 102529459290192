import React from 'react';
import { connect } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import ErrorAlert from '../../Common/ErrorAlert';
import GoBack from '../../Common/GoBack';
import NewAttachmentContainer from '../../Common/AttachmentsFunctional/NewAttachmentContainer';
import AttachmentContainer from '../../Common/AttachmentsFunctional/AttachmentContainer';

const ServiceProviderForm = ({
  error,
  name,
  showInSupplementalInformation,
  description,
  maxDescriptionLength,
  expires_at,
  handleChange,
  changeFocus,
  dateFocused,
  serviceProviderId,
  attachments,
  addAttachment,
  updateAttachment,
  removeAttachment,
  updateError,
  toggleCheckboxValue,
  saveServiceProvider,
  isLoading,
  publishDescription,
  characterLimit
}) => {
  const existingAttachments = attachments.map((x, index) => {
    return (
      <AttachmentContainer
        key={index}
        attachment={x}
        index={index}
        updateAttachment={updateAttachment}
        removeAttachment={removeAttachment}
        updateError={updateError}
        showInBORCheckbox
        checkboxText="Show Document in Supplemental Information"
        characterLimit={characterLimit}
      />
    );
  });
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        saveServiceProvider();
      }}>
      <div className="row">
        <div className="form-group col-xs-6">
          <label htmlFor={`service-providers-new-name`} className="control-label">
            Provider Name*
          </label>
          <input
            className="form-control"
            id={`service-providers-new-name`}
            name="name"
            type="text"
            value={name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-xs-6">
          <label htmlFor="expires_at" className="control-label">
            Contract Expiration Date (Format: MM/DD/YYYY )*
          </label>
          <div>
            <SingleDatePicker
              date={expires_at}
              showClearDate={true}
              readOnly={false}
              isOutsideRange={() => false}
              onDateChange={(value) =>
                handleChange({
                  target: { name: 'expires_at', value },
                  preventDefault: () => {}
                })
              }
              numberOfMonths={1}
              id="expires_at"
              focused={dateFocused === 'expires_at'}
              onFocusChange={({ focused }) => changeFocus(focused ? 'expires_at' : '')}
              withPortal={true}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-xs-6">
          <label htmlFor={`service-providers-new-description`} className="control-label">
            Description (max {characterLimit} characters)*
          </label>
          <textarea
            name="description"
            id={`service-providers-new-description`}
            rows={4}
            className="form-control"
            onChange={handleChange}
            value={description || ''}
            maxLength={characterLimit}
          />
          {maxDescriptionLength ? (
            <div style={{ color: '#989ca0' }}>
              You have reached the maximum description length of 255 characters
            </div>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-12">
          <div className="form-group checkbox">
            <label>
              <input
                type="checkbox"
                name="showInSupplementalInformation"
                checked={showInSupplementalInformation}
                onChange={() => toggleCheckboxValue('showInSupplementalInformation')}
              />
              Publish Service Provider in Supplemental Information
            </label>
          </div>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-sm-6 col-12">
          <div className="form-group checkbox">
            <label>
              <input
                type="checkbox"
                name="publishDescription"
                checked={publishDescription}
                onChange={() => toggleCheckboxValue('publishDescription')}
              />
              Show Description in Supplemental Information when published
            </label>
          </div>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-xs-12 col-sm-10">
          <p className="lead">Supporting Documents (max 2)</p>

          {existingAttachments}

          {existingAttachments.length < 2 ? (
            <NewAttachmentContainer
              saveAttachment={addAttachment}
              updateError={updateError}
              showInBORCheckbox
              updateValue={handleChange}
              characterLimit={characterLimit}
              checkboxText="Show Document in Supplemental Information"
            />
          ) : null}
        </div>
      </div>

      <div className="col-12 col-sm-10 text-right">
        <p className="lead">* Indicates a required field.</p>
        <ErrorAlert error={error} />
        <button type="submit" className="btn btn-primary">
          {serviceProviderId ? 'Update Service Provider' : 'Add Service Provider'}
        </button>
        <GoBack />
      </div>
    </form>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviderForm);
