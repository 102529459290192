import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

class DeletionButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  componentWillReceiveProps = nextProps => {
    this.setState({
      showModal: false
    });
  };

  openModal = () => {
    this.setState({
      showModal: true
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  deleteAndCloseModal = e => {
    e.preventDefault();
    this.closeModal();
    this.props.onConfirm();
  };

  render() {
    const props = this.props;
    const { onConfirm, buttonText, title, body, className, confirmButtonText, ...otherProps } = props;

    const modal = (
      <Modal onHide={this.closeModal} show={this.state.showModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title || "Are you sure that you want to delete this?"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body || "Once you delete this it cannot be undeleted."}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.deleteAndCloseModal} bsStyle="danger">
            {confirmButtonText || "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    );

    return (
      <span>
        <button
          className={className || "btn btn-xs btn-danger deletion-link"}
          {...otherProps}
          onClick={e => {
            e.preventDefault();
            this.openModal();
          }}
        >
          {buttonText || "Delete"}
        </button>
        {modal}
      </span>
    );
  }
}

DeletionButton.propTypes = {
  onConfirm: PropTypes.func.isRequired
};

export default DeletionButton;
