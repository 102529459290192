import React from "react";
import { Link } from "react-router-dom";
import renderFor from "../../../utils/renderFor";
import DeletionButton from "../../Common/DeletionButton";
import { withRouter } from "react-router";

const ProductRow = ({ product, deleteProduct, location }) => {

    const productLink = product.district_admin_inventory_record_id === null ? `/products/${product.id}` : `/inventory/${product.district_admin_inventory_record_id}`;

    const districtAdminManage = product.district_admin_inventory_record_id === null ?
        <td className="text-right">&nbsp;</td> :
        <td className="text-right">
            <Link
                to={{ pathname: `${productLink}/update`,
                    state: {returnUrl: "/products"}
                }}
                className="btn btn-xs btn-warning"
            >
                {" "}Edit
            </Link>{" "}
        </td>

    const districts = product.districts || [];
    const districtList = districts.map(x => {
        return (
            <p key={x.id}>
                <Link to={`/districts/${x.id}`}>
                    {x.name}
                </Link>
            </p>
        );
    });

    let districtCount = product.number_of_districts;

    return (
        <tr>
            <td>
                <Link to={{
                  pathname: productLink,
                  state: {pathname: '/products', search: location.search, backText: "← Back to Product List"}
                }}>
                    {product.name}
                </Link>
            </td>
            <td>
                {districtCount}
            </td>
            <td>
                {product.company ? <Link to={{
                  pathname: `/companies/${product.company.id}`,
                  state: {pathname: '/products', search: location.search, backText: "← Back to Product List"}
                }}>
                    {product.company.name}
                </Link> : ""}
            </td>
            {renderFor({}, () => {
                return (
                    <td className="text-right">
                        <Link
                            to={{
                                pathname: `/products/${product.id}/update`,
                                state: {returnUrl: '/products'}
                            }}
                            className="btn btn-xs btn-warning"
                        >
                            {" "}Edit
                        </Link>{" "}
                        <DeletionButton
                            onConfirm={() => {
                                deleteProduct(product.id);
                            }}
                        />
                    </td>
                );
            })}
            {renderFor({districtAdmin: true, districtSystemAdmin: true, superAdmin: false}, () => {
                return (
                    districtAdminManage
                );
            })}
        </tr>
    );
};

export default withRouter(ProductRow);
