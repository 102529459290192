import React from 'react';
import { Link } from 'react-router-dom';
import './SingleRecord.css';
import DeletionButton from '../../Common/DeletionButton';
import AttachmentTable from '../../Common/Attachments/AttachmentTable';
import ArchivedAttachmentTable from '../../Common/Attachments/ArchivedAttachmentTable';
import ErrorAlert from '../../Common/ErrorAlert';
import SuccessAlert from '../../Common/SuccessAlert';

const SingleRecord = ({ record, deleteDelegate, toggleAttachmentArchived, messages }) => {
  const handleUnarchiveAttachment = (attachmentId) => {
    toggleAttachmentArchived(record.id, attachmentId, false);
  };

  const recordDocumentationField =
    record.attachments.length === 0 ? (
      <div className="panel-body">No Product Documentation</div>
    ) : (
      <AttachmentTable attachments={record.attachments} />
    );

  const recordArchivedDocumentationField =
    record.archived_attachments.length === 0 ? (
      <div className="panel-body">No Archived Product Documentation</div>
    ) : (
      <ArchivedAttachmentTable
        attachments={record.archived_attachments}
        unarchiveAttachment={handleUnarchiveAttachment}
        disabled={!record.user_can_edit || record.attachments.length >= 6}
      />
    );

  const delegates = record.delegates.map((delegate) => {
    return (
      <tr key={delegate.id}>
        <td>
          <Link to={`/delegates/${delegate.id}`}>{delegate.name}</Link>
        </td>
        <td>
          <ul className="authority-list">
            {delegate.authorities.map((auth) => {
              return <li key={auth.id}>{auth.name}</li>;
            })}
          </ul>
        </td>

        {!!record.user_can_edit ? (
          <td className="text-right">
            <Link to={`/delegates/${delegate.id}/update`} className="btn btn-xs btn-warning">
              Edit
            </Link>{' '}
            <DeletionButton
              onConfirm={() => {
                deleteDelegate(record.id, delegate.id);
              }}
            />
          </td>
        ) : null}
      </tr>
    );
  });

  const addDelegateRow = !!record.user_can_edit ? (
    <tr>
      <td colSpan={3} className="text-right">
        <Link to={`/inventory/${record.id}/delegates/create`} className="btn btn-xs btn-primary">
          Add Delegate
        </Link>
      </td>
    </tr>
  ) : null;

  return (
    <div className="single-record">
      <div className="row">
        <div className="col-sm-12">
          <p className="lead">District Data</p>
          <div className="table-responsive">
            <table className="table table-district-view">
              <tbody>
                <tr>
                  <th>Contract Source</th>
                  <td>{record.contractor}</td>
                </tr>
                <tr>
                  <th>Contract Purpose</th>
                  <td>{record.contract_purpose}</td>
                </tr>
                <tr>
                  <th>Data Admin</th>
                  <td>
                    {record.data_admin
                      ? `${record.data_admin.first_name} ${record.data_admin.last_name}`
                      : ''}
                  </td>
                </tr>
                <tr>
                  <th>Buildings</th>
                  <td>{(record.number_of_users || '').split(',').join(', ')}</td>
                </tr>
                <tr>
                  <th>Expiration</th>
                  <td>
                    {record.expires_at
                      ? new Date(record.expires_at).toLocaleDateString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                          timeZone: 'UTC'
                        })
                      : ''}
                  </td>
                </tr>
                <tr>
                  <th>Archived</th>
                  <td>{record.archived ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <th>Additional Notes</th>
                  <td>{record.additional_notes || 'None'}</td>
                </tr>
              </tbody>
            </table>
            <div className="panel panel-default">
              <div className="panel-heading">Delegates</div>
              <table className="table">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Authorities</th>
                    {!!record.user_can_edit ? <th className="text-right">Manage</th> : null}
                  </tr>

                  {delegates}

                  {addDelegateRow}
                </tbody>
              </table>
            </div>
            <div className="row">
              {messages.length &&
                messages.map((message, i) => {
                  if (message.type === 'success') {
                    return (
                      <div className="col-sm-12" key={i}>
                        <SuccessAlert message={message.message} />
                      </div>
                    );
                  } else if (message.type === 'error') {
                    return (
                      <div className="col-sm-12" key={i}>
                        <ErrorAlert error={message.message} />
                      </div>
                    );
                  }
                })}
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">Attachments (max 6)</div>
              {recordDocumentationField}
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">Archived Attachments</div>
              {recordArchivedDocumentationField}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleRecord;
