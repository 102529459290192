import apiInstance from "../utils/apiInstance";
import { clearAuthKey } from "../utils/cookieHelper";

export const login = (email, password) => async (dispatch, getState) => {
  const response = await apiInstance.post("loginv2", { email, password });

  if(response.data.token) {
    dispatch({ type: "ACCOUNT_RECEIVED", user: response.data.user });
    dispatch({ type: "API_TOKEN_RECEIVED", authKey: response.data.token });
  }

  return response.data;
};


export const resetPasswordAndLogin = (email, token, old_password, new_password, new_password_confirmation) => async (dispatch, getState) => {
  const response = await apiInstance.post("reset-expired-password", { email, token, old_password, new_password, new_password_confirmation });

  if(response.data.token) {
    dispatch({ type: "ACCOUNT_RECEIVED", user: response.data.user });
    dispatch({ type: "API_TOKEN_RECEIVED", authKey: response.data.token });
  }

  return response.data;
};

export const setPasswordAndLogin = (email, token, new_password, new_password_confirmation) => async (dispatch, getState) => {
  const response = await apiInstance.post("set-password", { email, token, new_password, new_password_confirmation });

  if(response.data.token) {
    dispatch({ type: "ACCOUNT_RECEIVED", user: response.data.user });
    dispatch({ type: "API_TOKEN_RECEIVED", authKey: response.data.token });
  }

  return response.data;
};

export const resetInvalidPassword = (email, oldPassword, newPassword) => async (dispatch, getState) => {
  const response = await apiInstance.post("login", { email, oldPassword, newPassword });

  if(response.data.token) {
    dispatch({ type: "ACCOUNT_RECEIVED", user: response.data.user });
    dispatch({ type: "API_TOKEN_RECEIVED", authKey: response.data.token });
  }

  return response.data;
};

export const requestAccount = authKey => async (dispatch, getState) => {
  const response = await apiInstance.get("my-account", null, {
    headers: { Authorization: `Bearer: ${authKey}` }
  });

  dispatch({ type: "ACCOUNT_RECEIVED", user: response.data });

  return response.data;
};

export const logout = () => (dispatch, getState) => {
  clearAuthKey();
  dispatch({ type: "API_TOKEN_INVALID" });
};

export const updateAccountName = (first_name, last_name) => async (dispatch, getState) => {
  const data = {
    first_name,
    last_name
  };

  const response = await apiInstance.put("my-account/name", data);
  dispatch({ type: "ACCOUNT_RECEIVED", user: response.data });
  return response.data;
};

export const updateAccountPassword = (current_password, new_password, new_password_confirmation) => 
async (dispatch, getState) => {
  const data = {
    current_password,
    new_password,
    new_password_confirmation
  };

  const response = await apiInstance.put("my-account/password", data);
  dispatch({ type: "ACCOUNT_RECEIVED", user: response.data });
  return response.data;
};
