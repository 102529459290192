import apiInstance from "../utils/apiInstance";
import qs from "qs";

export const searchForServiceProviders = (districtId, search, sort, order, page, onSupplementInfoPage) => async (dispatch, getState) => {
    const urlOptions = { search, sort, order, page, on_supplemental_info_page: onSupplementInfoPage};
    const url = `district-service-providers/${districtId}?${qs.stringify(urlOptions)}`;

    const response = await apiInstance.get(url);

    const pagination = {
        current_page: response.data.serviceProviders.current_page,
        last_page: response.data.serviceProviders.last_page
    };


    if(pagination.current_page === 1) {
        dispatch({
          type: "SERVICE_PROVIDERS_RESULTS_RECEIVED",
          records: response.data.serviceProviders.data,
          pagination,
          districtId,
          districtName: response.data.districtName
        });
      } else {
        dispatch({ type: "SERVICE_PROVIDERS_RESULTS_APPEND_RECEIVED",
          records: response.data.serviceProviders.data,
          pagination,
          districtId,
          districtName: response.data.districtName
        });
      }
};

export const guestSearchForServiceProviders = (districtId, search, sort, order, page) => async (dispatch, getState) => {
    const urlOptions = { search, sort, order, page, on_supplemental_info_page: true };
    const url = `district-service-providers/guest/${districtId}?${qs.stringify(urlOptions)}`;

    const response = await apiInstance.get(url);

    const pagination = {
        current_page: response.data.serviceProviders.current_page,
        last_page: response.data.serviceProviders.last_page
    };


    if(pagination.current_page === 1) {
        dispatch({
          type: "SERVICE_PROVIDERS_RESULTS_RECEIVED",
          records: response.data.serviceProviders.data,
          pagination,
          districtId,
          districtName: response.data.districtName
        });
      } else {
        dispatch({ type: "SERVICE_PROVIDERS_RESULTS_APPEND_RECEIVED",
          records: response.data.serviceProviders.data,
          pagination,
          districtId,
          districtName: response.data.districtName
        });
      }
};

export const createServiceProvider = async (districtId, record) => {
    const url = `district-service-providers/${districtId}`;
    const response = await apiInstance.post(url, record);
    
    return response.data;
};

export const updateServiceProvider = async (serviceProviderId, record) => {
    const url = `service-providers/${serviceProviderId}`;
    const data = {
        ...record
    };

    const response = await apiInstance.put(url, data);
    return response.data;
};

export const getServiceProvider = async (serviceProviderId) => {
    const url = `service-providers/${serviceProviderId}`;
    const response = await apiInstance.get(url);
    
    return response.data;
};

export const deleteServiceProvider = async (serviceProviderId) => {
    const url = `service-providers/${serviceProviderId}`;
    const response = await apiInstance.delete(url);

    return response.data;
};

export const updateDistrictServiceProviderVisibility = async (serviceProvider, showInSupplementalInformation) => {
    const url = `service-providers/${serviceProvider.id}/updateServiceProviderVisibility`;
    const data = {showInSupplementalInformation};

    const response = await apiInstance.put(url, data);
    return response.data;
};

export const updateDistrictServiceProviderDescriptionVisibility = async (serviceProvider, publishDescription) => {
  const url = `service-providers/${serviceProvider.id}/updateDescriptionVisibility`;
    const data = {publishDescription};

    const response = await apiInstance.put(url, data);
    return response.data;
}

export const updateDistrictServiceProviderAttachmentVisibility = async (attachment, showInSupplementalInformation) => {
    const url = `service-providers/${attachment.service_provider_id}/attachment/${attachment.id}/updateServiceProviderAttachmentVisibility`;
    const data = {showInSupplementalInformation};

    const response = await apiInstance.put(url, data);
    return response.data;
};