import apiInstance from "../utils/apiInstance";
import qs from "qs";

export const searchForSuggestedProducts = page => async (
  dispatch,
  getState
) => {
  const urlOptions = { page };
  const url = `suggested-products?${qs.stringify(urlOptions)}`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "SUGGESTED_PRODUCT_SEARCH_RECEIVED",
    suggestedProducts: response.data
  });
};

export const getSuggestedProduct = suggestedProductId => async (
  dispatch,
  getState
) => {
  const url = `suggested-products/${suggestedProductId}`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "SUGGESTED_PRODUCT_RECEIVED",
    suggestedProductId,
    suggestedProduct: response.data
  });
};

export const updateSuggestedProduct = (
  suggestedProductId,
  name,
  type,
  company,
  data_classifications,
  registrar_first_name,
  registrar_last_name,
  registrar_email,
  has_underage_students,
  purpose,
  buildings
) => async (dispatch, getState) => {
  const url = `suggested-products/${suggestedProductId}`;
  const data = {
    name,
    type,
    company,
    data_classifications,
    registrar_first_name,
    registrar_last_name,
    registrar_email,
    has_underage_students,
    purpose,
    buildings
  };

  const response = await apiInstance.put(url, data);

  dispatch({
    type: "SUGGESTED_PRODUCT_UPDATED"
  });

  dispatch({
    type: "SUGGESTED_PRODUCT_RECEIVED",
    suggestedProductId,
    suggestedProduct: response.data
  });
};

export const deleteSuggestedProduct = suggestedProductId => async (
  dispatch,
  getState
) => {
  const url = `suggested-products/${suggestedProductId}`;

  await apiInstance.delete(url);

  dispatch({ type: "SUGGESTED_PRODUCT_DELETED", suggestedProductId });
};

export const approveSuggestedProduct = suggestedProductId => async (
  dispatch,
  getState
) => {
  const url = `suggested-products/${suggestedProductId}/approve`;

  await apiInstance.put(url);

  dispatch({ type: "SUGGESTED_PRODUCT_APPROVED", suggestedProductId });
};
