import React from "react";
import ErrorAlert from "../Common/ErrorAlert";
import PasswordPolicy from "../Common/PasswordPolicy";

const ResetPasswordForm = ({
  token,
  old_password,
  new_password,
  new_password_confirmation,
  updateToken,
  updateOldPassword,
  updateNewPassword,
  updateConfirmNewPassword,
  submitResetPassword,
  error,
}) =>
  <div className="row">
    <div className="col-sm-4 col-sm-offset-4">
      <div className="panel panel-primary login-panel">
        <div className="panel-heading">Reset Password</div>
        <div className="panel-body">
          <p style={{margin: '0 0 30px', fontWeight: 600}}>
            Your password has expired or is invalid. Check your email for a one-time token.
          </p>
          <form
            onSubmit={e => {
              e.preventDefault();
              submitResetPassword();
            }}
          >
            <div className="form-group">
              <label htmlFor="token">One Time Token</label>
              <input
                id="token"
                placeholder="One Time Token"
                name="token"
                className="form-control"
                value={token}
                onChange={e => {
                  e.preventDefault();
                  updateToken(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="old_password">Old Password</label>
              <input
                id="old_password"
                placeholder="Old Password"
                name="old_password"
                className="form-control"
                type="password"
                value={old_password}
                onChange={e => {
                  e.preventDefault();
                  updateOldPassword(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="new_password">New Password</label>
              <input
                id="=new_password"
                name="new_password"
                className="form-control"
                placeholder={'New Password'}
                type="password"
                value={new_password}
                onChange={e => {
                  e.preventDefault();
                  updateNewPassword(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="new_password_confirmation">Confirm New Password</label>
              <input
                id="=new_password_confirmation"
                name="new_password_confirmation"
                placeholder={"Confirm New Password"}
                className="form-control"
                type="password"
                value={new_password_confirmation}
                onChange={e => {
                  e.preventDefault();
                  updateConfirmNewPassword(e.target.value);
                }}
              />
            </div>
            {error
              ? <div className="row">
                  <div className="col-sm-12">
                    <ErrorAlert error={error} />
                  </div>
                </div>
              : null}
            <div className="form-group form-submit">
              <button
                name="Log In"
                className="btn btn-primary btn-block"
                type="submit"
              >
                Reset Password & Login
              </button>
            </div>
            <PasswordPolicy />
          </form>
        </div>
      </div>
    </div>
  </div>;

export default ResetPasswordForm;
