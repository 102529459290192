import apiInstance from '../../utils/apiInstance';
import { Button, Glyphicon, Pager, Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';

export default ({ search }) => {
  const [regionReport, setRegionReport] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [districtReport, setDistrictReport] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [productReport, setProductReport] = useState(null);
  const [orderBy, setOrderBy] = useState('name');
  const [direction, setDirection] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const fetchRegionReport = async () => {
    const { data } = await apiInstance.get(
      `reports/region?orderBy=${orderBy}&direction=${direction}&page=${currentPage}&search=${search}`
    );
    setRegionReport(data.data);
    setCurrentPage(data.current_page);
    setLastPage(data.last_page);
  };

  const fetchDistrictReport = async () => {
    const { data } = await apiInstance.get(
      `reports/region/${selectedRegion.id}?orderBy=${orderBy}&direction=${direction}&page=${currentPage}&search=${search}`
    );
    setDistrictReport(data.data);
    setCurrentPage(data.current_page);
    setLastPage(data.last_page);
  };

  const fetchProductReport = async () => {
    const { data } = await apiInstance.get(
      `reports/region/${selectedRegion.id}/district/${selectedDistrict.id}?orderBy=${orderBy}&direction=${direction}&page=${currentPage}&search=${search}`
    );
    setProductReport(data.data);
    setCurrentPage(data.current_page);
    setLastPage(data.last_page);
  };

  const fetchCurrentReport = async () => {
    setLoading(true);
    if (selectedDistrict) {
      await fetchProductReport();
    } else if (selectedRegion) {
      await fetchDistrictReport();
    } else {
      await fetchRegionReport();
    }
    setLoading(false);
  };

  const handleHeaderClick = (field) => {
    if (field === orderBy) {
      setCurrentPage(1);
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setCurrentPage(1);
      setOrderBy(field);
      setDirection('asc');
    }
  };

  const handleRowClick = (row) => {
    if (selectedDistrict) {
      return;
    } else if (selectedRegion) {
      setCurrentPage(1);
      setSelectedDistrict(row);
    } else {
      setCurrentPage(1);
      setSelectedRegion(row);
    }
  };

  const handleBack = () => {
    if (selectedDistrict) {
      setCurrentPage(1);
      setSelectedDistrict(null);
      setProductReport(null);
    } else if (selectedRegion) {
      setCurrentPage(1);
      setSelectedRegion(null);
      setDistrictReport(null);
    }
  };

  const rows = () =>
    (productReport || districtReport || regionReport).map((row) => (
      <tr key={row.id} onClick={() => handleRowClick(row)} style={{ cursor: selectedDistrict ? 'auto' : 'pointer' }}>
        <td>{row.name}</td>
        <td>{row.record_count}</td>
        <td>{row.attachment_count}</td>
      </tr>
    ));

  useEffect(() => {
    fetchCurrentReport();
  }, [orderBy, direction, selectedRegion, selectedDistrict, currentPage, search]);

  if (loading) {
    return <p>Loading...</p>;
  }

  const arrow = <Glyphicon glyph={direction === 'asc' ? 'chevron-up' : 'chevron-down'} />;

  return (
    <>
      <h1>Region Drill Down</h1>
      <Button disabled={selectedRegion === null && selectedDistrict === null} onClick={handleBack}>
        Back
      </Button>
      <h2>
        {(selectedDistrict ? `${selectedRegion.name} / ${selectedDistrict.name}` : null) ||
          selectedRegion?.name ||
          'Regions'}
      </h2>
      <Table hover={true} striped={true}>
        <thead>
          <tr>
            <th onClick={() => handleHeaderClick('name')}>
              Name {orderBy === 'name' ? arrow : null}
            </th>
            <th onClick={() => handleHeaderClick('record_count')}>
              Record Count {orderBy === 'record_count' ? arrow : null}
            </th>
            <th onClick={() => handleHeaderClick('attachment_count')}>
              Attachments {orderBy === 'attachment_count' ? arrow : null}
            </th>
          </tr>
        </thead>
        <tbody>{rows()}</tbody>
      </Table>
      <Pager>
        <Pager.Item
          previous
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          &larr; Previous
        </Pager.Item>
        { selectedDistrict ? `${currentPage} / ${lastPage}` : null }
        <Pager.Item
          next
          disabled={currentPage === lastPage}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next &rarr;
        </Pager.Item>
      </Pager>
      { ! selectedDistrict ? `${currentPage} / ${lastPage}` : null }
    </>
  );
};
