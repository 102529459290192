import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./Login.css";
import { setPasswordAndLogin } from "../../actions/Account";
import SetPasswordForm from "./SetPasswordForm";
import config from "../../config";
import { getUserHomePath } from "../../utils/paths";

class SetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: decodeURIComponent(this.props.history.location.search.slice(7,)),
      token: "",
      new_password: "",
      new_password_confirmation: "",
      error: "",
      showModal: false
    };
  }

  submitSetPassword = async () => {
    if (!this.state.token) {
      this.setState({ error: "You must provide your one time token. Check your email." });
      return;
    }
    if (!this.state.new_password) {
      this.setState({ error: "You must include your new password" });
      return;
    }
    if (!this.state.new_password_confirmation) {
      this.setState({ error: "You must confirm your new password" });
      return;
    }
    if (this.state.new_password_confirmation !== this.state.new_password) {
      this.setState({ error: "Your new password and new password confirmation must match." });
      return;
    }

    try {
      const response = await this.props.setPasswordAndLogin(
        this.state.email, this.state.token, this.state.new_password, this.state.new_password_confirmation
      );

      const user = response.user;

      if(response.token) {
        const newPath = getUserHomePath(user);
        this.props.history.push(newPath);
      }
      else if(response.reset_password) {
        // console.log('reset it');
      }
    } catch (err) {
      this.setState({ error: err });
    }
  };

  updateToken = token => {
    this.setState({
      token
    });
  };

  updateNewPassword = new_password => {
    this.setState({
      new_password
    });
  };

  updateConfirmNewPassword = new_password_confirmation => {
    this.setState({
      new_password_confirmation
    });
  };

  openModal = () => {
    this.setState({
      showModal: true
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  render() {
    return (
      <SetPasswordForm
        token={this.state.token}
        updateToken={this.updateToken}
        updateNewPassword={this.updateNewPassword}
        updateConfirmNewPassword={this.updateConfirmNewPassword}
        submitSetPassword={this.submitSetPassword}
        error={this.state.error}
        showModal={this.state.showModal}
        openModal={this.openModal}
        closeModal={this.closeModal}
        faqUrl={config.faqUrl}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    authKey: state.Account.authKey
  };
};

const actions = { setPasswordAndLogin };

SetPasswordContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(SetPasswordContainer)
);

export default SetPasswordContainer;
