import React from "react";
import ProductRow from "./ProductRow";
import PageHeader from "../../Common/PageHeader";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import renderFor from "../../../utils/renderFor";
import Search from "../../Common/Search";

const ProductList = ({
                         products,
                         selectType,
                         selectedProductType,
                         currentPage,
                         changePage,
                         totalPages,
                         performSearch,
                         query,
                         updateQuery,
                         deleteProduct
                     }) => {



    const productTypeItems =
        ['0-9', ...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'].map(x => {
            return (
                <button
                    key={x}
                    onClick={e => {
                        e.preventDefault();
                        window.scrollTo(0,0);
                        selectType(x);
                    }}
                    className={
                        "btn btn-link list-group-item" +
                        (x === selectedProductType ? " active" : "")
                    }
                >
                    {x}
                </button>
            );
        });

    const productRows = products
        ? products.map(product => (
            <ProductRow
                key={product.id}
                product={product}
                deleteProduct={deleteProduct}
            />
        ))
        : null;

    const suggestProductMessage = query ?
        <div className="products-not-found" >
            <p>If your search did not produce desired results, please try alternate spelling or spacing. If product is not found please submit a request to add the product using the following form</p>
            <p>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeeuNZ_1ptarKml5zAjBftD-wHCJ1zCzXiNGvqKEvNOH7orOA/viewform" target="_blank" rel="noreferrer noopener">Request New Product</a>
            </p>
        </div> : null;

    const paginator =
        totalPages > 1 ? (
            <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                items={totalPages}
                maxButtons={5}
                activePage={currentPage}
                onSelect={changePage}
            />
        ) : null;

    return (
        <div className="product-list-container">
            <PageHeader title="Products" subtitle="View Products" />
            <div className="row">
                <div className="col-sm-12 text-right">
                    <Search query={query} updateQuery={updateQuery} performSearch={performSearch}/>
                        {renderFor(
                            {
                                companyAdmin: true,
                                superAdmin: true
                            },
                            () => {
                                return (
                                    <div className="hidden-xs add-product-btn">
                                        <Link
                                            to="/products/create"
                                            className="btn btn-success btn-sm"
                                        >
                                            Add Product
                                        </Link>
                                    </div>
                                );
                            }
                        )}
                </div>
            </div>
            {renderFor(
                {
                    companyAdmin: true,
                    superAdmin: true
                },
                () => {
                    return (
                        <div className="row add-entity-row visible-xs">
                            <div className="col-sm-12">
                                <Link to="/products/create" className="btn btn-success btn-sm">
                                    Add Product
                                </Link>
                            </div>
                        </div>
                    );
                }
            )}
            <div className="row">
                <div className="col-md-10 col-md-push-2">
                    <div>
                        <p className="lead">
                            {selectedProductType || "Search Results"}
                        </p>

                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Number of Districts</th>
                                    <th>Company</th>
                                    {renderFor({districtAdmin: true,  districtSystemAdmin: true}, () => {
                                        return <th className="text-right">Manage</th>;
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {productRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    { suggestProductMessage }
                    { paginator }
                </div>
                <div className="col-md-2 col-md-pull-10">
                    <p className="lead">Product Filter</p>
                    <div className="list-group">{productTypeItems}</div>
                </div>
            </div>
        </div>
    );
};

export default ProductList;
