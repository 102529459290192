import React, { useState } from "react";
import { connect } from "react-redux";
import ServiceProviderForm from "./ServiceProviderForm";
import { createServiceProvider } from "../../../actions/ServiceProvider";
import PageHeader from "../../Common/PageHeader";


const NewServiceProviderContainer = (props) => {
    const [dateFocused, setDateFocused] = useState("");
    const [characterLimit, setCharacterLimit] = useState(255);
    const [formData, setFormData] = useState({
        error: "",
        maxDescriptionLength: false,
        name: "",
        expires_at: null,
        description: "",
        showInSupplementalInformation: false,
        attachments: [],
        publishDescription: false,
    });

    const addAttachment = (newAttachment) => {
        setFormData((oldFormData) => ({ ...oldFormData, attachments: [...oldFormData.attachments, { ...newAttachment, name: newAttachment.name }] }));
    };

    const updateAttachment = (updatedAttachment) => {
        setFormData((oldFormData) => ({ ...oldFormData, attachments: formData.attachments.map((existingAttachment, index) => {
            let shouldUpdate = false;

            if(typeof updatedAttachment.id !== "undefined") {
                if(existingAttachment.id === updatedAttachment.id) {
                    shouldUpdate = true;
                }
            } else if (typeof updatedAttachment.index !== "undefined" && index === updatedAttachment.index) {
                shouldUpdate = true;
            }

            return shouldUpdate ? {
                ...updatedAttachment,
                name: updatedAttachment.name
            } : existingAttachment;
        }) }));
    };

    const removeAttachment = (attachment) => {
        setFormData((oldFormData) => ({ ...oldFormData, attachments: oldFormData.attachments.filter(x => x !== attachment) }));
    };

    const updateError = err => {
        setFormData((oldFormData) => ({ ...oldFormData, error: err }));
    };

    const saveServiceProvider = async () => {
        const { match } = props;
        const serviceProviderId = match.params.serviceProviderId;
        const districtId = match.params.districtId;

        try {
            const newServiceProvider = await createServiceProvider(districtId, {
                ...formData,
                district_id: districtId,
                expires_at: formData.expires_at ? formData.expires_at.toISOString().split("T")[0] : null,
                attachments: formData.attachments.map((attachment) => {
                    delete attachment.index;
                    return attachment;
                }),
            });

            props.history.push(`/service-provider/${newServiceProvider.id}`);
        } catch (err) {
            updateError(err);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        const field = e.target.name;
        const value = e.target.value;

        if(field === 'description' && value.length === characterLimit) {
            setFormData((oldFormData) => ({ ...oldFormData, [field]: value, maxDescriptionLength: true }));
        } else {
            setFormData((oldFormData) => ({ ...oldFormData, [field]: value, maxDescriptionLength: false }));
        }
    };

    const toggleCheckboxValue = (field) => {
        setFormData((oldFormData) => ({ ...oldFormData, [field]: !formData[field] }));
    };

    const changeFocus = (newFocus) => {
        setDateFocused(newFocus);
    };

    return (
        <div className="new-service-provider-container">
            <PageHeader
                title="Service Providers"
                subtitle="Add a Service Provider"
            />
            <ServiceProviderForm
                {...formData}
                handleChange={handleChange}
                changeFocus={changeFocus}
                dateFocused={dateFocused}
                addAttachment={addAttachment}
                removeAttachment={removeAttachment}
                updateAttachment={updateAttachment}
                toggleCheckboxValue={toggleCheckboxValue}
                updateError={updateError}
                saveServiceProvider={saveServiceProvider}
                isLoading={false}
                characterLimit={characterLimit}
            />
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    createServiceProvider
};

export default connect(mapStateToProps, mapDispatchToProps)(NewServiceProviderContainer);