import React from 'react';

const AttachmentTable = ({ attachments }) => (
  <table className="table table-product-view">
    <tbody>
      {attachments.map((attachment) => {
        return (
          <tr key={attachment.id}>
            <th>
              <a href={attachment.uri} target="_blank" rel="noopener noreferrer">
                {attachment.name}{' '}
              </a>
            </th>
          </tr>
        );
      })}
    </tbody>
  </table>
);

export default AttachmentTable;
