import React from 'react';
import AttachmentTable from '../../Common/Attachments/AttachmentTable';

const SingleServiceProvider = ({ serviceProvider }) => {
  const serviceProviderDocumentationField =
    serviceProvider.attachments.length === 0 ? (
      <div className="panel-body">No Service Provider Documentation</div>
    ) : (
      <AttachmentTable attachments={serviceProvider.attachments} />
    );

  return (
    <div className="single-record">
      <div className="row">
        <div className="col-sm-12">
          <p className="lead">{serviceProvider.name}</p>
          <div className="panel panel-default">
            <div className="panel-heading">Service Provider Data</div>
            <table className="table">
              <tbody>
                <tr>
                  <th>District</th>
                  <td>{serviceProvider.district.name}</td>
                </tr>
                <tr>
                  <th>Expiration</th>
                  <td>
                    {serviceProvider.expires_at
                      ? new Date(serviceProvider.expires_at).toLocaleDateString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                          timeZone: 'UTC'
                        })
                      : ''}
                  </td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{serviceProvider.description || ''}</td>
                </tr>
                <tr>
                  <th>Publish in Supplemental Information</th>
                  <td>
                    <input
                      type="checkbox"
                      checked={serviceProvider.showInSupplementalInformation ? 'checked' : ''}
                      readOnly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading">Attachments</div>
            {serviceProviderDocumentationField}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleServiceProvider;
