import React from "react";
import PageHeader from "../../Common/PageHeader";
import { Link } from "react-router-dom";
import DeletionButton from "../../Common/DeletionButton";

const SingleDelegate = ({ delegate, deleteDelegate }) => {
  return (
    <div className="single-delegate">
      <PageHeader title="District Inventory" subtitle="View Delegate" />
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-8">
              <p className="lead">{delegate.name}</p>
            </div>
            <div className="col-sm-4 text-right">
              <Link
                to={`/delegates/${delegate.id}/update`}
                className="btn btn-default btn-sm"
              >
                Edit
              </Link>
              {" "}
              <DeletionButton
                onConfirm={() => {
                  deleteDelegate(delegate.id);
                }}
              />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-hover">
              <tbody>
                <tr>
                  <th>District</th>
                  <td>{delegate.inventory_record.district.name}</td>
                </tr>
                <tr>
                  <th>Location</th>
                  <td>{delegate.location}</td>
                </tr>
                <tr>
                  <th>Product</th>
                  <td>{delegate.inventory_record.product.name}</td>
                </tr>
                <tr>
                  <th>Authorities</th>
                  <td>
                    <ul>
                      {delegate.authorities.map(auth => (
                        <li key={auth.id}>{auth.name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SingleDelegate;
