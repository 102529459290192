import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import SupplementalInformationParentContainer from './SupplementalInformationParentContainer';

class SupplementalInformationContainer extends Component {
  render() {
    const { match } = this.props;
    return (
      <div className="bill-of-rights-container">
        <Switch>
          <Route path={`${match.url}/:districtId`} component={SupplementalInformationParentContainer} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = {};

SupplementalInformationContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(SupplementalInformationContainer);

export default SupplementalInformationContainer;
