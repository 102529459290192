import React from "react";

const Search = ({performSearch, updateQuery, query, showClearButton = false, searchButtonText = "", customId = ""}) => {
    const clearSearch = () => {
        updateQuery('');
        performSearch('');
    };

    let clearButton = showClearButton ?
        <button type="submit" className="btn btn-warning" color="black" onClick={clearSearch}>Clear Search</button>
        :
        null;

    return (
        <span>
        <label htmlFor="search-form" className="sr-only">Search</label>
        <form
            name="search-form"
            id="search-form"
            action="true"
            className="form-inline search-form"
            onSubmit={e => {
                e.preventDefault();
                performSearch(query);
            }}
        >
            <div className="input-group">
                <div className="btn-group search-clear-group">
                    <label htmlFor={`search-${customId}`} className="sr-only">Search</label>
                    <input
                        name={`search-${customId}`}
                        id={`search-${customId}`}
                        placeholder="Search for..."
                        className="form-control search-clear-input"
                        type="search"
                        onChange={e => {
                            e.preventDefault();
                            updateQuery(e.target.value);
                        }}
                        value={query}
                    />
                    <span className="glyphicon glyphicon-remove-circle search-clear" onClick={clearSearch}></span>
                </div>
                <span className="input-group-btn">
                <button type="submit" className="btn btn-default">
                  {searchButtonText ? searchButtonText : 'Search'}
                </button>
                    { clearButton }
              </span>
            </div>
        </form>
        </span>
    );
};

export default Search;