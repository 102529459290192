import { combineReducers } from "redux";
import Account from "./Account";
import Attachment from "./Attachment";
import Region from "./Region";
import District from "./District";
import Company from "./Company";
import Product from "./Product";
import Delegate from "./Delegate";
import User from "./User";
import Classification from "./Classification";
import Options from "./Options";
import BillOfRights from "./BillOfRights";
import InventoryRecord from "./InventoryRecord";
import SuggestedProduct from "./SuggestedProduct";
import DistrictAttachment from "./DistrictAttachment";
import ServiceProvider from "./ServiceProvider";
import { connectRouter } from "connected-react-router";

// Reducers are responsible for a slice of the application state. When an action happens, if
// A reducer handles that action, it can operate on its slice of the state and return a new copy
// of its slice
//
// A reducer is a pure function which takes in as an argument, State and Action. The action
// tells the reducer how to manipulate state, or, which portion of state to return
//
// combineReducers is how Redux manages splitting application state logically.
// Combine reducers will return a single redux state from the result of combining multiple redux reducers.

const AllReducers = (history) => combineReducers({
  router: connectRouter(history),
  Account,
  Attachment,
  Product,
  District,
  InventoryRecord,
  Options,
  Company,
  Region,
  Classification,
  User,
  Delegate,
  BillOfRights,
  SuggestedProduct,
  DistrictAttachment,
  ServiceProvider,
});

export default AllReducers;
