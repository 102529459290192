import React from 'react';
import AttachmentContainer from '../../Common/Attachments/AttachmentContainer';
import NewAttachmentContainer from '../../Common/Attachments/NewAttachmentContainer';
import renderFor from '../../../utils/renderFor';
import ErrorAlert from '../../Common/ErrorAlert';
import GoBack from '../../Common/GoBack';
import userTypes from '../../../utils/userTypes';
import { Creatable } from 'react-select';

const ProductForm = ({
  productId,
  editorInfo,
  saveProduct,
  error,
  currentUser,
  isSuggestedProduct,
  updateError
}) => {
  const company = editorInfo.company;
  const companyList = editorInfo.companies;

  const visibleCompanies = companyList.map((x) => {
    return {
      value: x.name,
      company: x,
      label: x.name
    };
  });

  if (company && companyList.filter((x) => x.name === company).length === 0) {
    visibleCompanies.push({ value: company, label: company });
  }

  const type = editorInfo.type;
  const typeList = editorInfo.productTypes;
  const visibleTypes = typeList.map((type) => {
    return {
      value: type,
      label: type
    };
  });
  if (type && typeList.indexOf(type) === -1) {
    visibleTypes.push({ value: type, label: type });
  }

  // const classificationIds = editorInfo.data_classifications.map(x => x.id);
  const educationalAgency = editorInfo.educationalAgency;
  const noPiiInAccount = editorInfo.noPiiInAccount;

  var attachmentTypes =
    noPiiInAccount || educationalAgency
      ? editorInfo.attachmentTypes.filter((x) => x !== 'POLICY_ABSTRACT')
      : editorInfo.attachmentTypes;
  const isCompanyAdmin = currentUser.user_type === userTypes.companyAdmin;

  const attachments = editorInfo.attachments.map((x, index) => {
    return (
      <AttachmentContainer
        key={index}
        attachment={x}
        index={index}
        attachmentTypes={attachmentTypes}
        updateAttachment={editorInfo.updateAttachment}
        removeAttachment={editorInfo.removeAttachment}
      />
    );
  });

  // Removed -- Products no longer use data categories
  // const classificationCheckboxes = editorInfo.classifications.map(x => {
  //   return (
  //     <div className="checkbox" key={x.id}>
  //       <label>
  //         <input
  //           name="classifications"
  //           type="checkbox"
  //           checked={classificationIds.indexOf(x.id) >= 0}
  //           onChange={e => {
  //             if (e.target.checked) {
  //               editorInfo.addDataClassification(x);
  //             } else {
  //               editorInfo.removeDataClassification(x);
  //             }
  //           }}
  //         />
  //         {x.label}
  //       </label>
  //     </div>
  //   );
  // });

  const buttons = () => {
    if (isSuggestedProduct) return null;

    return (
      <div className="col-sm-12 text-right">
        <button type="submit" className="btn btn-primary">
          {productId ? 'Update Product' : 'Create Product'}
        </button>
        <GoBack />
      </div>
    );
  };

  return (
    <div className="product-editor">
      <div className="row">
        <div className="col-sm-10">
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              saveProduct(productId, editorInfo);
            }}>
            <p className="lead">Product Information</p>
            <div className="row">
              <div className="form-group col-sm-6">
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  name="name"
                  placeholder="Name of Product"
                  className="form-control"
                  type="text"
                  value={editorInfo.name}
                  onChange={editorInfo.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="type">Type</label>
                  <Creatable
                    id="type"
                    name="type"
                    value={type}
                    clearable={false}
                    onChange={(selection) => {
                      const changeType = {
                        preventDefault: () => {},
                        target: {
                          name: 'type',
                          value: selection ? selection.value : null
                        }
                      };

                      editorInfo.handleChange(changeType);
                    }}
                    options={visibleTypes}
                  />
                </div>
              </div>
              {isCompanyAdmin || companyList.length === 0 ? null : (
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="company">Company</label>
                    <Creatable
                      id="company"
                      name="company"
                      value={company}
                      clearable={false}
                      onChange={(selection) => {
                        const company = (selection || {}).company;

                        const changeCompany = {
                          preventDefault: () => {},
                          target: {
                            name: 'company',
                            value: selection ? selection.value : null
                          }
                        };

                        const changeEducationalAgency = {
                          preventDefault: () => {},
                          target: {
                            name: 'educationalAgency',
                            value: company ? company.nys_education_law_does_not_apply : false
                          }
                        };

                        editorInfo.handleChange(changeCompany);
                        editorInfo.handleChange(changeEducationalAgency);
                      }}
                      options={visibleCompanies}
                    />
                  </div>
                </div>
              )}
            </div>
            {renderFor(
              {
                superAdmin: true
              },
              () => {
                return (
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <p>
                          <b>Inactive Product</b>
                        </p>
                        <div className="checkbox">
                          <label htmlFor="inactive">
                            <input
                              id="inactive"
                              name="inactive"
                              type="checkbox"
                              checked={editorInfo.inactive}
                              onChange={editorInfo.handleCheckbox}
                            />
                            When checked, this product will be hidden from search results, and
                            cannot be added to new Districts.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
            <div className="row">
              {/*<div className="col-sm-6">*/}
              {/*<div className="form-group">*/}
              {/*  <p>*/}
              {/*    <b>Data Categories</b>*/}
              {/*  </p>*/}
              {/*  {classificationCheckboxes}*/}
              {/*</div>*/}
              {/*</div>*/}
              {renderFor(
                {
                  companyAdmin: true
                },
                () => {
                  return (
                    <div className="col-sm-6">
                      <div className="form-group">
                        <p>
                          <b>Privacy Policy Contains Sections Covering:</b>
                        </p>
                        <p>
                          <b>Credentials</b>
                        </p>
                        <div className="checkbox">
                          <label htmlFor="sii">
                            <input
                              id="sii"
                              name="sii"
                              type="checkbox"
                              checked={editorInfo.sii}
                              onChange={editorInfo.handleCheckbox}
                            />
                            Software & Information Industry Association (SIIA), Student Data Privacy
                            Pledge
                          </label>
                        </div>
                        {/*<div className="checkbox">
                          <label htmlFor="nystcc">
                            <input
                              id="nystcc"
                              name="nystcc"
                              type="checkbox"
                              checked={editorInfo.nystcc}
                              onChange={editorInfo.handleCheckbox}
                            />
                            New York State Technology Contract Consortium
                          </label>
                        </div>*/}

                        <div className="form-group">
                          <label htmlFor="other">Other</label>
                          <input
                            id="other"
                            name="other"
                            className="form-control"
                            type="text"
                            value={editorInfo.other}
                            onChange={editorInfo.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            {renderFor(
              {
                companyAdmin: true
              },
              () => {
                return (
                  <div>
                    <p className="lead">Product Documentation</p>

                    {attachments}

                    <NewAttachmentContainer
                      attachmentTypes={attachmentTypes}
                      saveAttachment={editorInfo.addAttachment}
                      updateError={updateError}
                    />
                  </div>
                );
              }
            )}
            {error ? (
              <div className="row">
                <div className="col-sm-12">
                  <ErrorAlert error={error} />
                </div>
              </div>
            ) : null}
            <div className="row">{buttons()}</div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;
