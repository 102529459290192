import React from "react";
import PageHeader from "../../Common/PageHeader";
import { Link } from "react-router-dom";
import userTypes from "../../../utils/userTypes";
import DeletionButton from "../../Common/DeletionButton";
import {withRouter} from 'react-router';

const SingleCompany = ({ company, currentUser, deleteCompany, location }) => {
  const isAdminForCompany =
    currentUser.user_type === userTypes.companyAdmin &&
    currentUser.entity_id === company.id;

  return (
    <div className="single-company">
      <PageHeader title="Company" subtitle="Company Profile" />

      {
        (!isAdminForCompany || location.state) ? <p>
          <Link to={location.state && (location.state.previousState || location.state ) || {pathname: "/companies"}}>
            { location.state && location.state.backText ? location.state.backText : "← Back to Companies List" }
          </Link>
        </p>
          : null
      }

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-8">
              <p className="lead">
                {company.name}
              </p>
            </div>
            <div className="col-sm-4 text-right">
                {isAdminForCompany ? <Link
                to={`/companies/${company.id}/update`}
                className="btn btn-warning">Edit</Link>
                  : null}{" "}
              {isAdminForCompany
                ? <DeletionButton
                      onConfirm={() => {
                          deleteCompany(company.id);
                      }}
                  />
                : null}
            </div>
          </div>
          
          <p className="lead">Associated Products</p>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Type</th>
                  <th>Data Category</th>
                </tr>
              </thead>
              <tbody>
                {company.products.map(product => {
                  return (
                    <tr key={product.id}>
                      <td>
                        <Link to={{
                          pathname: `/products/${product.id}`,
                          state: { pathname: `/companies/${company.id}`, backText: `← Back to ${company.name}`, previousState: location.state}
                        }}>
                          {product.name}
                        </Link>
                      </td>
                      <td>
                        {product.type}
                      </td>
                      <td>
                        {product.data_classifications
                          .map(x => x.name)
                          .join(", ")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SingleCompany);
