import React, { Component } from "react";
import { connect } from "react-redux";
import { getDelegate, updateDelegate } from "../../../actions/Delegate";
import DelegateForm from "./DelegateForm";
import PageHeader from "../../Common/PageHeader";

class UpdateDelegateContainer extends Component {
  constructor(props) {
    super(props);

    const delegate = this.getDelegateFromProps(props) || {};

    this.state = {
      error: "",
      name: delegate.name || "",
      location: delegate.location || "",
      authorities: (delegate.authorities || []).map(x => x.id)
    };
  }

  getDelegateIdFromProps(props) {
    const { match } = props;
    const delegateId = match.params.delegateId;

    return delegateId ? parseInt(delegateId, 10) : undefined;
  }

  getDelegateFromProps(props) {
    const delegateId = this.getDelegateIdFromProps(props);
    if (delegateId === undefined) return undefined;

    const delegate = props.delegates[delegateId];
    return delegate;
  }

  setStateFromDelegate(delegate) {
    this.setState({
      ...delegate,
      authorities: delegate.authorities.map(x => x.id)
    });
  }

  async componentDidMount() {
    const delegateId = this.getDelegateIdFromProps(this.props);
    await this.props.getDelegate(delegateId);

    const delegate = this.getDelegateFromProps(this.props);
    this.setStateFromDelegate(delegate);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getDelegateIdFromProps(this.props);
    const newId = this.getDelegateIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getDelegate(newId);

      const delegate = this.getDelegateFromProps(nextProps);
      this.setStateFromDelegate(delegate);
    }
  }

  selectAuthority = authorityId => {
    this.setState({
      authorities: [...this.state.authorities, authorityId]
    });
  };

  removeAuthority = authorityId => {
    const currentAuths = this.state.authorities;
    const newAuthorities = currentAuths.filter(x => x !== authorityId);

    this.setState({ authorities: newAuthorities });
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  saveDelegate = async () => {
    const delegate = this.getDelegateFromProps(this.props);

    try {
      await this.props.updateDelegate(delegate.id, { ...this.state });

      const newPath = `/delegates/${delegate.id}`;
      this.props.history.push(newPath);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  render() {
    const delegate = this.getDelegateFromProps(this.props);
    if (!delegate) return <div>Loading...</div>;

    return (
      <div className="new-delegate-container">
        <PageHeader title="District Inventory" subtitle="Update Delegate" />
        <DelegateForm
          {...this.state}
          id={delegate.id}
          handleChange={this.handleChange}
          possibleAuthorities={delegate.inventory_record.authorities}
          selectAuthority={this.selectAuthority}
          removeAuthority={this.removeAuthority}
          saveDelegate={this.saveDelegate}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    delegates: state.Delegate.delegateDict
  };
};

const actions = { getDelegate, updateDelegate };

UpdateDelegateContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(UpdateDelegateContainer);

export default UpdateDelegateContainer;
