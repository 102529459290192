import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import NavContainer from "../Nav/NavContainer";
import { logout } from "../../actions/Account";

const authTimeoutSeconds = 1800; //30 Minutes
const authTimeoutCheckSeconds = 10;
const now = () => Math.floor(Date.now() / 1000);

class AppContainer extends Component {
    authTimeoutInterval = null;
    lastActivity = now();

    componentDidMount() {
        this.registerLastActivityListeners();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const loggedIn = prevProps.currentUser === null && this.props.currentUser !== null;
        const loggedOut = prevProps.currentUser !== null && this.props.currentUser === null;
        if (loggedIn) {
            this.registerAuthTimeoutInterval();
        } else if (loggedOut) {
            this.clearAuthTimeoutInterval();
        }
    }

    registerAuthTimeoutInterval() {
        if (this.authTimeoutInterval === null) {
            this.authTimeoutInterval = setInterval(() => {
                const idleTime = now() - this.lastActivity;
                if (idleTime >= authTimeoutSeconds) {
                    this.props.logout();
                }
            }, authTimeoutCheckSeconds * 1000);
        }
    }

    clearAuthTimeoutInterval() {
        if (this.authTimeoutInterval !== null) {
            clearInterval(this.authTimeoutInterval);
            this.authTimeoutInterval = null;
        }
    }

    registerLastActivityListeners() {
        const updateLastActivity = () => this.lastActivity = now();
        document.addEventListener('mousemove', updateLastActivity);
        document.addEventListener('mousedown', updateLastActivity);
        document.addEventListener('keydown', updateLastActivity);
        document.addEventListener('scroll', updateLastActivity, true);
    }

    hideNav = () => {
        return this.props.location.pathname.includes('supplemental-information') && ! this.props.currentUser;
    }

    render() {
    return (
      <div className={"App " + (this.hideNav() ? "hidden-nav" : "")}>
          {this.hideNav() ? null :
            <NavContainer /> }
        <div className="container-fluid">
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authKey: state.Account.authKey,
    currentUser: state.Account.currentUser
  };
};

const actions = { logout };

AppContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(AppContainer)
);

export default AppContainer;
