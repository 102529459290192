import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import DeletionButton from "../../Common/DeletionButton";
import {connect} from "react-redux";
import {updateDistrictAttachment} from "../../../actions/DistrictAttachment";

// TODO: Add Document show in supplemental
const DocumentRow = ({
                       districtAttachment,
                       deleteDistrictAttachment,
                       // updateDistrictAttachment
}) => {

  // const [showInBOR, setShowInBOR] = useState(districtAttachment.showInSupplementalInformation);
  //
  // useEffect(() => {
  //   updateDistrictAttachment(
  //     districtAttachment.name,
  //     districtAttachment.uri,
  //     districtAttachment.description,
  //     districtAttachment.district_id,
  //     districtAttachment.id,
  //     showInBOR)
  // }, [showInBOR]);

  return (
    <tr>
      <td>
        <a
          href={districtAttachment.uri}
          rel="noopener noreferrer"
          target="_blank"
        >
          {districtAttachment.name}
        </a>
      </td>
      <td>
        {districtAttachment.description}
      </td>
      {/*<td>*/}
      {/*  <input type="checkbox"*/}
      {/*         checked={showInBOR}*/}
      {/*         onChange={() => {*/}
      {/*           setShowInBOR(!showInBOR);*/}
      {/*         }}*/}
      {/*  />*/}
      {/*</td>*/}
      <td>
        <form>
          <input
            defaultValue="Delete Entry"
            className="sr-only"
            type="submit"
          />
          <Link
            to={`/districts/documents/${districtAttachment.id}/update`}
            className="btn btn-xs btn-warning"
          >
            Edit
          </Link>{" "}
          <DeletionButton
            onConfirm={() => {
              deleteDistrictAttachment(districtAttachment.id);
            }}
          />
        </form>
      </td>
    </tr>
  );
};

const mapDispatchToProps = {
  // updateDistrictAttachment
}

export default connect(() => ({}), mapDispatchToProps)(DocumentRow);
