import React, { useState } from "react";
import { Link } from "react-router-dom";
import {updateDistrictServiceProviderVisibility, updateDistrictServiceProviderDescriptionVisibility} from '../../../actions/ServiceProvider';
import ServiceProviderAttachmentTable from "./ServiceProviderAttachmentTable";

const ServiceProviderRow = ({ 
    serviceProvider, 
    expandAttachmentsServiceProviderId,
    updateExpandAttachmentsServiceProviderId,
    onSupplementalPage
}) => {
    
    const [isChecked, setIsChecked] = useState(!!serviceProvider.showInSupplementalInformation);
    const [descriptionPublished, setDescriptionPublished] = useState(!!serviceProvider.publishDescription);

    const updateShowInSupplemental = () => {
        const updatedValue = !isChecked;
        setIsChecked(updatedValue);
        updateDistrictServiceProviderVisibility(serviceProvider, updatedValue);
    };

    const updatePublishDescription = () => {
        const updatedValue = !descriptionPublished;
        setDescriptionPublished(updatedValue);
        updateDistrictServiceProviderDescriptionVisibility(serviceProvider, updatedValue);
    }

    let chevronClassName = "glyphicon service-provider-attachment-row-chevron ";

    if (expandAttachmentsServiceProviderId === serviceProvider.id) {
        chevronClassName += "glyphicon-chevron-up service-provider-collapse-attachments";
    }
    else {
        chevronClassName += "glyphicon-chevron-down service-provider-expand-attachments";
    }

    if (serviceProvider.attachments.length) {
        chevronClassName += " green";
    } else {
        chevronClassName = "glyphicon glyphicon-remove service-provider-attachment-row-chevron service-provider-expand-attachments";
    }

    const recordAttachmentField = serviceProvider.attachments.length === 0 ? null :
                serviceProvider.attachments.map(attachment => (
                    <div key={attachment.id}>
                        <a href={attachment.uri} target="_blank" title={"Link to " + attachment.name}>
                            <span className="glyphicon glyphicon-eye-open"></span> {attachment.name}
                        </a>

                    </div>
                ))

    const expires_at = serviceProvider.expires_at
        ? new Date(serviceProvider.expires_at.split(" ")[0]).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'UTC' })
        : "None";

    const expand = <span className={chevronClassName} onClick={() => updateExpandAttachmentsServiceProviderId(serviceProvider.id)}></span>;

    return (
        <React.Fragment key={serviceProvider.id}>
        <tr>
            <td>
                {onSupplementalPage ? serviceProvider.name :
                <Link to={`/service-provider/${serviceProvider.id}`}>
                    {serviceProvider.name}
                </Link>}
            </td>
            <td>{expires_at}</td>
            <td style={{wordBreak: 'break-word'}}>{serviceProvider.description ? serviceProvider.description : ""}</td>
            {onSupplementalPage ? null :
            <td>
                <label className="sr-only" htmlFor={`publish-description-checkbox-${serviceProvider.id}`}>Publish Description</label>
                <input 
                    name={`publish-description-checkbox-${serviceProvider.id}`}
                    id={`publish-description-checkbox-${serviceProvider.id}`}
                    type="checkbox" 
                    checked={descriptionPublished}
                    disabled={!!!serviceProvider.user_can_edit}
                    onChange={updatePublishDescription}
                />
            </td>}
            {onSupplementalPage ? null :
            <td>
                <label className="sr-only" htmlFor={`publish-service-provider-checkbox-${serviceProvider.id}`}>Publish Service Provider</label>
                <input 
                    name={`publish-service-provider-checkbox-${serviceProvider.id}`}
                    id={`publish-service-provider-checkbox-${serviceProvider.id}`}
                    type="checkbox" 
                    checked={isChecked}
                    disabled={!!!serviceProvider.user_can_edit}
                    onChange={updateShowInSupplemental}
                />
            </td>}
            <td>
                {onSupplementalPage ? recordAttachmentField : expand}
            </td>
        </tr>
            <ServiceProviderAttachmentTable
                serviceProvider={serviceProvider}
                allowShow={isChecked}
                isOpened={expandAttachmentsServiceProviderId === serviceProvider.id}
            />
        </React.Fragment>
    );
}

export default ServiceProviderRow;