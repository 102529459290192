import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { uploadAttachmentFile2 } from "../../../actions/Attachment";
import AttachmentPanel from './AttachmentPanel';

const AttachmentContainer = ({
    attachment,
    index,
    updateAttachment,
    removeAttachment,
    updateError,
    showInBORCheckbox,
    checkboxText,
    characterLimit
}) => {
    const initialForm = {
        error: "",
        maxDescriptionLength: false,
        name: "",
        description: "",
        service_provider_id: null,
        showInSupplementalInformation: false,
        uri: ""
    }
    const [formData, setFormData] = useState(initialForm);
    const [documentChanged, setDocumentChanged] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const onMounted = () => {
            if (attachment) {
                setFormData({
                    error: "",
                    maxDescriptionLength: false,
                    name: attachment.name,
                    uri: attachment.uri,
                    description: attachment.description || "",
                    showInSupplementalInformation: attachment.showInSupplementalInformation
                });
            }
        }
        onMounted();
    }, []);

    const handleChange = e => {
        e.preventDefault();

        const field = e.target.name;
        const value = e.target.value;

        if (field === 'uri') {
            setDocumentChanged(true);
        } else {
            if(field === 'description' && value.length >= characterLimit) {
                setFormData((oldFormData) => ({ ...oldFormData, [field]: value, maxDescriptionLength: true }));
            } else {
                setFormData((oldFormData) => ({ ...oldFormData, [field]: value, maxDescriptionLength: false }));
            }
        }
    };

    const handleUpdateAttachment = () => {
        updateAttachment({
            id: attachment.id,
            index: index,
            name: formData.name,
            uri: formData.uri,
            service_provider_id: attachment.service_provider_id,
            description: formData.description,
            showInSupplementalInformation: formData.showInSupplementalInformation
        });
    };

    const uploadFile = async e => {
        setLoading(true);
        try {
            const uri = await uploadAttachmentFile2(e.target.files[0]);
            setFormData((oldFormData) => ({ ...oldFormData, uri, error: '' }));
            setDocumentChanged(true);
            updateError('');
        } catch (err) {
            setFormData((oldFormData) => ({ ...oldFormData, error: err }));
            updateError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <AttachmentPanel
            index={index}
            attachment={attachment}
            {...formData}
            showInBORCheckbox
            uploadFile={uploadFile}
            updateAttachment={handleUpdateAttachment}
            removeAttachment={removeAttachment}
            documentChanged={documentChanged}
            loading={loading}
            handleChange={handleChange}
            checkboxText={checkboxText}
            characterLimit={characterLimit}
        />
    )
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    uploadAttachmentFile2
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentContainer);