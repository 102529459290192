import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createUser } from "../../../actions/User";
import {
  getDistrictList,
  getCompanyOptions,
  getRegionList
} from "../../../actions/Options";
import userTypes from "../../../utils/userTypes";
import UserForm from "./UserForm";
import { orderByName } from "../../Common/OptionList";

const NewUserContainer = (props) => {
  const initialForm = {
      userId: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirm: "",
      roleGroup: "",
      companyId: "",
      title: "",
      regions: [],
      districtId: ""
  };
  const [formData, setFormData] = useState(initialForm);
  const [error, setError] = useState("");
  const [hideDistrict, setHideDistrict] = useState(false);

  const handleChange = e => {
    e.preventDefault();
    const field = e.target.name;
    const value = e.target.value;

    setFormData((formData) => ({...formData, [field]: value }));
  };

  useEffect(() => {
    props.getCompanyOptions();

    if(props.currentUser.user_type === userTypes.superAdmin || props.currentUser.user_type === userTypes.regionAdmin) {
      props.getDistrictList();
      props.getRegionList();
    }

    if(props.currentUser.user_type === userTypes.districtAdmin) {
      setFormData((formData) => ({ ...formData, roleGroup: userTypes.dataAdmin }));
    }

    if(props.currentUser.user_type === userTypes.districtSystemAdmin) {
      setFormData((formData) => ({ ...formData, roleGroup: userTypes.districtAdmin }));
      setHideDistrict(true);
    } else if (props.currentUser.user_type === userTypes.districtAdmin) {
      setFormData((formData) => ({ ...formData, roleGroup: userTypes.dataAdmin }));
      setHideDistrict(true);
    }
  }, []);

  const saveUser = async (createUserForDataAdmin) => {
      if (!formData.firstName) {
        setError("You must provide a user name");
        return;
      }
      if(createUserForDataAdmin && !formData.email) {
        setError("You must provide an email");
        return;
      }
      
      if (props.currentUser.user_type === userTypes.districtAdmin) {
        setFormData((formData) => ({ ...formData, roleGroup: userTypes.dataAdmin, districtId: props.currentUser.entity_id}));
      }
  
      if (props.currentUser.user_type === userTypes.districtSystemAdmin) {
        setFormData((formData) => ({ ...formData, roleGroup: userTypes.districtSystemAdmin, districtId: props.currentUser.entity_id}));
      }
  
      try {
        const newUser = await props.createUser(
          formData.email,
          formData.firstName,
          formData.lastName,
          formData.password,
          formData.passwordConfirm,
          formData.roleGroup,
          formData.companyId,
          formData.districtId,
          formData.regions,
          formData.title,
        );
  
        props.history.push(`/users/${newUser.id}`);
      } catch (err) {
        setError(err);
      }
    };

    const addRegion = (region) => {
      const currentRegions = formData.regions;
      const newRegions = currentRegions.concat(region);

      setFormData((formData) => ({...formData, regions: newRegions }));
    }

    const removeRegion = (region) => {
      const currentRegions = formData.regions;
      const newRegions = currentRegions.filter(x => x !== region);

      setFormData((formData) => ({ ...formData, regions: newRegions }));
    }

    return (
      <div className="create-user-container">
        <UserForm
          error={error}
          handleChange={handleChange}
          {...formData}
          userId={formData.userId}
          email={formData.email}
          firstName={formData.firstName}
          lastName={formData.lastName}
          password={formData.password}
          passwordConfirm={formData.passwordConfirm}
          roleGroup={formData.roleGroup}
          companyId={formData.companyId}
          districtId={formData.districtId}
          regions={formData.regions}
          addRegion={addRegion}
          removeRegion={removeRegion}
          title={formData.title}
          districtList={props.districts ? orderByName(props.districts) : []}
          regionList={props.regions}
          companyList={props.companies}
          saveUser={saveUser}
          userType={props.currentUser.user_type}
          showDistrictListForDistrictAdmin={false}
          showEmailFormForDataAdmin={true}
          hideDistrict={hideDistrict}
        />
      </div>
    );
}

const mapStateToProps = state => {
  return {
    users: state.User.userDict,
    districts: state.Options.districts,
    regions: state.Options.regions,
    companies: state.Options.companyOptions,
    currentUser: state.Account.currentUser,
  };
};

const mapDispatchToProps = {
  createUser,
  getDistrictList,
  getRegionList,
  getCompanyOptions
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserContainer);

