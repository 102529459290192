import React from "react";

export function getOptListWithIdValue(arr) {
  return arr.map(x => {
    return <option value={x.id} key={x.id}>{x.label}</option>;
  });
}

export function orderByName(arr) {
  return Object.entries(arr)
    .sort(function(a, b) {
      if (a[1] < b[1]) {
        return -1;
      }
      if (a[1] > b[1]) {
        return 1;
      }

      return 0;
    })
    .map(x => {
      return { id: parseInt(x[0], 10), label: x[1] };
    });
}
