import React from "react";

const SupplementalInformationRow = ({ inventoryRecord }) => {
  const expires_at = inventoryRecord.expires_at
    ? new Date(inventoryRecord.expires_at.split(" ")[0]).toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'UTC'})
    : "None";

  const productAttachments = inventoryRecord.product.attachments;
  const policyAbstracts = productAttachments.filter(x => x.type === "PRIVACY POLICY");
  const policyAbstractField =
    policyAbstracts.length === 0 ? (
      <span className="glyphicon glyphicon-remove" title="No Privacy Policy" />
    ) : (
      <a href={policyAbstracts[0].uri} target="_blank" title="Link to Privacy Policy"><span className="glyphicon glyphicon-eye-open" /></a>
    );


  const recordAttachments = inventoryRecord.attachments.filter(attachment => (!!attachment.show_in_bor));

  const recordAttachmentField =
    recordAttachments.length === 0 ?
      null
     : recordAttachments.map(attachment => (
      <div key={attachment.id}><a href={attachment.uri} target="_blank" title={"Link to " + attachment.name}><span className="glyphicon glyphicon-eye-open"></span> {attachment.name}</a></div>
    ));

  return (
    <tr>
	    <td>{inventoryRecord.product.name}</td>
      <td>{inventoryRecord.product.company.name}</td>
      <td>{inventoryRecord.contractor === "BOCES" ? (!!inventoryRecord.boces_name && inventoryRecord.boces_name !== "BOCES" ? `BOCES - ${inventoryRecord.boces_name}` : "BOCES") : inventoryRecord.contractor}</td>
      <td>{expires_at}</td>
      <td>{recordAttachmentField}</td>
    </tr>
  );
};

export default SupplementalInformationRow;
