import apiInstance from "../utils/apiInstance";

export const getDistrictAttachmentList = districtId => async (
  dispatch,
  getState
) => {
  const url = `district-attachments/${districtId}`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "DISTRICT_ATTACHMENT_LIST_RECEIVED",
    districtAttachmentList: response.data
  });
};

export const createDistrictAttachment = (
  name,
  uri,
  description,
  districtId,
  showInSupplementalInformation
) => async (dispatch, getState) => {
  const url = `district-attachments/${districtId}`;
  const data = {
    name,
    uri,
    description,
    showInSupplementalInformation
  };

  await apiInstance.post(url, data);

  const response = await apiInstance.get(`district-attachments/${districtId}`);

  dispatch({
    type: "DISTRICT_ATTACHMENT_LIST_RECEIVED",
    districtAttachmentList: response.data
  });

  return response.data;
};

// TODO: Add Document show in supplemental
export const updateDistrictAttachment = (
  name,
  uri,
  description,
  district_id,
  districtAttachmentId,
  // showInSupplementalInformation
) => async (dispatch, getState) => {
  const url = `district-attachment/${districtAttachmentId}`;
  const data = {
    name,
    uri,
    description,
    district_id,
    // showInSupplementalInformation
  };

  await apiInstance.put(url, data);

  const response = await apiInstance.get(`district-attachments/${district_id}`);

  dispatch({
    type: "DISTRICT_ATTACHMENT_LIST_RECEIVED",
    districtAttachmentList: response.data
  });
};

export const getDistrictAttachment = districtAttachmentId => async (
  dispatch,
  getState
) => {
  const url = `district-attachment/${districtAttachmentId}`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "DISTRICT_ATTACHMENT_RECEIVED",
    districtAttachmentId,
    districtAttachment: response.data
  });
};

export const deleteDistrictAttachment = districtAttachmentId => async (
  dispatch,
  getState
) => {
  const url = `district-attachment/${districtAttachmentId}`;

  await apiInstance.delete(url);

  dispatch({ type: "DISTRICT_ATTACHMENT_DELETED", districtAttachmentId });
};
