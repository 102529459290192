import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router";
import ProductContainer from "../Products/ProductContainer";
import ClassificationContainer from "../Classifications/ClassificationContainer";
import CompanyContainer from "../Companies/CompanyContainer";
import RegionContainer from "../Regions/RegionContainer";
import SupplementalInformationContainer from "../SupplementalInformation/SupplementalInformationContainer";
import ServiceProvidersContainer from "../ServiceProviders/ServicerProvidersContainer";
import DistrictContainer from "../Districts/DistrictContainer";
import MyAccountContainer from "../MyAccount/MyAccountContainer";
import DelegateContainer from "../Delegates/DelegateContainer";
import UserContainer from "../Users/UserContainer";
import InventoryRecordContainer from "../InventoryRecord/InventoryRecordContainer";
import SecuritySettingsContainer from "../Security/SecuritySettingsContainer";
import ResetPasswordContainer from "../Login/ResetPasswordContainer";
import SetPasswordContainer from "../Login/SetPasswordContainer";
import Reports from "../Reports/Reports";
import { requestAccount } from "../../actions/Account";
import { getUserHomePath } from "../../utils/paths";
import bowser from 'bowser';
import 'react-dates/initialize';
import "react-select/dist/react-select.css";
import "react-dates/lib/css/_datepicker.css";

import "./app.css";


class App extends Component {
  async componentDidMount() {
    if (this.props.authKey) {
      this.props.requestAccount(this.props.authKey);
      // componentWillReceiveProps will fire after this
    }
  }

  async componentWillReceiveProps(nextProps) {
    const authKeyChanged = this.props.authKey !== nextProps.authKey;
    const newMatch = nextProps.match;

    if (authKeyChanged) {
      const user = await this.props.requestAccount(nextProps.authKey);
      const newPath = getUserHomePath(user);
      this.props.history.push(newPath);
    } else if (newMatch.isExact) {
      const newPath = getUserHomePath(nextProps.currentUser);
      this.props.history.push(newPath);
    }
  }

  render() {
    const { match, location } = this.props;


    if (!this.props.authKey && !this.props.currentUser) {
      /* Anonymous paths here */
      return (
        <Switch>
          <Route path="/supplemental-information" component={SupplementalInformationContainer} />
          <Route path="/reset-password" component={ResetPasswordContainer} />
          <Route path="/set-password" component={SetPasswordContainer} />
          <Redirect from="/billofrights/:districtId" to="/supplemental-information/:districtId" />
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        </Switch>
      );
    }

    if (this.props.authKey && !this.props.currentUser) {
      return (
        <div>
          <div>Loading...</div>
        </div>
      );
    }

    const unsupportedBrowser = bowser.msie ?
        <div style={{background: '#2A385D', width: '100%', color: 'white', position: 'fixed', left: 0, bottom: 0, textAlign: 'center'}}>
          <p style={{margin: '10px', fontWeight: 600}}>
            Internet Explorer users may experience bugs and undesired behavior with certain features of the Data Inventory Tool. Google Chrome, Mozilla Firefox or Microsoft Edge are the recommended web browsers.
          </p>
        </div> : null;

    return (
      <div>
        <Switch>
          <Route path="/products" component={ProductContainer} />
          <Route path="/categories" component={ClassificationContainer} />
          <Route path="/companies" component={CompanyContainer} />
          <Route path="/regions" component={RegionContainer} />
          <Route path="/districts" component={DistrictContainer} />
          <Route path="/inventory" component={InventoryRecordContainer} />
          <Route path="/users" component={UserContainer} />
          <Route path="/delegates" component={DelegateContainer} />
          <Route path="/my-account" component={MyAccountContainer} />
          <Route path="/supplemental-information" component={SupplementalInformationContainer} />
          <Route path="/service-provider" render={(props) => (<ServiceProvidersContainer {...props} />) } />
          <Route path={'/admin/security-settings'} component={SecuritySettingsContainer} />
          <Route path="/reports" component={Reports} />
          <Route path={match.url} exact render={() => <div>Loading...</div>} />
          <Redirect from="/billofrights/:districtId" to="/supplemental-information/:districtId" />
        </Switch>
          { unsupportedBrowser }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authKey: state.Account.authKey,
    currentUser: state.Account.currentUser
  };
};

const actions = { requestAccount };

App = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(App);

export default App;
