import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./Login.css";
import { login } from "../../actions/Account";
import LoginForm from "./LoginForm";
import config from "../../config";
import { getUserHomePath } from "../../utils/paths";

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      showModal: false
    };
  }

  submitLogin = async (email, password) => {
    if (!email) {
      this.setState({ error: "You must include a username" });
      return;
    }

    if (!password) {
      this.setState({ error: "You must include a password" });
      return;
    }

    try {
      const response = await this.props.login(email, password);
      const user = response.user;
      
      if(response.token) {
        const newPath = getUserHomePath(user);
        this.props.history.push(newPath);
      }
      else if(response.reset_password) {
        this.props.history.push({
          pathname: '/reset-password',
          state: {email: user.email}
        });
      }
    } catch (err) {
      this.setState({ error: err });
    }
  };

  updateEmail = email => {
    this.setState({
      email
    });
  };

  updatePassword = password => {
    this.setState({
      password
    });
  };

  openModal = () => {
    this.setState({
      showModal: true
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  render() {
    return (
      <LoginForm
        email={this.state.email}
        password={this.state.password}
        updateEmail={this.updateEmail}
        updatePassword={this.updatePassword}
        submitLogin={this.submitLogin}
        error={this.state.error}
        showModal={this.state.showModal}
        openModal={this.openModal}
        closeModal={this.closeModal}
        forgotPasswordPageUrl={config.forgotPasswordPageUrl}
        faqUrl={config.faqUrl}

      />
    );
  }
}

const mapStateToProps = state => {
  return {
    authKey: state.Account.authKey
  };
};

const actions = { login };

LoginContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(LoginContainer)
);

export default LoginContainer;
