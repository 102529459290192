import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { checkInventoryRecord, createInventoryRecord } from '../../../actions/InventoryRecord';
import PageHeader from '../../Common/PageHeader';
import RecordForm from './RecordForm';
import { getProduct } from '../../../actions/Product';
import { getDistrict } from '../../../actions/District';
import {
  getDataAdministrators,
  getAuthorities,
  getInventoryRecordAttachmentTypes
} from '../../../actions/Options';

class NewRecordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      criticality: '',
      data_classification_risk: '',
      contractor: '',
      boces_name: '',
      expires_at: null,
      archived: false,

      system_termination_date: null,
      system_implementation_date: null,
      systems_category: '',
      interoperability_data_in: '',
      interoperability_data_out: '',
      hosting_location: '',
      additional_notes: '',
      building_licenses: {},
      building_notes: {},

      dateFocused: '',

      data_admin_id: '',
      data_admin_title: '',
      authorities: [],
      data_classifications: [],
      number_of_users: [],
      number_of_records: '',
      renews_annually: false,
      attachments: []
    };
  }

  updateBuildingLicenses = (building, value, key = null) => {
    if (building === 'other' && key) {
      if (key === 'number') {
        value = value >= 0 ? value : 0;
      }

      this.setState({
        building_licenses: {
          ...this.state.building_licenses,
          [building]: { ...this.state.building_licenses[building], [key]: value }
        }
      });
    } else {
      this.setState({
        building_licenses: { ...this.state.building_licenses, [building]: value >= 0 ? value : 0 }
      });
    }
  };

  updateBuildingNotes = (building, value, key = null) => {
    if (building === 'other' && key) {
      this.setState({
        building_notes: {
          ...this.state.building_notes,
          [building]: { ...this.state.building_notes[building], [key]: value }
        }
      });
    } else {
      this.setState({
        building_notes: { ...this.state.building_notes, [building]: value }
      });
    }
  };

  addAttachment = (newAttachment) => {
    this.setState({
      attachments: this.state.attachments.concat({
        ...newAttachment,
        name: newAttachment.name || newAttachment.type
      })
    });
  };

  updateAttachment = (updatedAttachment) => {
    this.setState({
      attachments: this.state.attachments.map((existingAttachment) => {
        let shouldUpdate = false;

        if (typeof updatedAttachment.id !== 'undefined') {
          if (existingAttachment.id === updatedAttachment.id) {
            shouldUpdate = true;
          }
        } else if (
          typeof updatedAttachment.index !== 'undefined' &&
          existingAttachment.index === updatedAttachment.index
        ) {
          shouldUpdate = true;
        }

        return shouldUpdate
          ? {
              ...updatedAttachment,
              name: updatedAttachment.name || updatedAttachment.type
            }
          : existingAttachment;
      })
    });
  };

  removeAttachment = (attachment) => {
    this.setState({
      attachments: this.state.attachments.filter((x) => x !== attachment)
    });
  };

  updateError = (err) => {
    this.setState({ error: err });
  };

  conditionalSaveRecord = async () => {
    if (
      this.state.building_licenses &&
      this.state.building_licenses.other &&
      this.state.building_licenses.other.name
    ) {
      this.addNumberOfUsersOption(`Other: ${this.state.building_licenses.other.name}`, async () => {
        this.saveRecord();
      });
    } else {
      this.saveRecord();
    }
  };

  saveRecord = async () => {
    const state = this.state;
    const { match } = this.props;
    const productId = match.params.productId;
    const districtId = match.params.districtId;

    try {
      const newRecord = await this.props.createInventoryRecord(districtId, {
        ...this.state,
        boces_name: this.state.contractor !== 'BOCES' ? '' : this.state.boces_name,
        product_id: productId,
        district_id: districtId,
        delegates: [],
        attachments: this.state.attachments.map((attachment) => {
          delete attachment.index;
          return attachment;
        }),
        number_of_users: this.state.number_of_users.join(','),

        expires_at: state.expires_at
          ? moment.utc(state.expires_at).toISOString().split('T')[0]
          : null,
        system_implementation_date: state.system_implementation_date
          ? state.system_implementation_date.toISOString().split('T')[0]
          : null,
        system_termination_date: state.system_termination_date
          ? state.system_termination_date.toISOString().split('T')[0]
          : null
      });

      this.props.history.push(`/inventory/${newRecord.id}`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  async componentDidMount() {
    const { match } = this.props;

    const productId = match.params.productId;
    const districtId = match.params.districtId;

    const existingRecord = await checkInventoryRecord(productId, districtId);

    if (existingRecord.id !== undefined) {
      this.props.history.push(`/inventory/${existingRecord.id}/update`);
      return;
    }

    this.props.getAuthorities();
    this.props.getDataAdministrators(districtId);
    this.props.getDistrict(districtId);
    this.props.getProduct(productId);
  }

  async componentWillMount() {
    this.props.getInventoryRecordAttachmentTypes();
  }

  selectClassification = (classificationId) => {
    this.setState({
      data_classifications: [...this.state.data_classifications, classificationId]
    });
  };

  removeClassification = (classificationId) => {
    const currentClassifications = this.state.data_classifications;
    const newClassifications = currentClassifications.filter((x) => x !== classificationId);
    this.setState({ data_classifications: newClassifications });
  };

  selectAuthority = (authorityId) => {
    this.setState({
      authorities: [...this.state.authorities, authorityId]
    });
  };

  removeAuthority = (authorityId) => {
    const currentAuths = this.state.authorities;
    const newAuthorities = currentAuths.filter((x) => x !== authorityId);

    this.setState({ authorities: newAuthorities });
  };

  handleCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  changeDataAdmin = (e) => {
    e.preventDefault();

    const { match } = this.props;
    const districtId = match.params.districtId;

    const dataAdmins = this.props.dataAdmins[districtId];
    const newId = e.target.value;

    if (newId) {
      const selectedAdmin = dataAdmins[newId];

      this.setState({
        [e.target.name]: e.target.value,
        data_admin_title: selectedAdmin.title || ''
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  };

  changeFocus = (newFocus) => {
    this.setState({ dateFocused: newFocus });
  };

  async componentWillReceiveProps(nextProps) {
    const oldMatch = this.props.match;
    const { match } = nextProps;

    const oldProduct = oldMatch.params.productId;
    const productId = match.params.productId;
    const productChanged = oldProduct !== productId;

    const oldDistrict = oldMatch.params.districtId;
    const districtId = match.params.districtId;
    const districtChanged = oldDistrict !== districtId;

    const oldAuthorities = this.props.authorities;
    const authorities = nextProps.authorities;
    if (authorities && oldAuthorities !== authorities) {
      this.setState({
        authorities: Object.keys(authorities)
      });
    }

    if (districtChanged || productChanged) {
      const existingRecord = await checkInventoryRecord(productId, districtId);
      if (existingRecord.id !== undefined) {
        this.props.history.push(`/inventory/${existingRecord.id}/update`);
        return;
      }
    }

    if (districtChanged) {
      this.props.getDistrict(districtId);
      this.props.getDataAdministrators(districtId);
    }

    if (productChanged) {
      this.props.getProduct(productId);
    }
  }

  addNumberOfUsersOption = (option, callback) => {
    this.setState((prevState) => {
      const isOther = option.startsWith('Other:');
      const newOption = isOther ? option.substring('Other:'.length).trim() : option;

      // Create a new array with the updated option
      const updatedOptions = isOther
        ? prevState.number_of_users.filter((item) => !item.startsWith('Other:'))
        : prevState.number_of_users;
      if (isOther) {
        updatedOptions.push(`Other: ${newOption}`);
      } else {
        updatedOptions.push(option);
      }

      return {
        number_of_users: updatedOptions
      };
    }, callback);
  };

  removeNumberOfUsersOption = (option) => {
    this.setState((prevState) => {
      // Check if the option starts with "Other:" and extract the value to remove
      const isOther = option.startsWith('Other:');

      const updatedOptions = prevState.number_of_users.filter((item) => {
        if (isOther) {
          return !item.startsWith('Other:');
        } else {
          return item !== option;
        }
      });

      return {
        number_of_users: updatedOptions
      };
    });
  };

  render() {
    const { match } = this.props;
    const productId = match.params.productId;
    const districtId = match.params.districtId;

    const product = this.props.products[productId];
    const district = this.props.districts[districtId];
    const dataAdmins = this.props.dataAdmins[districtId];

    const possibleAuthorities = this.props.authorities;

    if (!product || !district || !dataAdmins || !possibleAuthorities) {
      return <div>Loading...</div>;
    }

    return (
      <div className="new-record-container">
        <PageHeader title="Products" subtitle="Add Product to District Inventory" />
        <p className="lead">
          Add the product <b>{product.name}</b> to the the District Inventory for{' '}
          <b>{district.name}</b>?
        </p>
        <RecordForm
          {...this.state}
          all_data_classifications={product.all_data_classifications}
          attachmentTypes={this.props.attachmentTypes}
          addAttachment={this.addAttachment}
          addNumberOfUsersOption={this.addNumberOfUsersOption}
          changeDataAdmin={this.changeDataAdmin}
          changeFocus={this.changeFocus}
          dataAdmins={dataAdmins}
          handleChange={this.handleChange}
          handleCheckbox={this.handleCheckbox}
          possibleAuthorities={possibleAuthorities}
          product={product}
          removeAuthority={this.removeAuthority}
          removeClassification={this.removeClassification}
          saveRecord={this.conditionalSaveRecord}
          selectAuthority={this.selectAuthority}
          selectClassification={this.selectClassification}
          removeNumberOfUsersOption={this.removeNumberOfUsersOption}
          removeAttachment={this.removeAttachment}
          updateAttachment={this.updateAttachment}
          updateError={this.updateError}
          updateBuildingLicenses={this.updateBuildingLicenses}
          updateBuildingNotes={this.updateBuildingNotes}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.Product.productDict,
    districts: state.District.districtDict,
    dataAdmins: state.Options.dataAdmins,
    authorities: state.Options.authorities,
    attachmentTypes: state.Options.inventoryRecordAttachmentTypes,
    currentUser: state.Account.currentUser
  };
};

const actions = {
  getProduct,
  getDistrict,
  getDataAdministrators,
  getAuthorities,
  createInventoryRecord,
  getInventoryRecordAttachmentTypes
};

NewRecordContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(NewRecordContainer)
);

export default NewRecordContainer;
