import React, { Component } from "react";
import { Route, Switch } from "react-router";
import ProductListContainer from "./ProductList/ProductListContainer";
import SingleProductContainer from "./SingleProduct/SingleProductContainer";
import UpdateProductContainer from "./ProductEditor/UpdateProductContainer";
import NewProductContainer from "./ProductEditor/NewProductContainer";
import ProductMergeContainer from "./ProductMerge/ProductMergeContainer";
import SuggestedProductListContainer
  from "./SuggestedProductList/SuggestedProductListContainer";
import VerifySuggestedProductContainer from "./SuggestedProductEditor/VerifySuggestedProductContainer";
import ProductDistrictSelector from "./ProductDistrictSelector";

class ProductContainer extends Component {
  render() {
    const { match } = this.props;

    return (
      <div className="product-container">
        <Switch>
          <Route
            path={`${match.url}/register/all`}
            component={SuggestedProductListContainer}
          />
          <Route
            path={`${match.url}/register/verify/:suggestedProductId`}
            component={VerifySuggestedProductContainer}
          />
          <Route path={`${match.url}/create`} component={NewProductContainer} />
          <Route
            path={`${match.url}/:productId/update`}
            component={UpdateProductContainer}
          />
          <Route
            path={`${match.url}/:productId/add-to-inventory`}
            component={ProductDistrictSelector}
          />

          <Route
            path={`${match.url}/:productId/merge`}
            component={ProductMergeContainer}
          />

          <Route
            path={`${match.url}/:productId`}
            component={SingleProductContainer}
          />
          <Route path={match.url} component={ProductListContainer} />
        </Switch>
      </div>
    );
  }
}

export default ProductContainer;
