import React from "react";
import PageHeader from "../../Common/PageHeader";
import { Link } from "react-router-dom";
import DeletionButton from "../../Common/DeletionButton";

const SingleDistrict = ({ district, deleteDistrict }) => {
  const attachments = district.district_attachments.map(attachment => {
    return (
      <tr key={attachment.id}>
        <td>
          Attachment: {attachment.type}
        </td>
        <td>
          <a href={attachment.uri} target="_blank" rel="noopener noreferrer">
            {attachment.name}
          </a>
        </td>
      </tr>
    );
  });

  const deleteButton = district.user_can_delete ? (<DeletionButton
    onConfirm={() => {
      deleteDistrict(district.id);
    }}
  />) : null;

  const editButton = district.user_can_edit ? (<Link
    to={`/districts/${district.id}/update`}
    className="btn btn-default"
  >
    Edit
  </Link>) : null;

  return (
    <div className="single-district">
      <PageHeader title="Districts" subtitle="District Information" />
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-8">
              <p className="lead">
                {district.name}
              </p>
            </div>
            <div className="col-sm-4 text-right">
              {editButton}
              {" "}
              {deleteButton}
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-hover">
              <tbody>
                <tr>
                  <th>District</th>
                  <td>
                    {district.name}
                  </td>
                </tr>
                {attachments}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDistrict;
