import React from "react";
import { getOptListWithIdValue } from "../../../Common/OptionList";

const DistrictAdminUserForm = ({
  districtList,
  districtId,
  isDistrictSystemAdmin,
  handleChange,
  hideDistrict,
  location,
  supervisorName,
  supervisorTitle
}) => {
  const districtOptions = getOptListWithIdValue(districtList);
  const adminInfoText = isDistrictSystemAdmin ? 'District SYSTEM ADMINS have all rights to add and edit district data, including adding Data ADMIN accounts and District ADMIN accounts.' : 'District ADMINS have all rights to add and edit district data, including adding Data ADMIN accounts.';
  return (
    <div id="district-user-form">
      <div className="row">
        <p className="lead col-xs-12">
          {adminInfoText}
        </p>
      </div>
      {hideDistrict ? "" :<div className="row">
         <div className="form-group col-xs-6">
          <label htmlFor="districtId">District</label>
          <select
            name="districtId"
            className="form-control"
            value={districtId}
            onChange={handleChange}
          >
            <option value>Choose a District...</option>
            {districtOptions}
          </select>
        </div>
      </div>}
    </div>
  );
};

export default DistrictAdminUserForm;
