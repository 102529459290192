import React from "react";
import SuggestedProductRow from "./SuggestedProductRow";
import PageHeader from "../../Common/PageHeader";
import { Pagination } from "react-bootstrap";

const SuggestedProductList = ({
  suggestedProductList,
  currentPage,
  changePage,
  totalPages,
  deleteSuggestedProduct
}) => {
  const suggestedProductRows = suggestedProductList
    ? suggestedProductList.map(suggestedProduct => (
        <SuggestedProductRow
          key={suggestedProduct.id}
          suggestedProduct={suggestedProduct}
          deleteDistrict={deleteSuggestedProduct}
        />
      ))
    : null;

  const paginator = totalPages > 1
    ? <Pagination
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        items={totalPages}
        maxButtons={5}
        activePage={currentPage}
        onSelect={changePage}
      />
    : null;

  return (
    <div className="suggested-product-list-container">
      <PageHeader title="Products" subtitle="Registered Products" />
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Type</th>
                  <th>Registered By</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {suggestedProductRows}
              </tbody>
            </table>
          </div>
          {paginator}
        </div>
      </div>
    </div>
  );
};

export default SuggestedProductList;
