import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getProduct, createProduct } from '../../../actions/Product';
import ProductForm from './ProductForm';
import wrapProductEditor from './productEditorWrapper';
import PageHeader from '../../Common/PageHeader';
import userTypes from '../../../utils/userTypes';

class NewProductContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };
  }

  updateError = (err) => {
    this.setState({ error: err });
  };

  saveProduct = async (productId, editorInfo) => {
    try {
      this.props.validateEditorInfo(editorInfo);
    } catch (err) {
      this.setState({ error: err });
      return;
    }

    const currentUser = this.props.currentUser;
    const exportable = this.props.exportInfo(editorInfo);
    const isCompanyAdmin = currentUser.user_type === userTypes.companyAdmin;

    try {
      const newProduct = await this.props.createProduct({
        ...exportable,
        company: isCompanyAdmin ? currentUser.entity_id : exportable.company
      });

      this.props.history.push(`/products/${newProduct.id}`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  render() {
    return (
      <div className="create-product-container">
        <PageHeader title="Products" subtitle="Create Product" />
        <ProductForm
          productId={null}
          saveProduct={this.saveProduct}
          editorInfo={this.props.editorInfo}
          error={this.state.error}
          currentUser={this.props.currentUser}
          updateError={this.updateError}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.Product.productDict,
    currentUser: state.Account.currentUser
  };
};

const actions = { getProduct, createProduct };

NewProductContainer = withRouter(
  wrapProductEditor(
    connect(
      mapStateToProps, // connect component props to application (redux) state
      actions // these actions are automatically wrapped in a dispatch by connect
    )(NewProductContainer)
  )
);

export default NewProductContainer;
