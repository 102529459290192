import React from "react";
import { connect } from "react-redux";
import DistrictSelector
from "../Common/DistrictSelector/DistrictSelectorContainer";
import PageHeader from "../Common/PageHeader";

const ServiceProviderDistrictSelector = (props) => {
    const onSelect = (district) => {
        props.history.push(`/districts/${district.id}/service-providers`);
    }

    return (
        <div>
            <PageHeader title="Service Providers" subtitle="Choose a Region" />
            <DistrictSelector onSelect={onSelect}></DistrictSelector>
        </div>
    )
} 

const mapStateToProps = (props) => ({
    ...props
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviderDistrictSelector);