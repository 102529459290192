import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getRegion, deleteRegion } from "../../../actions/Region";
import { deleteDistrict } from "../../../actions/District";

import SingleRegion from "./SingleRegion";

class SingleRegionContainer extends Component {
  getRegionIdFromProps(props) {
    const { match } = props;
    const regionId = match.params.regionId;
    return regionId ? parseInt(regionId, 10) : undefined;
  }

  async componentDidMount() {
    const regionId = this.getRegionIdFromProps(this.props);
    await this.props.getRegion(regionId);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getRegionIdFromProps(this.props);
    const newId = this.getRegionIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getRegion(newId);
    }
  }

  deleteRegion = async regionId => {
    await this.props.deleteRegion(regionId);
    this.props.history.push("/regions");
  };

  deleteDistrict = async districtId => {
    await this.props.deleteDistrict(districtId);
    this.props.history.push("/districts");
  };

  render() {
    const regionId = this.getRegionIdFromProps(this.props);
    const region = this.props.regions[regionId];
    if (!region) return <div>Loading...</div>;

    return (
      <SingleRegion
        region={region}
        deleteRegion={this.deleteRegion}
        deleteDistrict={this.deleteDistrict}
      />
    );
  }
}

const mapStateToProps = state => {
  return { regions: state.Region.regionDict };
};

const actions = { getRegion, deleteRegion, deleteDistrict };

SingleRegionContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(SingleRegionContainer)
);

export default SingleRegionContainer;
