import store from "../store";
import userTypes from "../utils/userTypes";

const simplifyType = type => {
  switch (type) {
    case userTypes.superAdmin:
      return "superAdmin";
    case userTypes.regionAdmin:
      return "regionAdmin";
    case userTypes.districtAdmin:
      return "districtAdmin";
    case userTypes.districtSystemAdmin:
      return "districtSystemAdmin";
    case userTypes.dataAdmin:
      return "dataAdmin";
    case userTypes.companyAdmin:
      return "companyAdmin";
    default:
      return "";
  }
};

// Get your authentication token before each request
export const getCurrentUserType = () => {
  const state = store.getState();

  return simplifyType((state.Account.currentUser || {}).user_type);
};

const renderFor = (renderOptions, makeChildren) => {
  const type = getCurrentUserType();
  if (!type) return null;

  const {superAdmin} = renderOptions;
  if (type === "superAdmin" && superAdmin !== false) {
    // Unless specifically set to false, do not render
    return makeChildren();
  }

  return renderOptions[type] === true ? makeChildren() : null;
};

export default renderFor;
