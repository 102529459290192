import * as Cookies from "js-cookie";
const authKeyCookie = "dpss-authKey";

const authKeyCookieExpirationMinutes = 30;

export const getAuthKey = () => {
  return Cookies.get(authKeyCookie);
};

export const clearAuthKey = () => {
  return Cookies.remove(authKeyCookie);
};

export const setAuthKey = (authKey) => {
  const expires = new Date(new Date().getTime() + authKeyCookieExpirationMinutes * 60 * 1000);
  return Cookies.set(authKeyCookie, authKey, { expires });
}