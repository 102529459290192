import React, { Component } from "react";
import { withRouter } from "react-router";
import RegionList from "./RegionList";
import { connect } from "react-redux";
import { searchForRegions, deleteRegion } from "../../../actions/Region";
import qs from "qs";

class RegionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ""
    };
  }

  componentDidMount() {
    const page = this.getCurrentPageFromProps(this.props);
    this.props.searchForRegions(page);
  }

  componentWillReceiveProps(nextProps) {
    const oldPage = this.getCurrentPageFromProps(this.props);
    const newPage = this.getCurrentPageFromProps(nextProps);
    const pageChanged = oldPage !== newPage;

    if (pageChanged) {
      this.props.searchForRegions(newPage);
    }
  }

  getCurrentPageFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    if (parsedSearch.page !== undefined) {
      return parseInt(parsedSearch.page, 10);
    }

    return 1;
  }

  changePage = page => {
    const { match, location } = this.props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    const newSearch = { ...parsedSearch, page };

    const newRoute = {
      pathname: match.url,
      search: qs.stringify(newSearch)
    };

    this.props.history.push(newRoute);
  };

  deleteRegion = async regionId => {
    const page = this.getCurrentPageFromProps(this.props);

    await this.props.deleteRegion(regionId);
    this.props.searchForRegions(page);
  };

  render() {
    const currentPage = this.getCurrentPageFromProps(this.props);

    const searchResult = this.props.regionSearchResults;
    const regionList = searchResult ? searchResult.data : null;
    const totalPages = searchResult ? searchResult.last_page : 0;

    return (
      <RegionList
        regions={regionList}
        currentPage={currentPage}
        changePage={this.changePage}
        totalPages={totalPages}
        deleteRegion={this.deleteRegion}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    regionSearchResults: state.Region.searchResults
  };
};

const actions = {
  searchForRegions,
  deleteRegion
};

RegionListContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(RegionListContainer)
);

export default RegionListContainer;
