import React from "react";
import DocumentRow from "./DocumentRow";
import PageHeader from "../../Common/PageHeader";
import { Link } from "react-router-dom";

// TODO: Add Document show in supplemental
const DocumentList = ({ districtAttachments, deleteDistrictAttachment, districtId }) => {
  const documentRows = districtAttachments
    ? districtAttachments.map(districtAttachment => (
        <DocumentRow
          key={districtAttachment.id}
          districtAttachment={districtAttachment}
          deleteDistrictAttachment={deleteDistrictAttachment}
        />
      ))
    : null;


  return (
    <div className="document-list-container">
      <PageHeader title="Document Library" subtitle="Additional Documents" />
      <div className="row">
        <div className="col-sm-6 text-left">
          {/*<p>*/}
          {/*  Note: Checking the Show in Supplemental Information box will display the document at the bottom of your Supplemental Information page under a heading of District Documents. The document will be public once you publish the Supplemental Information Page URL.*/}
          {/*</p>*/}

        </div>
        <div className="col-sm-6 text-right">
          <Link to={`/districts/${districtId}/documents/create`} className="btn btn-success">
            Add District Document
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Link</th>
                  <th>Description</th>
                  {/*<th>Show in Supplemental Information</th>*/}
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {documentRows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentList;
