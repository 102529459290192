import React from "react";

const NewAttachmentPanel = ({
  attachmentTypes,
  handleChange,
  type,
  name,
  uri,
  description,
  saveAttachment,
  uploadFile,
  showInBORCheckbox,
  show_in_bor
}) => {
  const disableUri =
    uri.indexOf("https://dpit.s3.amazonaws.com") >= 0 ||
    uri.indexOf("https://s3.amazonaws.com/dpit") >= 0;

  let uploadInput = null;

  const showInBOROption = showInBORCheckbox ?
    (<div className="form-group checkbox">
        <label>
          <input
            type="checkbox"
            name="show_in_bor"
            checked={show_in_bor}
            onChange={e => {
              const state = {
                target: {
                  value: e.target.checked,
                  name: e.target.name
                },
                preventDefault: ()=>{}
              };

              handleChange(state)
            }}
          />
          Show Document in Supplemental Information
        </label>
      </div>)
    : null;

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <div className="panel-title">
          New Attachment
        </div>
      </div>
      <div className="panel-body">
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="control-label" htmlFor={`attachments-new-type`}>
                Type
              </label>
              <select
                className="form-control"
                id={`attachments-new-type`}
                name="type"
                value={type || "undefined"}
                onChange={handleChange}
              >
                <option value>Choose...</option>
                {attachmentTypes.map(type => {
                  return <option key={type} value={type}>{type}</option>;
                })}
              </select>
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor={`attachments-new-uri`}>
                URL
              </label>
              <input
                className="form-control"
                name="uri"
                id={`attachments-new-uri`}
                value={uri}
                onChange={handleChange}
                type="text"
                disabled={disableUri}
              />
            </div>
            {showInBOROption}
          </div>

          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label htmlFor={`attachments-new-name`} className="control-label">
                Name
              </label>
              <input
                className="form-control"
                id={`attachments-new-name`}
                name="name"
                type="text"
                value={name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Or upload a file</label>
              <input ref={(input) => uploadInput = input} type="file" accept=".pdf" onChange={uploadFile} />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor={`attachments-new-description`}
              >
                Description
              </label>
              <textarea
                rows={4}
                name="description"
                id={`attachments-new-description`}
                className="form-control"
                onChange={handleChange}
                value={description || ""}
              />
            </div>
          </div>
          <div className="form-group form-group col-xs-12 text-right">
            <button
              type="button"
              disabled={uri === "" || typeof uri === "undefined"}
              onClick={e => {
                e.preventDefault();
                uploadInput.value = null;
                saveAttachment();
              }}
              className="btn btn-primary"
            >
              Save Document
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAttachmentPanel;
