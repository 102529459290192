import React, { Component } from "react";
import { connect } from "react-redux";
import DistrictSelector
  from "../Common/DistrictSelector/DistrictSelectorContainer";
import PageHeader from "../Common/PageHeader";

class UserDistrictSelector extends Component {
  onSelect = district => {
    this.props.history.push(`/users/?district=${district.id}`);
  };

  render() {
    return (
      <div>
        <PageHeader title="Users" subtitle="Choose a District" />
        <DistrictSelector onSelect={this.onSelect} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = {};

UserDistrictSelector = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(UserDistrictSelector);

export default UserDistrictSelector;
