import React from "react";
import Select from "react-select";

const CompanyAdminUserForm = ({ companyList, companyId, handleChange }) => {
  const companyOptions = companyList.map(x => {
    return { value: x.id, label: x.name };
  });

  return (
    <div id="company-user-form" className="form-group">
      <div className="row">
        <p className="lead col-xs-12">
          A Company Admin Can Modify, Create or Delete Products for their
          Company.
        </p>
      </div>
      <div className="row col-xs-6">
        <label htmlFor="companyId">Company</label>
        <Select
          id={`companyId`}
          name="companyId"
          value={companyId}
          onChange={company => {
            handleChange({
              preventDefault: () => {},
              target: {
                name: "companyId",
                value: company ? company.value : null
              }
            });
          }}
          options={companyOptions}
          clearable={false}
        />
      </div>
    </div>
  );
};

export default CompanyAdminUserForm;
