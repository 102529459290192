const initialState = {
  searchResults: null,
  mergeSearchResults: null,
  productTypes: null,
  productDict: {}
};

const Product = (state = initialState, action) => {
  switch (action.type) {
    case "PRODUCT_SEARCH_RECEIVED":
      return { ...state, searchResults: action.products };
    case "PRODUCT_MERGE_SEARCH_RECEIVED":
      return { ...state, mergeSearchResults: action.products };
    case "CLEAR_PRODUCT_SEARCH":
      return {...state, searchResults: initialState.searchResults}
    case "CLEAR_PRODUCT_MERGE_SEARCH":
      return {...state, mergeSearchResults: initialState.mergeSearchResults}
    case "PRODUCT_RECEIVED":
      return {
        ...state,
        productDict: {
          ...state.productDict,
          [action.productId]: action.product
        }
      };
    case "PRODUCT_DELETED":
      const newProductDict = { ...state.productDict };
      delete newProductDict[action.productId];

      return {
        ...state,
        productDict: newProductDict
      };
    default:
      return state;
  }
};

export default Product;
