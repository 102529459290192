import React from "react";
import CompanyRow from "./CompanyRow";
import PageHeader from "../../Common/PageHeader";
import {Pagination} from "react-bootstrap";
import {Link} from "react-router-dom";
import renderFor from "../../../utils/renderFor";
import Search from "../../Common/Search";

const CompanyList = ({
                       companies,
                       currentPage,
                       changePage,
                       performSearch,
                       query,
                       updateQuery,
                       totalPages,
                       deleteCompany
                     }) => {
  const companyRows = companies
    ? companies.map(company => (
      <CompanyRow
        key={company.id}
        company={company}
        deleteCompany={deleteCompany}
      />
    ))
    : null;

  const paginator = totalPages > 1
    ? <Pagination
      prev
      next
      first
      last
      ellipsis
      boundaryLinks
      items={totalPages}
      maxButtons={5}
      activePage={currentPage}
      onSelect={changePage}
    />
    : null;

  return (
    <div className="company-list-container">
      <PageHeader title="Companies" subtitle="Company Information"/>
      <div className="row">
        <div className="col-sm-12 text-right">
          <Search query={query} updateQuery={updateQuery} performSearch={performSearch}/>{" "}
          {renderFor({}, () => (
            <Link to="/companies/create" className="btn btn-sm btn-success">
              Add Company
            </Link>
          ))}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
              <tr>
                <th>Company</th>
                {
                  renderFor({}, () => <th className="text-right">Manage</th>)
                }

              </tr>
              </thead>
              <tbody>
              {companyRows}
              </tbody>
            </table>
          </div>
          {paginator}
        </div>
      </div>
    </div>
  );
};

export default CompanyList;
