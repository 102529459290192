import React from "react";
import ProductForm from "../ProductEditor/ProductForm";
import renderFor from "../../../utils/renderFor";
import DeletionButton from "../../Common/DeletionButton";
import GoBack from "../../Common/GoBack";

const SuggestedProductForm = ({
  registrarFirstName,
  productId,
  updateRegistrarFirstName,
  registrarLastName,
  updateRegistrarLastName,
  registrarEmail,
  updateRegistrarEmail,
  buildings,
  buildingOptions,
  addBuilding,
  removeBuilding,
  suggestedProductPurpose,
  updateSuggestedProductPurpose,
  hasUnderageStudents,
  updateHasUnderageStudents,
  saveProduct,
  editorInfo,
  ...productFormProps
}) => {
  const buildingIds = buildings.map(x => x.id);
  const buildingCheckboxes = buildingOptions.map(x => {
    return (
      <div className="checkbox" key={x.id}>
        <label>
          <input
            name="buildings"
            type="checkbox"
            checked={buildingIds.indexOf(x.id) >= 0}
            onChange={e => {
              if (e.target.checked) {
                addBuilding(x);
              } else {
                removeBuilding(x);
              }
            }}
          />
          {x.label}
        </label>
      </div>
    );
  });
  return (
    <div className="suggested-product-editor">
      {renderFor(
        { districtAdmin: true,  districtSystemAdmin: true, dataAdmin: true, superAdmin: false },
        () => {
          return (
            <div>
              <p className="lead">Register A Product</p>
              <p>
                The product below has been registered by someone in your
                district. You can approve this request, which will forward it to
                a LHRIC admin to be added to the system, or you can reject it.
              </p>
            </div>
          );
        }
      )}
      <div className="row">
        <div className="col-xs-12">
          <p className="lead">Registrar's Information</p>
        </div>
        <div className="form-group col-sm-5">
          <label htmlFor="registrarFirstName">First Name</label>
          <input
            id="registrarFirstName"
            type="text"
            name="registrarFirstName"
            placeholder="Registrar First Name"
            className="form-control"
            disabled
            value={registrarFirstName}
            onChange={e => {
              e.preventDefault();
              updateRegistrarFirstName(e.target.value);
            }}
          />
        </div>
        <div className="form-group col-sm-5">
          <label htmlFor="registrarLastName">Last Name</label>
          <input
            id="registrarLastName"
            type="text"
            name="registrarLastName"
            placeholder="Registrar Last Name"
            className="form-control"
            disabled
            value={registrarLastName}
            onChange={e => {
              e.preventDefault();
              updateRegistrarLastName(e.target.value);
            }}
          />
        </div>
        <div className="form-group col-sm-5">
          <label htmlFor="registrarEmail">Email Address</label>
          <input
            id="registrarEmail"
            type="text"
            name="registrarEmail"
            placeholder="Registrar Email Address"
            className="form-control"
            disabled
            value={registrarEmail}
            onChange={e => {
              e.preventDefault();
              updateRegistrarEmail(e.target.value);
            }}
          />
        </div>
      </div>
      <ProductForm
        {...productFormProps}
        editorInfo={editorInfo}
        isSuggestedProduct={true}
        saveProduct={(productId, editorInfo) => {
          saveProduct(
            productId,
            editorInfo,
            registrarFirstName,
            registrarLastName,
            registrarEmail,
            hasUnderageStudents,
            suggestedProductPurpose,
            buildings
          );
        }}
      />

      <div className="row">
        <div className="form-group col-sm-6">
          <label htmlFor="suggestedProductPurpose">Product Purpose</label>
          <input
            id="suggestedProductPurpose"
            type="text"
            name="suggestedProductPurpose"
            placeholder="Purpose"
            className="form-control"
            value={suggestedProductPurpose}
            onChange={e => {
              e.preventDefault();
              updateSuggestedProductPurpose(e.target.value);
            }}
          />
        </div>
        <div className="form-group col-sm-6">
          <label htmlFor="hasUnderageStudents">Student Age Range</label>
          <div className="checkbox">
            <label htmlFor="hasUnderageStudents">
              <input
                id="hasUnderageStudents"
                name="hasUnderageStudents"
                type="checkbox"
                checked={hasUnderageStudents}
                onChange={e => {
                  updateHasUnderageStudents(e.target.checked);
                }}
              />
              Has users under age 13
            </label>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <p><b>Buildings</b></p>
            {buildingCheckboxes}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="col-sm-12 text-right">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={e => {
                e.preventDefault();
                saveProduct(
                  productId,
                  editorInfo,
                  registrarFirstName,
                  registrarLastName,
                  registrarEmail,
                  hasUnderageStudents,
                  suggestedProductPurpose,
                  buildings
                );
              }}
            >
              Approve Product
            </button>
            {" "}
            <DeletionButton
              className="btn btn-danger deletion-link"
              onConfirm={() => {
                this.deleteSuggestedProduct(productId);
              }}
              buttonText="Reject Product"
            />
            <GoBack />
          </div>

        </div>
      </div>
    </div>
  );
};

export default SuggestedProductForm;
