import apiInstance from "../utils/apiInstance";
import qs from "qs";

export const searchForCompanies = (page, search) => async (dispatch, getState) => {
  const urlOptions = { page, search };
  const url = `companies?${qs.stringify(urlOptions)}`;
  const response = await apiInstance.get(url);

  dispatch({ type: "COMPANY_SEARCH_RECEIVED", companies: response.data });
};

export const createCompany = (
  name,
  contact_name,
  contact_title,
  contact_email,
  phone,
  address,
  nys_education_law_does_not_apply
) => async (dispatch, getState) => {
  const url = `companies`;
  const data = {
    name,
    contact_name,
    contact_title,
    contact_email,
    phone,
    address
  };

  const creationResponse = await apiInstance.post(url, data);

  const response = await apiInstance.get(
    `companies/${creationResponse.data.id}`
  );

  dispatch({
    type: "COMPANY_RECEIVED",
    companyId: response.data.id,
    company: response.data
  });

  return response.data;
};

export const deleteCompany = companyId => async (dispatch, getState) => {
  const url = `companies/${companyId}`;

  await apiInstance.delete(url);

  dispatch({ type: "COMPANY_DELETED", companyId });
};

export const getCompany = companyId => async (dispatch, getState) => {
  const url = `companies/${companyId}`;
  const response = await apiInstance.get(url);

  dispatch({ type: "COMPANY_RECEIVED", companyId, company: response.data });
};

export const updateCompany = (
  companyId,
  name,
  contact_name,
  contact_title,
  contact_email,
  phone,
  address,
  nys_education_law_does_not_apply
) => async (dispatch, getState) => {
  const url = `companies/${companyId}`;
  const data = {
    name,
    contact_name,
    contact_title,
    contact_email,
    phone,
    address,
    nys_education_law_does_not_apply
  };

  const response = await apiInstance.put(url, data);

  dispatch({ type: "COMPANY_RECEIVED", companyId, company: response.data });
};
