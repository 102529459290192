import React from "react";
import PageHeader from "../../Common/PageHeader";
import ErrorAlert from "../../Common/ErrorAlert";
import GoBack from "../../Common/GoBack";

const RegionForm = ({ regionId, name, updateName, saveRegion, error }) => {
  return (
    <div className="region-editor">
      <PageHeader
        title="Regions"
        subtitle={regionId ? "Update Region" : "Create Region"}
      />
      <form
        onSubmit={e => {
          e.preventDefault();
          saveRegion(regionId, name);
        }}
      >
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                name="name"
                placeholder="Name of region"
                className="form-control"
                type="text"
                value={name}
                onChange={e => {
                  e.preventDefault();
                  updateName(e.target.value);
                }}
              />
            </div>

            <ErrorAlert error={error} />

            <div className="form-group text-right">
              <button type="submit" className="btn btn-primary">
                Save Region
              </button>
              {" "}
              <GoBack />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegionForm;
