import { Col, FormGroup, FormControl, Grid, Row } from 'react-bootstrap';
import RegionReportDrillDown from './RegionReportDrillDown';
import ProductReportDrillDown from './ProductReportDrillDown';
import { useState, useRef } from 'react';

export default () => {
  const [search, setSearch] = useState('');

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <FormGroup bsSize="large">
            <FormControl
              type="text"
              placeholder="Product Search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm={7}>
          <RegionReportDrillDown search={search} />
        </Col>
        <Col sm={5}>
          <ProductReportDrillDown search={search} />
        </Col>
      </Row>
    </Grid>
  );
};
