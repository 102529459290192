import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getRegion, createRegion } from "../../../actions/Region";
import RegionForm from "./RegionForm";

class NewRegionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      name: ""
    };
  }

  saveRegion = async (regionId, name) => {
    if (!name) {
      this.setState({ error: "You must provide a region name" });
      return;
    }

    try {
      const newRegion = await this.props.createRegion(name);
      this.props.history.push(`/regions/${newRegion.id}`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  updateName = name => {
    this.setState({ name });
  };

  render() {
    const { name, error } = this.state;

    return (
      <div className="update-region-container">
        <RegionForm
          error={error}
          regionId={null}
          name={name}
          updateName={this.updateName}
          saveRegion={this.saveRegion}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { regions: state.Region.regionDict };
};

const actions = { getRegion, createRegion };

NewRegionContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(NewRegionContainer)
);

export default NewRegionContainer;
