import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getCompany, createCompany } from "../../../actions/Company";
import CompanyForm from "./CompanyForm";

class NewCompanyContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      name: "",
      contactName: "",
      contactTitle: "",
      contactEmail: "",
      phone: "",
      address: "",
      nys_education_law_does_not_apply: false
    };
  }

  saveCompany = async (
    companyId,
    name,
    contactName,
    contactTitle,
    contactEmail,
    phone,
    address,
    nys_education_law_does_not_apply
  ) => {
    if (!name) {
      this.setState({ error: "You must provide a company name" });
      return;
    }

    try {
      const newCompany = await this.props.createCompany(
        name,
        contactName,
        contactTitle,
        contactEmail,
        phone,
        address,
        nys_education_law_does_not_apply
      );

      this.props.history.push(`/companies/${newCompany.id}`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  handleCheckbox = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      name,
      contactEmail,
      contactTitle,
      contactName,
      phone,
      address,
      error,
      nys_education_law_does_not_apply
    } = this.state;

    return (
      <div className="update-company-container">
        <CompanyForm
          error={error}
          companyId={null}
          name={name}
          handleChange={this.handleChange}
          handleCheckbox={this.handleCheckbox}
          nys_education_law_does_not_apply={nys_education_law_does_not_apply}
          contactEmail={contactEmail}
          contactName={contactName}
          contactTitle={contactTitle}
          phone={phone}
          address={address}
          saveCompany={this.saveCompany}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { companys: state.Company.companyDict };
};

const actions = { getCompany, createCompany };

NewCompanyContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(NewCompanyContainer)
);

export default NewCompanyContainer;
