import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import SingleUserContainer from "./SingleUser/SingleUserContainer";
import UpdateUserContainer from "./UserEditor/UpdateUserContainer";
import NewUserContainer from "./UserEditor/NewUserContainer";
import UserDistrictSelector from "./UserDistrictSelector";
import UserListContainer from "./UserList/UserListContainer";

class UserContainer extends Component {
  render() {
    const { match } = this.props;

    return (
      <div className="user-container">
        <Switch>
          <Route path={`${match.url}/selector`} component={UserDistrictSelector} />
          <Route path={`${match.url}/create`} component={NewUserContainer} />
          <Route
            path={`${match.url}/:userId/update`}
            component={UpdateUserContainer}
          />
          <Route
            path={`${match.url}/:userId`}
            component={SingleUserContainer}
          />
          <Route path={match.url} component={UserListContainer} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = {};

UserContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(UserContainer);

export default UserContainer;
