import userTypes from "./userTypes";

export const getDistrictInventoryPath = user => {
  if (user === null) return "/login";

  const userType = user.user_type;

  switch (userType) {
    case userTypes.superAdmin:
    case userTypes.regionAdmin:
      return "/inventory/selector";
    case userTypes.districtAdmin:
    case userTypes.districtSystemAdmin:
    case userTypes.dataAdmin:
      return `/districts/${user.entity_id}/inventory`;
    default:
      return "/";
  }
};

export const getServiceProvidersPath = user => {
  if (user === null) return "/login";

  const userType = user.user_type;

  switch (userType) {
    case userTypes.superAdmin:
    case userTypes.regionAdmin:
      return "/service-provider/selector";
    case userTypes.districtAdmin:
    case userTypes.districtSystemAdmin:
    case userTypes.dataAdmin:
      return `/districts/${user.entity_id}/service-providers`;
    default:
      return "/";
  }
};

export const getUserHomePath = user => {
  if (user === null) return "/login";

  const userType = user.user_type;

  switch (userType) {
    case userTypes.superAdmin:
    case userTypes.regionAdmin:
    case userTypes.districtAdmin:
    case userTypes.districtSystemAdmin:
    case userTypes.companyAdmin:
      return `/products`;
    case userTypes.dataAdmin:
      return getDistrictInventoryPath(user);
    default:
      return "/products";
  }
};

export const addProductToInventoryRoute = (productId, districtId) => {
  return `/inventory/${productId}/${districtId}/create`;
};

export const getCreateInventoryRecordPath = (user, productId) => {
  const userType = user.user_type;

  switch (userType) {
    case userTypes.superAdmin:
    case userTypes.regionAdmin:
      return `/products/${productId}/add-to-inventory`;
    case userTypes.districtAdmin:
    case userTypes.districtSystemAdmin:
      return addProductToInventoryRoute(productId, user.entity_id);
    default:
      return "/";
  }
};
