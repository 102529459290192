import React from 'react';

export default ({product, mergingProductId, setMergingProductId}) => {

  return (
      <table className="table table-product-view">
        <tbody>
        <tr>
          <td className="col-xs-6">
            Product Name:
          </td>
          <td>
            {product.name}
          </td>
        </tr>
        <tr>
          <td className="col-xs-6">
            Product Id:
          </td>
          <td>
            {product.id}
          </td>
        </tr>
        <tr>
          <td className="col-xs-6">
            Product Type:
          </td>
          <td>
            {product.type}
          </td>
        </tr>
        <tr>
          <td className="col-xs-6">
            <a
              href="https://studentprivacypledge.org/privacy-pledge/"
              target="_blank"
              rel="noopener noreferrer">
              Software & Information Industry Association (SIIA),
              Student Data Privacy Pledge
            </a>
          </td>
          <td className="text-left">
            <strong>{product.sii ? "Signed" : "Not Signed"}</strong>
          </td>
        </tr>
        <tr>
          <td className="col-xs-6">
            Other Credentials
          </td>
          <td className="text-left">
            <strong>{product.other}</strong>
          </td>
        </tr>
        <tr>
          <td className="col-xs-6">
              Number of Districts:
          </td>
          <td className="text-left">
            <strong>{product.districts.length}</strong>
          </td>
        </tr>
        <tr>
          <td>
            <label className="form-check-label" htmlFor={`radio-${product.id}`}>Keep this product</label>
          </td>
          <td>
            <input id={`radio-${product.id}`} type="radio" onChange={() => setMergingProductId(product.id)} checked={mergingProductId === product.id}/>
          </td>
        </tr>
        </tbody>
      </table>
  )
}
