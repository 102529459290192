import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import DocumentForm from "./DocumentForm";

import {
  getDistrictAttachment,
  updateDistrictAttachment
} from "../../../actions/DistrictAttachment";
import { uploadAttachmentFile } from "../../../actions/Attachment";

// TODO: Add Document show in supplemental
class UpdateDocumentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      name: "",
      uri: "",
      description: "",
      // showInSupplementalInformation: false
    };
  }

  getDistrictAttachmentIdFromProps(props) {
    const { match } = props;
    const districtAttachmentId = match.params.districtAttachmentId;
    return districtAttachmentId
      ? parseInt(districtAttachmentId, 10)
      : undefined;
  }

  getDistrictAttachmentFromProps(props) {
    const districtAttachmentId = this.getDistrictAttachmentIdFromProps(props);
    if (districtAttachmentId === undefined) return undefined;

    const districtAttachment = props.districtAttachments[districtAttachmentId];
    return districtAttachment;
  }

  setStateFromDistrictAttachment(districtAttachment) {
    this.setState({
      ...districtAttachment
    });
  }

  async componentDidMount() {
    const districtAttachmentId = this.getDistrictAttachmentIdFromProps(
      this.props
    );
    await this.props.getDistrictAttachment(districtAttachmentId);

    const districtAttachment = this.getDistrictAttachmentFromProps(this.props);
    this.setStateFromDistrictAttachment(districtAttachment);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getDistrictAttachmentIdFromProps(this.props);
    const newId = this.getDistrictAttachmentIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getDistrictAttachment(newId);

      const districtAttachment = this.getDistrictAttachmentFromProps(nextProps);
      this.setStateFromDistrictAttachment(districtAttachment);
    }
  }

  saveDistrictAttachment = async (
    districtAttachmentId,
    name,
    uri,
    description,
    // showInSupplementalInformation
  ) => {
    const { district_id } = this.state;
    if (!name) {
      this.setState({ error: "You must provide a name" });
      return;
    }

    try {
      await this.props.updateDistrictAttachment(
        name,
        uri,
        description,
        district_id,
        districtAttachmentId,
        // showInSupplementalInformation
      );
      this.props.history.push(`/districts/${district_id}/documents`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  uploadFile = async e => {
    const uri = await this.props.uploadAttachmentFile(e.target.files[0]);
    this.setState({ uri });
  };

  render() {
    const state = this.state;
    const districtAttachmentId = this.getDistrictAttachmentIdFromProps(
      this.props
    );

    if (!this.state.id) {
      return <div>Loading...</div>;
    }
    return (
      <div className="update-district-container">
        <DocumentForm
          {...state}
          handleChange={this.handleChange}
          saveDistrictAttachment={this.saveDistrictAttachment}
          uploadFile={this.uploadFile}
          districtAttachmentId={districtAttachmentId}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    districtAttachments: state.DistrictAttachment.districtAttachmentDict
  };
};

const actions = {
  uploadAttachmentFile,
  updateDistrictAttachment,
  getDistrictAttachment
};

UpdateDocumentContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(UpdateDocumentContainer)
);

export default UpdateDocumentContainer;
