import React from "react";

const RegionAdminUserForm = ({
  regionList,
  regions,
  addRegion,
  removeRegion
}) => {
  const regionCheckboxes = Object.entries(regionList).map(entry => {
    return (
      <div key={entry[0]} className="checkbox">
        <label>
          <input
            type="checkbox"
            name="regions"
            checked={regions.indexOf(entry[0]) >= 0}
            onChange={e => {
              if (e.target.checked) {
                addRegion(entry[0]);
              } else {
                removeRegion(entry[0]);
              }
            }}
          />
          {entry[1]}
        </label>
      </div>
    );
  });
  return (
    <div id="region-user-form" className="form-group">
      <div className="row">
        <p className="lead col-xs-12">
          A Region Admin Can Modify, Create or Delete Data for their assigned Regions and Districts.
        </p>
      </div>
      <div className="row">
        <div className="col-xs-3">
          {regionCheckboxes}
        </div>
      </div>
    </div>
  );
};

export default RegionAdminUserForm;
