import React from "react";
import { Link } from "react-router-dom";
import DeletionButton from "../../Common/DeletionButton";

const SuggestedProductRow = ({ suggestedProduct, deleteSuggestedProduct }) => {
  return (
    <tr>
      <td>
        <Link to={`/products/register/verify/${suggestedProduct.id}`}>
          {suggestedProduct.name}
        </Link>
      </td>
      <td>
        {suggestedProduct.type}
      </td>
      <td>
        <Link to={suggestedProduct.registrar_email}>
          {suggestedProduct.registrar_first_name +
            " " +
            suggestedProduct.registrar_last_name}
        </Link>
      </td>
      <td>
        <form>
          <input
            defaultValue="Delete Entry"
            className="sr-only"
            type="submit"
          />
          <Link
            to={`/products/register/verify/${suggestedProduct.id}`}
            className="btn btn-xs btn-success"
          >
            Verify
          </Link>
          {" "}
          <DeletionButton
            onConfirm={() => {
              deleteSuggestedProduct(suggestedProduct.id);
            }}
            buttonText="Reject"
          />
        </form>
      </td>
    </tr>
  );
};

export default SuggestedProductRow;
