import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { UnmountClosed } from 'react-collapse';
import DistrictInventoryAttachmentRow from './DistrictInventoryAttachmentRow';
import DistrictInventoryAttachmentForm from './DistrictInventoryAttachmentForm';
import SuccessAlert from '../../Common/SuccessAlert';

const DistrictInventoryAttachmentTable = ({
  record,
  isOpened,
  allowShowInBOR,
  expandedAttachmentId,
  updateExpandedAttachmentId,
  attachmentTypes,
  updateAttachment,
  removeAttachment,
  createAttachment,
  attachmentErrors,
  updateAddingAttachmentInventoryRecordId,
  addingAttachmentInventoryRecordId,
  attachmentCreationSuccess,
  successMessage
}) => {
  useEffect(() => {
    if (attachmentCreationSuccess) {
      updateAddingAttachmentInventoryRecordId(addingAttachmentInventoryRecordId);
    }
  }, [attachmentCreationSuccess]);

  const handleCancelEdit = () => {
    updateExpandedAttachmentId(null);
  };

  const handleCancelAdd = () => {
    updateAddingAttachmentInventoryRecordId(false);
  };

  const toggleAddAttachment = () => {
    if (addingAttachmentInventoryRecordId) {
      updateAddingAttachmentInventoryRecordId(null);
    } else {
      updateAddingAttachmentInventoryRecordId(record.id);
    }
  };

  return (
    <>
      <tr className="inventory-record-attachment-row">
        <td colSpan="4"></td>
        <td colSpan="6">
          <UnmountClosed isOpened={isOpened}>
            <table className="table table-condensed">
              <thead>
                <tr>
                  <th>Document Name</th>
                  <th>Show Document In Supplemental Information</th>
                  <th>
                    <Button
                      bsStyle="success"
                      onClick={() => toggleAddAttachment()}
                      disabled={record.attachments.length >= 6 || record.archived}>
                      Add Document
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {record.attachments.length ? (
                  record.attachments.map((attachment) => {
                    return (
                      <DistrictInventoryAttachmentRow
                        key={attachment.id}
                        record={record}
                        attachment={attachment}
                        allowShowInBOR={allowShowInBOR}
                        updateExpandedAttachmentId={updateExpandedAttachmentId}
                        borDisabled={expandedAttachmentId === attachment.id || record.archived}
                        isArchived={record.archived}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="3">
                      <p className="text-center">No Attachments Found</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="col-12">
              {successMessage && (
                <>
                  <br></br>
                  <SuccessAlert message={successMessage} />
                </>
              )}
            </div>
          </UnmountClosed>
        </td>
      </tr>
      {record.attachments.length ? (
        record.attachments.map((attachment, index) => {
          return (
            attachment.id === expandedAttachmentId && (
              <tr className="inventory-record-attachment-row" key={index}>
                <td colSpan="4"></td>
                <td colSpan="6">
                  <DistrictInventoryAttachmentForm
                    isOpened={attachment.id === expandedAttachmentId}
                    {...attachment}
                    attachment={attachment}
                    key={attachment.id}
                    attachmentTypes={attachmentTypes}
                    index={index}
                    submitAttachment={updateAttachment}
                    removeAttachment={removeAttachment}
                    handleCancel={handleCancelEdit}
                    attachmentErrors={attachmentErrors}
                    newAttachment={false}
                    isArchived={record.archived}
                  />
                </td>
              </tr>
            )
          );
        })
      ) : (
        <></>
      )}
      {addingAttachmentInventoryRecordId === record.id ? (
        <tr className="inventory-record-attachment-row">
          <td colSpan="4"></td>
          <td colSpan="6">
            <DistrictInventoryAttachmentForm
              isOpened={addingAttachmentInventoryRecordId === record.id}
              key="1"
              attachmentTypes={attachmentTypes}
              index={0}
              handleCancel={handleCancelAdd}
              attachmentErrors={attachmentErrors}
              submitAttachment={createAttachment}
              newAttachment={true}
              isArchived={record.archived}
            />
          </td>
        </tr>
      ) : (
        <></>
      )}
    </>
  );
};

export default DistrictInventoryAttachmentTable;
