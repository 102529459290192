import React from "react";

const SuperAdminUserForm = () => {
  return (
    <div id="super-user-form">
      <div className="row">
        <p className="lead col-xs-12">
          A Super Admin Can Modify, Create or Delete Data for Any District, Region or Company.
        </p>
      </div>
    </div>
  );
};

export default SuperAdminUserForm;
