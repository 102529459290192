import React, { Component } from "react";
import { withRouter } from "react-router";
import CompanyList from "./CompanyList";
import { connect } from "react-redux";
import { searchForCompanies, deleteCompany } from "../../../actions/Company";
import qs from "qs";

class CompanyListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ""
    };
  }

  componentDidMount() {
    const page = this.getCurrentPageFromProps(this.props);
    const search = this.getCurrentQueryFromProps(this.props);
    this.setState({
      query: search
    });
    this.props.searchForCompanies(page, search);
  }

  componentWillReceiveProps(nextProps) {
    const oldPage = this.getCurrentPageFromProps(this.props);
    const newPage = this.getCurrentPageFromProps(nextProps);
    const pageChanged = oldPage !== newPage;

    const oldQuery = this.getCurrentQueryFromProps(this.props);
    const newQuery = this.getCurrentQueryFromProps(nextProps);
    const queryChanged = oldQuery !== newQuery;

    if (pageChanged || queryChanged) {
      this.props.searchForCompanies(newPage, newQuery);
    }
  }

  getCurrentQueryFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);

    return parsedSearch.query || "";
  }

  getCurrentPageFromProps(props) {
    const { location } = props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    if (parsedSearch.page !== undefined) {
      return parseInt(parsedSearch.page, 10);
    }

    return 1;
  }

  performSearch = query => {
    const { match, location } = this.props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    const newSearch = {
      ...parsedSearch,
      query,
      page: 1
    };

    const newRoute = {
      pathname: match.url,
      search: qs.stringify(newSearch)
    };

    this.props.history.push(newRoute);
  };

  updateQuery = query => {
    this.setState({ query });
  };

  changePage = page => {
    const { match, location } = this.props;

    const search = location.search ? location.search.slice(1) : "";
    const parsedSearch = qs.parse(search);
    const newSearch = { ...parsedSearch, page };

    const newRoute = {
      pathname: match.url,
      search: qs.stringify(newSearch)
    };

    this.props.history.push(newRoute);
  };

  deleteCompany = async companyId => {
    const page = this.getCurrentPageFromProps(this.props);

    await this.props.deleteCompany(companyId);
    this.props.searchForCompanies(page);
  };

  render() {
    const currentPage = this.getCurrentPageFromProps(this.props);

    const searchResult = this.props.companySearchResults;
    const companyList = searchResult ? searchResult.data : null;
    const totalPages = searchResult ? searchResult.last_page : 0;

    return (
      <CompanyList
        companies={companyList}
        currentPage={currentPage}
        changePage={this.changePage}
        totalPages={totalPages}
        deleteCompany={this.deleteCompany}
        query={this.state.query}
        updateQuery={this.updateQuery}
        performSearch={this.performSearch}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    companySearchResults: state.Company.searchResults
  };
};

const actions = {
  searchForCompanies,
  deleteCompany
};

CompanyListContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(CompanyListContainer)
);

export default CompanyListContainer;
