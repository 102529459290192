import React from "react";
import PageHeader from "../../Common/PageHeader";
import { Link } from "react-router-dom";
import DeletionButton from "../../Common/DeletionButton";

const SingleRegion = ({ region, deleteRegion, deleteDistrict }) => {
  return (
    <div className="single-region">
      <PageHeader title="Regions" subtitle="View Region" />
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-8">
              <p className="lead">{region.name}</p>
            </div>
            <div className="col-sm-4 text-right">
              <Link
                to={`/regions/${region.id}/update`}
                className="btn btn-default"
              >
                Edit
              </Link>
              {" "}
              <DeletionButton
                onConfirm={() => {
                  deleteRegion(region.id);
                }}
              />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>District</th>
                  <th>District Inventory</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {region.districts.map(district => {
                  return (
                    <tr key={district.id}>
                      <td>
                        {district.name}
                      </td>
                      <td>
                        <Link to={`/districts/${district.id}/inventory`}>
                          View Inventory
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/districts/${district.id}/update`}
                          className="btn-xs btn-warning"
                        >
                          Edit
                        </Link>
                        {" "}
                        <DeletionButton
                          onConfirm={() => {
                            deleteDistrict(district.id);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SingleRegion;
