import apiInstance from "../utils/apiInstance";

export const getClassificationList = () => async (dispatch, getState) => {
  const response = await apiInstance.get("data-classifications");

  dispatch({
    type: "CLASSIFICATIONS_RECEIVED",
    classifications: response.data
  });
};

export const deleteClassification = classificationId => async (
  dispatch,
  getState
) => {
  const url = `data-classifications/${classificationId}`;

  await apiInstance.delete(url);

  dispatch({ type: "CLASSIFICATION_DELETED", classificationId });
};

export const getClassification = classificationId => async (
  dispatch,
  getState
) => {
  const url = `data-classifications/${classificationId}`;
  const response = await apiInstance.get(url);

  dispatch({
    type: "CLASSIFICATION_RECEIVED",
    classificationId,
    classification: response.data
  });
};

export const updateClassification = (classificationId, name) => async (
  dispatch,
  getState
) => {
  const url = `data-classifications/${classificationId}`;
  const data = {
    name
  };

  const response = await apiInstance.put(url, data);

  dispatch({
    type: "CLASSIFICATION_RECEIVED",
    classificationId,
    classification: response.data
  });
};

export const createClassification = name => async (dispatch, getState) => {
  const url = `data-classifications`;
  const data = {
    name
  };

  const creationResponse = await apiInstance.post(url, data);

  const response = await apiInstance.get(
    `data-classifications/${creationResponse.data.id}`
  );
  dispatch({
    type: "CLASSIFICATION_RECEIVED",
    classificationId: response.data.id,
    classification: response.data
  });

  return response.data;
};
