import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getInventoryRecord, deleteInventoryRecord, updateInventoryRecordAttachmentArchived } from '../../../actions/InventoryRecord';
import { deleteDelegate } from '../../../actions/Delegate';
import SingleRecord from './SingleRecord';
import SingleProduct from '../../Products/SingleProduct/SingleProduct';
import DeletionButton from '../../Common/DeletionButton';
import PageHeader from '../../Common/PageHeader';
import { getDistrictInventoryPath } from '../../../utils/paths';
import { Link } from 'react-router-dom';

class SingleRecordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDistricts: false,
      messages: []
    };
  }

  toggleAttachmentArchived = async (recordId, attachmentId, status) => {
    this.setState({ messages: [] });
    try {
      await this.props.updateInventoryRecordAttachmentArchived(recordId, attachmentId, status);
      this.setState({ messages: [{type: 'success', message: `Attachment ${!status ? 'Unarchived' : 'Archived'} successfully.`}] });
    } catch (e) {
      console.log(e);
      this.setState({ messages: [{type: 'error', message: Object.values(e).flat(1)[0]}] });
    }
  }

  toggleShowDistricts = () => {
    this.setState({ showDistricts: !this.state.showDistricts });
  };

  getInventoryRecordIdFromProps(props) {
    const { match } = props;
    const recordId = match.params.inventoryId;
    return recordId ? parseInt(recordId, 10) : undefined;
  }

  async componentDidMount() {
    const recordId = this.getInventoryRecordIdFromProps(this.props);
    await this.props.getInventoryRecord(recordId);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getInventoryRecordIdFromProps(this.props);
    const newId = this.getInventoryRecordIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getInventoryRecord(newId);
    }
  }

  deleteDelegate = (inventoryId, delegateId) => {
    this.props.deleteDelegate(inventoryId, delegateId);
  };

  deleteRecord = async (recordId) => {
    await this.props.deleteInventoryRecord(recordId);

    const newPath = getDistrictInventoryPath(this.props.currentUser);
    this.props.history.push(newPath);
  };

  render() {
    const recordId = this.getInventoryRecordIdFromProps(this.props);
    const record = this.props.records[recordId];
    if (!record) return <div>Loading...</div>;

    const recordDeleteButton = !!record.user_can_delete ? (
      <DeletionButton
        onConfirm={() => {
          this.deleteRecord(record.id);
        }}
        className="btn btn-danger deletion-link"
        buttonText="Remove From District Inventory"
      />
    ) : null;

    const recordEditButton = !!record.user_can_edit ? (
      <Link to={`/inventory/${record.id}/update`} className="btn btn-warning">
        Edit
      </Link>
    ) : null;

    return (
      <div className="">
        <PageHeader title="District Inventory" subtitle="View Product" />
        <div className="text-right form-group">
          {recordEditButton} {recordDeleteButton}
        </div>

        <SingleProduct
          product={record.product}
          archived={record.archived}
          showDistricts={this.state.showDistricts}
          toggleShowDistricts={this.toggleShowDistricts}
        />
        <SingleRecord record={record} deleteDelegate={this.deleteDelegate} toggleAttachmentArchived={this.toggleAttachmentArchived} messages={this.state.messages}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    records: state.InventoryRecord.inventoryRecordDict,
    currentUser: state.Account.currentUser
  };
};

const actions = { getInventoryRecord, deleteInventoryRecord, deleteDelegate, updateInventoryRecordAttachmentArchived };

SingleRecordContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(SingleRecordContainer)
);

export default SingleRecordContainer;
