import React from "react";
import PageHeader from "../../Common/PageHeader";
import ErrorAlert from "../../Common/ErrorAlert";
import GoBack from "../../Common/GoBack";

const ClassificationForm = ({
  classificationId,
  name,
  updateName,
  saveClassification,
  error
}) => {
  return (
    <div className="classification-editor">
      <PageHeader
        title="Data Categories"
        subtitle={
          classificationId ? "Update Category" : "Create Category"
        }
      />
      <form
        onSubmit={e => {
          e.preventDefault();
          saveClassification(classificationId, name);
        }}
      >
        <div className="row">
          <div className="form-group col-sm-6">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              name="name"
              placeholder="Name of data category"
              className="form-control"
              type="text"
              value={name}
              onChange={e => {
                e.preventDefault();
                updateName(e.target.value);
              }}
            />
          </div>
        </div>
        <ErrorAlert error={error} />
        <div className="form-group text-right col-sm-6">
          <button type="submit" className="btn btn-primary">
            Save Category
          </button>
          {" "}
          <GoBack />
        </div>
      </form>
    </div>
  );
};

export default ClassificationForm;
