import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./sass/main.css";
import history from "./history";
import store from "./store";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";

import AppContainer from "./components/App/AppContainer";
import App from "./components/App/App";

import LoginContainer from "./components/Login/LoginContainer";
import LogoutContainer from "./components/Logout/LogoutContainer";
import ScrollToTop from "./components/Utils/ScrollToTop";

ReactDOM.render(
  <Provider store={store}>
    {/* ConnectedRouter will use the store from Provider automatically */}
    <ConnectedRouter history={history}>
      <AppContainer>
        <ScrollToTop />
        <Switch>
            <Route exact path="/login" component={LoginContainer} />
            <Route exact path="/logout" component={LogoutContainer} />
            <Route path="/" component={App} />
        </Switch>
      </AppContainer>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
