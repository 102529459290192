const renderIf = (condition, makeChildren) => {
  if (condition) {
    // Unless specifically set to false, do not render
    return makeChildren();
  }

  return null;
};

export default renderIf;
