import React from "react";
import { Link } from "react-router-dom";
import DeletionButton from "../../Common/DeletionButton";

const RegionRow = ({ region, deleteRegion }) => {
  return (
    <tr>
      <td>
        <Link to={`/regions/${region.id}`}>
          {region.name}
        </Link>
      </td>
      <td>
        <form>
          <input
            defaultValue="Delete Entry"
            className="sr-only"
            type="submit"
          />
          <Link
            to={`/regions/${region.id}/update`}
            className="btn btn-xs btn-warning"
          >
            Edit
          </Link>
          {" "}
          <DeletionButton
            onConfirm={() => {
              deleteRegion(region.id);
            }}
          />
        </form>
      </td>
    </tr>
  );
};

export default RegionRow;
