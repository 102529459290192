import thunk from "redux-thunk";
import history from "./history";
import {createStore, applyMiddleware, compose} from "redux";
import {routerMiddleware} from "connected-react-router";
import AllReducers from "./reducers/index";

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(AllReducers(history), composeEnhancers(
  applyMiddleware(thunk, routerMiddleware(history))
));

export default store;
