import {compareInventoryRecordNames} from "../utils/sortFunctions";

const initialState = {
  searchResults: null,
  pagination: {
    current_page: 1,
    last_page: 1
  },
};

const BillOfRights = (state = initialState, action) => {
  switch (action.type) {
    case "BILL_OF_RIGHTS_SEARCH_RECEIVED":
      return {
        ...state,
        searchResults: {
          district: action.district,
          inventoryRecords: action.inventoryRecords,
        },
        pagination: action.pagination
      };
    case "BILL_OF_RIGHTS_SEARCH_APPEND_RECEIVED":
      return {
        ...state,
        searchResults: {
            ...state.searchResults,
            inventoryRecords: [...state.searchResults.inventoryRecords, ...action.inventoryRecords].sort(compareInventoryRecordNames)
        },
        pagination: action.pagination
      };
    default:
      return state;
  }
};

export default BillOfRights;
