import React from "react";
// import { Link } from "react-router-dom";
// import { LinkContainer } from "react-router-bootstrap";
import { Alert } from "react-bootstrap";
import PageHeader from "../Common/PageHeader";
import ErrorAlert from "../Common/ErrorAlert";
import PasswordPolicy from "../Common/PasswordPolicy";

const MyAccount = ({
  currentUser,
  firstName,
  lastName,
  currentPassword,
  newPassword,
  confirmNewPassword,
  handleChange,
  submitUserInfoForm,
  submitPasswordForm,
  error,
  succeeded
}) => (
  <form>
    <PageHeader title="My Account" />
    <div className="row">
      <div className="col-sm-6">
        <p className="lead">User Information</p>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            id="firstName"
            type="text"
            name="firstName"
            placeholder="First Name"
            required
            className="form-control"
            value={firstName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            type="text"
            name="lastName"
            placeholder="Last Name"
            required
            className="form-control"
            value={lastName}
            onChange={handleChange}
          />
        </div>
        <div className="row">
          <div className="col-xs-12 text-right">
            <button type="button" className="btn btn-primary" onClick={submitUserInfoForm}>
              Update User Info
            </button>
          </div>
        </div>
        <p className="lead">Password</p>

        <PasswordPolicy />

        <div className="form-group">
          <label htmlFor="currentPassword">Current Password</label>
          <input
            id="currentPassword"
            type="password"
            placeholder="Current Password"
            name="currentPassword"
            className="form-control"
            autoComplete="off"
            style={{
              backgroundImage: 'url("data:image/png',
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "16px 18px",
              backgroundPosition: "98% 50%"
            }}
            value={currentPassword}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="newPassword">New Password</label>
          <input
            id="newPassword"
            type="password"
            placeholder="New Password"
            name="newPassword"
            className="form-control"
            style={{
              backgroundImage: 'url("data:image/png',
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "16px 18px",
              backgroundPosition: "98% 50%"
            }}
            value={newPassword}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="newPasswordConfirm">Confirm New Password</label>
          <input
            id="newPasswordConfirm"
            type="password"
            placeholder="Confirm New Password"
            name="confirmNewPassword"
            className="form-control"
            style={{
              backgroundImage: 'url("data:image/png',
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "16px 18px",
              backgroundPosition: "98% 50%"
            }}
            value={confirmNewPassword}
            onChange={handleChange}
          />
        </div>
        <ErrorAlert error={error} />
        {succeeded
          ? <Alert bsStyle="success">Your changes have been saved</Alert>
          : null}
        <div className="row">
          <div className="form-group">
            <div className="col-xs-12 text-right">
              <button type="button" className="btn btn-primary" onClick={submitPasswordForm}>
                Update Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
);

export default MyAccount;
