import { getAuthKey, setAuthKey, clearAuthKey } from "../utils/cookieHelper";

let initialState = {
  authKey: undefined,
  currentUser: null
};

const Account = (state = initialState, action) => {
  switch (action.type) {
    case "API_TOKEN_INVALID":
      return persistState({ ...state, authKey: null, currentUser: null });
    case "API_TOKEN_RECEIVED":
      return persistState({ ...state, authKey: action.authKey });
    case "ACCOUNT_RECEIVED":
      return { ...state, currentUser: action.user };
    default:
      return persistState(state); // Persist state on every Redux action to extend cookie expiration.
  }
};

const authKeyFromCookie = getAuthKey();

if (authKeyFromCookie) {
  initialState = { ...initialState, ...{ authKey: authKeyFromCookie } };
}

function persistState(state) {
  state.authKey ? setAuthKey(state.authKey) : clearAuthKey();
  return state;
}

export default Account;
