const crypto = require("crypto"), algorithm = "aes-256-ctr", password = "dpit";

function pad(num, size) {
  var s = num + "";
  while (s.length < size)
    s = "0" + s;
  return s;
}

export function encode(districtId) {
  const cipher = crypto.createCipher(algorithm, password);
  let crypted = cipher.update(pad(districtId, 10), "utf8", "hex");
  crypted += cipher.final("hex");
  return crypted;
}

export function decode(districtIdCipher) {
  const decipher = crypto.createDecipher(algorithm, password);
  let dec = decipher.update(districtIdCipher, "hex", "utf8");
  dec += decipher.final("utf8");
  return parseInt(dec, 10);
}
