import apiInstance from "../utils/apiInstance";
import qs from "qs";


export const searchForBillOfRights = (districtId, search, page) => async (dispatch, getState) => {
  const urlOptions = { search, page };
  const url = `bill-of-rights/index/${districtId}?${qs.stringify(urlOptions)}`;
  const response = await apiInstance.get(url);

  const pagination = {
      current_page: response.data.inventoryRecords.current_page,
      last_page: response.data.inventoryRecords.last_page
  }

  if(pagination.current_page === 1) {
    dispatch({ type: "BILL_OF_RIGHTS_SEARCH_RECEIVED",
      district: response.data.district,
      inventoryRecords: response.data.inventoryRecords.data,
      pagination
    });
  } else {
    dispatch({ type: "BILL_OF_RIGHTS_SEARCH_APPEND_RECEIVED",
      inventoryRecords: response.data.inventoryRecords.data,
      pagination
    });
  }
};
