import React, {useState, useEffect, useRef} from "react";
import PageHeader from "../../Common/PageHeader";
import GoBack from "../../Common/GoBack";
import ErrorAlert from "../../Common/ErrorAlert";
import SuccessAlert from "../../Common/SuccessAlert";

import { sendSetPasswordEmail } from "../../../actions/User";
import CompanyAdminUserForm from "./UserFormComponents/CompanyAdminUserForm";
import DataAdminUserForm from "./UserFormComponents/DataAdminUserForm";
import DistrictAdminUserForm from "./UserFormComponents/DistrictAdminUserForm";
import RegionAdminUserForm from "./UserFormComponents/RegionAdminUserForm";
import SuperAdminUserForm from "./UserFormComponents/SuperAdminUserForm";
import userTypes from "../../../utils/userTypes";

const UserForm = ({
                    handleChange,
                    userId,
                    email,
                    firstName,
                    lastName,
                    roleGroup,
                    companyId,
                    districtId,
                    regions,
                    addRegion,
                    removeRegion,
                    title,
                    saveUser,
                    districtList,
                    companyList,
                    regionList,
                    error,
                    userType,
                    showEmailFormForDataAdmin,
                    hideDistrict
                  }) => {

  const [dataAdminCreateUser, setDataAdminCreateUser] = useState(!!email || showEmailFormForDataAdmin);
  const [message, setMessage] = useState("");

  const userEmailLocked = useRef(!!userId && !!email).current;

  useEffect(() => {
    if(!userEmailLocked) {
      handleChange(
        {
          preventDefault: () => {},
          target: {
            name: "email",
            value: ""
          }});
    }
  }, [dataAdminCreateUser])

  const showUserForm = roleGroup !== userTypes.dataAdmin && roleGroup !== userTypes.districtSystemAdmin || userEmailLocked || dataAdminCreateUser;
  const userTypeOptions = userType => {
    switch (userType) {
      case userTypes.superAdmin:
        return (
          <div>
            <div className="p lead">User Type</div>
            <div className="form-group">
              <label htmlFor="roleGroup">Role Group</label>

              <select
                id="roleGroup"
                name="roleGroup"
                className="form-control col-xs-6"
                value={roleGroup}
                onChange={handleChange}
              >
                <option value>Choose a Role Group...</option>
                <option value={userTypes.companyAdmin}>
                  Company Administrator
                </option>
                <option value={userTypes.districtAdmin}>
                  District Administrator
                </option>
                <option value={userTypes.districtSystemAdmin}>
                  District System Administrator
                </option>
                <option value={userTypes.superAdmin}>Super Administrator</option>
                <option value={userTypes.regionAdmin}>
                  Region Administrator
                </option>
                <option value={userTypes.dataAdmin}>Data Administrator</option>
              </select>
            </div>
          </div>
        );
      case userTypes.regionAdmin:
        return (
          <div>
            <div className="p lead col-xs-12">User Type</div>
            <div className="form-group">
              <label htmlFor="roleGroup">Role Group</label>

              <select
                id="roleGroup"
                name="roleGroup"
                className="form-control col-xs-6"
                value={roleGroup}
                onChange={handleChange}
              >
                <option value>Choose a Role Group...</option>
                <option value={userTypes.districtAdmin}>
                  District Administrator
                </option>
                <option value={userTypes.districtSystemAdmin}>
                  District System Administrator
                </option>
                <option value={userTypes.dataAdmin}>Data Administrator</option>
              </select>
            </div>
          </div>
        );
      case userTypes.districtSystemAdmin:
        return (
          <div>
            <div className="p lead col-xs-12">User Type</div>
            <div className="form-group">
              <label htmlFor="roleGroup">Role Group</label>

              <select
                id="roleGroup"
                name="roleGroup"
                className="form-control col-xs-6"
                value={roleGroup}
                onChange={handleChange}
              >
                <option value>Choose a Role Group...</option>
                <option value={userTypes.districtAdmin}>
                  District Administrator
                </option>
                <option value={userTypes.dataAdmin}>Data Administrator</option>
              </select>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const form = userType => {
    if (userType === userTypes.districtAdmin) {
      return (
        <DataAdminUserForm
          handleChange={handleChange}
          districtList={districtList}
          districtId={districtId}
          showCreateUser={dataAdminCreateUser}
          handleCreateUserCheckbox={setDataAdminCreateUser}
          userEmailLocked={userEmailLocked}
          showDistrictSelect={false}
        />
      );
    }
    switch (roleGroup) {
      case userTypes.companyAdmin:
        return (
          <CompanyAdminUserForm
            companyList={companyList}
            companyId={companyId}
            handleChange={handleChange}
          />
        );
      case userTypes.districtAdmin:
        return (
          <DistrictAdminUserForm
            districtList={districtList}
            districtId={districtId}
            isDistrictSystemAdmin={false}
            handleChange={handleChange}
            hideDistrict={hideDistrict}
          />
        );
      case userTypes.districtSystemAdmin:
        return (
          <DistrictAdminUserForm
            districtList={districtList}
            districtId={districtId}
            isDistrictSystemAdmin={true}
            handleChange={handleChange}
            hideDistrict={hideDistrict}
          />
        );
      case userTypes.superAdmin:
        return <SuperAdminUserForm/>;
      case userTypes.regionAdmin:
        return (
          <RegionAdminUserForm
            regionList={regionList}
            regions={regions}
            addRegion={addRegion}
            removeRegion={removeRegion}
          />
        );
      case userTypes.dataAdmin:
        return (
          <DataAdminUserForm
            districtList={districtList}
            districtId={districtId}
            handleChange={handleChange}
            showCreateUser={dataAdminCreateUser}
            handleCreateUserCheckbox={setDataAdminCreateUser}
            userEmailLocked={userEmailLocked}
            showDistrictSelect={!hideDistrict}
          />
        );
      default:
        return null;
    }
  };

  const renderTitleField = () => {
    if (roleGroup === userTypes.dataAdmin || roleGroup === userTypes.districtAdmin || roleGroup === userTypes.districtSystemAdmin) {
      return (
        <div className="form-group col-xs-6">
          <label htmlFor="title">Title</label>
          <input
            id="title"
            type="text"
            name="title"
            placeholder="Title"
            value={title}
            className="form-control"
            onChange={handleChange}
          />
        </div>
      );
    }
    return null;
  }

  const save = e => {
    e.preventDefault();

    saveUser(
      dataAdminCreateUser
    );
  };

  return (
    <div className="user-editor">
      <PageHeader
        title="Users"
        subtitle={userId ? "Update User" : "Create a User"}
      />
      <div className="row">
        <div className="col-sm-12">
          <form
            onSubmit={save}
          >
            <div className="row">
              <p className="lead col-xs-12">User Information</p>
                <div className="form-group col-xs-6">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    id="firstName"
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={firstName}
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-xs-6">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    id="lastName"
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
                { renderTitleField() }
            </div>

            <div className="row">
              <div className="form-group col-xs-6">
                {userTypeOptions(userType)}
              </div>
            </div>

            {form(userType)}

            {showUserForm && <div className="row">
              <p className="lead col-xs-12">Account Information</p>
              <div className="form-group col-xs-6">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
            </div>}

            <ErrorAlert error={error}/>
            <SuccessAlert message={message} />

            <div className="row" style={{marginTop: 8 + 'px'}}>
              <div className="col-xs-4">
                {userId &&
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            sendSetPasswordEmail(userId)().
                                then((response) => {
                                    setMessage(response.data.message);
                                }
                        )}}
                        className="btn btn-primary"
                        style={{marginRight: 4 + 'px'}}
                    >
                    Send Set Password Email
                    </button>
                }
                <button type="submit" className="btn btn-primary">
                  Save User
                </button>
                <GoBack/>
              </div>
            </div>
            <div className="row">
                {!userId &&
                <div className="col-xs-4" style={{marginTop: 10 + 'px'}}>The new user will be emailed a temporary password. They will be prompted to reset the new password before logging in for the first time.</div>
                }
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
