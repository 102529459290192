import apiInstance from "../utils/apiInstance";

export const createDelegate = (inventoryRecordId, delegate) => async (
  dispatch,
  getState
) => {
  const url = `inventory-records/${inventoryRecordId}/delegates`;
  const data = {
    ...delegate
  };

  const response = await apiInstance.post(url, data);

  dispatch({
    type: "DELEGATE_RECEIVED",
    delegateId: response.data.id,
    delegate: response.data
  });

  return response.data;
};

export const deleteDelegate = (inventoryRecordId, delegateId) => async (
  dispatch,
  getState
) => {
  const url = `delegates/${delegateId}`;

  await apiInstance.delete(url);

  dispatch({ type: "DELEGATE_DELETED", delegateId, inventoryRecordId });
};

export const getDelegate = delegateId => async (dispatch, getState) => {
  const url = `delegates/${delegateId}`;
  const response = await apiInstance.get(url);

  dispatch({ type: "DELEGATE_RECEIVED", delegateId, delegate: response.data });
};

export const updateDelegate = (delegateId, delegate) => async (
  dispatch,
  getState
) => {
  const url = `delegates/${delegateId}`;
  const data = {
    ...delegate
  };

  const response = await apiInstance.put(url, data);

  dispatch({ type: "DELEGATE_RECEIVED", delegateId, delegate: response.data });
};
