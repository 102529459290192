import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getClassification,
  updateClassification
} from "../../../actions/Classification";
import ClassificationForm from "./ClassificationForm";

class UpdateClassificationContainer extends Component {
  constructor(props) {
    super(props);

    const classification = this.getClassificationFromProps(props) || {};

    this.state = {
      error: "",
      name: classification.name || ""
    };
  }

  getClassificationIdFromProps(props) {
    const { match } = props;
    const classificationId = match.params.classificationId;
    return classificationId ? parseInt(classificationId, 10) : undefined;
  }

  getClassificationFromProps(props) {
    const classificationId = this.getClassificationIdFromProps(props);

    if (classificationId === undefined) return undefined;
    const classification = props.classifications[classificationId];
    return classification;
  }

  setStateFromClassification(classification) {
    this.setState({
      name: classification.name || ""
    });
  }

  async componentDidMount() {
    const classificationId = this.getClassificationIdFromProps(this.props);
    await this.props.getClassification(classificationId);

    const classification = this.getClassificationFromProps(this.props);
    this.setStateFromClassification(classification);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getClassificationIdFromProps(this.props);
    const newId = this.getClassificationIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getClassification(newId);

      const classification = this.getClassificationFromProps(nextProps);
      this.setStateFromClassification(classification);
    }
  }

  saveClassification = async (classificationId, name) => {
    if (!name) {
      this.setState({ error: "You must provide a Category name" });
      return;
    }

    try {
      await this.props.updateClassification(classificationId, name);

      this.props.history.push(`/categories`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  updateName = name => {
    this.setState({ name });
  };

  render() {
    const classificationId = this.getClassificationIdFromProps(this.props);
    const { name, error } = this.state;

    return (
      <div className="update-Classification-container">
        <ClassificationForm
          error={error}
          classificationId={classificationId}
          name={name}
          updateName={this.updateName}
          saveClassification={this.saveClassification}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { classifications: state.Classification.classificationDict };
};

const actions = { getClassification, updateClassification };

UpdateClassificationContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(UpdateClassificationContainer)
);

export default UpdateClassificationContainer;
