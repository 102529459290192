import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  getClassificationList,
  deleteClassification
} from "../../../actions/Classification";
import ClassificationList from "./ClassificationList";

class ClassificationListContainer extends Component {
  async componentDidMount() {
    await this.props.getClassificationList();
  }

  deleteClassification = async classificationId => {
    await this.props.deleteClassification(classificationId);
    this.props.getClassificationList();
  };

  render() {
    const classifications = this.props.classifications ? this.props.classifications.data : null;

    return (
      <ClassificationList
        classifications={classifications}
        deleteClassification={this.deleteClassification}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    classifications: state.Classification.classifications
  };
};

const actions = {
  getClassificationList,
  deleteClassification
};

ClassificationListContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(ClassificationListContainer)
);

export default ClassificationListContainer;
