import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  getDistrict,
  searchForDistrictInventory,
  sortForDistrictInventory,
  getDistrictInventoryPDF,
  updateDistrictInventoryRecordAttachment,
  removeDistrictInventoryRecordAttachment,
  createDistrictInventoryRecordAttachment
} from '../../../actions/District';
import { getInventoryRecordAttachmentTypes } from '../../../actions/Options';
import DistrictInventory from './DistrictInventory';
import qs from 'qs';

class DistrictInventoryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandAttachmentsInventoryRecordId: null,
      expandedAttachmentId: null,
      expandedContractInventoryRecordId: null,
      addingAttachmentInventoryRecordId: null,
      query: '',
      sortBy: 'product',
      sortOrder: 'asc',
      archived: 'false',
      attachmentErrors: [],
      attachmentCreationSuccess: false,
      inventoryRecordArchivedSuccess: false,
      inventoryRecordUnarchivedSuccess: false,
      successMessage: '',
      listSuccessMessage: ''
    };

    this.SORT_ASC = 'asc';
    this.SORT_DESC = 'desc';
  }

  componentDidMount() {
    const districtId = this.getDistrictIdFromProps(this.props);
    this.props.getDistrict(districtId);
    this.performSimpleSearch();
    this.props.getInventoryRecordAttachmentTypes();
  }

  getDistrictIdFromProps(props) {
    const { match } = props;
    const districtId = match.params.districtId;
    return districtId ? parseInt(districtId, 10) : undefined;
  }

  getDistrictFromProps(props) {
    const districtId = this.getDistrictIdFromProps(props);
    if (districtId === undefined) return undefined;

    const district = props.districts[districtId];
    return district;
  }

  getInventoryRecordsFromProps(props) {
    const districtId = this.getDistrictIdFromProps(props);
    if (districtId === undefined) return undefined;

    const records = props.inventoryRecords[districtId];
    return records;
  }

  performSimpleSearch = (page = 1) => {
    const districtId = this.getDistrictIdFromProps(this.props);
    const search = this.getSearchFromProps(this.props);

    this.props.searchForDistrictInventory(
      districtId,
      search.query,
      search.sortby,
      search.sortorder,
      search.archived,
      page
    );
  };

  getArchivedFilterFromProps = () => {
    const search = this.getSearchFromProps(this.props);
    return search.archived ?? 'false';
  };

  getSearchFromProps = (props) => {
    const { location } = props;
    const search = location.search ? location.search.slice(1) : '';
    const parsedSearch = qs.parse(search);
    const query = parsedSearch.query || '';
    const sortby = parsedSearch.sortby || '';
    const sortorder = parsedSearch.sortorder || '';
    const archived = parsedSearch.archived || '';
    return { query: query, sortby: sortby, sortorder: sortorder, archived: archived };
  };

  componentWillReceiveProps(nextProps) {
    const oldId = this.getDistrictIdFromProps(this.props);
    const newId = this.getDistrictIdFromProps(nextProps);
    const idChanged = oldId !== newId;

    const oldSearch = this.getSearchFromProps(this.props);
    const newSearch = this.getSearchFromProps(nextProps);

    const searchChanged = oldSearch.query !== newSearch.query;
    const sortChanged = !(
      oldSearch.sortby === newSearch.sortby &&
      oldSearch.sortorder === newSearch.sortorder &&
      oldSearch.archived === newSearch.archived
    );
    this.setState({ archived: newSearch.archived ?? 'false' });

    if (idChanged) {
      this.props.getDistrict(newId);
    }

    if (searchChanged || sortChanged) {
      this.props.searchForDistrictInventory(
        newId,
        newSearch.query,
        newSearch.sortby,
        newSearch.sortorder,
        newSearch.archived,
        1
      );
    }
  }

  updateSort = (sortByColumn) => {
    this.resetMessages();
    const newOrder =
      this.state.sortBy === sortByColumn
        ? this.state.sortOrder === this.SORT_ASC
          ? this.SORT_DESC
          : this.SORT_ASC
        : this.SORT_ASC;

    this.setState({ ...this.state, sortBy: sortByColumn, sortOrder: newOrder });
    this.performSearch(this.state.query, sortByColumn, newOrder, this.state.archived);
    if (!sortByColumn) {
      this.performSearch('');
    }
  };

  updateArchivedFilter = (value) => {
    this.resetMessages();
    this.setState({ ...this.state, archived: value });
    this.performSearch(this.state.query, this.state.sortBy, this.state.sortOrder, value);
    if (!value) {
      this.performSearch('');
    }
  };

  updateQuery = (query, sortBy = '', sortOrder = '', archived = '') => {
    this.setState({ ...this.state, query, sortBy, sortOrder, archived });
    if (!query) {
      this.performSearch('');
    }
  };

  performSearch = (query, sortBy = '', sortOrder = '', archived = '') => {
    this.resetMessages();
    const { match, location } = this.props;

    if (query || (sortBy && sortOrder) || archived) {
      const search = location.search ? location.search.slice(1) : '';
      const parsedSearch = qs.parse(search);

      let newSearch = {
        ...parsedSearch,
        query,
        sortby: sortBy,
        sortorder: sortOrder,
        archived: archived
      };

      delete newSearch.startsWith;

      const newRoute = {
        pathname: match.url,
        search: qs.stringify(newSearch)
      };

      this.props.history.push(newRoute);
    } else {
      const search = location.search ? location.search.slice(1) : '';
      const parsedSearch = qs.parse(search);

      let newSearch = {
        ...parsedSearch,
        query: '',
        sortby: '',
        sortorder: '',
        archived: archived
      };

      delete newSearch.query;
      delete newSearch.type;

      const newRoute = {
        pathname: match.url,
        search: qs.stringify(newSearch)
      };

      this.props.history.push(newRoute);
    }
  };

  updateExpandAttachmentsInventoryRecordId = (inventoryRecordId) => {
    this.resetMessages();
    this.setState({
      expandedAttachmentId: null,
      expandedContractInventoryRecordId: null,
      addingAttachmentInventoryRecordId: null
    });
    if (inventoryRecordId === this.state.expandAttachmentsInventoryRecordId) {
      this.setState({ expandAttachmentsInventoryRecordId: null });
    } else {
      this.setState({ expandAttachmentsInventoryRecordId: inventoryRecordId });
    }
  };

  updateExpandedAttachmentId = (attachmentId) => {
    this.resetMessages();
    this.setState({
      expandedContractInventoryRecordId: null,
      addingAttachmentInventoryRecordId: null
    });
    if (attachmentId === this.state.expandedAttachmentId) {
      this.setState({ expandedAttachmentId: null });
    } else {
      this.setState({ expandedAttachmentId: attachmentId });
    }
  };

  updateExpandedContractInventoryRecordId = (inventoryRecordId) => {
    this.resetMessages();
    this.setState({ expandedAttachmentId: null, addingAttachmentInventoryRecordId: null });
    if (inventoryRecordId === this.state.expandedContractInventoryRecordId) {
      this.setState({ expandedContractInventoryRecordId: null });
    } else if (inventoryRecordId !== this.state.expandAttachmentsInventoryRecordId) {
      this.setState({
        expandedContractInventoryRecordId: inventoryRecordId,
        expandAttachmentsInventoryRecordId: null
      });
    } else {
      this.setState({
        expandedContractInventoryRecordId: inventoryRecordId,
        expandAttachmentsInventoryRecordId: null
      });
    }
  };

  updateAddingAttachmentInventoryRecordId = (inventoryRecordId) => {
    this.setState({ expandedContractInventoryRecordId: null, expandedAttachmentId: null });
    if (inventoryRecordId === this.state.addingAttachmentInventoryRecordId) {
      this.setState({ addingAttachmentInventoryRecordId: null });
    } else {
      this.resetMessages();
      this.setState({ addingAttachmentInventoryRecordId: inventoryRecordId });
    }
  };

  updateAttachment = async (attachment, inventoryRecord) => {
    try {
      this.resetMessages();
      const response = await this.props.updateDistrictInventoryRecordAttachment(attachment, inventoryRecord);
      this.setState({
        successMessage: attachment.archived ? 'The document was updated successfully. Click the product name link to view archived documents.' : 'The document was updated successfully.',
        expandedAttachmentId: null
      });
    } catch (err) {
      this.setState({
        attachmentErrors: [...this.state.attachmentErrors, ...Object.values(err).flat(1)]
      });
    }
  };

  removeAttachment = async (attachment, inventoryRecord) => {
    try {
      this.resetMessages();
      await this.props.removeDistrictInventoryRecordAttachment(attachment, inventoryRecord);
      this.setState({
        successMessage: 'The attachment was removed successfully.',
        expandedAttachmentId: null
      });
    } catch (err) {
      this.setState({
        attachmentErrors: [...this.state.attachmentErrors, ...Object.values(err).flat(1)]
      });
    }
  };

  createAttachment = async (attachment, inventoryRecord) => {
    try {
      this.resetMessages();
      await this.props.createDistrictInventoryRecordAttachment(attachment, inventoryRecord);
      this.setState({
        successMessage: 'The attachment was created successfully.',
        expandedAttachmentId: null,
        attachmentCreationSuccess: true
      });
    } catch (err) {
      this.setState({
        attachmentErrors: [...this.state.attachmentErrors, ...Object.values(err).flat(1)]
      });
    }
  };

  handleSuccessfulContractUpdate = () => {
    this.resetMessages();
    this.setState({ listSuccessMessage: 'Successfully updated Contract.' });
  };

  requestPDFView = () => {
    const districtId = this.getDistrictIdFromProps(this.props);
    const districtName = this.getDistrictFromProps(this.props).name;

    this.props.getDistrictInventoryPDF(
      districtId,
      districtName,
      this.state.query,
      this.state.sortBy,
      this.state.sortOrder,
      this.state.archived
    );
  };

  resetMessages = () => {
    this.setState({
      attachmentErrors: [],
      attachmentCreationSuccess: false,
      inventoryRecordArchivedSuccess: false,
      inventoryRecordUnarchivedSuccess: false,
      successMessage: '',
      listSuccessMessage: ''
    });
  };

  displayArchivedMessage = (status) => {
    this.resetMessages();
    if (status) {
      this.setState({ listSuccessMessage: 'Successfully archived Inventory Record.' });
    } else if (status === false) {
      this.setState({ listSuccessMessage: 'Successfully unarchived Inventory Record.' });
    } else {
      this.setState({ listSuccessMessage: '' });
    }
  };

  render() {
    const district = this.getDistrictFromProps(this.props);
    const inventoryRecords = this.getInventoryRecordsFromProps(this.props);
    if (!district || !inventoryRecords) return <div>Loading...</div>;

    return (
      <div className="district-inventory-container">
        <DistrictInventory
          district={district}
          records={inventoryRecords}
          expandAttachmentsInventoryRecordId={this.state.expandAttachmentsInventoryRecordId}
          updateExpandAttachmentsInventoryRecordId={this.updateExpandAttachmentsInventoryRecordId}
          updateExpandedAttachmentId={this.updateExpandedAttachmentId}
          expandedAttachmentId={this.state.expandedAttachmentId}
          expandedContractInventoryRecordId={this.state.expandedContractInventoryRecordId}
          updateExpandedContractInventoryRecordId={this.updateExpandedContractInventoryRecordId}
          updateAddingAttachmentInventoryRecordId={this.updateAddingAttachmentInventoryRecordId}
          addingAttachmentInventoryRecordId={this.state.addingAttachmentInventoryRecordId}
          infiniteScrollFunction={this.performSimpleSearch}
          infiniteScrollPagination={this.props.pagination}
          query={this.state.query}
          sortBy={this.state.sortBy}
          sortOrder={this.state.sortOrder}
          updateQuery={this.updateQuery}
          performSearch={this.performSearch}
          updateSort={this.updateSort}
          requestPDFView={this.requestPDFView}
          currentUser={this.props.currentUser}
          attachmentTypes={this.props.attachmentTypes}
          updateAttachment={this.updateAttachment}
          removeAttachment={this.removeAttachment}
          createAttachment={this.createAttachment}
          attachmentErrors={this.state.attachmentErrors}
          attachmentCreationSuccess={this.state.attachmentCreationSuccess}
          updateArchivedFilter={this.updateArchivedFilter}
          archivedFilter={this.getArchivedFilterFromProps()}
          displayArchivedMessage={this.displayArchivedMessage}
          inventoryRecordArchivedSuccess={this.state.inventoryRecordArchivedSuccess}
          inventoryRecordUnarchivedSuccess={this.state.inventoryRecordUnarchivedSuccess}
          successMessage={this.state.successMessage}
          listSuccessMessage={this.state.listSuccessMessage}
          handleSuccessfulContractUpdate={this.handleSuccessfulContractUpdate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    districts: state.District.districtDict,
    inventoryRecords: state.District.inventoryRecords,
    pagination: state.District.pagination,
    currentUser: state.Account.currentUser,
    attachmentTypes: state.Options.inventoryRecordAttachmentTypes
  };
};

const actions = {
  getDistrict,
  searchForDistrictInventory,
  sortForDistrictInventory,
  getDistrictInventoryPDF,
  getInventoryRecordAttachmentTypes,
  updateDistrictInventoryRecordAttachment,
  removeDistrictInventoryRecordAttachment,
  createDistrictInventoryRecordAttachment
};

DistrictInventoryContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(DistrictInventoryContainer)
);

export default DistrictInventoryContainer;
