import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getProduct, deleteProduct } from "../../../actions/Product";
import SingleProduct from "./SingleProduct";
import PageHeader from "../../Common/PageHeader";
import ProductHeader from "./ProductHeader";
import { getCreateInventoryRecordPath } from "../../../utils/paths";

class SingleProductContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showDistricts:  false,
    };
  }

  getProductIdFromProps(props) {
    const { match } = props;
    const productId = match.params.productId;
    return productId ? parseInt(productId, 10) : undefined;
  }

  async componentDidMount() {
    const productId = this.getProductIdFromProps(this.props);
    await this.props.getProduct(productId);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getProductIdFromProps(this.props);
    const newId = this.getProductIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getProduct(newId);
    }
  }

  deleteProduct = async productId => {
    await this.props.deleteProduct(productId);
    this.props.history.push("/products");
  };

  addProductToDistrictInventory = async product => {
    const currentUser = this.props.currentUser;
    const newPath = getCreateInventoryRecordPath(currentUser, product.id);
    this.props.history.push(newPath);
  };

  toggleShowDistricts = () => {
    this.setState({showDistricts: ! this.state.showDistricts});
  };

  buildState = () => {
    if(!this.props.location.state) {
      return {pathname: "/products"};
    }

    return {...this.props.location.state, state: this.props.location.state.previousState}
  }

  render() {
    const productId = this.getProductIdFromProps(this.props);
    const product = this.props.products[productId];

    if (!product) return <div>Loading...</div>;
    console.log('product state: ', this.props.location.state);

    return (
      <div>
        <PageHeader title="Products" subtitle="Product Profile" />
        <p>
          <Link to={this.buildState()}>
            { this.props.location.state ? this.props.location.state.backText : "← Back to Product List" }</Link>
        </p>

        <ProductHeader
          product={product}
          addProductToDistrictInventory={this.addProductToDistrictInventory}
          deleteProduct={this.deleteProduct}
        />
        <SingleProduct product={product}
                       showDistricts={this.state.showDistricts}
                       toggleShowDistricts={this.toggleShowDistricts} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.Product.productDict,
    currentUser: state.Account.currentUser
  };
};

const actions = { getProduct, deleteProduct };

SingleProductContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(SingleProductContainer)
);

export default SingleProductContainer;
