import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./Login.css";
import { resetPasswordAndLogin } from "../../actions/Account";
import ResetPasswordForm from "./ResetPasswordForm";
import config from "../../config";
import { getUserHomePath } from "../../utils/paths";

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    const emailQueryString = new URLSearchParams(window.location.search).get('email');
    this.state = {
      email: emailQueryString || this.props.location.state.email,
      token: "",
      old_password: "",
      new_password: "",
      new_password_confirmation: "",
      error: "",
      showModal: false
    };
  }

  submitResetPassword = async () => {
    if (!this.state.token) {
      this.setState({ error: "You must provide your one time token. Check your email." });
      return;
    }
    if (!this.state.old_password) {
      this.setState({ error: "You must include your old password" });
      return;
    }
    if (!this.state.new_password) {
      this.setState({ error: "You must include your new password" });
      return;
    }
    if (!this.state.new_password_confirmation) {
      this.setState({ error: "You must confirm your new password" });
      return;
    }
    if (this.state.new_password_confirmation !== this.state.new_password) {
      this.setState({ error: "Your new password and new password confirmation must match." });
      return;
    }

    try {
      const response = await this.props.resetPasswordAndLogin(
        this.state.email, this.state.token, this.state.old_password, this.state.new_password, this.state.new_password_confirmation
      );

      const user = response.user;

      if(response.token) {
        const newPath = getUserHomePath(user);
        this.props.history.push(newPath);
      }
      else if(response.reset_password) {
        // console.log('reset it');
      }
    } catch (err) {
      this.setState({ error: err });
    }
  };

  updateToken = token => {
    this.setState({
      token
    });
  };

  updateOldPassword = old_password => {
    this.setState({
      old_password
    });
  };

  updateNewPassword = new_password => {
    this.setState({
      new_password
    });
  };

  updateConfirmNewPassword = new_password_confirmation => {
    this.setState({
      new_password_confirmation
    });
  };

  openModal = () => {
    this.setState({
      showModal: true
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  render() {
    return (
      <ResetPasswordForm
        email={this.state.email}
        token={this.state.token}
        password={this.state.password}
        updateToken={this.updateToken}
        updateOldPassword={this.updateOldPassword}
        updateNewPassword={this.updateNewPassword}
        updateConfirmNewPassword={this.updateConfirmNewPassword}
        submitResetPassword={this.submitResetPassword}
        error={this.state.error}
        showModal={this.state.showModal}
        openModal={this.openModal}
        closeModal={this.closeModal}
        faqUrl={config.faqUrl}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    authKey: state.Account.authKey
  };
};

const actions = { resetPasswordAndLogin };

ResetPasswordContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(ResetPasswordContainer)
);

export default ResetPasswordContainer;
