import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ServiceProvidersList from "./ServiceProvidersList";
import { searchForServiceProviders, guestSearchForServiceProviders } from "../../../actions/ServiceProvider";
import qs from "qs";
import { decode } from "../../../utils/cryptoHelper";

const ServiceProvidersListContainer = (props) => {
    const [expandAttachmentsServiceProviderId, setExpandAttachmentsServiceProviderId] = useState(null);
    const [search, setSearch] = useState({
        query: "",
        sort: "name",
        order: "asc"
    });
    const [tempQuery, setTempQuery] = useState("");
    const [initialSearch, setInitialSearch] = useState(true);

    const SORT_ASC = "asc";
    const SORT_DESC = "desc";

    useEffect(() => {
        const onMount = async () => {
            performSimpleSearch(1);
        }

        onMount()
            .catch(console.error);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const oldSearch = getSearchFromURL();
            const newSearch = search;

            const searchChanged = oldSearch.query !== newSearch.query;
            const sortChanged = !(oldSearch.sort === newSearch.sort && oldSearch.order === newSearch.order);

            if (searchChanged || sortChanged) {
                updateSearchURL();

                if(props.currentUser) {
                    props.searchForServiceProviders(getDistrictIdFromProps(), newSearch.query, newSearch.sort, newSearch.order, 1, props.onSupplementalInformationPage);
                } else {
                    props.guestSearchForServiceProviders(getDistrictIdFromProps(), newSearch.query, newSearch.sort, newSearch.order, 1);
                }
            }
        }

        if(!initialSearch) {
            fetchData().catch(console.error);
        } else {
            setInitialSearch(false);
        }
    }, [search]);

    const updateSearchURL = () => {
        const { match } = props;
        const newSearchData = {
            query: search.query,
            sort: search.sort,
            order: search.order
        };

        const newRoute = {
            pathname: match.url,
            search: qs.stringify(newSearchData)
        };

        props.history.push(newRoute);
    };

    const getDistrictIdFromProps = () => {
        const { match } = props;
        let districtId = null;
        if(match.url.includes('supplemental')) {
            districtId = decode(match.params.districtId);
        } else {
            districtId = match.params.districtId;
        }
        
        return districtId ? parseInt(districtId, 10) : undefined;
    };

    const performSimpleSearch = async (page = 1) => {
        if(props.currentUser) {
            props.searchForServiceProviders(getDistrictIdFromProps(), search.query, search.sort, search.order, page, props.onSupplementalInformationPage);
        } else {
            props.guestSearchForServiceProviders(getDistrictIdFromProps(), search.query, search.sort, search.order, page);
        }
    };

    const getSearchFromURL = () => {
        const { location } = props;
        const search = location.search ? location.search.slice(1) : "";
        const parsedSearch = qs.parse(search);
        const query = parsedSearch.query || "";
        const sort = parsedSearch.sortb || "";
        const order = parsedSearch.order || "";
        return { query: query, sort: sort, order: order };
    };

    const performSearch = async (query, sort, order) => {
        if (query || (sort && order)) {
            setSearch({...search, query });
        } else {
            setSearch({ query: "", sort: "", order: "" });
        }
    };

    const updateSort = sortByColumn => {
        if (!sortByColumn) {
            performSearch('');
        }
        const newOrder = search.sort === sortByColumn ? (search.order === SORT_ASC ? SORT_DESC : SORT_ASC) : SORT_ASC;

        setSearch({ ...search, sort: sortByColumn, order: newOrder });
    };

    const updateQuery = (query) => {
        if (!query) {
            performSearch('');
        }
        setTempQuery(query);
    };

    const updateExpandAttachmentsServiceProviderId = (serviceProviderId) => {
        if (serviceProviderId === expandAttachmentsServiceProviderId) {
            setExpandAttachmentsServiceProviderId(null);
        } else {
            setExpandAttachmentsServiceProviderId(serviceProviderId);
        }
    };

    return (
        <div className="service-provider-container" style={{display: props.hidden ? 'none' : ''}}>
            <ServiceProvidersList
                {...search}
                districtId={getDistrictIdFromProps()}
                districtName={props.districtName}
                districtServiceProviders={props.serviceProviders[getDistrictIdFromProps()]}
                infiniteScrollFunction={performSimpleSearch}
                performSearch={performSearch}
                updateQuery={updateQuery}
                updateSort={updateSort}
                infiniteScrollPagination={props.pagination}
                expandAttachmentsServiceProviderId={expandAttachmentsServiceProviderId}
                updateExpandAttachmentsServiceProviderId={updateExpandAttachmentsServiceProviderId}
                tempQuery={tempQuery}
                onSupplementalPage={props.onSupplementalInformationPage}
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        serviceProviders: state.ServiceProvider.serviceProviders,
        pagination: state.ServiceProvider.pagination,
        districtName: state.ServiceProvider.districtName,
        currentUser: state.Account.currentUser,
        ...state
    }
};

const mapDispatchToProps = {
    searchForServiceProviders,
    guestSearchForServiceProviders
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProvidersListContainer);