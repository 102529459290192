const initialState = {
  classifications: null,
  classificationDict: {},
};

const Classification = (state = initialState, action) => {
  switch (action.type) {
    case "CLASSIFICATIONS_RECEIVED":
      return { ...state, classifications: action.classifications };
    case "CLASSIFICATION_RECEIVED":
      return {
        ...state,
        classificationDict: {
          ...state.classificationDict,
          [action.classificationId]: action.classification
        }
      };
    case "CLASSIFICATION_DELETED":
      const newClassificationDict = { ...state.classificationDict };
      delete newClassificationDict[action.classificationId];

      return {
        ...state,
        classificationDict: newClassificationDict
      };
    default:
      return state;
  }
};

export default Classification;
