import React from 'react';
import { Link } from 'react-router-dom';
import './SingleProduct.css';
import AttachmentTable from '../../Common/Attachments/AttachmentTable';
import { Collapse } from 'react-collapse';
import { getCurrentUserType } from '../../../utils/renderFor';

const SingleProduct = ({ product, archived, showDistricts, toggleShowDistricts }) => {
  const currentUserType = getCurrentUserType();

  const attachments = product.attachments;
  const otherFiles = attachments.filter((x) => x.type !== 'POLICY_ABSTRACT');
  const productDocumentationField =
    otherFiles.length === 0 ? (
      <div className="panel-body">No Product Documentation</div>
    ) : (
      <AttachmentTable attachments={otherFiles} />
    );

  const districts = product.districts.map((district) => {
    const districtText =
      currentUserType === 'superAdmin' || currentUserType === 'regionAdmin'
        ? `${district.name} (${district.region_name})`
        : district.name;

    return district.link ? (
      <p key={district.id}>
        <Link
          to={`/districts/${district.id}`}
          className={
            product.districts_with_attachments &&
            product.districts_with_attachments.includes(district.id)
              ? 'hasattachments'
              : ''
          }>
          {districtText}
        </Link>
      </p>
    ) : (
      <p
        key={district.id}
        className={
          product.districts_with_attachments &&
          product.districts_with_attachments.includes(district.id)
            ? 'hasattachments'
            : ''
        }>
        {districtText}
      </p>
    );
  });

  let unlistedDistrictCount = null;

  if (product.number_of_unlisted_districts !== null) {
    unlistedDistrictCount =
      product.number_of_unlisted_districts === 1 ? (
        <p>{product.number_of_unlisted_districts} Unlisted District</p>
      ) : (
        <p>{product.number_of_unlisted_districts} Unlisted Districts</p>
      );
  }

  let chevron = null;

  if (product.districts.length > 0 || product.number_of_unlisted_districts > 0) {
    chevron = showDistricts ? (
      <span className="glyphicon glyphicon-chevron-up expand" onClick={toggleShowDistricts}></span>
    ) : (
      <span
        className="glyphicon glyphicon-chevron-down expand"
        onClick={toggleShowDistricts}></span>
    );
  }

  let archivedText = archived ? <span className="text-danger">(Archived)</span> : null;

  return (
    <div className="single-product">
      <div className="row">
        <div className="col-sm-12">
          <p className="lead">
            {product.name} {archivedText}
          </p>
          <div className="panel panel-default">
            <div className="panel-heading">Product Information</div>
            <table className="table table-product-view">
              <tbody>
                <tr>
                  <th>Company</th>
                  <td>{product.company ? product.company.name : ''}</td>
                  <td></td>
                </tr>
                <tr>
                  <th>Product Type</th>
                  <td>{product.type}</td>
                  <td></td>
                </tr>
                {/*{ dataClassificationRow }*/}
                <tr key="numDistricts">
                  <th>Number of Districts</th>
                  <td>
                    <div>
                      {districts.length + product.number_of_unlisted_districts}&nbsp;
                      {chevron}
                      <Collapse isOpened={showDistricts}>
                        {districts}
                        {unlistedDistrictCount}
                      </Collapse>
                    </div>
                  </td>
                  <td>
                    <div>
                      <p
                        style={{ fontWeight: 'normal', display: showDistricts ? 'block' : 'none' }}>
                        Districts in bold green text have Supporting Documents attached.
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingleProduct;
