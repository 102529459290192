const initialState = {
  searchResults: null,
  regionDict: {},
  regions: null,
  selectedRegion: null
};

const Region = (state = initialState, action) => {
  switch (action.type) {
    case "REGION_SEARCH_RECEIVED":
      return { ...state, searchResults: action.regions };
    case "REGION_RECEIVED":
      return {
        ...state,
        regionDict: {
          ...state.regionDict,
          [action.regionId]: action.region
        }
      };
    case "REGION_DELETED":
      const newRegionDict = { ...state.regionDict };
      delete newRegionDict[action.regionId];

      return {
        ...state,
        regionDict: newRegionDict
      };
    default:
      return state;
  }
};

export default Region;
