const initialState = {
  searchResults: null,
  userDict: {},
  districtAdminList: null
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case "USER_SEARCH_RECEIVED":
      return { ...state, searchResults: action.users };
    case "USER_RECEIVED":
      return {
        ...state,
        userDict: {
          ...state.userDict,
          [action.userId]: action.user
        }
      };
    case "USER_DELETED":
      const newUserDict = { ...state.userDict };
      delete newUserDict[action.userId];

      return {
        ...state,
        userDict: newUserDict
      };
    case "DISTRICT_ADMIN_LIST_RECEIVED":
      return { ...state, districtAdminList: action.userList};
    default:
      return state;
  }
};

export default User;
