import React, { Component } from "react";
import { withRouter } from "react-router";
import DocumentList from "./DocumentList";
import { connect } from "react-redux";
import {
  getDistrictAttachmentList,
  deleteDistrictAttachment
} from "../../../actions/DistrictAttachment";
import { getDistrict } from "../../../actions/District";

class DocumentListContainer extends Component {
  getDistrictIdFromProps(props) {
    const { match } = props;
    const districtId = match.params.districtId;
    return districtId ? parseInt(districtId, 10) : undefined;
  }

  getDistrictFromProps(props) {
    const districtId = this.getDistrictIdFromProps(props);
    if (districtId === undefined)  return undefined;

    return props.districts[districtId];
  }

  //getDistrict ???

  componentDidMount() {
    const districtId = this.getDistrictIdFromProps(this.props);

    this.props.getDistrictAttachmentList(districtId);
  }

  componentWillReceiveProps(nextProps) {
    const oldId = this.getDistrictIdFromProps(this.props);
    const newId = this.getDistrictIdFromProps(nextProps);

    if (oldId !== newId) {
      const districtId = this.getDistrictIdFromProps(nextProps);
      this.props.getDistrictAttachmentList(districtId);
    }
  }

  deleteDistrictAttachment = async districtAttachmentId => {
    await this.props.deleteDistrictAttachment(districtAttachmentId);
    //needs id
    const districtId = this.getDistrictIdFromProps(this.props);
    this.props.getDistrictAttachmentList(districtId);
  };

  render() {
    return (
      <DocumentList
        districtAttachments={this.props.districtAttachmentList}
        deleteDistrictAttachment={this.deleteDistrictAttachment}
        districtId={this.getDistrictIdFromProps(this.props)}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    districtAttachmentList: state.DistrictAttachment.districtAttachmentList,
    districts: state.District.districtDict
  };
};

const actions = {
  getDistrictAttachmentList,
  deleteDistrictAttachment,
  getDistrict
};

DocumentListContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(DocumentListContainer)
);

export default DocumentListContainer;
