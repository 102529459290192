import React from "react";
import PageHeader from "../../Common/PageHeader";
import { Link } from "react-router-dom";
import renderFor from "../../../utils/renderFor";
import userTypes from "../../../utils/userTypes";
import DeletionButton from "../../Common/DeletionButton";

const SingleUser = ({ user, deleteUser }) => {
  const entity = user => {
    switch (user.user_type) {
      case userTypes.companyAdmin:
        return (
          <tr>
            <th>Company</th>
            <td>
              <Link to={`/companies/${user.entity.id}/`}>
                {user.entity.name}
              </Link>
            </td>
          </tr>
        );
      case userTypes.districtAdmin:
        return (
          <tr>
            <th>District</th>
            <td>
              <Link to={`/districts/${user.entity.id}/`}>
                {user.entity.name}
              </Link>
            </td>
          </tr>
        );
      case userTypes.districtSystemAdmin:
        return (
          <tr>
            <th>District</th>
            <td>
              <Link to={`/districts/${user.entity.id}/`}>
                {user.entity.name}
              </Link>
            </td>
          </tr>
        );
      case userTypes.dataAdmin:
        return (
          <tr>
            <th>District</th>
            <td>
              <Link to={`/districts/${user.entity.id}/`}>
                {user.entity.name}
              </Link>
            </td>
          </tr>
        );
      default:
        return null;
    }
  };

  const regions = user.regions.length !== 0
    ? <tr>
        <th>Regions</th>
        <td>
          {user.regions.map(region => (
            <div key={region.id}>
              <Link to={`/regions/${region.id}/`}>
                {region.name}
              </Link>
            </div>
          ))}
        </td>
      </tr>
    : null;

  const assignedProducts = renderFor(
    { regionAdmin: true, districtAdmin: true, districtSystemAdmin: true },
    () => {
      return user.data_admin_records.length !== 0
        ? <tr>
            <th>Products Assigned To</th>
            <td>
              {user.data_admin_records.map(record => (
                <div key={record.id} className="row">
                  <div className="col-md-3">
                    <Link to={`/inventory/${record.id}/`}>
                      {record.product.name + " "}
                    </Link>
                  </div>
                  <div className="col-md-9">
                    Authorities:
                    {record.authorities.length > 0
                      ? " " +
                          record.authorities.map(
                            authority => " " + authority.name
                          )
                      : " None"}
                  </div>
                </div>
              ))}
            </td>
          </tr>
        : null;
    }
  );

  const userTypeSplit = user.user_type.split("_");
  const userTypeJoin = [];
  userTypeSplit.forEach(word => {
    userTypeJoin.push(word.charAt(0) + word.slice(1).toLowerCase())
  });
  const userType = userTypeJoin.join(" ");

  return (
    <div className="single-user">
      <PageHeader title="User" subtitle="User Information" />
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-8">
              <p className="lead">{user.first_name} {user.last_name}</p>
            </div>
            <div className="col-sm-4 text-right">
              <Link
                to={`/users/${user.id}/update`}
                className="btn btn-s btn-warning"
              >
                Edit
              </Link>
              {" "}
              <DeletionButton
                onConfirm={() => {
                  deleteUser(user.id);
                }}
              />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-user-view">
              <tbody>
                <tr>
                  <th>Email</th>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <th>Enabled</th>
                  <td>{user.deleted_at ? "No" : "Yes"}</td>
                </tr>
                {entity(user)}
                {regions}
                <tr>
                  <th>Role Groups</th>
                  <td>{userType}</td>
                </tr>
                {assignedProducts}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SingleUser;
