import { UnmountClosed } from 'react-collapse';
import { useState } from 'react';
import { connect } from 'react-redux';
import ErrorAlert from '../../Common/ErrorAlert';
import ConfirmationButton from '../../Common/ConfirmationButton';
import DeletionButton from '../../Common/DeletionButton';
import DistrictInventoryAttachmentPanel from './DistrictInventoryAttachmentPanel';
import { uploadAttachmentFile } from '../../../actions/Attachment';

const DistrictInventoryAttachmentForm = ({
  isOpened,
  attachment,
  attachmentTypes,
  index,
  updateAttachment,
  submitAttachment,
  removeAttachment,
  handleCancel,
  attachmentErrors,
  uploadAttachmentFile,
  type,
  name,
  uri,
  description,
  show_in_bor,
  newAttachment,
  id,
  isArchived
}) => {
  const [attachmentForm, setAttachmentForm] = useState({
    id: id ?? null,
    type: type ?? '',
    name: name ?? '',
    uri: uri ?? '',
    description: description ?? '',
    show_in_bor: show_in_bor ?? false,
    documentChanged: false,
    loading: false,
    error: '',
    archived: isArchived ?? false
  });

  const handleTypeChange = (newType) => {
    if (
      attachmentForm.name === '' ||
      attachmentForm.name === null ||
      attachmentTypes.includes(attachmentForm.name)
    ) {
      setAttachmentForm({ ...attachmentForm, name: newType, type: newType });
    } else {
      setAttachmentForm({ ...attachmentForm, type: newType });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();

    const field = e.target.name;
    const value = e.target.value;

    if (field === 'uri') {
      setAttachmentForm({
        ...attachmentForm,
        uri: value,
        documentChanged: true
      });
    } else {
      if (field === 'type') {
        handleTypeChange(value);
      } else {
        setAttachmentForm({ ...attachmentForm, [field]: value });
      }
    }
  };

  const generateConfirmationMessage = () => {
    if(attachmentForm.documentChanged && attachmentForm.archived !== isArchived) {
      return `The supporting document has changed, and you have chosen to ${attachmentForm.archived ? 'archive' : 'unarchive'} this document. Saving will permanently remove the old supporting document and ${attachmentForm.archived ? 'archive' : 'unarchive'} this document.`;
    } else if (attachmentForm.documentChanged) {
      return 'The supporting document has changed, saving will permanently remove the old supporting document';
    } else if (attachmentForm.archived !== isArchived) {
      return `You have chosen to ${attachmentForm.archived ? 'archive' : 'unarchive'} this document. Are you sure you want to ${attachmentForm.archived ? 'archive' : 'unarchive'} the document.`;
    }
  }

  const uploadFile = async (e) => {
    setAttachmentForm({ ...attachmentForm, loading: true });
    try {
      const newAttachment = await uploadAttachmentFile(e.target.files[0]);
      setAttachmentForm((attachmentForm) => ({ ...attachmentForm, uri: newAttachment, error: '' }));
      setAttachmentForm((attachmentForm) => ({ ...attachmentForm, documentChanged: true }));
    } catch (err) {
      setAttachmentForm((attachmentoForom) => ({ ...attachmentForm, error: err }));
    } finally {
      setAttachmentForm((attachmentForm) => ({ ...attachmentForm, loading: false }));
    }
  };

  const errorMessages =
    attachmentErrors.length > 0
      ? attachmentErrors.map((error, i) => {
          return <ErrorAlert key={i} error={error} />;
        })
      : null;

  return (
    <div className="row">
      <UnmountClosed isOpened={isOpened}>
        <div className="col-xs-12 col-sm-10">
          <DistrictInventoryAttachmentPanel
            attachment={attachment}
            attachmentTypes={attachmentTypes}
            handleChange={handleChange}
            {...attachmentForm}
            updateAttachment={updateAttachment}
            removeAttachment={removeAttachment}
            uploadFile={uploadFile}
            index={index}
            showInBORCheckbox
            isArchived={isArchived}
            newAttachment={newAttachment}
          />
          {errorMessages}
          <div className="form-group col-xs-12 text-right">
            <ConfirmationButton
              isDisabled={attachmentForm.loading}
              title="Are you sure?"
              body={generateConfirmationMessage()}
              confirmBeforeExecuting={(attachmentForm.documentChanged || attachmentForm.archived !== isArchived) && !newAttachment}
              confirmCallback={() => {
                submitAttachment({ ...attachmentForm });
              }}
              className="btn btn-md btn-primary"
              buttonText="Save Document"
            />{' '}
            {newAttachment ? (
              <></>
            ) : (
              <DeletionButton
                onConfirm={() => {
                  removeAttachment(attachment);
                }}
                buttonText="Remove Document"
                className="btn btn-md btn-danger"
                title="Remove Document"
              />
            )}{' '}
            <button
              className="btn btn-md btn-secondary"
              type="button"
              title="Cancel Document Edit"
              onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </UnmountClosed>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { uploadAttachmentFile };

export default connect(mapStateToProps, mapDispatchToProps)(DistrictInventoryAttachmentForm);
