import React, { Component } from "react";
import { connect } from "react-redux";
import Nav from "./Nav";
import { requestDistrictExportDownload } from "../../actions/District";
import { requestRegionExportDownload } from "../../actions/Region";
import { withRouter } from "react-router";

class NavContainer extends Component {
  exportDistrictData = async districtId => {
    const url = await requestDistrictExportDownload();
    window.location = url;
  };
  
  exportDistrictDataAdvanced = async districtId => {
    const url = await requestDistrictExportDownload("ADVANCED");
    window.location = url;
  }

  exportRegionData = async districtId => {
    const url = await requestRegionExportDownload(this.props.currentUser.regions[0].id);
    window.location = url;
  }

  exportRegionDataAdvanced = async districtId => {
    const url = await requestRegionExportDownload(this.props.currentUser.regions[0].id, "ADVANCED");
    window.location = url;
  }

  render() {
    return (
      <Nav
        currentUser={this.props.currentUser}
        exportDistrictData={this.exportDistrictData}
        exportDistrictDataAdvanced={this.exportDistrictDataAdvanced}
        exportRegionData={this.exportRegionData}
        exportRegionDataAdvanced={this.exportRegionDataAdvanced}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    currentUser: state.Account.currentUser
  };
};

const actions = {};

NavContainer = withRouter(connect(mapStateToProps, actions, null, { pure: false })(NavContainer));

export default NavContainer;
