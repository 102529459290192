import React from "react";
import PageHeader from "../../Common/PageHeader";
import ErrorAlert from "../../Common/ErrorAlert";
import GoBack from "../../Common/GoBack";

const DistrictForm = ({
  districtId,
  regionId,
  name,
  showInProductProfileListing,
  hideVendorPublicPrivacySi,
  hideServiceProvidersSi,
  updateName,
  updateRegionId,
  updateShowInProductProfileListing,
  updateHideVendorPublicPrivacySi,
  updateHideServiceProvidersSi,
  regions,
  attachments,
  saveDistrict,
  attachmentTypes,
  updateAttachment,
  removeAttachment,
  addAttachment,
  isDistrictAdmin,
  error
}) => {
  const option = Object.entries(regions).map(entry => {
    return (
      <option key={entry[0]} value={entry[0]}>
        {entry[1]}
      </option>
    );
  });
  return (
    <div className="district-editor">
      <PageHeader
        title="Districts"
        subtitle={districtId ? "Update District" : "Create District"}
      />
      <form
        onSubmit={e => {
          e.preventDefault();
          saveDistrict(districtId, name, regionId);
        }}
      >
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                readOnly={isDistrictAdmin}
                id="name"
                name="name"
                placeholder="Name of district"
                className="form-control"
                type="text"
                value={name}
                onChange={e => {
                  e.preventDefault();
                  updateName(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="region">Region</label>
              <select
                disabled={isDistrictAdmin}
                id="region"
                name="regionId"
                className="form-control"
                defaultValue={String(regionId)}
                onChange={e => {
                  e.preventDefault();
                  updateRegionId(e.target.value);
                }}
              >
                {option}
              </select>
            </div>
            <div className="checkbox">
              <label>
                <input
                    type="checkbox"
                    name="showInProductProfileListing"
                    checked={showInProductProfileListing}
                    onChange={updateShowInProductProfileListing}
                />
                List District Name in Product Profile
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input
                    type="checkbox"
                    name="hideServiceProvidersSi"
                    checked={hideServiceProvidersSi}
                    onChange={updateHideServiceProvidersSi}
                />
                Hide 'Service Providers' on Supplemental Information Page
              </label>
            </div>
          </div>
        </div>

        <ErrorAlert error={error} />
        <div className="row">
          <div className="col-sm-6">

            <div className="form-group text-right">
              <button type="submit" className="btn btn-primary">
                Save District
              </button>
              <GoBack />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DistrictForm;
