import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getCompany, updateCompany } from "../../../actions/Company";
import CompanyForm from "./CompanyForm";

class UpdateCompanyContainer extends Component {
  constructor(props) {
    super(props);

    const company = this.getCompanyFromProps(props) || {};

    this.state = {
      error: "",
      name: company.name || "",
      contactName: company.contact_name || "",
      contactTitle: company.contact_title || "",
      contactEmail: company.contact_email || "",
      phone: company.phone || "",
      address: company.address || "",
      nys_education_law_does_not_apply: false
    };
  }

  getCompanyIdFromProps(props) {
    const { match } = props;
    const companyId = match.params.companyId;
    return companyId ? parseInt(companyId, 10) : undefined;
  }

  getCompanyFromProps(props) {
    const companyId = this.getCompanyIdFromProps(props);
    if (companyId === undefined) return undefined;

    const company = props.companys[companyId];
    return company;
  }

  setStateFromCompany(company) {
    this.setState({
      name: company.name || "",
      contactName: company.contact_name || "",
      contactTitle: company.contact_title || "",
      contactEmail: company.contact_email || "",
      phone: company.phone || "",
      address: company.address || ""
    });
  }

  async componentDidMount() {
    const companyId = this.getCompanyIdFromProps(this.props);
    await this.props.getCompany(companyId);

    const company = this.getCompanyFromProps(this.props);
    this.setStateFromCompany(company);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getCompanyIdFromProps(this.props);
    const newId = this.getCompanyIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getCompany(newId);

      const company = this.getCompanyFromProps(nextProps);
      this.setStateFromCompany(company);
    }
  }

  saveCompany = async (
    companyId,
    name,
    contactName,
    contactTitle,
    contactEmail,
    phone,
    address,
    nys_education_law_does_not_apply
  ) => {
    if (!name) {
      this.setState({ error: "You must provide a company name" });
      return;
    }

    try {
      await this.props.updateCompany(
        companyId,
        name,
        contactName,
        contactTitle,
        contactEmail,
        phone,
        address,
        nys_education_law_does_not_apply
      );

      this.props.history.push(`/companies/${companyId}`);
    } catch (err) {
      this.setState({ error: err });
    }
  };

  handleCheckbox = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const companyId = this.getCompanyIdFromProps(this.props);
    const {
      name,
      nys_education_law_does_not_apply,
      contactEmail,
      contactTitle,
      contactName,
      phone,
      address,
      error
    } = this.state;

    return (
      <div className="update-company-container">
        <CompanyForm
          error={error}
          companyId={companyId}
          name={name}
          nys_education_law_does_not_apply={nys_education_law_does_not_apply}
          handleChange={this.handleChange}
          handleCheckbox={this.handleCheckbox}
          contactEmail={contactEmail}
          contactName={contactName}
          contactTitle={contactTitle}
          phone={phone}
          address={address}
          saveCompany={this.saveCompany}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { companys: state.Company.companyDict };
};

const actions = { getCompany, updateCompany };

UpdateCompanyContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(UpdateCompanyContainer)
);

export default UpdateCompanyContainer;
