import apiInstance from "../utils/apiInstance";
import qs from "qs";
import moment from "moment";

export const searchForDistricts = (page, search) => async (dispatch, getState) => {
  const urlOptions = { page, search };
  const url = `districts?${qs.stringify(urlOptions)}`;
  const response = await apiInstance.get(url);

  dispatch({ type: "DISTRICT_SEARCH_RECEIVED", districts: response.data });
};

export const searchForDistrictInventory = (districtId, search, sortby, sortorder, archived, page) => async (
  dispatch,
  getState
) => {

  const urlOptions = { search: search, sortby: sortby, sortorder: sortorder, archived: archived, page };
  const url = `district-inventory/${districtId}?${qs.stringify(urlOptions)}`;
  const response = await apiInstance.get(url);

  const pagination = {
    current_page: response.data.current_page,
    last_page: response.data.last_page
  }

  if(pagination.current_page === 1) {
    dispatch({
      type: "DISTRICT_INVENTORY_RESULTS_RECEIVED",
      records: response.data.data,
      pagination,
      districtId
    });
  } else {
    dispatch({ type: "DISTRICT_INVENTORY_RESULTS_APPEND_RECEIVED",
      records: response.data.data,
      pagination,
      districtId
    });
  }
};

export const sortForDistrictInventory = (districtId, sortby, sortorder, page) => async (
  dispatch,
  getState
) => {

  const urlOptions = { sortby, sortorder, page };
  const url = `district-inventory/${districtId}?${qs.stringify(urlOptions)}`;
  const response = await apiInstance.get(url);

  const pagination = {
    current_page: response.data.current_page,
    last_page: response.data.last_page
  }

  if(pagination.current_page === 1) {
    dispatch({
      type: "DISTRICT_INVENTORY_RESULTS_RECEIVED",
      records: response.data.data,
      pagination,
      districtId
    });
  } else {
    dispatch({ type: "DISTRICT_INVENTORY_RESULTS_APPEND_RECEIVED",
      records: response.data.data,
      pagination,
      districtId
    });
  }
}

export const getDistrictInventory = (districtId) => async (
  dispatch,
  getState
) => {

  const urlOptions = { paginate: false };
  const url = `district-inventory/${districtId}?${qs.stringify(urlOptions)}`;
  const response = await apiInstance.get(url);

  const pagination = {
    current_page: 1,
    last_page: 1
  }

  dispatch({
    type: "DISTRICT_INVENTORY_RESULTS_RECEIVED",
    records: response.data,
    pagination,
    districtId
  });
}

export const requestDistrictExportDownload = async (type = "BASIC") => {
  const url = `export/token/${type}`;
  const response = await apiInstance.get(url);
  return response.data.url;
};

export const getDistrictInventoryPDF = (districtId, districtName = "", query, sortby, sortorder, archived) => async (
  dispatch,
  getState
) => {
  const url = `district-inventory/${districtId}/print-pdf`;
  const data = {
    query: query,
    sortby: sortby,
    sortorder: sortorder,
    archived: archived
  };
  const today = moment().format('YYYY-MM-DD');
  const response = await apiInstance.post(url, data, { responseType: 'blob' });
  const blob = new Blob([response.data], { type: 'application/pdf' });
  const pdfUrl = window.URL.createObjectURL(blob);
  let tempLink = document.createElement("a");
  tempLink.href = pdfUrl;
  tempLink.setAttribute('download', `${districtName}-inventory-${today}.pdf`);
  tempLink.click();
  return true;
}

export const createDistrict = (district) => async (
  dispatch,
  getState
) => {
  const url = `districts`;
  const data = {
    ...district
  };

  const creationResponse = await apiInstance.post(url, data);

  const response = await apiInstance.get(
    `districts/${creationResponse.data.id}`
  );

  dispatch({
    type: "DISTRICT_RECEIVED",
    districtId: response.data.id,
    district: response.data
  });

  return response.data;
};

export const deleteDistrict = districtId => async (dispatch, getState) => {
  const url = `districts/${districtId}`;

  await apiInstance.delete(url);

  dispatch({ type: "DISTRICT_DELETED", districtId });
};

export const getDistrict = districtId => async (dispatch, getState) => {
  const url = `districts/${districtId}`;
  const response = await apiInstance.get(url);

  dispatch({ type: "DISTRICT_RECEIVED", districtId, district: response.data });
};

export const updateDistrict = (districtId, district) => async (
  dispatch,
  getState
) => {
  const url = `districts/${districtId}`;
  const data = {
    ...district
  };

  const response = await apiInstance.put(url, data);

  dispatch({ type: "DISTRICT_RECEIVED", districtId, district: response.data });
};

export const updateDistrictInventoryRecord = (districtId, inventoryRecordId, record) => async (
    dispatch,
    getState
) => {
    const url = `inventory-records/${inventoryRecordId}`;
    const data = {
        ...record
    };

    const response = await apiInstance.put(url, data);

    dispatch({
        type: "DISTRICT_INVENTORY_RECORD_RECEIVED",
        districtId,
        inventoryRecordId,
        inventoryRecord: response.data
    });

    // Dont think we need this

    // dispatch({
    //     type: "INVENTORY_RECORD_RECEIVED",
    //     inventoryRecordId,
    //     inventoryRecord: response.data
    // });


    return response.data;
};

export const updateDistrictInventoryRecordBOR = (inventoryRecord, show_in_bor) => async (
    dispatch,
    getState
) => {
    const url = `inventory-records/${inventoryRecord.id}/BOR`;
    const data = {show_in_bor};

    const response = await apiInstance.put(url, data);

    dispatch({
      type: "DISTRICT_INVENTORY_RECORD_RECEIVED",
      districtId: response.data.district_id,
      inventoryRecordId: response.data.id,
      inventoryRecord: response.data
    });
};

export const updateDistrictInventoryRecordArchived = (inventoryRecord, archived) => async (
  dispatch,
  getState
) => {
  const url = `inventory-records/${inventoryRecord.id}/archive`;
  const data = {archived};

  const response = await apiInstance.put(url, data);

  dispatch({
    type: "DISTRICT_INVENTORY_RECORD_RECEIVED",
    districtId: response.data.district_id,
    inventoryRecordId: response.data.id,
    inventoryRecord: response.data
  });
}

export const updateDistrictInventoryRecordContract = (inventoryRecordId, data) => async (
  dispatch,
  getState
) => {
  const url = `inventory-records/${inventoryRecordId}/contract`;
  const response = await apiInstance.put(url, data);

  dispatch({
    type: "DISTRICT_INVENTORY_RECORD_RECEIVED",
    districtId: response.data.district_id,
    inventoryRecordId: response.data.id,
    inventoryRecord: response.data
  });

  return response.data;
};

export const updateDistrictInventoryRecordAttachmentBOR = (attachment, show_in_bor) => async (
  dispatch,
  getState
) => {
  const url = `inventory-records/${attachment.inventory_record_id}/attachment/${attachment.id}/BOR`;
  const data = {show_in_bor};

  const response = await apiInstance.put(url, data);

  dispatch({
    type: "DISTRICT_INVENTORY_RECORD_RECEIVED",
    districtId: response.data.district_id,
    inventoryRecordId: response.data.id,
    inventoryRecord: response.data
  });
};

export const createDistrictInventoryRecordAttachment = (attachment, inventoryRecord) => async (
  dispatch,
  getState
) => {
  const url = `inventory-records/${inventoryRecord.id}/attachment`;
  const data = {
    uri: attachment.uri,
    name: attachment.name,
    description: attachment.description,
    show_in_bor: attachment.show_in_bor,
    type: attachment.type,
  };;

  const response = await apiInstance.post(url, data);

  dispatch({
    type: "DISTRICT_INVENTORY_RECORD_RECEIVED",
    districtId: response.data.district_id,
    inventoryRecordId: response.data.id,
    inventoryRecord: response.data
  });
}

export const updateDistrictInventoryRecordAttachment = (attachment, inventoryRecord) => async (
  dispatch,
  getState
) => {
  const url = `inventory-records/${inventoryRecord.id}/attachment/${attachment.id}`;
  const data = {
    uri: attachment.uri,
    name: attachment.name,
    description: attachment.description,
    show_in_bor: attachment.show_in_bor,
    type: attachment.type,
    archived: attachment.archived
  };

  const response = await apiInstance.put(url, data);

  dispatch({
    type: "DISTRICT_INVENTORY_RECORD_RECEIVED",
    districtId: response.data.district_id,
    inventoryRecordId: response.data.id,
    inventoryRecord: response.data
  });
}

export const removeDistrictInventoryRecordAttachment = (attachment, inventoryRecord) => async (
  dispatch,
  getState
) => {
  const url = `inventory-records/${inventoryRecord.id}/attachment/${attachment.id}`;

  const response = await apiInstance.delete(url);

  dispatch({
    type: "DISTRICT_INVENTORY_RECORD_RECEIVED",
    districtId: response.data.district_id,
    inventoryRecordId: response.data.id,
    inventoryRecord: response.data
  });
}

export const updateDistrictDataAdminProducts = (districtId, adminId, records) => async (
  dispatch,
  getState
) => {

  const url = `district-inventory/${districtId}/update-data-admin-products/${adminId}`;
  const data = {
    records
  };

  const response = await apiInstance.put(url, data);

  const pagination = {
    current_page: 1,
    last_page: 1
  }

  if(response.status === 200) {
    const message = "Successfully updated inventory records";
    dispatch({
      type: "DISTRICT_INVENTORY_RESULTS_RECEIVED",
      records: response.data,
      pagination,
      districtId,
      message
    });
  }
}

export const impersonateDistrictAdmin = (districtId) => async (
    dispatch,
    getState
) => {

  const url = `districts/${districtId}/impersonate`;

  const response = await apiInstance.get(url);

  if (response.status === 200) {
    window.location.href = '/products';
  }
}

export const restoreImpersonatingRegionAdmin = () => async (
    dispatch,
    getState
) => {

  const url = 'districts/restore-original-session';

  const response = await apiInstance.get(url);

  if (response.status === 200) {
    window.location.href = '/districts';
  }
}