import React, { Component } from "react";
import { connect } from "react-redux";
import DistrictSelector
  from "../Common/DistrictSelector/DistrictSelectorContainer";
import PageHeader from "../Common/PageHeader";
import { addProductToInventoryRoute } from "../../utils/paths";

class ProductDistrictSelector extends Component {
  onSelect = district => {
    const { productId } = this.props.match.params;
    const newPath = addProductToInventoryRoute(productId, district.id);
    this.props.history.push(newPath);
  };

  render() {
    return (
      <div>
        <PageHeader title="Products" subtitle="Choose a District" />
        <DistrictSelector onSelect={this.onSelect} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = {};

ProductDistrictSelector = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(ProductDistrictSelector);

export default ProductDistrictSelector;
