import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getUser, deleteUser } from "../../../actions/User";

import SingleUser from "./SingleUser";

class SingleUserContainer extends Component {
  getUserIdFromProps(props) {
    const { match } = props;
    const userId = match.params.userId;
    return userId ? parseInt(userId, 10) : undefined;
  }

  async componentDidMount() {
    const userId = this.getUserIdFromProps(this.props);
    await this.props.getUser(userId);
  }

  async componentWillReceiveProps(nextProps) {
    const oldId = this.getUserIdFromProps(this.props);
    const newId = this.getUserIdFromProps(nextProps);

    if (oldId !== newId) {
      await this.props.getUser(newId);
    }
  }

  deleteUser = async userId => {
    await this.props.deleteUser(userId);
    this.props.history.push("/users");
  };

  render() {
    const userId = this.getUserIdFromProps(this.props);
    const user = this.props.users[userId];
    if (!user) return <div>Loading...</div>;

    return (
      <SingleUser user={user} deleteUser={this.deleteUser} />
    );
  }
}

const mapStateToProps = state => {
  return { users: state.User.userDict};
};

const actions = { getUser, deleteUser };

SingleUserContainer = withRouter(
  connect(
    mapStateToProps, // connect component props to application (redux) state
    actions // these actions are automatically wrapped in a dispatch by connect
  )(SingleUserContainer)
);

export default SingleUserContainer;
