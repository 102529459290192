import React from "react";
import DistrictRow from "./DistrictRow";
import PageHeader from "../../Common/PageHeader";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import Search from "../../Common/Search";

const DistrictList = ({
  districts,
  currentPage,
  changePage,
  totalPages,
  deleteDistrict,
                        performSearch,
                        query,
                        updateQuery,
    isRegionAdmin,
    impersonateDistrictAdmin,
}) => {
  const districtRows = districts
    ? districts.map(district => (
        <DistrictRow
          key={district.id}
          district={district}
          deleteDistrict={deleteDistrict}
          isRegionAdmin={isRegionAdmin}
          impersonateDistrictAdmin={impersonateDistrictAdmin}
        />
      ))
    : null;

  const paginator = totalPages > 1
    ? <Pagination
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        items={totalPages}
        maxButtons={5}
        activePage={currentPage}
        onSelect={changePage}
      />
    : null;

  return (
    <div className="district-list-container">
      <PageHeader title="Districts" subtitle="District Listing" />
      <div className="row">
        <div className="col-sm-12 text-right">
          <Search query={query} updateQuery={updateQuery} performSearch={performSearch} />
          <Link to="/districts/create" className="btn btn-success add-product-btn">
            Add District
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Region</th>
                  <th>District</th>
                  <th>Supplemental Information</th>
                  <th>District Inventory</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {districtRows}
              </tbody>
            </table>
          </div>
          {paginator}
        </div>
      </div>
    </div>
  );
};

export default DistrictList;
