import React from "react";
import ErrorAlert from "../Common/ErrorAlert";

const LoginForm = ({
  email,
  password,
  updateEmail,
  updatePassword,
  submitLogin,
  forgotPasswordPageUrl,
  error,
  faqUrl
}) =>
  <div className="row">
    <div className="col-sm-4 col-sm-offset-4">
      <div className="panel panel-primary login-panel">
        <div className="panel-heading">Log In</div>
        <div className="panel-body">
          <form
            onSubmit={e => {
              e.preventDefault();
              submitLogin(email, password);
            }}
          >
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                placeholder="user@example.com"
                name="username"
                className="form-control"
                type="text"
                value={email}
                onChange={e => {
                  e.preventDefault();
                  updateEmail(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                id="=password"
                name="password"
                className="form-control"
                type="password"
                value={password}
                onChange={e => {
                  e.preventDefault();
                  updatePassword(e.target.value);
                }}
              />
            </div>
            {error
              ? <div className="row">
                  <div className="col-sm-12">
                    <ErrorAlert error={error} />
                  </div>
                </div>
              : null}
            <div className="form-group form-submit">
              <button
                name="Log In"
                className="btn btn-primary btn-block"
                type="submit"
              >
                Login
              </button>
            </div>
            <div className="form-group">
              <a
                href={forgotPasswordPageUrl}
                className="btn btn btn-default btn-block"
              >
                Forgot Password
              </a>
            </div>
            <div className="form-group">
              <a href={faqUrl}>
                FAQ
              </a>
              <p style={{margin: '10px 0', fontWeight: 600}}>
                The DPSS Inventory Tool is optimized for the Google Chrome, Mozilla Firefox, and Microsoft Edge web browsers.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>;

export default LoginForm;
