import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import SingleCompanyContainer from "./SingleCompany/SingleCompanyContainer";
import UpdateCompanyContainer from "./CompanyEditor/UpdateCompanyContainer";
import NewCompanyContainer from "./CompanyEditor/NewCompanyContainer";
import CompanyListContainer from "./CompanyList/CompanyListContainer";

class CompanyContainer extends Component {
  render() {
    const { match } = this.props;

    return (
      <div className="company-container">
        <Switch>
          <Route path={`${match.url}/create`} component={NewCompanyContainer} />
          <Route
            path={`${match.url}/:companyId/update`}
            component={UpdateCompanyContainer}
          />
          <Route
            path={`${match.url}/:companyId`}
            component={SingleCompanyContainer}
          />
          <Route path={match.url} component={CompanyListContainer} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const actions = {};

CompanyContainer = connect(
  mapStateToProps, // connect component props to application (redux) state
  actions // these actions are automatically wrapped in a dispatch by connect
)(CompanyContainer);

export default CompanyContainer;
